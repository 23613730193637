const MapRouteTitle = location => {
  const { pathname } = location;

  function locate(route) {
    return pathname.indexOf(route) !== -1;
  }

  if (locate('home')) {
    return 'Home';
  }
  if (locate('login')) {
    return 'Login';
  }
  if (locate('events')) {
    return 'Events';
  }
  if (locate('coupons')) {
    return 'Coupons';
  }
  if (locate('managers')) {
    return 'Managers';
  }
  if (locate('products')) {
    return 'Products';
  }
  if (locate('registrations')) {
    return 'Registrations';
  }

  return 'Home';
};

export default MapRouteTitle;
