import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { find, memoize, orderBy } from 'lodash';
import { Grid } from '@material-ui/core';

class TicketPurchasing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableInstallments: []
    };
  }

  /* Need to run this on mount in case we're loading in information from the cart */
  componentDidMount() {
    this.updatePurchasePlans();
  }

  componentDidUpdate() {
    const { tickets, purchasePlans, eventId } = this.props;
    this.updatePurchasePlans(tickets, purchasePlans, eventId);
  }

  updatePurchasePlans = memoize(
    (tickets, purchasePlans, eventId) => {
      // Don't do anything if tickets aren't defined.
      if (!tickets) {
        return;
      }
      // Make a list of purchase plans for this event and that have tickets for this event.
      const validPurchasePlans = purchasePlans.filter(plan => {
        /* If an event ID is defined, it must match. */
        if (plan.eventId) {
          return plan.eventId === eventId && tickets[plan.productId] > 0;
        }
        return tickets[plan.productId] > 0;
      });

      /* Create an object organized by product id specifying how many installments are available for
       each ticket type. */
      const ticketsList = {};
      Object.keys(tickets).forEach(ticket => {
        if (tickets[ticket] > 0) {
          ticketsList[ticket] = validPurchasePlans.reduce((list, plan) => {
            if (plan.productId.toString() === ticket) {
              list.push(plan.installments);
            }
            return list;
          }, []);
        }
      });

      /* Make a list of plans from the list of previously sorted plans. This list should only
    contain plans that have installments for all products and if a plan with the same number
    of installments isn't in the list. */
      const filteredInstallments = validPurchasePlans.reduce((installments, plan) => {
        if (
          Object.values(ticketsList).every(item => item.includes(plan.installments)) &&
          installments.every(p => p.installments !== plan.installments)
        ) {
          installments.push(plan);
        }
        return installments;
      }, []);
      this.setState({
        availableInstallments: orderBy(filteredInstallments, 'name')
      });
    },
    (tickets, purchasePlans, eventId) => {
      return (
        (tickets ? Object.keys(tickets).join('') + Object.values(tickets).join('') : '') +
        (purchasePlans ? purchasePlans.map(plan => plan.id).join('') : '') +
        eventId
      );
    }
  );

  render() {
    const {
      products,
      selectedPurchasePlan,
      tickets,
      updatePurchasePlan,
      classes,
      displayOnly
    } = this.props;
    return (
      <div>
        <Typography variant="subheading" className={classes.subheading}>
          Ticket Purchasing Information
        </Typography>
        <Grid container spacing={16}>
          {tickets &&
            products.length &&
            products.map(product => {
              const purchasePlan = find(product.purchasePlans, {
                installments: selectedPurchasePlan
              });
              return (
                <Grid item xs={12} sm={2} key={product.id}>
                  <FormControl className={classes.formControl} style={{ minWidth: 200 }}>
                    <TextValidator
                      label={`# of ${product.name}s`}
                      name={product.name}
                      type="text"
                      margin="normal"
                      value={tickets[product.id]}
                      onChange={e => {
                        this.props.updateTickets(
                          product.id,
                          e.target.value,
                          this.updatePurchasePlans
                        );
                      }}
                      helperText={product.description}
                      validators={['minTickets', 'maxTickets']}
                      errorMessages={[
                        'At least one ticket must be selected.',
                        purchasePlan && purchasePlan.limit
                          ? `You may only purchase ${purchasePlan.limit} tickets.`
                          : 'You have selected more than the maximum number of tickets'
                      ]}
                      disabled={displayOnly}
                    />
                    <br />
                  </FormControl>
                </Grid>
              );
            })}
          <Grid item xs={12} sm={2}>
            <FormControl className={classes.formControl} style={{ marginTop: '1rem' }}>
              <SelectValidator
                label="Payment Plan"
                name="Payment Plan"
                validators={['required']}
                errorMessages={['A payment plan must be selected.']}
                onChange={e => updatePurchasePlan(e.target.value)}
                value={selectedPurchasePlan}
                disabled={displayOnly}
              >
                {this.state.availableInstallments &&
                  this.state.availableInstallments.map(plan => {
                    return (
                      <MenuItem key={plan.installments} value={plan.installments}>
                        {plan.name && `${plan.name} - `}
                        {`${plan.installments} Installment(s) Plan`}
                      </MenuItem>
                    );
                  })}
              </SelectValidator>
            </FormControl>
          </Grid> 
          
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs>
            <h4>Current Total:</h4>
            <p>{this.props.currentTotal}</p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  formControl: {
    minWidth: 140
  },
  subheading: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem'
  }
});

TicketPurchasing.defaultProps = {
  displayOnly: false,
  currentTotal: '0'
};

TicketPurchasing.propTypes = {
  classes: PropTypes.object.isRequired,

  eventId: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  selectedPurchasePlan: PropTypes.number.isRequired,
  purchasePlans: PropTypes.array.isRequired,
  tickets: PropTypes.object.isRequired,
  currentTotal: PropTypes.string,
  displayOnly: PropTypes.bool,

  updateTickets: PropTypes.func.isRequired,
  updatePurchasePlan: PropTypes.func.isRequired
};

export default withStyles(styles)(TicketPurchasing);
