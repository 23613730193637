import Immutable from 'immutable';
import { findIndex, isEmpty } from 'lodash';
import {
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAILURE,
  CREATE_COUPON_SUCCESS,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAILURE,
  SET_SELECTED_COUPON,
  UPDATE_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  CLEAR_SELECTED_COUPON
} from './coupons.actions';

const initialState = Immutable.Map({
  coupons: [],
  selectedCoupon: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COUPONS_SUCCESS: {
      return state.set('coupons', action.response.data).set('isLoading', false);
    }

    case GET_COUPONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_COUPONS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CREATE_COUPON_SUCCESS: {
      const coupons = [...state.get('coupons')];
      const newCoupon = { ...action.response };
      coupons.push(newCoupon);

      return state.set('coupons', coupons).set('isLoading', false);
    }

    case GET_COUPON_SUCCESS:
      return state.set('selectedCoupon', action.response).set('isLoading', false);

    case GET_COUPON_FAILURE:
      return state.set('isLoading', false);

    case GET_COUPON_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_COUPON:
      return state.set('selectedCoupon', action.response);

    case CLEAR_SELECTED_COUPON:
      return state.set('selectedCoupon', null);

    case UPDATE_COUPON_SUCCESS: {
      let selectedCoupon = { ...state.get('selectedCoupon') };
      const coupons = [...state.get('coupons')];
      const couponId = action.actionMetadata;

      if (!isEmpty(selectedCoupon) && selectedCoupon.id === couponId) {
        selectedCoupon = Object.assign(selectedCoupon, action.payload);
      }

      const idx = findIndex(coupons, { id: couponId });
      if (idx > -1) {
        coupons[idx] = Object.assign(coupons[idx], action.payload);
      }

      return state
        .set('selectedCoupon', selectedCoupon)
        .set('coupons', coupons)
        .set('isLoading', false);
    }

    case DELETE_COUPON_SUCCESS: {
      const coupons = [...state.get('coupons')];

      const idx = findIndex(coupons, { id: action.actionMetadata.couponId });
      if (idx > -1) {
        coupons.splice(idx, 1);
      }

      return state.set('coupons', coupons).set('isLoading', false);
    }

    default:
      return state;
  }
}
