import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const EmptyState = ({ classes, text }) => (
  <div className={classes.wrapper}>
    <h2>{text}</h2>
  </div>
);

const styles = (/* theme */) => ({
  wrapper: {
    width: '100%',
    margin: '1rem 0',
    textAlign: 'center'
  }
});

EmptyState.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

export default withStyles(styles)(EmptyState);
