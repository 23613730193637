import { IS_ADMIN, IS_AGENCY_ADMIN } from '../login/permissions';

const EVENTS = {
  route: '/events',
  name: 'Events'
};

const NOTIFICATIONS = {
  route: '/notifications',
  name: 'Notifications'
};

const BIG_FIVE = {
  route: '/big5',
  name: 'Big 5',
  permissions: [IS_ADMIN]
};

const PRODUCTS = {
  route: '/products',
  name: 'Products',
  permissions: [IS_ADMIN]
};

const PURCHASE_PLANS = {
  route: '/purchasePlans',
  name: 'Purchase Plans'
};

const COUPONS = {
  route: '/coupons',
  name: 'Coupons'
};

const STRIPE = {
  route: 'https://dashboard.stripe.com',
  name: 'Stripe',
  permissions: [IS_ADMIN]
};

const AGENCY_STRIPE = {
  route: '/stripe',
  name: 'Stripe',
  permissions: [IS_AGENCY_ADMIN]
};

const MY_TICKETS = {
  route: '/toggleView',
  name: 'My Tickets',
  permissions: [IS_AGENCY_ADMIN],
  highlight: true
};

const LOGOUT = {
  route: '/logout',
  name: 'Logout'
};

export default [
  EVENTS,
  NOTIFICATIONS,
  BIG_FIVE,
  PRODUCTS,
  PURCHASE_PLANS,
  COUPONS,
  STRIPE,
  AGENCY_STRIPE,
  MY_TICKETS,
  LOGOUT
];
