import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import firebase from 'firebase/app';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { withRouter } from 'react-router-dom';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';

import EMAIL_ADDRESSES from '../../types/emailAddresses';
import ERROR_MESSAGES from '../../types/errorMessages';
import LoadingOverlay from '../layout/loadingOverlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import SUCCESS_MESSAGES from '../../types/successMessages';
import loginHandler from '../../utilities/loginHandler';
import {
  CHECK_USER_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  checkUser,
  loginAuth0,
  resetPassword,
  setUserEmail,
} from './login.actions';
import { getIsUserEmailVerified } from '../../utilities/loginUtils';
import { handleToastMessage } from '../layout/layout.actions';

const LOGIN_TYPE = {
  AUTH_0: 'auth0',
  FIREBASE_EMAIL: 'firebase',
};

const INITIAL_STATE = {
  password: '',
  forgotPasswordDialogOpen: false,
  accountNotFoundDialogOpen: false,
  loginType: null,
  hasLoggedIn: false,
};

class Login extends Component {
  state = {
    ...INITIAL_STATE,
  };

  forgotPassword = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('Are you sure you want to reset your password?')) return;

    const response = await this.props.resetPassword(this.props.email);
    if (response.type !== RESET_PASSWORD_SUCCESS) {
      this.props.handleToastMessage(ERROR_MESSAGES.RESET_PASSWORD_FAILURE, true);
      return;
    }

    this.props.handleToastMessage(SUCCESS_MESSAGES.RESET_PASSWORD_SUCCESS);
  };

  checkEmail = async () => {
    const request = await this.props.checkUser(this.props.email);
    if (request.type !== CHECK_USER_SUCCESS) return;

    if (request.response.found) {
      loginHandler.auth0.loginWithRedirect({
        login_hint: this.props.email,
      });
    } else if (request.response.foundFirebase) {
      this.setState({ loginType: LOGIN_TYPE.FIREBASE_EMAIL });
      /* Set the focus on the password field */
      document.getElementsByName('password')[0].focus();
    } else {
      this.setState({
        accountNotFoundDialogOpen: true,
      });
    }
  };

  closeForgotPasswordDialog = () => {
    this.setState({ forgotPasswordDialogOpen: false });
  };

  closeAccountNotFoundDialog = () => {
    this.setState({ accountNotFoundDialogOpen: false });
  };

  updateField = (field, value) => {
    const state = { ...this.state };
    state[field] = value;
    this.setState(state);
  };

  // If the user presses enter, click on the get started button.
  // note: this also happens even when the user has been found, but there is no longer
  // a getStartedBtn, so no action takes place.
  listenForEnter = e => {
    if (e.key === 'Enter') {
      const elem = document.getElementById('getStartedBtn');
      if (elem) elem.click();
    }
  };

  onSubmit = async () => {
    const { loginType, password } = this.state;
    const { email } = this.props;
    let loginSuccess = false;
    if (loginType === LOGIN_TYPE.FIREBASE_EMAIL) {
      try {
        /* Trigger the firebase login */
        await firebase.auth().signInWithEmailAndPassword(email, password);
        this.setState({ hasLoggedIn: true });
        if (await getIsUserEmailVerified()) {
          this.props.setUserEmail(email);
          loginSuccess = true;
        }
      } catch (error) {
        this.setState({ hasLoggedIn: false });
        console.log('ERROR', error.message);
        this.props.handleToastMessage(
          `Email address or password is incorrect. Please try again.`,
          true
        );
      }
    }
    if (loginSuccess) {
      const redirectURL = new URLSearchParams(this.props.location.search).get('redirectURL');
      if (redirectURL) {
        // allow time for main.layout to re-render with the new routes component
        setTimeout(() => {
          this.props.history.push(redirectURL);
        }, 100);
      }
    }
  };

  render() {
    const { password, loginType } = this.state;
    const { classes, isLoading, setEmail, email } = this.props;

    return (
      <ValidatorForm onSubmit={this.onSubmit}>
        <TextValidator
          label="Email Address"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          margin="normal"
          validators={['required', 'isEmail']}
          errorMessages={['Email address is required', 'Must enter a valid email address.']}
          onKeyPress={this.listenForEnter}
          fullWidth
          className={classes.spacerBottom}
          disabled={!isNil(loginType)}
          autoFocus
          helperText={
            isNil(loginType)
              ? 'Are you an agent? Please make sure to use your QuilityHQ email!'
              : ''
          }
        />
        {!loginType && (
          <div className={classes.spacerBottom}>
            <SFGOutlinedButton
              id="getStartedBtn"
              className="button"
              color="primary"
              disabled={email.length === 0 || isLoading}
              fullWidth
              onClick={this.checkEmail}
            >
              Get Started
            </SFGOutlinedButton>
          </div>
        )}
        {loginType === LOGIN_TYPE.FIREBASE_EMAIL && (
          // eslint-disable-next-line react/jsx-fragments
          <Fragment>
            <TextValidator
              label="Password"
              name="password"
              value={password}
              onChange={e => this.updateField('password', e.target.value)}
              type="password"
              margin="normal"
              validators={['required']}
              errorMessages={['Password is required']}
              fullWidth
              className={classes.spacerBottom}
            />
            <div className={classes.spacerBottom}>
              <SFGOutlinedButton
                className="button"
                color="primary"
                disabled={
                  this.props.email.length === 0 || this.state.password.length === 0 || isLoading
                }
                fullWidth
                type="submit"
              >
                Login
              </SFGOutlinedButton>
            </div>

            {loginType === LOGIN_TYPE.FIREBASE_EMAIL && (
              <div className={classes.spacerBottom}>
                <SFGOutlinedButton className="button" fullWidth onClick={this.forgotPassword}>
                  I Don&apos;t Know My Password
                </SFGOutlinedButton>
              </div>
            )}
            {isLoading && <LoadingOverlay />}
          </Fragment>
        )}

        <div className={classes.helpWrapper}>
          {loginType != null && (
            <SFGTextButton
              className={classes.spacerBottom}
              onClick={() => {
                this.setState({ ...INITIAL_STATE });
              }}
            >
              Try a different email?
            </SFGTextButton>
          )}
          {/* SFGCR-1742: No longer allow users to create account */}
          {/* {loginType === null && (
            <SFGTextButton className={classes.spacerBottom} onClick={this.props.showCreateAccount}>
              Create An Account
            </SFGTextButton>
          )} */}
          <a
            className={classNames('button', classes.mailTo)}
            href={`mailto:${EMAIL_ADDRESSES.SUPPORT}?subject=Help logging in`}
          >
            Trouble Logging In?
          </a>
        </div>

        <Dialog
          open={this.state.forgotPasswordDialogOpen}
          onClose={this.closeForgotPasswordDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">A Password Reset Email Has Been Sent!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Please check your email for a password reset email. If you have not received this,
              please email ${EMAIL_ADDRESSES.SUPPORT} Your username is the email that was used at the
              time of Purchase OR Assignment to a ticket`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SFGOutlinedButton
              autoFocus
              className="button"
              onClick={this.closeForgotPasswordDialog}
            >
              OKAY
            </SFGOutlinedButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.accountNotFoundDialogOpen}
          onClose={this.closeAccountNotFoundDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Account not found</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We could not find an account with your email address. Would you like to create an
              account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SFGOutlinedButton className="button" onClick={this.closeAccountNotFoundDialog}>
              CANCEL
            </SFGOutlinedButton>
            <SFGOutlinedButton
              autoFocus
              className="button"
              onClick={this.props.showCreateAccount}
              color="primary"
            >
              CREATE ACCOUNT
            </SFGOutlinedButton>
          </DialogActions>
        </Dialog>
      </ValidatorForm>
    );
  }
}

const styles = theme => ({
  spacerBottom: {
    marginBottom: '1rem',
  },
  helpWrapper: {
    marginTop: '4rem',
  },
  mailTo: {
    display: 'block',
    color: theme.palette.primary.main,
  },
});

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,

  setEmail: PropTypes.func.isRequired,
  showCreateAccount: PropTypes.func.isRequired,
  checkUser: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setUserEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,

  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

Login.defaultProps = {
  email: '',
};

const mapStateToProps = state => {
  return {
    isLoading: state.login.get('isLoading'),
  };
};

const routedLogin = withRouter(
  connect(mapStateToProps, {
    checkUser,
    loginAuth0,
    handleToastMessage,
    setUserEmail,
    resetPassword,
  })(Login)
);

export default withStyles(styles)(routedLogin);
