import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import EventForm from './eventForm.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { UPDATE_EVENT_SUCCESS, clearSelectedEvent, getEvent, updateEvent } from './events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EventEditContainer extends Component {
  componentDidMount() {
    this.props.setPageTitle('Edit Event');
    this.props.clearSelectedEvent();
    this.props.getEvent(this.props.match.params.id);
  }

  handleEdit = async eventData => {
    const response = await this.props.updateEvent(this.props.selectedEvent.id, eventData);

    if (response.type === UPDATE_EVENT_SUCCESS) {
      this.props.handleToastMessage('Event updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update event.', true);
    }
  };

  render() {
    const { agencies, selectedEvent, isLoading } = this.props;

    return (
      <div className="container">
        {isLoading && isEmpty(selectedEvent) && <LoadingOverlay />}

        {!isEmpty(selectedEvent) && (
          <EventForm event={selectedEvent} submitAction={this.handleEdit} agencies={agencies} />
        )}
      </div>
    );
  }
}

EventEditContainer.defaultProps = {
  selectedEvent: null,
};

EventEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  agencies: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  clearSelectedEvent: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    agencies: state.bigFive.get('agencies'),
    selectedEvent: state.events.get('selectedEvent'),
    isLoading: state.events.get('isLoading'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    clearSelectedEvent,
    getEvent,
    updateEvent,
    handleToastMessage,
  })(EventEditContainer)
);
