/* eslint-disable react/jsx-fragments */
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import memoize from 'memoize-one';
import {
  Check,
  Close,
  MoreVert as MoreVertIcon,
  HelpOutline as Question,
} from '@material-ui/icons';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { findIndex, isEmpty, sortBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import CustomSimpleMenu from '../../layout/customSimpleMenu/customSimpleMenu.component';
import EventHelpDeskBreakoutSessions from './eventHelpDeskBreakoutSessions.component';
import EventHelpDeskCheckInForm from './eventHelpDeskCheckInForm.component';
import EventHelpDeskLinkVueForm from './eventHelpDeskLinkVueForm.component';
import MaxWidthDialog from '../../layout/dialogs/maxWidthDialog.component';
import NameUtils from '../../../utilities/nameUtils';
import { isEventFinished } from '../../../utilities/eventStatus';

const initVueFormState = {
  isValid: false,
  emailAddress: '',
  confirmEmailAddress: '',
};

const initCheckInFormData = {
  responses: {},
  isValid: false,
};

const RECORD_TYPES = {
  ATTENDEE: 'Attendee',
  PURCHASER: 'Purchaser',
  ATTENDEE_AND_PURCHASER: 'Attendee/Purchaser',
};

class EventHelpDeskResultTable extends Component {
  /* Memoize this function so it only runs if attendees or purchases are different from last time */
  prepareEntries = memoize((attendees, purchases) => {
    let entries = [];
    if (attendees.length) {
      entries = attendees;
      if (purchases.length) {
        const listOfEmails = [];

        const filteredList = purchases.filter(p => {
          if (listOfEmails.includes(p.email)) {
            return false;
          }
          listOfEmails.push(p.email);
          return findIndex(attendees, { email: p.email }) === -1;
        });
        entries.push(...filteredList);
        entries = sortBy(entries, [e => ('name' in e ? e.name : e.firstName)]);
      }
    } else if (purchases.length) {
      const listOfPeople = [];

      entries = purchases.filter(p => {
        const person = { firstName: p.firstName, lastName: p.lastName, email: p.email };
        if (findIndex(listOfPeople, person) > -1) {
          return false;
        }
        listOfPeople.push(person);
        return true;
      });
    }
    return entries;
  });

  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      page: 0,
      showLinkToVue: false,
      showCheckInDialog: false,
      showBreakoutSessions: false,
      linkVueFormData: initVueFormState,
      checkInFormData: initCheckInFormData,
      selectedAttendee: {},
    };
  }

  getRowActionMenuItems = (attendee, attendeePurchases) => {
    const eventComplete = isEventFinished(this.props.event);
    const hasAssignedTicket = 'name' in attendee;
    return [
      {
        text: 'Breakout Sessions',
        action: (/* e */) => {
          this.setState({ showBreakoutSessions: true, selectedAttendee: attendee });
        },
        disabled: !hasAssignedTicket,
      },
      {
        text: `Check In${eventComplete === false ? '' : ' (Event is Finished)'}`,
        action: (/* e */) => {
          this.setState({ showCheckInDialog: true, selectedAttendee: attendee });
        },
        disabled: eventComplete === true || !hasAssignedTicket,
      },
      {
        text: 'Link to Quility Account',
        action: (/* e */) => {
          this.setState({ showLinkToVue: true, selectedAttendee: attendee });
        },
        disabled: !isEmpty(attendee.vue) || !hasAssignedTicket,
      },
      {
        text: 'Manage Purchases',
        action: (/* e */) => {
          this.props.history.push(
            `/events/${this.props.event.id}/helpDesk/purchases/${attendee.email}`,
            {
              purchases: attendeePurchases,
            }
          );
        },
        disabled: attendeePurchases.length === 0,
      },
      {
        text: 'Ticket Assignment',
        action: (/* e */) => {
          // window.open(`/eventAttendees/${attendee.id}`, '_blank');
          this.props.history.push(`/eventAttendees/${attendee.id}`);
        },
        disabled: !hasAssignedTicket,
      },
      {
        text: 'Clear Ticket Assignment',
        action: (/* e */) => {
          this.props.clearTicketAction(attendee);
        },
        disabled: !hasAssignedTicket,
      },
      {
        text: 'Resend Ticket Assignment Email',
        action: (/* e */) => {
          this.props.resendTicketEmailAction(attendee);
        },
        disabled: !hasAssignedTicket,
      },
    ];
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleVueFormUpdate = updateObj => {
    this.setState(prevState => ({
      ...prevState, // JM: I don't think we need this line
      linkVueFormData: {
        ...prevState.linkVueFormData,
        ...updateObj,
      },
    }));
  };

  handleCheckInFormUpdate = updateObj => {
    this.setState(prevState => ({
      ...prevState, // JM: I don't think we need this line
      checkInFormData: {
        ...prevState.checkInFormData,
        ...updateObj,
      },
    }));
  };

  handleLinkToVue = () => {
    this.props.linkVueEmail(
      this.state.linkVueFormData.emailAddress,
      this.state.selectedAttendee.email
    );
    /*  Clear the state */
    this.setState({
      showLinkToVue: false,
      selectedAttendee: {},
      linkVueFormData: initVueFormState,
    });
  };

  handleCheckIn = () => {
    this.props.handleCheckIn(
      this.props.event.id,
      this.state.checkInFormData.responses,
      this.state.selectedAttendee.email
    );
    /*  Clear the state */
    this.setState({ showCheckInDialog: false, checkInFormData: initCheckInFormData });
  };

  handleCloseDialog = dialogIsOpenKey => {
    const updateState = {};
    updateState[dialogIsOpenKey] = false;
    this.setState(updateState);
  };

  allTransactionsComplete = attendee => {
    const allTheseComplete =
      attendee.transactions.length ===
      attendee.transactions.filter(t => t.status === 'COMPLETE').length;
    if (!allTheseComplete) return false;
    const otherPurchases = this.props.purchases.filter(p => p.email === attendee.email);
    if (otherPurchases.length < 2) return allTheseComplete;

    return otherPurchases.reduce((areComplete, p) => {
      if (areComplete === false) return false;
      return p.transactions.length === p.transactions.filter(t => t.status === 'COMPLETE').length;
    }, true);
  };

  render() {
    const {
      rowsPerPage,
      page,
      showLinkToVue,
      showCheckInDialog,
      linkVueFormData,
      selectedAttendee,
      checkInFormData,
      showBreakoutSessions,
    } = this.state;
    const { attendees, event, purchases } = this.props;
    const entries = this.prepareEntries(attendees, purchases);
    return (
      <Fragment>
        {entries.length > 0 && (
          <Fragment>
            <Table style={{ paddingTop: '1rem' }}>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Name</TableCell>
                  <TableCell width="25%">Email</TableCell>
                  <TableCell width="10%">Record Type</TableCell>
                  <TableCell width="10%">Ticket Type</TableCell>
                  <TableCell width="15%">Paid in Full</TableCell>
                  <TableCell width="10%">Linked to Vue</TableCell>
                  <TableCell width="10%" style={{ textAlign: 'center' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(attendee => {
                    const hasAssignedTicket = 'name' in attendee;
                    let recordType = RECORD_TYPES.ATTENDEE;
                    let { name } = attendee;
                    let paidInFull = attendee.delinquent === false;
                    const attendeePurchases = purchases.filter(p => p.email === attendee.email);
                    if (!hasAssignedTicket) {
                      recordType = RECORD_TYPES.PURCHASER;

                      name = NameUtils.getFormattedName(attendee);
                      /* If all of the transactions are complete, they have paid in full */
                      paidInFull = this.allTransactionsComplete(attendee);
                    } else if (attendeePurchases.length) {
                      recordType = RECORD_TYPES.ATTENDEE_AND_PURCHASER;
                    }

                    let ticketType = 'N/A';
                    if (attendee.ticket && attendee.ticket.product) {
                      ticketType = attendee.ticket.product.name;
                    }

                    const actionMenuItems = this.getRowActionMenuItems(attendee, attendeePurchases);
                    return (
                      <TableRow key={attendee.id}>
                        <TableCell>{name}</TableCell>
                        <TableCell>{attendee.email}</TableCell>
                        <TableCell>{recordType}</TableCell>
                        <TableCell>{ticketType}</TableCell>
                        <TableCell>{paidInFull ? <Check /> : <Close />}</TableCell>
                        {hasAssignedTicket ? (
                          <TableCell>{!isEmpty(attendee.vue) ? <Check /> : <Close />}</TableCell>
                        ) : (
                          <TableCell>
                            {/* We don't know the VUE connection status because they haven't logged in */}
                            <Question />
                          </TableCell>
                        )}
                        <TableCell>
                          <CustomSimpleMenu menuItems={actionMenuItems} icon={<MoreVertIcon />} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={entries.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Fragment>
        )}

        <MaxWidthDialog
          actionText="Submit"
          cancelText="Cancel"
          dialogContentElem={<EventHelpDeskLinkVueForm onFormUpdate={this.handleVueFormUpdate} />}
          headingText="Link to Quility Account"
          isActionDisabled={linkVueFormData.isValid !== true}
          maxWidth="sm"
          open={showLinkToVue}
          handleAction={this.handleLinkToVue}
          onDialogClose={() => this.handleCloseDialog('showLinkToVue')}
        />

        <MaxWidthDialog
          open={showCheckInDialog}
          headingText={`Check In - ${selectedAttendee.name}`}
          actionText="Check In"
          cancelText="Cancel"
          dialogContentElem={
            <EventHelpDeskCheckInForm
              onFormUpdate={this.handleCheckInFormUpdate}
              event={event}
              attendee={selectedAttendee}
            />
          }
          handleAction={this.handleCheckIn}
          onDialogClose={() => this.handleCloseDialog('showCheckInDialog')}
          isActionDisabled={checkInFormData.isValid !== true}
        />

        <MaxWidthDialog
          open={showBreakoutSessions}
          headingText={`Breakout Sessions - ${selectedAttendee.name}`}
          cancelText="Close"
          dialogContentElem={<EventHelpDeskBreakoutSessions schedule={selectedAttendee.schedule} />}
          onDialogClose={() => this.handleCloseDialog('showBreakoutSessions')}
        />

        {entries.length <= 0 && (
          <Typography variant="caption" style={{ paddingLeft: '0.5rem' }}>
            No results found.
          </Typography>
        )}
      </Fragment>
    );
  }
}

const styles = (/* theme */) => ({
  formControl: {
    display: 'block',
  },
});

EventHelpDeskResultTable.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  attendees: PropTypes.array.isRequired,
  purchases: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  linkVueEmail: PropTypes.func.isRequired,
  handleCheckIn: PropTypes.func.isRequired,
  resendTicketEmailAction: PropTypes.func.isRequired,
  clearTicketAction: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(EventHelpDeskResultTable));
