import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import EmptyState from '../layout/emptyState.component';
import LoadingInlay from '../layout/loadingInlay.component';
import NotificationsSelector from './notifications.selector';
import NotificationsTable from './notificationsTable.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { getNotifications } from './notifications.actions';
import { setPageTitle } from '../layout/layout.actions';

const ALL_EVENTS_ID = 99;

class NotificationsContainer extends Component {
  state = {
    selectedEventId: ALL_EVENTS_ID,
    filteredNotifications: [],
  };

  componentDidMount() {
    this.props.setPageTitle('Notifications');
    this.props.getNotifications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.notifications !== this.props.notifications ||
      prevState.selectedEventId !== this.state.selectedEventId
    ) {
      this.setState({ filteredNotifications: this.getFilteredNotifications() });
    }
  }

  getFilteredNotifications = () => {
    return this.props.notifications.filter(notification => {
      if (this.state.selectedEventId === ALL_EVENTS_ID) {
        return true;
      }

      return notification.deepLink === this.state.selectedEventId;
    });
  };

  handleSelectEvent = e => {
    this.setState({ selectedEventId: e.target.value });
  };

  editNotification = notification => {
    this.props.history.push({
      pathname: `/notifications/${notification.id}`,
    });
  };

  renderSelectItem = event => {
    const { agencies } = this.props;

    const agency = agencies.find(a => a.id === event.agencyId);
    if (agency) {
      return (
        <MenuItem key={event.id} value={event.id}>
          {`${event.name} (${agency.owner.name})`}
        </MenuItem>
      );
    }

    return (
      <MenuItem key={event.id} value={event.id}>
        {event.name}
      </MenuItem>
    );
  };

  render() {
    const { isLoading, events, classes } = this.props;
    const { filteredNotifications, selectedEventId } = this.state;

    if (filteredNotifications.length === 0 && isLoading) {
      return <LoadingInlay />;
    }

    return (
      <div className="container">
        <div className={classes.controlsWrapper}>
          <SFGOutlinedButton
            className="button"
            color="primary"
            onClick={() => this.props.history.push('/notificationNew')}
          >
            New Notification
          </SFGOutlinedButton>

          <Select
            value={selectedEventId}
            onChange={this.handleSelectEvent}
            inputProps={{
              name: 'eventDeepLink',
              id: 'deepLink',
            }}
            style={{ minWidth: '16rem' }}
          >
            <MenuItem value={ALL_EVENTS_ID}>All Events</MenuItem>
            {events.map(event => this.renderSelectItem(event))}
          </Select>
        </div>

        {filteredNotifications.length === 0 && !isLoading && (
          <EmptyState text="No Notifications Found" />
        )}

        {filteredNotifications.length !== 0 && !isLoading && (
          <NotificationsTable
            notifications={filteredNotifications}
            editNotification={this.editNotification}
          />
        )}
      </div>
    );
  }
}

NotificationsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  classes: PropTypes.object.isRequired,

  notifications: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    notifications: NotificationsSelector.selectSortedNotifications(state),
    isLoading: state.notifications.get('isLoading'),
    events: state.events.get('events'),
    agencies: state.bigFive.get('agencies'),
  };
};

const styles = (/* theme */) => ({
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      getNotifications,
    })(NotificationsContainer)
  )
);
