/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Add, HighlightOff } from '@material-ui/icons';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';

class VenueMapUrlsInput extends Component {
  updateAtIndex = (urlIndex, update) => {
    const venueMapUrls = [...this.props.venueMapUrls];
    if (update.label !== undefined) {
      venueMapUrls[urlIndex].label = update.label;
    }
    if (update.url !== undefined) {
      venueMapUrls[urlIndex].url = update.url;
    }
    this.props.handleUpdate('venueMapUrls', venueMapUrls);
  };

  addUrl = () => {
    const venueMapUrls = [...this.props.venueMapUrls, { label: '', url: '' }];
    this.props.handleUpdate('venueMapUrls', venueMapUrls);
  };

  removeUrl = urlIndex => {
    const venueMapUrls = [...this.props.venueMapUrls];
    venueMapUrls.splice(urlIndex, 1);
    this.props.handleUpdate('venueMapUrls', venueMapUrls);
  };

  render = () => {
    const { venueMapUrls } = this.props;

    return (
      <Fragment>
        <Typography variant="body2" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
          Venue Map Urls (Aspect ratio of 2/3 is ideal)
        </Typography>
        {venueMapUrls.map((urlObj, urlIndex) => (
          <Grid container spacing={24} key={`url${urlIndex}`}>
            <Grid item xs={4}>
              <TextField
                label="Label (optional)"
                type="text"
                margin="normal"
                value={urlObj.label}
                onChange={e => this.updateAtIndex(urlIndex, { label: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item container xs={8} alignItems="center">
              <Grid item xs>
                <TextField
                  label="Url"
                  type="text"
                  margin="normal"
                  value={urlObj.url}
                  onChange={e => this.updateAtIndex(urlIndex, { url: e.target.value })}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    this.removeUrl(urlIndex);
                  }}
                >
                  <HighlightOff />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <SFGOutlinedButton onClick={this.addUrl}>
          <Add />
          Add Url
        </SFGOutlinedButton>
      </Fragment>
    );
  };
}

VenueMapUrlsInput.propTypes = {
  handleUpdate: PropTypes.func.isRequired,
  venueMapUrls: PropTypes.array.isRequired,
};

export default VenueMapUrlsInput;
