/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BreakoutSessionContent from './breakoutSessionContent.component';
import Colors from '../../styles/colors';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import StyledDialogActions from '../layout/dialogs/dialogActions';

class DownlineAgentEvents extends Component {
  state = {
    selectedEvent: null,
  };

  getEventListItemText = event => {
    let toReturn = '';
    if (event.sessions) {
      toReturn = `${event.name} - ${event.sessions ? event.sessions.length : 0} breakout session`;
      if (event.sessions.length !== 1) {
        toReturn += 's';
      }
    } else {
      toReturn = event.name;
    }

    return toReturn;
  };

  render() {
    const { classes, events, agent, isEventSelected } = this.props;

    if (events.length === 0) {
      if (isEventSelected) {
        return (
          <div className={classes.eventsWrapper}>
            <h5 className={classes.sectionHeading}>No Event Tickets</h5>
          </div>
        );
      }
      return null;
    }

    return (
      <div className={classes.eventsWrapper}>
        <h5 className={classes.sectionHeading}>Event Tickets</h5>
        <ul style={{ margin: 0 }}>
          {events.map(event => (
            <li
              key={event.id}
              onClick={() => this.setState({ selectedEvent: event })}
              className={classes.ticketItem}
            >
              {this.getEventListItemText(event)}
            </li>
          ))}
        </ul>

        <Dialog
          open={this.state.selectedEvent != null}
          onClose={() => this.setState({ selectedEvent: null })}
        >
          {this.state.selectedEvent != null && (
            <DialogTitle>{`${this.state.selectedEvent.name} - Breakout Sessions for ${agent.name} `}</DialogTitle>
          )}
          <DialogContent>
            {this.state.selectedEvent && (
              <BreakoutSessionContent sessions={this.state.selectedEvent.sessions} />
            )}
          </DialogContent>
          <StyledDialogActions>
            <SFGOutlinedButton onClick={() => this.setState({ selectedEvent: null })}>
              Close
            </SFGOutlinedButton>
          </StyledDialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = () => ({
  eventsWrapper: {
    paddingLeft: '3rem',
  },
  sectionHeading: {
    marginTop: 0,
    marginBottom: '0.75rem',
  },
  ticketItem: {
    cursor: 'pointer',
    color: Colors.primary,
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
});

DownlineAgentEvents.propTypes = {
  classes: PropTypes.object.isRequired,

  events: PropTypes.array.isRequired,
  agent: PropTypes.object.isRequired,
  isEventSelected: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DownlineAgentEvents);
