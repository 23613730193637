import Immutable from 'immutable';
import {
  GET_MY_TICKETS_FAILURE,
  GET_MY_TICKETS_REQUEST,
  GET_MY_TICKETS_SUCCESS,
  GET_MY_PURCHASES_FAILURE,
  GET_MY_PURCHASES_SUCCESS,
  GET_MY_PURCHASES_REQUEST,
  GET_USER_PURCHASE_FAILURE,
  GET_USER_PURCHASE_SUCCESS,
  GET_USER_PURCHASE_REQUEST,
  CLEAR_USER_SELECTED_PURCHASE
} from './purchases.actions';

const initialState = Immutable.Map({
  myPurchases: [],
  myTickets: [],
  selectedPurchase: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MY_TICKETS_SUCCESS:
      return state.set('myTickets', action.response.data).set('isLoading', false);

    case GET_MY_TICKETS_FAILURE:
      return state.set('isLoading', false);

    case GET_MY_TICKETS_REQUEST:
      return state.set('isLoading', true);

    case GET_MY_PURCHASES_SUCCESS:
      console.log('MY PURCHASES', action.response);
      return state.set('myPurchases', action.response).set('isLoading', false);

    case GET_MY_PURCHASES_FAILURE:
      return state.set('isLoading', false);

    case GET_MY_PURCHASES_REQUEST:
      return state.set('isLoading', true);

    case GET_USER_PURCHASE_SUCCESS:
      return state.set('selectedPurchase', action.response).set('isLoading', false);

    case GET_USER_PURCHASE_FAILURE:
      return state.set('isLoading', false);

    case GET_USER_PURCHASE_REQUEST:
      return state.set('isLoading', true);

    case CLEAR_USER_SELECTED_PURCHASE:
      return state.set('selectedPurchase', null);

    default:
      return state;
  }
}
