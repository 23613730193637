import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import PurchasePlanForm from './purchasePlanForm.component';
import {
  clearSelectedPurchasePlan,
  createPurchasePlan,
  CREATE_PURCHASEPLAN_SUCCESS
} from './purchasePlans.actions';

class PurchasePlanNewContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('New Purchase Plan');
    this.props.clearSelectedPurchasePlan();
  }

  handleCreate = async purchaseplanData => {
    /* Prices are stored on the server in cents, but we display dollars */
    purchaseplanData.cost = Math.round(purchaseplanData.cost * 100);

    const response = await this.props.createPurchasePlan(purchaseplanData);

    if (response.type === CREATE_PURCHASEPLAN_SUCCESS) {
      this.props.handleToastMessage('Purchase Plan Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create Purchase Plan.', true);
    }
  };

  render() {
    const { agencies, events, products } = this.props;
    return (
      <div className="container">
        <PurchasePlanForm
          agencies={agencies}
          events={events}
          products={products}
          submitAction={this.handleCreate}
        />
      </div>
    );
  }
}

PurchasePlanNewContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  agencies: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,

  createPurchasePlan: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  clearSelectedPurchasePlan: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  agencies: state.bigFive.get('agencies'),
  events: state.events.get('eventsAdmin'),
  products: state.products.get('products')
});

export default withRouter(
  connect(mapStateToProps, {
    createPurchasePlan,
    handleToastMessage,
    setPageTitle,
    clearSelectedPurchasePlan
  })(PurchasePlanNewContainer)
);
