import Immutable from 'immutable';
import { GET_REPORTS_REQUEST, GET_REPORTS_SUCCESS, GET_REPORTS_FAILURE } from './reports.actions';

const initialState = Immutable.Map({
  reports: [],
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS_SUCCESS:
      return state.set('reports', action.response).set('isLoading', false);

    case GET_REPORTS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_REPORTS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    default:
      return state;
  }
}
