import { isEmpty } from 'lodash';

export const getOwner = ownerObj => {
  if (!isEmpty(ownerObj.name)) {
    return ownerObj.name;
  }

  if (!isEmpty(ownerObj.email)) {
    return ownerObj.email;
  }

  return '';
};

export default getOwner;
