import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty } from 'lodash';

import PurchaserInfo from '../registrations/purchaserInfo.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';

class AttendeeInfoPage extends Component {
  componentDidMount() {
    /* Add an email confirmation validation rule to be sure the user enters the correct email address */
    ValidatorForm.addValidationRule('isSameEmail', (/* value */) => {
      return this.props.state.email === this.props.state.confirmEmail;
    });
  }

  render() {
    const { managers, event, onSubmit, state, updateField } = this.props;
    if (isEmpty(event)) {
      return null;
    }

    return (
      // eslint-disable-next-line react/no-string-refs
      <ValidatorForm ref="form" onSubmit={onSubmit} debounceTime={300}>
        <PurchaserInfo
          state={state}
          managers={managers}
          updateField={updateField}
          newRegistration
        />
        <br />
        <SFGOutlinedButton color="primary" type="submit">
          Go To Next Page
        </SFGOutlinedButton>
      </ValidatorForm>
    );
  }
}

AttendeeInfoPage.defaultProps = {
  event: null,
};

AttendeeInfoPage.propTypes = {
  managers: PropTypes.array.isRequired,
  event: PropTypes.object,
  state: PropTypes.object.isRequired,

  onSubmit: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default AttendeeInfoPage;
