import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { findIndex, isEmpty, omit } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import SpecialEventForm from './specialEventForm.component';
import {
  updateSpecialEvent,
  uninviteFromSpecialEvent,
  UPDATE_SPECIAL_EVENT_SUCCESS,
  UNINVITE_FROM_SPECIAL_EVENT_SUCCESS,
  getSpecialEvent
} from './specialEvents.actions';
import inviteeFormTypes from '../../types/inviteeFormTypes';
import { getEvent } from '../events/events.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

class SpecialEventEdit extends Component {
  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    this.props.setPageTitle('Edit Special Event');
    const { match, selectedEvent, specialEvent } = this.props;
    const { specialEventId, eventId } = match.params;
    if (isEmpty(selectedEvent) || selectedEvent.id !== eventId) {
      this.props.getEvent(eventId);
    }
    if (isEmpty(specialEvent) || specialEvent.id !== specialEventId) {
      this.props.getSpecialEvent(eventId, specialEventId);
    }
  }

  async handleUpdate(specialEventData /* , isAdmin = false */) {
    const { eventId, specialEventId } = this.props.match.params;
    let updateObj = { ...specialEventData };
    updateObj.inviteeEmails = updateObj.inviteeEmails.map(obj => obj.email);

    // map bulk invitation data
    const invitees = {};
    if (specialEventData.isIncludeAllRegistrants === true) {
      invitees.type = inviteeFormTypes.BULK_INVITATION_ALL_REGISTERED;
    }

    if (specialEventData.isIncludeAllCheckedIn === true) {
      invitees.type = inviteeFormTypes.BULK_INVITATION_CHECKED_IN;
    }

    if (!isEmpty(invitees)) {
      updateObj.invitees = invitees;
    } else {
      // Get the initial specialEvent data.
      const { specialEvent } = this.props;
      const invitationsToDelete = [];
      // Create a list of invitations that need to be uninvited from the special event.
      specialEvent.invitees.forEach(i => {
        if (findIndex(specialEventData.inviteeEmails, { email: i.email }) < 0) {
          invitationsToDelete.push(i.email);
        }
      });

      // If there are attendees who need to be uninvited, uninvite them.
      if (invitationsToDelete.length > 0) {
        const results = await this.props.uninviteFromSpecialEvent(
          eventId,
          specialEventId,
          invitationsToDelete
        );
        if (results.type !== UNINVITE_FROM_SPECIAL_EVENT_SUCCESS) {
          this.props.handleToastMessage('Failed to remove invitees from special event.', true);
          return;
        }
      }
    }

    // omit mapped fields
    updateObj = omit(updateObj, [
      'bulkInvitationEventId',
      'isIncludeAllCheckedIn',
      'isIncludeAllRegistrants'
    ]);
    const response = await this.props.updateSpecialEvent(specialEventId, eventId, updateObj);

    if (response.type === UPDATE_SPECIAL_EVENT_SUCCESS) {
      this.props.handleToastMessage('Special Event Updated.');
      // reload the special event data
      this.props.getSpecialEvent(eventId, specialEventId);
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update special event.', true);
    }
  }

  render() {
    const { loginStatus, selectedEvent, specialEvent, isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <div className="container">
        <SpecialEventForm
          specialEvent={specialEvent}
          submitAction={this.handleUpdate}
          selectedEvent={selectedEvent}
          loginStatus={loginStatus}
        />
      </div>
    );
  }
}

SpecialEventEdit.defaultProps = {
  selectedEvent: null,
  specialEvent: null
};

SpecialEventEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  selectedEvent: PropTypes.object,
  specialEvent: PropTypes.object,
  loginStatus: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,

  getEvent: PropTypes.func.isRequired,
  getSpecialEvent: PropTypes.func.isRequired,
  updateSpecialEvent: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  uninviteFromSpecialEvent: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedEvent: state.events.get('selectedEvent'),
  specialEvent: state.specialEvents.get('selectedEvent'),
  loginStatus: state.login.get('loginStatus'),
  isLoading: state.events.get('isLoading') || state.specialEvents.get('isLoading')
});

export default withRouter(
  connect(mapStateToProps, {
    getEvent,
    getSpecialEvent,
    updateSpecialEvent,
    handleToastMessage,
    setPageTitle,
    uninviteFromSpecialEvent
  })(SpecialEventEdit)
);
