import firebase from 'firebase';
import { isNil } from 'lodash';

import { CALL_API } from '../../middleware/api';

export const GET_GATHERINGS_REQUEST = 'GET_GATHERINGS_REQUEST';
export const GET_GATHERINGS_SUCCESS = 'GET_GATHERINGS_SUCCESS';
export const GET_GATHERINGS_FAILURE = 'GET_GATHERINGS_FAILURE';

export const GET_GATHERINGS_BY_LOCATION_REQUEST = 'GET_GATHERINGS_BY_LOCATION_REQUEST';
export const GET_GATHERINGS_BY_LOCATION_SUCCESS = 'GET_GATHERINGS_BY_LOCATION_SUCCESS';
export const GET_GATHERINGS_BY_LOCATION_FAILURE = 'GET_GATHERINGS_BY_LOCATION_FAILURE';

export const GET_GATHERINGS_BY_ZIP_REQUEST = 'GET_GATHERINGS_BY_ZIP_REQUEST';
export const GET_GATHERINGS_BY_ZIP_SUCCESS = 'GET_GATHERINGS_BY_ZIP_SUCCESS';
export const GET_GATHERINGS_BY_ZIP_FAILURE = 'GET_GATHERINGS_BY_ZIP_FAILURE';

export const CHECK_IN_GATHERING_ATTENDEE_REQUEST = 'CHECK_IN_GATHERING_ATTENDEE_REQUEST';
export const CHECK_IN_GATHERING_ATTENDEE_SUCCESS = 'CHECK_IN_GATHERING_ATTENDEE_SUCCESS';
export const CHECK_IN_GATHERING_ATTENDEE_FAILURE = 'CHECK_IN_GATHERING_ATTENDEE_FAILURE';

export const getGatherings = () => {
  return {
    [CALL_API]: {
      types: [GET_GATHERINGS_REQUEST, GET_GATHERINGS_SUCCESS, GET_GATHERINGS_FAILURE],
      authenticated: !isNil(firebase.auth().currentUser),
      endpoint: `v1/localevents`,
      method: 'GET',
    },
  };
};

export const getGatheringsByLocation = (lat, lng) => {
  return {
    [CALL_API]: {
      types: [
        GET_GATHERINGS_BY_LOCATION_REQUEST,
        GET_GATHERINGS_BY_LOCATION_SUCCESS,
        GET_GATHERINGS_BY_LOCATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/localevents?lat=${lat}&lng=${lng}`,
      method: 'GET',
    },
  };
};

export const getGatheringsByZip = zip => {
  return {
    [CALL_API]: {
      types: [
        GET_GATHERINGS_BY_ZIP_REQUEST,
        GET_GATHERINGS_BY_ZIP_SUCCESS,
        GET_GATHERINGS_BY_ZIP_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/localevents?zip=${zip}`,
      method: 'GET',
    },
  };
};

export const checkinGatheringAttendee = (eventId, attendee) => {
  return {
    [CALL_API]: {
      types: [
        CHECK_IN_GATHERING_ATTENDEE_REQUEST,
        CHECK_IN_GATHERING_ATTENDEE_SUCCESS,
        CHECK_IN_GATHERING_ATTENDEE_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/localevents/${eventId}/registrants`,
      method: 'POST',
      payload: attendee,
    },
  };
};
