import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CustomSimpleMenu from '../layout/customSimpleMenu/customSimpleMenu.component';
import transactionStatusTypes from '../../types/transactionStatusTypes';

const DATE_FORMAT = 'MMM DD, YYYY h:mm a';

function TransactionsTable({ transactions, retryTransaction }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Transaction Number</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Refunded Amount</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map(transaction => {
          return (
            <TableRow key={transaction.id}>
              <TableCell>{moment(transaction.scheduleDate).format(DATE_FORMAT)}</TableCell>
              <TableCell>{`${transaction.number} / ${transactions.length}`}</TableCell>
              <TableCell>{`$${(transaction.amount / 100).toFixed(2)}`}</TableCell>
              <TableCell>{`$${(transaction.refundAmount / 100).toFixed(2)}`}</TableCell>
              <TableCell>{transaction.status}</TableCell>
              <TableCell>
                {transaction.status === transactionStatusTypes.FAILED && (
                  <CustomSimpleMenu
                    menuItems={[
                      {
                        text: 'Reprocess',
                        action: () => {
                          retryTransaction(transaction.id);
                        },
                        disabled: transaction.status !== transactionStatusTypes.FAILED,
                      },
                    ]}
                    icon={<MoreVertIcon />}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const styles = () => ({});

TransactionsTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  retryTransaction: PropTypes.func.isRequired,
};

export default withStyles(styles)(TransactionsTable);
