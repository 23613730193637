import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import LoadingInlay from '../layout/loadingInlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import { getAgencies } from './bigFive.actions';
import { setPageTitle } from '../layout/layout.actions';

class BigFive extends Component {
  componentDidMount() {
    this.props.setPageTitle('Big 5 Agencies');
    this.props.getAgencies();
  }

  handleEdit = bigFiveAgency => {
    this.props.history.push(`/big5/${bigFiveAgency.id}`);
  };

  render() {
    const { agencies, classes, isLoading } = this.props;
    if (agencies.length === 0 && isLoading) {
      return <LoadingInlay />;
    }

    return (
      <div className="container">
        <Grid container>
          <Grid item xs={12}>
            <SFGOutlinedButton onClick={() => this.props.history.push('/big5/new')}>
              New Big 5 Agency
            </SFGOutlinedButton>
            <br />
            {agencies.length === 0 && (
              <Typography className={classes.centerText}>No Big 5 Agencies found.</Typography>
            )}
            <br />
          </Grid>

          {agencies.length > 0 && (
            <Grid item xs={12}>
              <Table style={{ margin: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.centerText}>Agency Name</TableCell>
                    <TableCell className={classes.centerText}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agencies.map(bigFiveAgency => (
                    <TableRow key={bigFiveAgency.id}>
                      <TableCell className={classes.centerText}>
                        {bigFiveAgency.owner.name}
                      </TableCell>
                      <TableCell className={classes.centerText}>
                        <SFGTextButton onClick={() => this.handleEdit(bigFiveAgency)}>
                          Edit
                        </SFGTextButton>
                        {'stripeSetupLink' in bigFiveAgency && (
                          <SFGTextButton
                            onClick={() => window.open(bigFiveAgency.stripeSetupLink, '_blank')}
                          >
                            Stripe Setup
                          </SFGTextButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const styles = () => ({
  centerText: {
    textAlign: 'center',
  },
});

BigFive.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  classes: PropTypes.object.isRequired,

  agencies: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  getAgencies: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    agencies: state.bigFive.get('agencies'),
    isLoading: state.bigFive.get('isLoading'),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getAgencies,
    setPageTitle,
  })(BigFive)
);
