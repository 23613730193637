import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';

import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';

class CustomValidatedTextEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      isValid: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
      isValid: !(nextProps.value === '' && this.props.validators.indexOf('required') > -1),
    });
  }

  validatorListener = isValid => {
    // ignore isvalid if empty input
    // default behavior is to set valid when blank
    if (this.state.value !== '') {
      this.setState({
        isValid,
      });
    }
  };

  handleAction = () => {
    this.props.handleAction(this.state.value);
  };

  render() {
    const { label, onChange, validators, fullWidth, errorMessages, actionText, name } = this.props;
    const { value, isValid } = this.state;

    return (
      <div>
        <TextValidator
          ref={ref => {
            this.textValidatorRef = ref;
          }}
          label={label}
          name={name}
          type="text"
          margin="normal"
          value={value}
          onChange={onChange}
          validators={validators}
          validatorListener={this.validatorListener}
          fullWidth={fullWidth}
          errorMessages={errorMessages}
        />
        <SFGOutlinedButton autoFocus disabled={!isValid} onClick={this.handleAction}>
          {actionText}
        </SFGOutlinedButton>
      </div>
    );
  }
}

CustomValidatedTextEntry.defaultProps = {
  validators: [],
  fullWidth: false,
  errorMessages: [],
};

CustomValidatedTextEntry.propTypes = {
  handleAction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.array,
  fullWidth: PropTypes.bool,
  errorMessages: PropTypes.array,
  actionText: PropTypes.string.isRequired,
};

export default CustomValidatedTextEntry;
