import BackIcon from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import DrawerMenu from './drawerMenuUser.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import logo from '../../images/logoHorizontalAgentEvents.png';
import responsiveBreakpoints from './responsiveBreakpoints';
import routeLinks from './routeLinksUser';
import { hasOneOfPermissions } from '../login/permissions';
import { toggleDrawerMenu as toggleDrawerMenuAction } from './layout.actions';

const Header = ({
  classes,
  history,
  location,
  isDrawerMenuOpen,
  pageTitle,
  permissions,
  responsiveBreakpoint,
  shouldShowBackButton,
  toggleDrawerMenu,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const isActiveRoute = route => {
    const { pathname } = location;

    // explicitly return true/false because matchpath doesn't return a boolean
    if (
      matchPath(pathname, {
        path: route,
        exact: true,
        strict: false,
      })
    ) {
      return true;
    }

    return false;
  };

  const viewPage = route => {
    if (!isActiveRoute(route)) {
      if (route.indexOf('http') > -1) {
        window.open(route, '_blank');
      } else if (route.match(/^mailto/)) {
        window.location.href = route;
      } else {
        history.push(route);
      }
    }

    if (anchorEl) handleCloseMenu();
  };

  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <>
      <DrawerMenu
        isDrawerMenuOpen={isDrawerMenuOpen}
        toggleDrawerMenu={toggleDrawerMenu}
        permissions={permissions}
      />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {responsiveBreakpoint !== responsiveBreakpoints.LARGE ? (
            <IconButton color="primary" aria-label="Close" onClick={toggleDrawerMenu} alt="menu">
              <i className="material-icons">menu</i>
            </IconButton>
          ) : (
            <img src={logo} className={classes.logo} alt="Quility Logo" />
          )}

          {shouldShowBackButton && (
            <IconButton color="primary" aria-label="Back" onClick={history.goBack}>
              <BackIcon />
            </IconButton>
          )}

          <Typography
            variant="title"
            color="primary"
            className={classes.flex}
            style={{ fontSize: '1rem' }}
          >
            {pageTitle}
          </Typography>

          {responsiveBreakpoint === responsiveBreakpoints.LARGE &&
            routeLinks.map(routeLink => {
              /* If a required permission is defined, make sure the user has it before displaying the link. */
              if (
                'permissions' in routeLink &&
                !hasOneOfPermissions(routeLink.permissions, permissions)
              ) {
                return <span key={routeLink.route} />;
              }
              /* Define a gold highlight if a link should have it. */
              const setHighlight =
                'highlight' in routeLink && routeLink.highlight ? { color: 'ffcc00' } : {};
              return (
                <SFGTextButton
                  key={routeLink.route}
                  color={isActiveRoute(routeLink.route) ? 'primary' : 'secondary'}
                  size="small"
                  style={setHighlight}
                  onClick={() => viewPage(routeLink.route)}
                >
                  {routeLink.name}
                </SFGTextButton>
              );
            })}
        </Toolbar>
      </AppBar>
    </>
  );
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 15%)', // copied from quility.com
  },
  logo: {
    maxHeight: '2.15rem',
    margin: '0 0.5rem 0 0',
  },
  flex: {
    flex: 1,
  },
});

Header.defaultProps = {
  shouldShowBackButton: false,
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  shouldShowBackButton: PropTypes.bool,

  responsiveBreakpoint: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  isDrawerMenuOpen: PropTypes.bool.isRequired,
  permissions: PropTypes.array.isRequired,

  toggleDrawerMenu: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    responsiveBreakpoint: state.layout.get('responsiveBreakpoint'),
    pageTitle: state.layout.get('pageTitle'),
    isDrawerMenuOpen: state.layout.get('isDrawerMenuOpen'),
    permissions: state.login.get('permissions'),
  };
};

const withRouterHeader = withRouter(
  connect(mapStateToProps, {
    toggleDrawerMenu: toggleDrawerMenuAction,
  })(Header)
);

export default withStyles(styles)(withRouterHeader);
