import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import firebase from 'firebase';
import moment from 'moment';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { withRouter } from 'react-router-dom';

import CustomSimpleMenu from '../layout/customSimpleMenu/customSimpleMenu.component';
import ERROR_MESSAGES from '../../types/errorMessages';
import NameUtils from '../../utilities/nameUtils';
import SUCCESS_MESSAGES from '../../types/successMessages';
import SfgPrimaryButton from '../../common/buttons/sfgPrimaryButton.component';
import SfgTextButton from '../../common/buttons/sfgTextButton.component';
import TicketDelegateForm from './ticketDelegateForm.component';
import ValidatorFormDialog from '../layout/dialogs/validatorFormDialog.component';
import {
  ADD_UNASSIGNED_SUCCESS,
  CANCEL_DELEGATE_TICKET_FAILURE,
  CANCEL_TICKETS_SUCCESS,
  DELEGATE_TICKET_FAILURE,
  RESEND_ASSIGNMENT_EMAIL_SUCCESS,
  RESEND_DELEGATE_TICKET_FAILURE,
  UPDATE_DELEGATE_TICKET_FAILURE,
  addUnassignedTicketsToPurchaser,
  cancelDelegateTicket,
  cancelTickets,
  delegateTicket,
  getTickets,
  getTicketsAdmin,
  resendConfirmationEmail,
  resendDelegateTicket,
  updateDelegateTicket,
} from './tickets.actions';
import { MANAGE_TICKET_ASSIGNMENTS, hasPermission } from '../login/permissions';
import { handleToastMessage } from '../layout/layout.actions';

const DATE_FORMAT = 'MMM DD, YYYY h:mm a';

const TABLE_COLUMN_FIELDS = {
  ACTION: 'action',
  ASSIGNEE: 'assignee',
  CREATED_DATE: 'createdDate',
  EMAIL: 'email',
  NAME: 'name',
  PRODUCT: 'product',
};

const DELEGATE_STATUSES = {
  SENT: 'SENT',
  VIEWED: 'VIEWED',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
};

const sortTicketsAssignedFirst = (a, b) => {
  if (a.assignee && b.assignee) {
    const tmp = [a.assignee.lastName, b.assignee.lastName].sort();
    if (tmp[0] === a.assignee.lastName) {
      return -1;
    }
    return 1;
  }
  if (!a.assignee && b.assignee) {
    return 1;
  }
  if (a.assignee && !b.assignee) {
    return -1;
  }
  return 0;
};

const isAdmin = async () => {
  const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
  return idTokenResult.claims.admin;
};

class TicketAssignmentsList extends Component {
  state = {
    email: '',
    isDelegateDialogOpen: false,
    isEditDelegateDialogOpen: false,
    sortedTickets: [],
    ticket: null,
    numUnassignedToAdd: 0,
    showAdvanced: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.sortedTickets !== props.tickets) {
      return { ...state, sortedTickets: props.tickets.sort(sortTicketsAssignedFirst) };
    }

    return state;
  }

  handleCreateAssignment = ticket => {
    this.props.history.push(`${this.props.createUrl}/${ticket.id}`);
  };

  handleUpdateAssignment = ticket => {
    this.props.history.push(`${this.props.updateUrl}/${ticket.assignee.id}`);
  };

  handleClearAssignment = async ticket => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to clear this ticket assignment?')) {
      console.log('remove ticket assignment');
      await this.props.removeAction(ticket);

      // Clearing a ticket assignment is admin only
      const { id, purchaserId } = this.props.match.params;
      this.props.getTicketsAdmin(id, purchaserId);
    }
  };

  handleResendConfirmationEmail = async ticket => {
    const response = await this.props.resendConfirmationEmail(ticket.id);
    if (response.type === RESEND_ASSIGNMENT_EMAIL_SUCCESS) {
      this.props.handleToastMessage('Email resent.');
    } else {
      this.props.handleToastMessage('Failed to resend email.', true);
    }
  };

  handleCancelTicket = async ticket => {
    /*
    Use the url params to cancel the ticket. The event ID and purchaser ID are available there. 
    Cancel, and then request the tickets again so that they update.
    */
    let prompt = 'Are you sure you want to cancel this ticket?';
    if (ticket.assignee) {
      prompt = `Are you sure you want to cancel the ticket assigned to ${NameUtils.getFormattedName(
        ticket.assignee
      )}`;
    }

    if (window.confirm(prompt)) {
      const { id, purchaserId } = this.props.match.params;

      const cancelTicketResponse = await this.props.cancelTickets(purchaserId, [ticket.id]);

      if (cancelTicketResponse.type === CANCEL_TICKETS_SUCCESS) {
        this.props.handleToastMessage('The requested ticket has been canceled');

        this.props.getTicketsAdmin(id, purchaserId);
      }
    }
  };

  handleToggleDelegateDialog = (ticket = null) =>
    this.setState(prevState => ({
      isDelegateDialogOpen: !prevState.isDelegateDialogOpen,
      ticket,
    }));

  handleToggleEditDelegateDialog = (ticket = null) =>
    this.setState(prevState => ({
      email: !isNil(ticket) ? ticket.delegate.value : '',
      isEditDelegateDialogOpen: !prevState.isEditDelegateDialogOpen,
      ticket,
    }));

  handleAddUnassigned = async () => {
    const prompt = `Are you sure you want to add ${this.state.numUnassignedToAdd} tickets to this purchase?`;

    // eslint-disable-next-line no-alert
    if (window.confirm(prompt)) {
      const { id, purchaserId } = this.props.match.params;
      const addUnassignedResponse = await this.props.addUnassignedTicketsToPurchaser(
        purchaserId,
        this.state.numUnassignedToAdd
      );

      if (addUnassignedResponse.type === ADD_UNASSIGNED_SUCCESS) {
        this.props.handleToastMessage(
          `Added ${this.state.numUnassignedToAdd} tickets to this purchase`
        );
        this.props.getTicketsAdmin(id, purchaserId);
      }
    }
  };

  getActionMenuItems = ticket => {
    const menuItems = [
      {
        text: 'Update Ticket Assignment',
        action: () => this.handleUpdateAssignment(ticket),
      },
      {
        text: 'Resend Ticket Assignment Email',
        action: () => this.handleResendConfirmationEmail(ticket),
      },
      {
        text: 'Unassign Ticket',
        action: () => this.handleClearAssignment(ticket),
      },
      {
        text: 'Cancel Ticket',
        action: () => this.handleCancelTicket(ticket),
      },
    ];

    return menuItems;
  };

  getActionMenuItemsNoAssignee = (ticket, hasManageTicketPermission = false) => {
    let menuItems = [
      {
        text: 'Assign Ticket',
        action: () => this.handleCreateAssignment(ticket),
      },
      {
        text: 'Send Ticket',
        action: () => this.handleToggleDelegateDialog(ticket),
      },
    ];
    const hasDelegate = !isNil(ticket.delegate);
    const delegateStatus = hasDelegate && ticket.delegateStatus;

    if (hasDelegate) {
      switch (delegateStatus) {
        case DELEGATE_STATUSES.SENT:
        case DELEGATE_STATUSES.VIEWED:
        case DELEGATE_STATUSES.FAILED:
          menuItems = [
            {
              text: 'Update Email',
              action: () => this.handleToggleEditDelegateDialog(ticket),
            },
            {
              text: 'Resend Ticket',
              action: () => this.handleResendDelegateTicket(ticket),
            },
          ];
          break;

        default:
          break;
      }

      // Admins should be able to copy the url or unsend the ticket
      if (hasManageTicketPermission) {
        menuItems.push(
          {
            text: 'Copy Ticket URL',
            action: () => this.handleCopyTicketDelegateUrl(ticket),
          },
          {
            text: 'Unsend Ticket',
            action: () => this.handleUnsendTicket(ticket),
          }
        );
      }
    }

    if (!hasManageTicketPermission) return menuItems;

    return [
      ...menuItems,
      {
        text: 'Cancel Ticket',
        action: () => this.handleCancelTicket(ticket),
      },
    ];
  };

  renderRowActions = ticket => {
    const { updateUrl, permissions } = this.props;

    const hasManageTicketPermission =
      hasPermission(MANAGE_TICKET_ASSIGNMENTS, permissions) && updateUrl;
    const hasAssignee = !isEmpty(ticket.assignee);

    if (!hasManageTicketPermission && hasAssignee) return <TableCell />;

    let menuItems;

    if (hasManageTicketPermission) {
      menuItems = hasAssignee
        ? this.getActionMenuItems(ticket)
        : this.getActionMenuItemsNoAssignee(ticket, true);
    } else {
      menuItems = this.getActionMenuItemsNoAssignee(ticket);
    }

    return (
      <TableCell style={{ textAlign: 'center' }}>
        <CustomSimpleMenu menuItems={menuItems} icon={<MoreVertIcon />} />
      </TableCell>
    );
  };

  renderAssigneeCell = ticket => {
    const { palette } = this.props.theme;

    if (!isEmpty(ticket.assignee))
      return (
        <TableCell>
          <Typography variant="body1">{NameUtils.getFormattedName(ticket.assignee)}</Typography>
        </TableCell>
      );

    const hasDelegate = !isNil(ticket.delegate);

    if (hasDelegate) {
      const getDelegateStatusText = () => {
        switch (ticket.delegateStatus) {
          case DELEGATE_STATUSES.FAILED:
            return `Failed to send ticket to ${ticket.delegate.value}`;

          case DELEGATE_STATUSES.SENT:
            return `SENT to ${ticket.delegate.value}`;

          case DELEGATE_STATUSES.VIEWED:
            return `VIEWED by ${ticket.delegate.value}`;

          case DELEGATE_STATUSES.COMPLETE:
            return `COMPLETED by ${ticket.delegate.value}`;

          default:
            return '';
        }
      };

      const getDelegateStatusStyle = () => {
        if (ticket.delegateStatus === DELEGATE_STATUSES.FAILED)
          return { color: palette.error.main };

        return { color: palette.primary.main };
      };
      return (
        <TableCell>
          <Typography variant="body1" style={getDelegateStatusStyle()}>
            {getDelegateStatusText()}
          </Typography>
        </TableCell>
      );
    }

    return (
      <TableCell>
        <Typography variant="body1" style={{ color: palette.error.main }}>
          Unassigned
        </Typography>
      </TableCell>
    );
  };

  renderTableCell = (ticket, field) => {
    const { registration } = this.props;

    if (ticket.canceled && field === TABLE_COLUMN_FIELDS.ASSIGNEE)
      return <TableCell style={{ fontStyle: 'italic' }}>Ticket Canceled</TableCell>;
    if (ticket.canceled) return <TableCell />;

    const hasAssignee = !isEmpty(ticket.assignee);

    switch (field) {
      case TABLE_COLUMN_FIELDS.ASSIGNEE:
        return this.renderAssigneeCell(ticket);

      case TABLE_COLUMN_FIELDS.EMAIL:
        return hasAssignee ? (
          <TableCell>
            <Typography variant="body1">{ticket.assignee[field]}</Typography>
          </TableCell>
        ) : (
          <TableCell />
        );

      case TABLE_COLUMN_FIELDS.CREATED_DATE:
        return hasAssignee ? (
          <TableCell>{moment(ticket.assignee.createdDate).format(DATE_FORMAT)}</TableCell>
        ) : (
          <TableCell />
        );

      case TABLE_COLUMN_FIELDS.NAME:
        return hasAssignee ? (
          <TableCell>
            {`${registration.firstName} ${registration.lastName} `}
            {!isEmpty(registration.suffix) && registration.suffix}
          </TableCell>
        ) : (
          <TableCell />
        );

      case TABLE_COLUMN_FIELDS.PRODUCT:
        return <TableCell>{ticket.product && ticket.product.name}</TableCell>;

      case TABLE_COLUMN_FIELDS.ACTION:
        return this.renderRowActions(ticket);

      default:
        return <TableCell />;
    }
  };

  handleDelegateTicket = async () => {
    const { ticket, email } = this.state;

    const isCurrentUserAdmin = await isAdmin();

    const response = await this.props.delegateTicket(
      ticket.id,
      {
        type: 'email',
        value: email,
      },
      isCurrentUserAdmin
    );

    this.handleToggleDelegateDialog();

    if (response.type === DELEGATE_TICKET_FAILURE) {
      this.props.handleToastMessage(ERROR_MESSAGES.SEND_TICKET_FAILURE, true);
      return;
    }

    this.props.handleToastMessage(SUCCESS_MESSAGES.SEND_TICKET_SUCCESS);

    if (isCurrentUserAdmin) {
      const { id, purchaserId } = this.props.match.params;
      this.props.getTicketsAdmin(id, purchaserId);
      return;
    }

    this.props.getTickets(this.props.userEmail);
  };

  handleResendDelegateTicket = async ticket => {
    const isCurrentUserAdmin = await isAdmin();

    const response = await this.props.resendDelegateTicket(
      ticket.id,
      {
        type: 'email',
        value: ticket.delegate.value,
      },
      isCurrentUserAdmin
    );

    // this.handleToggleDelegateDialog(); --- this shouldn't be needed here

    if (response.type === RESEND_DELEGATE_TICKET_FAILURE) {
      this.props.handleToastMessage(ERROR_MESSAGES.SEND_TICKET_FAILURE, true);
      return;
    }

    this.props.handleToastMessage(SUCCESS_MESSAGES.SEND_TICKET_SUCCESS);

    if (isCurrentUserAdmin) {
      const { id, purchaserId } = this.props.match.params;
      this.props.getTicketsAdmin(id, purchaserId);
      return;
    }

    this.props.getTickets(this.props.userEmail);
  };

  handleUpdateDelegateTicket = async () => {
    const { ticket, email } = this.state;

    const isCurrentUserAdmin = await isAdmin();

    const response = await this.props.updateDelegateTicket(
      ticket.id,
      {
        type: 'email',
        value: email,
      },
      isCurrentUserAdmin
    );

    this.handleToggleEditDelegateDialog();

    if (response.type === UPDATE_DELEGATE_TICKET_FAILURE) {
      this.props.handleToastMessage(ERROR_MESSAGES.SEND_TICKET_FAILURE, true);
      return;
    }

    this.props.handleToastMessage(SUCCESS_MESSAGES.SEND_TICKET_SUCCESS);

    if (isCurrentUserAdmin) {
      const { id, purchaserId } = this.props.match.params;
      this.props.getTicketsAdmin(id, purchaserId);
      return;
    }

    this.props.getTickets(this.props.userEmail);
  };

  handleCopyTicketDelegateUrl = ticket => {
    navigator.clipboard.writeText(ticket.delegateUrl).then(() => {
      this.props.handleToastMessage(SUCCESS_MESSAGES.COPY_TICKET_URL_SUCCESS);
    });
  };

  handleUnsendTicket = async ticket => {
    const isCurrentUserAdmin = await isAdmin();

    if (isCurrentUserAdmin) {
      const response = await this.props.cancelDelegateTicket(ticket.id);

      if (response.type === CANCEL_DELEGATE_TICKET_FAILURE) {
        this.props.handleToastMessage(ERROR_MESSAGES.CANCEL_DELEGATED_TICKET_FAILURE, true);
        return;
      }
      this.props.handleToastMessage(SUCCESS_MESSAGES.CANCEL_DELEGATED_TICKET_SUCCESS);

      const { id, purchaserId } = this.props.match.params;
      this.props.getTicketsAdmin(id, purchaserId);
    }
  };

  render() {
    const { registration, tickets, classes } = this.props;

    if (tickets.length === 0) {
      return (
        <div className="container">
          <Grid container>
            <Grid item xs>
              <Typography variant="title">No tickets purchased for this event.</Typography>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <>
        <div className="container">
          <Grid container>
            <Grid item xs>
              {!isEmpty(this.state.sortedTickets) && (
                <>
                  <Typography variant="headline">{`Tickets Purchased: ${this.state.sortedTickets.length}`}</Typography>
                  <Grid container spacing={8} alignItems="flex-end" direction="column">
                    <Grid item>
                      <SfgTextButton onClick={() => this.setState({ showAdvanced: true })}>
                        Show Advanced Options
                      </SfgTextButton>
                    </Grid>
                    {this.state.showAdvanced && (
                      <>
                        <Grid item className={classes.addUnassignedContainer}>
                          <TextField
                            label="Number of Tickets to Add"
                            onChange={e => {
                              this.setState({ numUnassignedToAdd: e.target.value });
                            }}
                            value={this.state.numUnassignedToAdd}
                            type="number"
                            fullWidth
                            margin="normal"
                          />
                          <SfgPrimaryButton
                            onClick={this.handleAddUnassigned}
                            disabled={this.state.numUnassignedToAdd < 1}
                          >
                            Add Unassigned Tickets to Purchase
                          </SfgPrimaryButton>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">Assignee Name</TableCell>
                        <TableCell width="20%">Assignee Email</TableCell>
                        <TableCell width="15%">Assignment Date</TableCell>
                        {registration && <TableCell width="20%">Purchaser Name</TableCell>}
                        <TableCell width="10%">Ticket Type</TableCell>
                        <TableCell width="15%" style={{ textAlign: 'center' }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.sortedTickets.map(ticket => (
                        <TableRow key={ticket.id}>
                          {this.renderTableCell(ticket, TABLE_COLUMN_FIELDS.ASSIGNEE)}
                          {this.renderTableCell(ticket, TABLE_COLUMN_FIELDS.EMAIL)}
                          {this.renderTableCell(ticket, TABLE_COLUMN_FIELDS.CREATED_DATE)}
                          {registration && this.renderTableCell(ticket, TABLE_COLUMN_FIELDS.NAME)}
                          {this.renderTableCell(ticket, TABLE_COLUMN_FIELDS.PRODUCT)}
                          {this.renderTableCell(ticket, TABLE_COLUMN_FIELDS.ACTION)}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Grid>
          </Grid>
        </div>
        <ValidatorFormDialog
          formElements={
            <TicketDelegateForm
              email={this.state.email}
              onChange={({ target: { name, value } }) => this.setState({ [name]: value })}
            />
          }
          title="Send Ticket"
          open={this.state.isDelegateDialogOpen}
          onSubmit={this.handleDelegateTicket}
          onClose={() => this.handleToggleDelegateDialog()}
        />
        <ValidatorFormDialog
          formElements={
            <TicketDelegateForm
              email={this.state.email}
              onChange={({ target: { name, value } }) => this.setState({ [name]: value })}
            />
          }
          title="Edit Ticket"
          open={this.state.isEditDelegateDialogOpen}
          onSubmit={this.handleUpdateDelegateTicket}
          onClose={() => this.handleToggleEditDelegateDialog()}
        />
      </>
    );
  }
}

TicketAssignmentsList.defaultProps = {
  registration: null,
  tickets: null,
  permissions: [],
  updateUrl: null,
  removeAction: null,
};

TicketAssignmentsList.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,

  createUrl: PropTypes.string.isRequired,
  permissions: PropTypes.array,
  registration: PropTypes.object,
  removeAction: PropTypes.func,
  tickets: PropTypes.array,
  updateUrl: PropTypes.string,
  userEmail: PropTypes.string.isRequired,

  cancelTickets: PropTypes.func.isRequired,
  delegateTicket: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
  getTicketsAdmin: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  resendConfirmationEmail: PropTypes.func.isRequired,
  resendDelegateTicket: PropTypes.func.isRequired,
  updateDelegateTicket: PropTypes.func.isRequired,
  cancelDelegateTicket: PropTypes.func.isRequired,
  addUnassignedTicketsToPurchaser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userEmail: state.login.get('userEmail'),
});

const wrappedByRouter = withStyles(
  () => ({
    addUnassignedContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  }),
  { withTheme: true }
)(withRouter(TicketAssignmentsList));

export default connect(mapStateToProps, {
  cancelTickets,
  delegateTicket,
  getTickets,
  getTicketsAdmin,
  handleToastMessage,
  resendConfirmationEmail,
  resendDelegateTicket,
  updateDelegateTicket,
  cancelDelegateTicket,
  addUnassignedTicketsToPurchaser,
})(wrappedByRouter);
