import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import {
  updateScheduleSlotSession,
  UPDATE_SCHEDULE_SLOT_SESSION_SUCCESS
} from './schedule.actions';
import ScheduleSlotSessionForm from './scheduleSlotSessionForm.component';

class ScheduleSlotSessionEdit extends Component {
  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentWillMount() {
    if (isEmpty(this.props.selectedEvent)) {
      this.props.history.replace('/events');
    }
    this.props.setPageTitle('Edit Session');
  }

  async handleUpdate(sessionData) {
    const response = await this.props.updateScheduleSlotSession(
      this.props.match.params.sessionId,
      this.props.match.params.slotId,
      sessionData
    );

    if (response.type === UPDATE_SCHEDULE_SLOT_SESSION_SUCCESS) {
      this.props.handleToastMessage('Session Updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update session.', true);
    }
  }

  render() {
    return (
      <div className="container">
        <ScheduleSlotSessionForm
          session={this.props.location.state.session}
          slot={this.props.location.state.slot}
          submitAction={this.handleUpdate}
          cancelAction={() => this.props.history.goBack()}
        />
      </div>
    );
  }
}

ScheduleSlotSessionEdit.defaultProps = {
  selectedEvent: null
};

ScheduleSlotSessionEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  selectedEvent: PropTypes.object,

  updateScheduleSlotSession: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedEvent: state.events.get('selectedEvent')
});

export default withRouter(
  connect(mapStateToProps, {
    updateScheduleSlotSession,
    handleToastMessage,
    setPageTitle
  })(ScheduleSlotSessionEdit)
);
