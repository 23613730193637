import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import LoadingOverlay from '../layout/loadingOverlay.component';
import TicketAssignment from './ticketAssignment.component';
import isLoadingSelector from './tickets.selector';
import {
  CREATE_ASSIGNEE_SUCCESS,
  UPDATE_TICKET_SUCCESS,
  createAssigneeAdmin,
  getTicket,
  updateTicket,
} from './tickets.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class NewTicketAssignmentAdmin extends Component {
  componentDidMount() {
    this.props.setPageTitle(`Ticket Assignment`);
    const { match, ticket } = this.props;
    const { ticketId } = match.params;
    if (isEmpty(ticket) || ticket.id !== ticketId) {
      this.props.getTicket(ticketId);
    }
  }

  submitForm = async formData => {
    if (!isEmpty(formData.spouseAgent)) {
      formData.spouseAgentCode = formData.spouseAgent.code;
    } else {
      delete formData.spouseAgentCode;
    }

    // remove spouseAgent and spouse from submission - only send spouseAgentCode
    delete formData.spouseAgent;
    delete formData.spouse;

    const response = await this.props.createAssigneeAdmin(formData);
    if (response.type === CREATE_ASSIGNEE_SUCCESS) {
      const ticket = { ...this.props.ticket, assignee: response.response.id };
      const ticketId = ticket.id;
      /* Remove read only keys of the ticket object. */
      delete ticket.id;
      delete ticket.createdDate;
      delete ticket.updatedDate;
      delete ticket.delegateStatus;

      const updateTicketResponse = await this.props.updateTicket(ticketId, ticket);
      if (updateTicketResponse.type === UPDATE_TICKET_SUCCESS) {
        this.props.history.goBack();
        this.props.handleToastMessage('Created ticket assignment', false);
      } else {
        this.props.handleToastMessage('Failed to update ticket assignment', true);
      }
    } else {
      this.props.handleToastMessage('Failed to create ticket assignment', true);
    }
  };

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
  };

  render() {
    const { isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <div className="container">
        <TicketAssignment handleSubmit={this.submitForm} />
      </div>
    );
  }
}

NewTicketAssignmentAdmin.defaultProps = {
  ticket: null,
};

NewTicketAssignmentAdmin.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  ticket: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getTicket: PropTypes.func.isRequired,
  updateTicket: PropTypes.func.isRequired,
  createAssigneeAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    managers: state.managers.get('managers'),
    ticket: state.tickets.get('selectedTicket'),
    isLoading: isLoadingSelector(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getTicket,
    updateTicket,
    createAssigneeAdmin,
  })(NewTicketAssignmentAdmin)
);
