import PropTypes from 'prop-types';
import React from 'react';

import AllAgentsAutocomplete from '../../modules/layout/autocomplete/allAgentsAutocomplete.component';
import SFGCustomValidator from './sfgCustomValidator.component';

const SFGAgentAutocompleteValidator = ({
  disableBlurValidation,
  errorMessages,
  label,
  name,
  validators,
  value,
  onBlur,
  onChange,
  ...restProps
}) => (
  <SFGCustomValidator
    disableBlurValidation={false}
    errorMessages={errorMessages}
    label={label}
    name={name}
    validators={validators}
    value={value}
    onBlur={onBlur}
    onChange={onChange}
  >
    <AllAgentsAutocomplete label={label} name={name} value={value} {...restProps} />
  </SFGCustomValidator>
);

SFGAgentAutocompleteValidator.propTypes = {
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SFGAgentAutocompleteValidator.defaultProps = {
  disableBlurValidation: false,
  disabled: false,
  errorMessages: [],
  validators: [],
  value: '',
  onBlur: () => {},
};

export default SFGAgentAutocompleteValidator;
