import { EditorState } from 'draft-js';
import { isEmpty } from 'lodash';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

import linkDecorator from '../modules/layout/richEditor/linkDecorator';
import { formatDate } from './convertTimes';

const initEventState = event => {
  event = event || {};
  return {
    name: event.name || '',
    agencyId: event.agencyId || '',
    allowGeoCheckin: event.allowGeoCheckin,
    startDate: event.startDate ? formatDate(event.startDate, false) : '',
    endDate: event.endDate ? formatDate(event.endDate, false) : '',
    locationName: event.locationName || '',
    summary: event.summary || '',
    headerGraphicUrl: event.headerGraphicUrl || '',
    previewGraphicUrl: event.previewGraphicUrl || '',
    venueMapUrls: event.venueMapUrls || [],
    eventDetailsUrl: event.eventDetailsUrl || '',
    externalRegistrationUrl: event.externalRegistrationUrl || '',
    locationGraphicUrl: event.locationGraphicUrl || '',
    bookingUrl: event.bookingUrl || '',
    address: event.address || '',
    coordinates: event.coordinates || null,
    daysBeforeRegistrationOpens: event.daysBeforeRegistrationOpens || '',
    hasExternalTicketAssignments: event.hasExternalTicketAssignments || false,
    locationDescription: event.locationDescription || '',
    welcomeMessage: !isEmpty(event.welcomeMessage)
      ? EditorState.createWithContent(stateFromHTML(event.welcomeMessage), linkDecorator)
      : EditorState.createEmpty(linkDecorator),
    travelDetails: !isEmpty(event.travelDetails)
      ? EditorState.createWithContent(stateFromHTML(event.travelDetails), linkDecorator)
      : EditorState.createEmpty(linkDecorator),
    checkinQuestions: !isEmpty(event.checkinQuestions) ? event.checkinQuestions : [],
    archived: event.archived || false,
    badgeColors: event.badgeColors || null,
    eventTemplates: event.eventTemplates || null,
    isDraft: event.isDraft || false,
    emailTemplates: event.emailTemplates || null,
  };
};

const prepareEventData = state => {
  const eventData = {
    agencyId: state.agencyId,
    allowGeoCheckin: state.allowGeoCheckin,
    name: state.name,
    startDate: state.startDate,
    endDate: state.endDate,
    locationName: state.locationName,
    summary: state.summary,
    headerGraphicUrl: state.headerGraphicUrl,
    previewGraphicUrl: state.previewGraphicUrl,
    daysBeforeRegistrationOpens: state.daysBeforeRegistrationOpens,
    hasExternalTicketAssignments: state.hasExternalTicketAssignments === true,
    welcomeMessage: stateToHTML(state.welcomeMessage.getCurrentContent()),
    travelDetails: stateToHTML(state.travelDetails.getCurrentContent()),
    checkinQuestions: state.checkinQuestions,
    archived: state.archived,
    isDraft: state.isDraft,
  };

  if (!isEmpty(state.address)) {
    eventData.address = state.address;
  }

  if (!isEmpty(state.coordinates)) {
    eventData.coordinates = state.coordinates;
  }

  if (!isEmpty(state.locationDescription)) {
    eventData.locationDescription = state.locationDescription;
  }

  if (!isEmpty(state.bookingUrl)) {
    eventData.bookingUrl = state.bookingUrl;
  }

  if (!isEmpty(state.venueMapUrls)) {
    eventData.venueMapUrls = state.venueMapUrls;
  } else {
    eventData.venueMapUrls = null; // set this explicitly in case it's an empty array
  }

  if (!isEmpty(state.eventDetailsUrl)) {
    eventData.eventDetailsUrl = state.eventDetailsUrl;
  } else {
    eventData.eventDetailsUrl = null;
  }

  if (!isEmpty(state.externalRegistrationUrl)) {
    eventData.externalRegistrationUrl = state.externalRegistrationUrl;
  }

  if (!isEmpty(state.locationGraphicUrl)) {
    eventData.locationGraphicUrl = state.locationGraphicUrl;
  }

  if (!isEmpty(state.badgeColors)) {
    eventData.badgeColors = state.badgeColors;
  } else {
    eventData.badgeColors = null; // set this explicitly in case it's an empty array
  }

  if (!isEmpty(state.emailTemplates)) {
    eventData.emailTemplates = state.emailTemplates;
  } else {
    eventData.emailTemplates = null; // set this explicitly in case it's an empty array
  }

  eventData.startDate = formatDate(eventData.startDate);
  eventData.endDate = formatDate(eventData.endDate);
  // console.log('EVENT DATA!', eventData);

  return eventData;
};

export default { initEventState, prepareEventData };
