import { CALL_API } from '../../middleware/api';

export const GET_REGISTRATIONS_REQUEST = 'GET_REGISTRATIONS_REQUEST';
export const GET_REGISTRATIONS_SUCCESS = 'GET_REGISTRATIONS_SUCCESS';
export const GET_REGISTRATIONS_FAILURE = 'GET_REGISTRATIONS_FAILURE';

export const CREATE_REGISTRATION_REQUEST = 'CREATE_REGISTRATION_REQUEST';
export const CREATE_REGISTRATION_SUCCESS = 'CREATE_REGISTRATION_SUCCESS';
export const CREATE_REGISTRATION_FAILURE = 'CREATE_REGISTRATION_FAILURE';

export const GET_REGISTRATION_REQUEST = 'GET_REGISTRATION_REQEUST';
export const GET_REGISTRATION_SUCCESS = 'GET_REGISTRATION_SUCCESS';
export const GET_REGISTRATION_FAILURE = 'GET_REGISTRATION_FAILURE';

export const SET_SELECTED_REGISTRATION = 'SET_SELECTED_REGISTRATION';
export const CLEAR_SELECTED_REGISTRATION = 'CLEAR_SELECTED_REGISTRATION';

export const UPDATE_REGISTRATION_REQUEST = 'UPDATE_REGISTRATION_REQUEST';
export const UPDATE_REGISTRATION_SUCCESS = 'UPDATE_REGISTRATION_SUCCES';
export const UPDATE_REGISTRATION_FAILURE = 'UPDATE_REGISTRATION_FAILURE';

export const DELETE_REGISTRATION_REQUEST = 'DELETE_REGISTRATION_REQUEST';
export const DELETE_REGISTRATION_SUCCESS = 'DELETE_REGISTRATION_SUCCESS';
export const DELETE_REGISTRATION_FAILURE = 'DELETE_REGISTRATION_FAILURE';

export const RESEND_REGISTRATION_EMAIL_REQUEST = 'RESEND_REGISTRATION_EMAIL_REQUEST';
export const RESEND_REGISTRATION_EMAIL_SUCCESS = 'RESEND_REGISTRATION_EMAIL_SUCCESS';
export const RESEND_REGISTRATION_EMAIL_FAILURE = 'RESEND_REGISTRATION_EMAIL_FAILURE';

export const getRegistrations = eventId => {
  return {
    [CALL_API]: {
      types: [GET_REGISTRATIONS_REQUEST, GET_REGISTRATIONS_SUCCESS, GET_REGISTRATIONS_FAILURE],
      authenticated: true,
      endpoint: eventId ? `admin/events/${eventId}/registrations` : `admin/purchasers`,
      method: 'GET',
    },
  };
};

export const createRegistration = registrationData => {
  return {
    [CALL_API]: {
      types: [
        CREATE_REGISTRATION_REQUEST,
        CREATE_REGISTRATION_SUCCESS,
        CREATE_REGISTRATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/purchasers`,
      method: 'POST',
      payload: registrationData,
    },
  };
};

export const getRegistration = registrationId => {
  return {
    [CALL_API]: {
      types: [GET_REGISTRATION_REQUEST, GET_REGISTRATION_SUCCESS, GET_REGISTRATION_FAILURE],
      authenticated: true,
      endpoint: `admin/purchasers/${registrationId}?relations=agencyManager`,
      method: 'GET',
      actionMetadata: {
        registrationId,
      },
    },
  };
};

export const setSelectedRegistration = registration => {
  return {
    type: SET_SELECTED_REGISTRATION,
    response: registration,
  };
};

export const clearSelectedRegistration = () => {
  return {
    type: CLEAR_SELECTED_REGISTRATION,
  };
};

export const updateRegistration = (registrationId, registrationData) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_REGISTRATION_REQUEST,
        UPDATE_REGISTRATION_SUCCESS,
        UPDATE_REGISTRATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/purchasers/${registrationId}`,
      method: 'PUT',
      payload: registrationData,
      actionMetadata: {
        registrationId,
      },
    },
  };
};

export const deleteRegistration = registrationId => {
  return {
    [CALL_API]: {
      types: [
        DELETE_REGISTRATION_REQUEST,
        DELETE_REGISTRATION_SUCCESS,
        DELETE_REGISTRATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/purchasers/${registrationId}`,
      method: 'DELETE',
      actionMetadata: {
        registrationId,
      },
    },
  };
};

export const resendRegistrationEmail = registrationId => {
  return {
    [CALL_API]: {
      types: [
        RESEND_REGISTRATION_EMAIL_REQUEST,
        RESEND_REGISTRATION_EMAIL_SUCCESS,
        RESEND_REGISTRATION_EMAIL_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/purchases/${registrationId}/sendConfirmation`,
      method: 'POST',
      actionMetadata: {
        registrationId,
      },
    },
  };
};
