import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import NotificationForm from './notificationForm.component';
import {
  getNotification,
  setSelectedNotification,
  updateNotification,
  UPDATE_NOTIFICATION_SUCCESS
} from './notifications.actions';
import LoadingInlay from '../layout/loadingInlay.component';

class NotificationEditContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('Notification');
    const { notificationId } = this.props.match.params;
    if (
      isEmpty(this.props.selectedNotification) ||
      this.props.selectedNotification.id !== notificationId
    )
      this.props.getNotification(notificationId);
  }

  handleEdit = async notificationData => {
    const response = await this.props.updateNotification(
      this.props.selectedNotification.id,
      notificationData
    );

    if (response.type === UPDATE_NOTIFICATION_SUCCESS) {
      this.props.handleToastMessage('Notification updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update notification.', true);
    }
  };

  render() {
    const { events, agencies, selectedNotification, isLoading } = this.props;
    if (isLoading) {
      return <LoadingInlay />;
    }
    return (
      <div className="container">
        <NotificationForm
          agencies={agencies}
          notification={selectedNotification}
          submitAction={this.handleEdit}
          isReadOnly
          events={events}
        />
      </div>
    );
  }
}

NotificationEditContainer.defaultProps = {
  selectedNotification: null
};

NotificationEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  selectedNotification: PropTypes.object,
  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getNotification: PropTypes.func.isRequired,
  // setSelectedNotification: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired
};

/*
JM: isLoading should be used with LoadingInlay while loading the event
*/

const mapStateToProps = state => {
  return {
    selectedNotification: state.notifications.get('selectedNotification'),
    events: state.events.get('eventsAdmin'),
    isLoading: state.notifications.get('isLoading'),
    agencies: state.bigFive.get('agencies')
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    getNotification,
    setSelectedNotification,
    updateNotification,
    handleToastMessage
  })(NotificationEditContainer)
);
