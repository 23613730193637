const Colors = {
  primary: '#1A4C9A',
  secondary: '#4CBDF9',
  highlight: '#6ECF93',
  dark: '#323434',
  grayDark: '#7B858E',
  grayMiddle: '#777777',
  grayMediumDark: '#C9C9C9',
  grayMediumLight: '#E3E3E3',
  grayLight: '#F7F7F7',
  error: '#E86C60',
  green: '#249016',
  white: '#FFFFFF',

  tealPrimary: '#46C3B2',
  teal80: 'rgba(70, 195, 178, 0.8)',
  teal60: 'rgba(70, 195, 178, 0.6)',

  forestGreenPrimary: '#005851',

  grayPrimary: '#5F6B78',
  graySecondary: '#7B858E',

  redPrimary: '#FF5851',
  redSecondary: 'rgb(171, 0, 29)',

  yellowPrimary: '#FFCC00',
  yellowSecondary: 'rgb(242, 162, 65)',

  greenPrimary: '#6ECF93',
  greenSecondary: 'rgb(1, 78, 71)',
};

export default Colors;
