import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { find, findIndex, isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import BigFiveForm from './bigFiveForm.component';
import {
  clearSelectedAgencyAdmins,
  getAgencyAdmins,
  addAgencyAdmin,
  removeAgencyAdmin,
  ADD_BIG5_AGENCY_ADMIN_SUCCESS,
  REMOVE_BIG5_AGENCY_ADMIN_SUCCESS
} from './bigFive.actions';

class EditBigFiveAgency extends Component {
  constructor(props) {
    super(props);
    this.state = { agency: {} };
  }

  componentWillMount() {
    const { match, agencies } = this.props;

    this.props.setPageTitle('Edit Big 5 Agency');
    this.props.clearSelectedAgencyAdmins();

    const id = match.params.agencyId;
    this.props.getAgencyAdmins(id);

    const agency = find(agencies, { id });
    if (agency) {
      this.setState({ agency });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agencies !== this.props.agencies) {
      const agency = find(nextProps.agencies, { id: this.props.match.params.agencyId });
      if (!agency) {
        this.props.handleToastMessage('Could not find that agency', true);
        this.props.history.replace('/big5');
      } else {
        this.setState({ agency });
      }
    }
  }

  onSubmit = data => {
    if (isEmpty(data.agencyOwner)) {
      this.props.handleToastMessage('An agency must have an agency owner.', true);
      return;
    }

    // Find all new agents. We know they are new because they don't have an ID set.
    const newAgents = data.agencyAdmins.filter(a => {
      return !('id' in a);
    });

    const removeAgents = this.props.agencyAdmins.filter(a => {
      return findIndex(data.agencyAdmins, a) === -1;
    });

    let hasFailure = false;
    newAgents.forEach(async agent => {
      const result = await this.props.addAgencyAdmin(data.agencyId, agent);
      if (result.type !== ADD_BIG5_AGENCY_ADMIN_SUCCESS) hasFailure = true;
    });

    removeAgents.forEach(async agent => {
      const result = await this.props.removeAgencyAdmin(data.agencyId, agent.id);
      if (result.type !== REMOVE_BIG5_AGENCY_ADMIN_SUCCESS) hasFailure = true;
    });

    if (hasFailure) {
      this.props.handleToastMessage('Updating agency admins failed!', true);
    } else {
      this.props.handleToastMessage('Agency updated!', false);
      this.props.history.goBack();
    }
  };

  render() {
    const { agencyAdmins, agencyAdminsLoading } = this.props;
    const { agency } = this.state;
    return (
      <div className="page-padding-bottom page-padding-top">
        <BigFiveForm
          agency={agency}
          agencyAdmins={agencyAdmins}
          agencyAdminsLoading={agencyAdminsLoading}
          onSubmit={this.onSubmit}
          handleToastMessage={this.props.handleToastMessage}
        />
      </div>
    );
  }
}

EditBigFiveAgency.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  agencies: PropTypes.array.isRequired,
  agencyAdmins: PropTypes.array.isRequired,
  agencyAdminsLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  clearSelectedAgencyAdmins: PropTypes.func.isRequired,
  getAgencyAdmins: PropTypes.func.isRequired,
  addAgencyAdmin: PropTypes.func.isRequired,
  removeAgencyAdmin: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    agencies: state.bigFive.get('agencies'),
    agencyAdmins: state.bigFive.get('agencyAdmins'),
    agencyAdminsLoading: state.bigFive.get('isLoading')
  };
};

export default connect(mapStateToProps, {
  setPageTitle,
  handleToastMessage,
  clearSelectedAgencyAdmins,
  getAgencyAdmins,
  addAgencyAdmin,
  removeAgencyAdmin
})(EditBigFiveAgency);
