import Immutable from 'immutable';
import { findIndex } from 'lodash';
import {
  GET_ATTENDEE_EVENT_STATUS_REQUEST,
  GET_ATTENDEE_EVENT_STATUS_SUCCESS,
  GET_ATTENDEE_EVENT_STATUS_FAILURE,
  SET_ATTENDEE_EVENT_STATUS_REQUEST,
  SET_ATTENDEE_EVENT_STATUS_SUCCESS,
  SET_ATTENDEE_EVENT_STATUS_FAILURE,
  DELETE_ATTENDEE_EVENT_STATUS_REQUEST,
  DELETE_ATTENDEE_EVENT_STATUS_SUCCESS,
  DELETE_ATTENDEE_EVENT_STATUS_FAILURE,
  CLEAR_ATTENDEE_EVENT_STATUSES
} from './vips.actions';

const initialState = Immutable.Map({
  attendeeEventStatuses: [],
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ATTENDEE_EVENT_STATUS_SUCCESS:
      return state
        .set(
          'attendeeEventStatuses',
          action.response.data.map(status => Object.assign(status, { assignee: status.attendee }))
        )
        .set('isLoading', false);

    case GET_ATTENDEE_EVENT_STATUS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_ATTENDEE_EVENT_STATUS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case SET_ATTENDEE_EVENT_STATUS_SUCCESS:
      return state.set('isLoading', false);

    case SET_ATTENDEE_EVENT_STATUS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case SET_ATTENDEE_EVENT_STATUS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case DELETE_ATTENDEE_EVENT_STATUS_SUCCESS: {
      const attendeeEventStatuses = [...state.get('attendeeEventStatuses')];

      const idx = findIndex(attendeeEventStatuses, {
        id: action.actionMetadata.attendeeEventStatusId
      });
      if (idx > -1) {
        attendeeEventStatuses.splice(idx, 1);
      }

      return state.set('attendeeEventStatuses', attendeeEventStatuses).set('isLoading', false);
    }

    case DELETE_ATTENDEE_EVENT_STATUS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case DELETE_ATTENDEE_EVENT_STATUS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CLEAR_ATTENDEE_EVENT_STATUSES:
      return state.set('attendeeEventStatuses', []);

    default:
      return state;
  }
}
