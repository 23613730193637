export const EMAIL_TEMPLATES = {
  ASSIGNMENT_CONFIRMATION: 'assignment-confirmation',
  APP_INVITE: 'app-invite',
  EVENT_REMINDER: 'event-reminder',
  PAYMENT_DECLINED: 'payment-declined',
  SEND_TICKET: 'send-ticket',
};

export const EMAIL_TEMPLATE_TOOLTIPS = {
  [EMAIL_TEMPLATES.ASSIGNMENT_CONFIRMATION]:
    'Sent when an email address has been assigned a ticket',
  [EMAIL_TEMPLATES.APP_INVITE]:
    'Sent to email addresses that have been invited to a download the mobile app',
  [EMAIL_TEMPLATES.EVENT_REMINDER]:
    'Sent to all email addresses assigned to tickets, 48 hours prior to the event',
  [EMAIL_TEMPLATES.PAYMENT_DECLINED]:
    'Sent to email addresses who have had a recurring payment get declined',
  [EMAIL_TEMPLATES.SEND_TICKET]:
    'Sent to email addresses who have been chosen to fill out their own ticket',
};

export const EMAIL_TEMPLATE_LABELS = {
  [EMAIL_TEMPLATES.ASSIGNMENT_CONFIRMATION]: 'Ticket Assignment Confirmation',
  [EMAIL_TEMPLATES.APP_INVITE]: 'App Invitation',
  [EMAIL_TEMPLATES.EVENT_REMINDER]: '48 Hour Event Reminder',
  [EMAIL_TEMPLATES.PAYMENT_DECLINED]: 'Payment Declined Notice',
  [EMAIL_TEMPLATES.SEND_TICKET]: 'Self-serve Ticket Completion',
};
