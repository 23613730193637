import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import memoize from 'memoize-one';
import { CSVLink } from 'react-csv';
import {
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';
import { withRouter } from 'react-router-dom';

import LoadingOverlayWithText from '../layout/loadingOverlayWithText.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import {
  LOOKUP_PURCHASES_SUCCESS,
  lookupPurchases,
} from '../layout/autocomplete/autocomplete.actions';
import { getEvent, setSelectedEvent } from './events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class AccountsReceivable extends Component {
  /* Memoize the function so it only runs if the purchases change. */
  processingPurchases = memoize(purchases => {
    const filteredPurchases = [];
    purchases.forEach(p => {
      const transactions = p.transactions.filter(t => {
        if (t.status === 'FAILED' || t.status === 'PENDING') return true;
        return false;
      });
      if (transactions.length)
        filteredPurchases.push({ ...p, numberOfTransactions: p.transactions.length, transactions });
    });
    sortBy(filteredPurchases, p => p.createdDate);
    this.prepareForDownload(filteredPurchases);
    this.setState({
      purchases: filteredPurchases,
      origPurchases: filteredPurchases,
      processingPurchases: false,
    });
  });

  constructor(props) {
    super(props);

    this.state = {
      csvData: [],
      page: 0,
      origPurchases: [],
      purchases: [],
      processingPurchases: false,
      rowsPerPage: 10,
      searchBox: '',
    };
  }

  async componentDidMount() {
    this.props.setPageTitle('Accounts Receivable');
    const { match, event } = this.props;
    const eventId = match.params.id;
    if (isEmpty(event) || event.id !== eventId) {
      this.props.getEvent(eventId);
    }

    // Set the eventId and the processingPurchases flag so the loader will stay up.
    this.setState({ eventId, processingPurchases: true });
    const result = await this.props.lookupPurchases('.', eventId);
    if (result.type === LOOKUP_PURCHASES_SUCCESS) {
      this.processingPurchases(result.response);
    } else {
      this.setState({ processingPurchases: false });
      this.props.handleToastMessage(`Failed to get purchases!`, true);
    }
  }

  prepareForDownload = purchases => {
    const csvData = purchases.reduce((data, p) => {
      const sharedData = {
        Purchaser: `${p.firstName} ${p.lastName}${!isEmpty(p.suffix) ? ` ${p.suffix}` : ''}`,
        'Original Purchase Date': p.date,
        Email: p.email,
      };
      const newLines = sortBy(p.transactions, 'number').map(t => {
        return {
          ...sharedData,
          'Transaction Date': t.scheduleDate,
          'Transaction Number': `${t.number} / ${p.numberOfTransactions}`,
          Amount: `${(t.amount / 100).toFixed(2)}`,
          Status: t.status,
        };
      });
      data.push(...newLines);
      return data;
    }, []);

    this.setState({ csvData });
  };

  search = value => {
    this.setState({ searchBox: value });
    const toMatch = new RegExp(value, 'i');
    if (value.length >= 2) {
      // JM: This needs to be commented or reworked with appropriate var names
      // eslint-disable-next-line react/no-access-state-in-setstate
      const purchases = this.state.purchases.filter(
        r => r.firstName.match(toMatch) || r.lastName.match(toMatch)
      );
      this.setState({ purchases });
    } else {
      this.setState(prevState => ({
        purchases: prevState.origPurchases,
      }));
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { isLoading } = this.props;
    const { csvData, purchases, page, processingPurchases, rowsPerPage } = this.state;

    const title = `Accounts Receivable${this.props.event ? ` - ${this.props.event.name}` : ''}`;
    if (isLoading || processingPurchases) return <LoadingOverlayWithText />;
    return (
      <div className="container">
        <Grid container>
          <Grid item>
            <Typography color="primary" variant="title">
              {title}
            </Typography>
            <div style={{ margin: '1.5rem 1rem' }}>
              <SFGOutlinedButton color="primary" disabled={!csvData.length}>
                <CSVLink
                  style={{ color: 'black' }}
                  filename={`${title} - ${new Date().toJSON()}.csv`}
                  data={csvData}
                >
                  Export
                </CSVLink>
              </SFGOutlinedButton>
              <Input
                placeholder="Filter"
                disableUnderline={false}
                inputProps={{
                  'aria-label': 'Filter',
                }}
                variant="outlined"
                onChange={e => this.search(e.target.value)}
                style={{
                  float: 'right',
                  minWidth: '10rem',
                  padding: '0.15rem 0.25rem',
                  marginRight: '10rem',
                }}
                value={this.state.searchBox}
              />
            </div>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Purchaser</TableCell>
                    <TableCell>Original Purchase Date</TableCell>
                    <TableCell>Transaction Date</TableCell>
                    <TableCell>Transaction Number</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchases &&
                    /* Calculate the number of tickets for this purchaser. */
                    purchases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(p => {
                      const date = p.createdDate.substring(0, p.createdDate.indexOf('T'));
                      return sortBy(p.transactions, 'number').map((t, i) => (
                        <TableRow key={t.id}>
                          {i === 0 && (
                            // eslint-disable-next-line react/jsx-fragments
                            <Fragment>
                              <TableCell rowSpan={p.transactions.length}>
                                {p.firstName} {p.lastName}
                                {!isEmpty(p.suffix) ? ` ${p.suffix}` : ''}
                              </TableCell>
                              <TableCell rowSpan={p.transactions.length}>{date}</TableCell>
                            </Fragment>
                          )}
                          <TableCell>
                            {t.scheduleDate.substring(0, t.scheduleDate.indexOf('T'))}
                          </TableCell>
                          <TableCell>
                            {t.number} /{p.numberOfTransactions}
                          </TableCell>
                          <TableCell>${(t.amount / 100).toFixed(2)}</TableCell>
                          <TableCell>{t.status}</TableCell>
                          {i === 0 && (
                            <TableCell rowSpan={p.transactions.length}>
                              <SFGTextButton
                                onClick={() => {
                                  this.props.history.push(
                                    `/events/${this.state.eventId}/helpDesk/purchases/${p.email}#${date}`
                                  );
                                }}
                              >
                                Manage Purchase
                              </SFGTextButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ));
                    })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.purchases.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AccountsReceivable.defaultProps = {
  event: null,
};

AccountsReceivable.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  isLoading: PropTypes.bool.isRequired,
  event: PropTypes.object,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  lookupPurchases: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading:
      state.events.get('isLoading') ||
      state.products.get('isLoading') ||
      state.registrations.get('isLoading'),
    event: state.events.get('selectedEvent'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getEvent,
    setSelectedEvent,
    lookupPurchases,
  })(AccountsReceivable)
);
