import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Colors from '../../styles/colors';
import EventsHeader, { SEARCH_BY_ZIP } from './eventsHeader.component';
import { getEvents as reducerGetEvents } from './events.actions';
import {
  getGatherings as reducerGetGatherings,
  getGatheringsByLocation as reducerGetGatheringsByLocation,
  getGatheringsByZip as reducerGetGatheringsByZip,
} from '../gatherings/gatherings.actions';
import { setPageTitle as reducerSetPageTitle } from '../layout/layout.actions';
import { sortByDate } from '../../utilities/dataUtils';

import MaxWidthDialog from '../layout/dialogs/maxWidthDialog.component';
import 'firebase/auth';

const EventsContainer = ({ classes, setPageTitle }) => {
  const [currentPositionErrorOpen, setCurrentPositionErrorOpen] = useState(false);

  useEffect(() => {
    setPageTitle('Events');
  }, [setPageTitle]);

  // SFGCR-1742: No longer showing events on main screen
  // const filteredGatherings = useMemo(() => {
  //   return filterByDate(
  //     // Have to rename startTime and endTime to startDate / endDate
  //     gatherings.map(gathering => ({
  //       ...gathering,
  //       startDate: gathering.startTime,
  //       endDate: gathering.endTime,
  //     })),
  //     startDate,
  //     endDate
  //   );
  // }, [gatherings, startDate, endDate]);

  // const filteredEvents = useMemo(() => {
  //   return filterByDate(events, startDate, endDate);
  // }, [events, startDate, endDate]);

  // const renderLists = useCallback(() => {
  //   if (currentPositionError)
  //     return (
  //       <Typography align="center">
  //         Unable to acquire your current location. Please check location preferences and try again.
  //       </Typography>
  //     );

  //   if (
  //     ((events.length === 0 || gatherings.length === 0) && isLoading) ||
  //     (isNil(currentPosition) && searchType === SEARCH_BY_NEAR_ME)
  //   )
  //     return <LoadingInlay />;

  //   if (filteredEvents.length === 0 && filteredGatherings.length === 0)
  //     return (
  //       <Typography align="center" variant="display1">
  //         No Events
  //       </Typography>
  //     );

  //   return (
  //     <>
  //       {searchType !== SEARCH_BY_NEAR_ME && searchType !== SEARCH_BY_ZIP && (
  //         <EventsList events={filteredEvents} />
  //       )}
  //       {searchType !== SEARCH_BY_CONFERENCES && (
  //         <GatheringsList
  //           gatherings={filteredGatherings}
  //           loginStatus={loginStatus}
  //           isSearch={searchType === SEARCH_BY_NEAR_ME || searchType === SEARCH_BY_ZIP}
  //         />
  //       )}
  //     </>
  //   );
  // }, [
  //   currentPosition,
  //   currentPositionError,
  //   events,
  //   filteredGatherings,
  //   filteredEvents,
  //   isLoading,
  //   loginStatus,
  //   searchType,
  //   gatherings.length,
  // ]);

  // const handleSearchGatheringsByLocation = useCallback(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     position => {
  //       setCurrentPosition(position);
  //       getGatheringsByLocation(position.coords.latitude, position.coords.longitude);
  //     },
  //     err => {
  //       console.log('ERROR', err);
  //       setCurrentPositionError(err.message);
  //       setCurrentPositionErrorOpen(true);
  //     },
  //     {
  //       timeout: 10000,
  //     }
  //   );
  // }, [getGatheringsByLocation]);

  // const handleSearchTypeChange = useCallback(
  //   event => {
  //     setCurrentPositionError(null);
  //     setSearchType(event.target.value);
  //     setCurrentPosition(null);

  //     if (event.target.value === SEARCH_BY_NEAR_ME) {
  //       handleSearchGatheringsByLocation();
  //     }
  //   },
  //   [handleSearchGatheringsByLocation]
  // );

  return (
    <div>
      <div className="container">
        <EventsHeader
          isLoggedIn={false}
          searchType={SEARCH_BY_ZIP}
          onSearchTypeChange={() => {}}
          onZipCodeSearch={() => {}}
          setCurrentPosition={() => {}}
          onUpdateStartDate={() => {}}
          onUpdateEndDate={() => {}}
        />
        <Grid container className={classes.gridContainer} spacing={16}>
          <Grid item xs={12}>
            <h3 className={classes.message}>
              <b>events.quility.com</b>
              {` is no longer your go to for Quility Agent Events. For upcoming events information,
              please reach out to `}
              <a className={classes.link} href="mailto:Events@Quility.com">
                Events@Quility.com.
              </a>
            </h3>
            {/* SFGCR-1742: No longer showing events on main screen */}
            {/* {renderLists()} */}
          </Grid>
        </Grid>
      </div>
      <MaxWidthDialog
        cancelText="Close"
        dialogContentElem={
          <Typography align="center" className={classes.dialogContent}>
            Unable to acquire your current location. Please check location preferences and try
            again.
          </Typography>
        }
        headingText="Current Location Error"
        maxWidth="sm"
        open={currentPositionErrorOpen}
        onDialogClose={() => setCurrentPositionErrorOpen(false)}
      />
    </div>
  );
};

const styles = theme => ({
  message: {
    textAlign: 'center',
    fontWeight: 'normal',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  gridContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  logo: {
    width: '100%',
  },
  itemIcon: {
    marginRight: '1rem',
  },
  rotateIcon: {
    transform: 'rotate(90deg)',
  },
  select: {
    padding: '.5rem',
  },
  searchTitle: {
    color: '#00BFFC',
  },
  logoGrid: {
    paddingRight: theme.spacing.unit * 4,
  },
  searchContainer: {
    backgroundColor: Colors.grayLight,
    padding: '1rem 2rem',
  },
  searchInput: {
    paddingBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
  },
  dialogContent: {
    marginTop: theme.spacing.unit * 4,
  },
});

EventsContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  setPageTitle: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    events: sortByDate(state.events.get('events')),
    gatherings: sortByDate(state.gatherings.get('gatherings')),
    isLoading: state.events.get('isLoading') || state.gatherings.get('isLoading'),
    loginStatus: state.login.get('loginStatus'),
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    getEvents: reducerGetEvents,
    getGatherings: reducerGetGatherings,
    getGatheringsByLocation: reducerGetGatheringsByLocation,
    getGatheringsByZip: reducerGetGatheringsByZip,
    setPageTitle: reducerSetPageTitle,
  })
)(EventsContainer);
