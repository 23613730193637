import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import LoadingOverlay from '../layout/loadingOverlay.component';
import TicketAssignmentsList from '../tickets/ticketAssignmentsList.component';
import { LOGIN_STATE } from '../login/login.actions';
import { clearSelectedEvent, getEvent, setSelectedEvent } from '../events/events.actions';
import { getTickets } from '../tickets/tickets.actions';
import { setPageTitle } from '../layout/layout.actions';

class AttendeesList extends Component {
  componentDidMount() {
    const { match, selectedEvent, userEmail, loginStatus } = this.props;
    const { eventId } = match.params;

    this.props.setPageTitle('Assign Tickets');
    if (isEmpty(selectedEvent) || selectedEvent.id !== eventId) {
      this.props.getEvent(eventId);
    }

    if (userEmail) {
      /* Only get the list of tickets once the login state is correct. There is
      a race condition where this component will load before the auth state is
      correct. This prevents an error fetching the tickets list. The entire
      component will remount once the login state changes and this check will
      pass.
      */
      if (loginStatus === LOGIN_STATE.STANDARD_USER) {
        this.props.getTickets(userEmail);
      }
    }
  }

  render() {
    const { selectedEvent, tickets, ticketsLoading, match } = this.props;
    const { eventId } = match.params;

    if (!isEmpty(selectedEvent) && !ticketsLoading) {
      return (
        <TicketAssignmentsList
          createUrl={`/attendees/${eventId}/new`}
          loginStatus={this.props.loginStatus}
          registration={null}
          tickets={tickets.filter(t => {
            if (t.event) {
              return t.event.id === eventId;
            }
            return null;
          })}
          updateUrl={null}
        />
      );
    }
    return <LoadingOverlay />;
  }
}

AttendeesList.defaultProps = {
  selectedEvent: null,
};

AttendeesList.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

  loginStatus: PropTypes.number.isRequired,
  userEmail: PropTypes.string.isRequired,
  selectedEvent: PropTypes.object,
  tickets: PropTypes.array.isRequired,
  ticketsLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    loginStatus: state.login.get('loginStatus'),
    userEmail: state.login.get('userEmail'),
    selectedEvent: state.events.get('selectedEvent'),
    tickets: state.tickets.get('tickets'),
    ticketsLoading: state.tickets.get('isLoading'),
  };
};

export default connect(mapStateToProps, {
  setPageTitle,
  getEvent,
  getTickets,
  clearSelectedEvent,
  setSelectedEvent,
})(AttendeesList);
