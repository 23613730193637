import Immutable from 'immutable';
import { findIndex, isEmpty } from 'lodash';
import {
  GET_REGISTRATIONS_REQUEST,
  GET_REGISTRATIONS_SUCCESS,
  GET_REGISTRATIONS_FAILURE,
  CREATE_REGISTRATION_SUCCESS,
  GET_REGISTRATION_REQUEST,
  GET_REGISTRATION_SUCCESS,
  GET_REGISTRATION_FAILURE,
  SET_SELECTED_REGISTRATION,
  UPDATE_REGISTRATION_SUCCESS,
  DELETE_REGISTRATION_SUCCESS,
  CLEAR_SELECTED_REGISTRATION
} from './registrations.actions';

const initialState = Immutable.Map({
  registrations: [],
  selectedRegistration: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_REGISTRATIONS_SUCCESS:
      if (Array.isArray(action.response)) {
        return state.set('registrations', action.response).set('isLoading', false);
      }
      return state.set('registrations', action.response.data).set('isLoading', false);

    case GET_REGISTRATIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_REGISTRATIONS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CREATE_REGISTRATION_SUCCESS: {
      const registrations = [...state.get('registrations')];
      const newRegistration = { ...action.response };
      registrations.push(newRegistration);

      return state.set('registrations', registrations).set('isLoading', false);
    }

    case GET_REGISTRATION_SUCCESS:
      return state.set('selectedRegistration', action.response).set('isLoading', false);

    case GET_REGISTRATION_FAILURE:
      return state.set('isLoading', false);

    case GET_REGISTRATION_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_REGISTRATION:
      return state.set('selectedRegistration', action.response);

    case CLEAR_SELECTED_REGISTRATION:
      return state.set('selectedRegistration', null);

    case UPDATE_REGISTRATION_SUCCESS: {
      let selectedRegistration = { ...state.get('selectedRegistration') };
      const registrations = [...state.get('registrations')];
      const registrationId = action.actionMetadata;

      if (!isEmpty(selectedRegistration) && selectedRegistration.id === registrationId) {
        selectedRegistration = Object.assign(selectedRegistration, action.payload);
      }

      const idx = findIndex(registrations, { id: registrationId });
      if (idx > -1) {
        registrations[idx] = Object.assign(registrations[idx], action.payload);
      }

      return state
        .set('selectedRegistration', selectedRegistration)
        .set('registrations', registrations)
        .set('isLoading', false);
    }

    case DELETE_REGISTRATION_SUCCESS: {
      const registrations = [...state.get('registrations')];

      const idx = findIndex(registrations, { id: action.actionMetadata.registrationId });
      if (idx > -1) {
        registrations.splice(idx, 1);
      }

      return state.set('registrations', registrations).set('isLoading', false);
    }

    default:
      return state;
  }
}
