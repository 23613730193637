import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import LoadingOverlay from '../layout/loadingOverlay.component';
import NameUtils from '../../utilities/nameUtils';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import { BASE_URL } from '../../middleware/api';
import { getEvent, setSelectedEvent } from './events.actions';
import { getRegistrations } from '../registrations/registrations.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EventAssignmentsContainer extends Component {
  state = {
    page: 0,
    purchasers: [],
    rowsPerPage: 10,
    searchBox: '',
  };

  componentDidMount() {
    this.props.setPageTitle('Event Assignments');
    const { match, event } = this.props;
    const eventId = match.params.id;
    if (isEmpty(event) || event.id !== eventId) {
      this.props.getEvent(eventId);
    }
    this.setState({ eventId });
    this.props.getRegistrations(eventId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.purchasers !== this.props.purchasers) {
      /* If the confirmation code is null, then the cart was 
       abandoned or is in progress so do not show it. */
      const purchasers = nextProps.purchasers.filter(p => p.cart.confirmationCode !== null);
      this.setState({ purchasers, origPurchasers: purchasers });
    }
  }

  handleExport = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      // Set the authorization header for this request instance;
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await instance({
        url: `${BASE_URL}admin/events/${this.props.event.id}/ticketcsv`,
        method: 'GET',
        responseType: 'blob', // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PurchasesExport.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.props.handleToastMessage('Failed to export list!', true);
      console.error(e);
    }
  };

  handleBreakoutSessions = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      // Set the authorization header for this request instance;
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await instance({
        url: `${BASE_URL}v1/reports/ATTENDEE_BREAKOUT_SESSIONS?eventId=${this.state.eventId}`,
        method: 'GET',
        responseType: 'blob', // important
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'BreakoutSessions.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.props.handleToastMessage('Failed to get breakout sessions', true);
      console.error(e);
    }
  };

  search = value => {
    this.setState({ searchBox: value });
    const toMatch = new RegExp(value, 'i');
    if (value.length >= 2) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const purchasers = this.state.purchasers.filter(
        r => r.firstName.match(toMatch) || r.lastName.match(toMatch)
      );
      this.setState({ purchasers });
    } else {
      this.setState(prevState => ({ purchasers: prevState.origPurchasers }));
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { isLoading, products } = this.props;
    const { purchasers, page, rowsPerPage } = this.state;
    /* Create an object that has the productId as the key and item count per 
    product as the value. This is used to show the total number of tickets by 
    purchaser. */
    const counts = products.reduce((prods, p) => {
      prods[p.id] = p.count;
      return prods;
    }, {});

    if (isLoading) return <LoadingOverlay />;

    return (
      <div className="container">
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Typography color="primary" variant="title">
              {this.props.event && `${this.props.event.name} - Ticket Assignments`}
            </Typography>
            <div style={{ margin: '1.5rem 1rem' }}>
              <SFGOutlinedButton color="primary" onClick={this.handleExport}>
                Export List
              </SFGOutlinedButton>
              <SFGOutlinedButton
                color="primary"
                style={{
                  marginLeft: '1rem',
                  visibility: purchasers.length > 0 ? 'visible' : 'hidden',
                }}
                onClick={this.handleBreakoutSessions}
              >
                Export Breakout Sessions
              </SFGOutlinedButton>
              <Input
                placeholder="Filter"
                disableUnderline={false}
                inputProps={{
                  'aria-label': 'Filter',
                }}
                variant="outlined"
                onChange={e => this.search(e.target.value)}
                style={{
                  float: 'right',
                  minWidth: '10rem',
                  padding: '0.15rem 0.25rem',
                  // marginRight: '10rem',
                }}
                value={this.state.searchBox}
              />
            </div>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Purchaser</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Tickets Assigned / Tickets Purchased
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchasers &&
                    products &&
                    /* Calculate the number of tickets for this purchaser. */
                    purchasers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(p => {
                        // JM: This is very unclear what's happening. Needs to be reworked or at least have better comments
                        const ticketsAssigned = p.cart.tickets.reduce((sum, t) => {
                          // eslint-disable-next-line no-plusplus
                          if (t.assignee !== null) sum++;
                          return sum;
                        }, 0);
                        const totalTickets = p.cart.items.reduce((sum, i) => {
                          if (i.eventId === this.state.eventId) {
                            return sum + i.quantity * counts[i.productId];
                          }
                          return sum;
                        }, 0);
                        return (
                          <TableRow key={p.id}>
                            <TableCell>{NameUtils.getFormattedName(p)}</TableCell>
                            <TableCell>{p.email}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {`${ticketsAssigned} / ${totalTickets}`}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              <SFGTextButton
                                onClick={() => {
                                  this.props.history.push(
                                    `/events/${this.state.eventId}/tickets/${p.id}`
                                  );
                                }}
                              >
                                Manage Ticket Assignments
                              </SFGTextButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.purchasers.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

EventAssignmentsContainer.defaultProps = {
  event: null,
};

EventAssignmentsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  isLoading: PropTypes.bool.isRequired,
  event: PropTypes.object,
  products: PropTypes.array.isRequired,
  purchasers: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  getRegistrations: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading:
      state.events.get('isLoading') ||
      state.products.get('isLoading') ||
      state.registrations.get('isLoading'),
    event: state.events.get('selectedEvent'),
    products: state.products.get('products'),
    purchasers: state.registrations.get('registrations'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getEvent,
    setSelectedEvent,
    getRegistrations,
  })(EventAssignmentsContainer)
);
