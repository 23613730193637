import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import { find, isEmpty } from 'lodash';

import BillingInfo from '../registrations/billingInfo.component';
import TicketPurchasing from '../registrations/ticketPurchasing.component';

class OrderingPage extends Component {
  state = {
    automaticallyAssignTicket: false,
    shouldUsePersonalAddress: false,
  };

  componentDidMount() {
    this.shouldAutomaticallyAssignTicket();
  }

  componentDidUpdate(prevProps) {
    if (this.props.state.tickets !== prevProps.state.tickets)
      this.shouldAutomaticallyAssignTicket();
  }

  shouldAutomaticallyAssignTicket = () => {
    this.props.products.forEach(product => {
      const purchasePlan = find(product.purchasePlans, {
        installments: this.props.state.purchasePlan,
      });

      /* If no purchase plan could be found, return. */
      if (typeof purchasePlan === 'undefined') return;

      /* If no limit is set, don't do anything. */
      if (!purchasePlan.limit) return;

      /* Automatically assign a ticket to yourself when a purchase plan has a limit of one ticket. */
      const disabled =
        purchasePlan &&
        purchasePlan.limit === 1 &&
        parseInt(this.props.state.tickets[product.id], 10);

      if (disabled !== this.state.automaticallyAssignTicket) {
        const val = !!disabled;
        this.setState({ automaticallyAssignTicket: val });
        this.props.updateField('assignTicket', val);
      }
    });
  };

  setBillingToPersonalAddress = () => {
    const { updateBilling, state } = this.props;

    if (!this.state.shouldUsePersonalAddress) {
      this.setState({ shouldUsePersonalAddress: true });
      updateBilling('address', state.purchaser.address);
      updateBilling('address2', state.purchaser.address2);
      updateBilling('city', state.purchaser.city);
      updateBilling('state', state.purchaser.state);
      updateBilling('zip', state.purchaser.zip);
    } else {
      this.setState({ shouldUsePersonalAddress: false });
    }
  };

  render() {
    const { automaticallyAssignTicket } = this.state;
    return (
      <div className="page-padding-bottom">
        {!isEmpty(this.props.event) && (
          <div>
            <TicketPurchasing
              currentTotal={this.props.state.currentTotal}
              eventId={this.props.event.id}
              products={this.props.products}
              tickets={this.props.state.tickets}
              selectedPurchasePlan={this.props.state.purchasePlan}
              purchasePlans={this.props.purchasePlans}
              updateTickets={this.props.updateTickets}
              updatePurchasePlan={value => this.props.updateField('purchasePlan', value)}
            />
            <br />
            <FormControl style={{ display: 'block' }}>
              <FormLabel>
                Would you like to assign one of the tickets being purchased to yourself?
              </FormLabel>
              <RadioGroup
                name="assignTicket"
                value={this.props.state.assignTicket ? 'true' : 'false'}
                required
                onChange={e => this.props.updateField('assignTicket', e.target.value === 'true')}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" disabled={automaticallyAssignTicket} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" disabled={automaticallyAssignTicket} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {this.props.state.assignTicket && (
              <TextValidator
                style={{ maxWidth: 350, width: '100%' }}
                label="Name to be Printed on Badge"
                placeholder="At least first and last name"
                type="text"
                name="alsoKnownAs"
                margin="normal"
                helperText="First and Last as you'd like them to be seen"
                value={this.props.state.purchaser.alsoKnownAs}
                onChange={e => this.props.updateAttendee('alsoKnownAs', e.target.value)}
                validators={['required']}
                errorMessages={['Name on Badge is required']}
              />
            )}
            {Number(this.props.state.totalAmount) > 0 && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.shouldUsePersonalAddress}
                      onChange={this.setBillingToPersonalAddress}
                      value="isDraft"
                      color="primary"
                    />
                  }
                  label="Billing address is same as personal address"
                />
                <BillingInfo
                  state={this.props.state.billing}
                  updateField={this.props.updateBilling}
                  disableAddress={this.state.shouldUsePersonalAddress}
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

OrderingPage.defaultProps = {
  event: null,
};

OrderingPage.propTypes = {
  event: PropTypes.object,
  state: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  purchasePlans: PropTypes.array.isRequired,

  updateField: PropTypes.func.isRequired,
  updateBilling: PropTypes.func.isRequired,
  updateAttendee: PropTypes.func.isRequired,
  updateTickets: PropTypes.func.isRequired,
};

export default OrderingPage;
