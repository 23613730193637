import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { memoize } from 'lodash';

import LoadingOverlay from '../modules/layout/loadingOverlay.component';
import { GET_BIG5_AGENCIES_FAILURE, getAgencies } from '../modules/bigFive/bigFive.actions';
import { GET_COUPONS_FAILURE, getCoupons } from '../modules/coupons/coupons.actions';
import {
  GET_EVENTS_ADMIN_FAILURE,
  GET_EVENTS_FAILURE,
  getEvents,
  getEventsAdmin,
} from '../modules/events/events.actions';
import { GET_GATHERINGS_FAILURE, getGatherings } from '../modules/gatherings/gatherings.actions';
import { GET_MANAGERS_FAILURE, getManagers } from '../modules/managers/managers.actions';
import {
  GET_NOTIFICATIONS_FAILURE,
  getNotifications,
} from '../modules/notifications/notifications.actions';
import { GET_PRODUCTS_FAILURE, getProducts } from '../modules/products/products.actions';
import { GET_PROFILE_FAILURE, getProfile } from '../modules/profile/profile.actions';
import {
  GET_PURCHASEPLANS_FAILURE,
  getPurchasePlans,
} from '../modules/purchasePlans/purchasePlans.actions';
import { LOGIN_STATE, setInitialLoadComplete } from '../modules/login/login.actions';
import { handleToastMessage } from '../modules/layout/layout.actions';

/**
 * This component wraps the component you provide as an argument
 * and performs an initial load of data if it has not already done so.
 * You can optionally provide a callback that will be called on mount.
 * @param component: the component you want to show
 * @param callback: optional callback called on mount.
 */
const getInitializingComponent = memoize((component, cb) => {
  return withRouteOnEnter(cb)(component);
});

const withRouteOnEnter = callback => BaseComponent => {
  const routeOnEnterCallback = props => {
    if (callback && typeof callback === 'function') {
      callback(props);
    }
  };

  class routeOnEnterComponent extends Component {
    state = {
      isLoading: false,
    };

    componentDidMount() {
      routeOnEnterCallback(this.props);
      if (!this.props.initialLoadComplete) {
        this.initialLoad();
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.location !== this.props.location) {
        routeOnEnterCallback(this.props);
      }
    }

    render() {
      if (this.state.isLoading) {
        return <LoadingOverlay />;
      }
      return <BaseComponent {...this.props} />;
    }

    initialLoad = async () => {
      this.setState({ isLoading: true });

      let someFailed = false;
      const commonRequests = [];
      const userRequests = [];
      const altitudeRequests = [];
      const adminRequests = [];
      // requests made regardless of login state
      commonRequests.push(
        this.props.getEvents().then(response => {
          someFailed = someFailed || response.type === GET_EVENTS_FAILURE;
        })
      );
      commonRequests.push(
        this.props.getGatherings().then(response => {
          someFailed = someFailed || response.type === GET_GATHERINGS_FAILURE;
        })
      );
      await Promise.all([...commonRequests]);
      // console.log('DONE COMMON');

      if (this.props.loginStatus > LOGIN_STATE.NOT_LOGGED_IN) {
        // requests made for any logged in user
        userRequests.push(
          this.props.getProfile().then(response => {
            someFailed = someFailed || response.type === GET_PROFILE_FAILURE;
          })
        );
        userRequests.push(
          this.props.getNotifications().then(response => {
            someFailed = someFailed || response.type === GET_NOTIFICATIONS_FAILURE;
          })
        );
        userRequests.push(
          this.props.getManagers().then(response => {
            someFailed = someFailed || response.type === GET_MANAGERS_FAILURE;
          })
        );
      }
      await Promise.all([...userRequests]);
      // console.log('DONE USER');

      if (this.props.loginStatus > LOGIN_STATE.STANDARD_USER) {
        // requests made for altitude user and above
        altitudeRequests.push(
          this.props.getAgencies().then(response => {
            someFailed = someFailed || response.type === GET_BIG5_AGENCIES_FAILURE;
          })
        );
      }
      await Promise.all([...altitudeRequests]);
      // console.log('DONE ALTITUDE');

      if (this.props.loginStatus === LOGIN_STATE.SYSTEM_ADMIN) {
        // system admin only requests
        adminRequests.push(
          this.props.getEventsAdmin().then(response => {
            someFailed = someFailed || response.type === GET_EVENTS_ADMIN_FAILURE;
          })
        );
        adminRequests.push(
          this.props.getAgencies().then(response => {
            someFailed = someFailed || response.type === GET_BIG5_AGENCIES_FAILURE;
          })
        );
        adminRequests.push(
          this.props.getProducts().then(response => {
            someFailed = someFailed || response.type === GET_PRODUCTS_FAILURE;
          })
        );
        adminRequests.push(
          this.props.getPurchasePlans().then(response => {
            someFailed = someFailed || response.type === GET_PURCHASEPLANS_FAILURE;
          })
        );
        adminRequests.push(
          this.props.getCoupons().then(response => {
            someFailed = someFailed || response.type === GET_COUPONS_FAILURE;
          })
        );
      }
      await Promise.all([...adminRequests]);
      // console.log('DONE ADMIN');

      this.setState({ isLoading: false });
      if (!someFailed) {
        this.props.setInitialLoadComplete(true);
      }
    };
  }

  routeOnEnterComponent.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,

    initialLoadComplete: PropTypes.bool.isRequired,
    loginStatus: PropTypes.number.isRequired,

    getAgencies: PropTypes.func.isRequired,
    getCoupons: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
    getEventsAdmin: PropTypes.func.isRequired,
    getGatherings: PropTypes.func.isRequired,
    getManagers: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    getPurchasePlans: PropTypes.func.isRequired,
    handleToastMessage: PropTypes.func.isRequired,
    setInitialLoadComplete: PropTypes.func.isRequired,
  };

  const mapStateToProps = state => {
    return {
      loginStatus: state.login.get('loginStatus'),
      initialLoadComplete: state.login.get('initialLoadComplete'),
    };
  };

  return connect(mapStateToProps, {
    getAgencies,
    getCoupons,
    getEvents,
    getEventsAdmin,
    getGatherings,
    getManagers,
    getNotifications,
    getProducts,
    getProfile,
    getPurchasePlans,
    handleToastMessage,
    setInitialLoadComplete,
  })(routeOnEnterComponent);
};

export default getInitializingComponent;
