import { CALL_API } from '../../middleware/api';

export const GET_EVENT_SCHEDULE_REQUEST = 'GET_EVENT_SCHEDULE_REQUEST';
export const GET_EVENT_SCHEDULE_SUCCESS = 'GET_EVENT_SCHEDULE_SUCCESS';
export const GET_EVENT_SCHEDULE_FAILURE = 'GET_EVENT_SCHEDULE_FAILURE';

export const GET_MY_SCHEDULE_REQUEST = 'GET_MY_SCHEDULE_REQUEST';
export const GET_MY_SCHEDULE_SUCCESS = 'GET_MY_SCHEDULE_SUCCESS';
export const GET_MY_SCHEDULE_FAILURE = 'GET_MY_SCHEDULE_FAILURE';

export const GET_MY_SCHEDULE_CURRENT_REQUEST = 'GET_MY_SCHEDULE_CURRENT_REQUEST';
export const GET_MY_SCHEDULE_CURRENT_SUCCESS = 'GET_MY_SCHEDULE_CURRENT_SUCCESS';
export const GET_MY_SCHEDULE_CURRENT_FAILURE = 'GET_MY_SCHEDULE_CURRENT_FAILURE';

export const REGISTER_FOR_BREAKOUT_SESSION_REQUEST = 'REGISTER_FOR_BREAKOUT_SESSION_REQUEST';
export const REGISTER_FOR_BREAKOUT_SESSION_SUCCESS = 'REGISTER_FOR_BREAKOUT_SESSION_SUCCESS';
export const REGISTER_FOR_BREAKOUT_SESSION_FAILURE = 'REGISTER_FOR_BREAKOUT_SESSION_FAILURE';

export const UNREGISTER_FOR_BREAKOUT_SESSION_REQUEST = 'UNREGISTER_FOR_BREAKOUT_SESSION_REQUEST';
export const UNREGISTER_FOR_BREAKOUT_SESSION_SUCCESS = 'UNREGISTER_FOR_BREAKOUT_SESSION_SUCCESS';
export const UNREGISTER_FOR_BREAKOUT_SESSION_FAILURE = 'UNREGISTER_FOR_BREAKOUT_SESSION_FAILURE';

export const RESET_MY_SCHEDULE = 'RESET_MY_SCHEDULE';

export const getEventSchedule = eventId => {
  return {
    [CALL_API]: {
      types: [GET_EVENT_SCHEDULE_REQUEST, GET_EVENT_SCHEDULE_SUCCESS, GET_EVENT_SCHEDULE_FAILURE],
      authenticated: true,
      endpoint: `v1/events/${eventId}/schedule`,
      method: 'GET',
    },
  };
};

export const getMySchedule = eventId => {
  return {
    [CALL_API]: {
      types: [GET_MY_SCHEDULE_REQUEST, GET_MY_SCHEDULE_SUCCESS, GET_MY_SCHEDULE_FAILURE],
      authenticated: true,
      endpoint: `v1/events/${eventId}/myschedule`,
      method: 'GET',
    },
  };
};

export const getMyScheduleCurrent = eventId => {
  return {
    [CALL_API]: {
      types: [
        GET_MY_SCHEDULE_CURRENT_REQUEST,
        GET_MY_SCHEDULE_CURRENT_SUCCESS,
        GET_MY_SCHEDULE_CURRENT_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/events/${eventId}/myschedule?current=true`,
      method: 'GET',
    },
  };
};

export const registerForBreakoutSession = (eventId, slotId, sessionId) => {
  return {
    [CALL_API]: {
      types: [
        REGISTER_FOR_BREAKOUT_SESSION_REQUEST,
        REGISTER_FOR_BREAKOUT_SESSION_SUCCESS,
        REGISTER_FOR_BREAKOUT_SESSION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/events/${eventId}/schedule/${slotId}/sessions/${sessionId}/register`,
      method: 'POST',
    },
  };
};

export const unregisterForBreakoutSession = (eventId, slotId, sessionId) => {
  return {
    [CALL_API]: {
      types: [
        UNREGISTER_FOR_BREAKOUT_SESSION_REQUEST,
        UNREGISTER_FOR_BREAKOUT_SESSION_SUCCESS,
        UNREGISTER_FOR_BREAKOUT_SESSION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/events/${eventId}/schedule/${slotId}/sessions/${sessionId}/unregister`,
      method: 'POST',
    },
  };
};

export const resetMySchedule = () => {
  return {
    type: RESET_MY_SCHEDULE,
  };
};
