import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import MaxWidthDialog from '../layout/dialogs/maxWidthDialog.component';
import { handleToastMessage } from '../layout/layout.actions';
import {
  resendVerification,
  setEmailToVerify,
  setNonAdminEmail,
  setShowVerifyAction,
} from './login.actions';

const LoginWarning = ({ emailToVerify, showAction, nonAdminEmail }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleClose = () => {
    if (emailToVerify) {
      // set emailToVerify to null in redux store, which should close the dialog
      dispatch(setEmailToVerify(null));
    }
    if (nonAdminEmail) {
      // set nonAdminEmail to null in redux store, which should close the dialog
      dispatch(setNonAdminEmail(null));
    }
    dispatch(setShowVerifyAction(false));
    // replace url with no email and no verification flag --- not necessary since rerouting would have already happened
    history.replace(location.pathname);
  };

  const sendEmail = () => {
    dispatch(resendVerification({ email: emailToVerify }));
    // Might be good to interpret error ressponse here too
    dispatch(handleToastMessage('Verification email sent.'));
    handleClose();
  };

  let message = '';
  let heading = '';

  if (nonAdminEmail) {
    heading = 'Non Admin Account';
    message = `${nonAdminEmail} is not an admin account. Only admins may log in.`;
  } else if (emailToVerify) {
    heading = 'Account Not Verified';
    message = `${emailToVerify} is not a verified account. Please check your email for a link to verify your account${
      showAction ? ' or click to send another verification email.' : '.'
    }`;
  }

  return (
    <MaxWidthDialog
      // null action text can be set to result in no action being shown
      actionText={showAction ? 'Send Verification Email' : null}
      cancelText={showAction ? 'Cancel' : 'OK'}
      dialogContentElem={<Typography align="center">{message}</Typography>}
      headingText={heading}
      open={!isNil(emailToVerify) || !isNil(nonAdminEmail)}
      handleAction={sendEmail}
      onDialogClose={handleClose}
    />
  );
};

LoginWarning.defaultProps = {
  nonAdminEmail: null,
  emailToVerify: null,
  showAction: false,
};

LoginWarning.propTypes = {
  nonAdminEmail: PropTypes.string,
  emailToVerify: PropTypes.string,
  showAction: PropTypes.bool,
};

const mapStateToProps = state => ({
  nonAdminEmail: state.login.get('nonAdminEmail'),
  emailToVerify: state.login.get('emailToVerify'),
  showAction: state.login.get('showVerifyAction'),
});

export default connect(mapStateToProps, null)(LoginWarning);
