import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import { find, isEmpty } from 'lodash';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { formatDate, utcToLocal } from '../../utilities/convertTimes';

class CouponForm extends Component {
  constructor(props) {
    super(props);

    let state = {
      code: '',
      percentOff: 1,
      maxValue: '',
      startDate: '',
      endDate: '',
      eventId: '',
    };

    if (!isEmpty(props.coupon)) {
      state = {
        ...props.coupon,
      };

      state.startDate = formatDate(utcToLocal(props.coupon.startDate), false);
      state.endDate = formatDate(utcToLocal(props.coupon.endDate), false);

      if (state.maxValue) {
        state.maxValue = parseFloat(state.maxValue / 100).toFixed(2);
      } else {
        state.maxValue = '';
      }
    }
    this.state = state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coupon !== this.props.coupon) {
      const state = { ...nextProps.coupon };
      state.startDate = formatDate(nextProps.coupon.startDate, false);
      state.endDate = formatDate(nextProps.coupon.endDate, false);
      if (state.maxValue) {
        state.maxValue = parseFloat(state.maxValue / 100).toFixed(2);
      } else {
        state.maxValue = '';
      }
      this.setState(state);
    }
  }

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
  };

  isFormValid = () => {
    const requiredFields = ['code', 'percentOff', 'startDate', 'endDate', 'eventId'];
    const invalidFields = requiredFields.filter(field => {
      return isEmpty(this.state[field]) && !Number.isInteger(this.state[field]);
    });
    return !(invalidFields.length > 0);
  };

  handleSubmit = e => {
    e.preventDefault();

    const couponData = {
      code: this.state.code,
      percentOff: this.state.percentOff,
      maxValue: this.state.maxValue,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      eventId: this.state.eventId,
    };

    couponData.startDate = formatDate(couponData.startDate);
    couponData.endDate = formatDate(couponData.endDate);

    // console.log('SUBMITTING COUPON DATA!', couponData);

    /* If the maxValue isn't set, remove the field before adding/updating the
    data. If it is set, convert from dollars to cents for the server. */
    if (couponData.maxValue !== '') {
      couponData.maxValue *= 100;
    } else {
      couponData.maxValue = null;
    }
    console.log(couponData);
    this.props.submitAction(couponData);
  };

  render() {
    const { events } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container>
          <Grid container item spacing={16}>
            <Grid item xs>
              <TextField
                label="Coupon Code"
                type="text"
                margin="normal"
                value={this.state.code}
                onChange={e => this.updateField('code', e.target.value)}
                style={{ minWidth: 280 }}
                autoCapitalize="off"
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="Percent Off"
                type="number"
                step="1"
                margin="normal"
                value={this.state.percentOff}
                onChange={e => this.updateField('percentOff', e.target.value)}
                style={{ minWidth: 280 }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="Max Value"
                type="number"
                step="1"
                margin="normal"
                value={this.state.maxValue}
                onChange={e => this.updateField('maxValue', e.target.value)}
                style={{ minWidth: 280 }}
                helperText="This is the maximum dollar amount that a coupon can be worth."
              />
            </Grid>
          </Grid>

          <Grid container item spacing={16}>
            <Grid item xs={12} sm>
              <TextField
                label="Start Date"
                helperText="All dates are relative to EST"
                type="date"
                margin="normal"
                value={this.state.startDate}
                onChange={e => this.updateField('startDate', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm>
              <TextField
                label="End Date"
                helperText="All dates are relative to EST"
                type="date"
                margin="normal"
                value={this.state.endDate}
                onChange={e => this.updateField('endDate', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm />
          </Grid>

          <Grid container item spacing={16}>
            <Grid item xs>
              <FormControl style={{ minWidth: 280 }}>
                <Select
                  value={this.state.eventId}
                  displayEmpty
                  onChange={e => this.updateField('eventId', e.target.value)}
                  inputProps={{
                    name: 'eventName',
                  }}
                  style={{ marginTop: '1rem' }}
                >
                  <MenuItem key="" value="" disabled>
                    Event
                  </MenuItem>

                  {events.map(evt => {
                    const agency = find(this.props.agencies, { id: evt.agencyId });
                    return (
                      <MenuItem key={evt.id} value={evt.id}>
                        {`${evt.name} `}
                        {agency ? `(${agency.owner.name})` : ''}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs />
            <Grid item xs />
          </Grid>

          <Grid item>
            <div style={{ marginTop: '4rem' }}>
              <SFGOutlinedButton
                color="primary"
                disabled={!this.isFormValid()}
                type="submit"
                onClick={this.handleSubmit}
              >
                Submit
              </SFGOutlinedButton>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

CouponForm.defaultProps = {
  coupon: null,
};

CouponForm.propTypes = {
  coupon: PropTypes.object,
  submitAction: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,
};

export default CouponForm;
