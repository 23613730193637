import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import LoadingOverlay from '../layout/loadingOverlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import {
  DELETE_PURCHASEPLAN_SUCCESS,
  deletePurchasePlan,
  getPurchasePlans,
} from './purchasePlans.actions';
import { formatDate, utcToLocal } from '../../utilities/convertTimes';
import { getEventsAdminArchived } from '../events/events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import { selectAllEventsAdmin } from '../events/events.selector';

class PurchasePlansContainer extends Component {
  componentDidMount() {
    this.props.setPageTitle('Purchase Plans');
    this.props.getPurchasePlans();
    this.props.getEventsAdminArchived();
  }

  editPurchasePlan = purchasePlan => {
    this.props.history.push({
      pathname: `purchaseplans/${purchasePlan.id}`,
    });
  };

  removePurchasePlan = async id => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to remove this Purchase Plan?`)) {
      const response = await this.props.deletePurchasePlan(id);
      if (response.type === DELETE_PURCHASEPLAN_SUCCESS) {
        this.props.handleToastMessage('Purchase Plan deleted.');
      } else {
        this.props.handleToastMessage('Failed to delete Purchase Plan', true);
      }
    }
  };

  render() {
    const { events, products, purchasePlans, isLoading, classes } = this.props;

    if (isLoading) return <LoadingOverlay />;
    return (
      <div className="container">
        <SFGOutlinedButton
          color="primary"
          className="button"
          onClick={() => this.props.history.push('/purchasePlanNew')}
        >
          New Purchase Plan
        </SFGOutlinedButton>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="20%">Name</TableCell>
              <TableCell width="20%">Event</TableCell>
              <TableCell width="18%">Product</TableCell>
              <TableCell width="30%">Date Range</TableCell>
              <TableCell width="4%">Cost</TableCell>
              <TableCell width="5%" style={{ textAlign: 'center' }}>
                Installments
              </TableCell>
              <TableCell width="4%" style={{ textAlign: 'center' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchasePlans.map((purchasePlan, i) => {
              let eventName = '';
              const evt = events.find(e => {
                return e.id === purchasePlan.eventId;
              });
              /* This can be null only when an event no longer exists. It
                should never happen but if it does, the page won't error
                out this way.
                */
              eventName = evt ? evt.name : '';
              let productName = '';
              if (!isEmpty(products)) {
                const prod = products.find(p => p.id === purchasePlan.productId);
                productName = prod ? prod.name : '';
              }
              return (
                <TableRow
                  key={purchasePlan.id}
                  className={classNames({
                    [classes.borderTopHeavy]:
                      i > 0 && purchasePlans[i - 1].eventId !== purchasePlans[i].eventId,
                  })}
                >
                  <TableCell
                    className="clickable"
                    onClick={() => this.editPurchasePlan(purchasePlan)}
                  >
                    <Typography variant="body1">
                      {purchasePlan.name ? purchasePlan.name : '- - -'}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className="clickable"
                    onClick={() => this.editPurchasePlan(purchasePlan)}
                  >
                    <Typography variant="body1">{eventName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{productName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {formatDate(utcToLocal(purchasePlan.startDate), false)}
                      <span>&nbsp;&mdash;&nbsp;</span>
                      {formatDate(utcToLocal(purchasePlan.endDate), false)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {/* eslint-disable-next-line */}
                      {parseFloat(purchasePlan.cost / 100).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Typography variant="body1">{purchasePlan.installments}</Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <SFGTextButton
                      color="primary"
                      onClick={() => this.removePurchasePlan(purchasePlan.id)}
                    >
                      <i className="material-icons">close</i>
                    </SFGTextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  borderTopHeavy: {
    borderTop: '0.175rem solid',
  },
});

PurchasePlansContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  events: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  purchasePlans: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  deletePurchasePlan: PropTypes.func.isRequired,
  getPurchasePlans: PropTypes.func.isRequired,
  getEventsAdminArchived: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    events: selectAllEventsAdmin(state),
    products: state.products.get('products'),
    purchasePlans: state.purchasePlans.get('purchasePlans').sort((a, b) => {
      const eventIdA = a.eventId;
      const eventIdB = b.eventId;

      if (eventIdA > eventIdB) {
        return 1;
      }

      if (eventIdB > eventIdA) {
        return -1;
      }

      // If the events are the same, sort the two purchase plans by the name.
      const sortedArray = [a.name, b.name].sort();
      //  If the A is the first name or the names are the same, put A first.
      if (a.name === sortedArray[0]) {
        return -1;
        // Otherwise, B is first.
      }
      return 1;
    }),
    isLoading: state.purchasePlans.get('isLoading') || state.events.get('isLoading'),
  };
};

const routedConnectedPurchasedPlansContainer = withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getPurchasePlans,
    deletePurchasePlan,
    getEventsAdminArchived,
  })(PurchasePlansContainer)
);

export default withStyles(styles)(routedConnectedPurchasedPlansContainer);
