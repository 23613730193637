import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  TextField
} from '@material-ui/core';
import { find, isEmpty } from 'lodash';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

import linkDecorator from '../../layout/richEditor/linkDecorator';
import ReadOnlyTextField from '../../layout/readOnlyTextField/readOnlyTextField.component';
import LoadingOverlay from '../../layout/loadingOverlay.component';
import { getOwner } from '../../../utilities/notificationHelper';
import RichEditor from '../../layout/richEditor/richEditor.component';
import ImageUploader from '../../layout/imageUploader.component';

class NotificationBasicInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      deepLink: '',
      owner: {},
      descriptionHtml: EditorState.createEmpty(linkDecorator),
      image: {},
      imageUrl: ''
    };
  }

  componentDidMount() {
    const { notification } = this.props;
    if (!isEmpty(notification)) {
      this.setState({
        title: notification.title ? notification.title : '',
        description: notification.description ? notification.description : '',
        deepLink: notification.deepLink ? notification.deepLink : '',
        owner: notification.owner ? notification.owner : {},
        imageUrl: notification.imageUrl ? notification.imageUrl : '',
        descriptionHtml: !isEmpty(notification.descriptionHtml)
          ? EditorState.createWithContent(
              stateFromHTML(notification.descriptionHtml),
              linkDecorator
            )
          : EditorState.createEmpty(linkDecorator)
      });
    }
  }

  handleImageUpdate = image => {
    this.updateField('image', image);
  };

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
    this.props.onFormUpdate(state);
  };

  getEventName = id => {
    if (!isEmpty(this.props.events)) {
      const event = this.props.events.find(e => e.id === id);
      let agency;
      if (event) {
        agency = find(this.props.agencies, { id: event.agencyId });
      }
      return event ? `${event.name} ${agency ? `(${agency.owner.name})` : `${event.name}`} ` : '';
    }
    return '';
  };

  render() {
    const { classes, isReadOnly, events, agencies } = this.props;

    if (events.length === 0 && agencies.length === 0) {
      return <LoadingOverlay />;
    }

    return (
      <div className="container">
        <Grid container spacing={32}>
          <Grid item xs={12} md={6}>
            <div>
              <Typography variant="subheading">Notification Info</Typography>
              {!isReadOnly && (
                <TextField
                  label="Notification Title"
                  type="text"
                  margin="normal"
                  value={this.state.title}
                  onChange={e => this.updateField('title', e.target.value)}
                  fullWidth
                  required={isReadOnly !== true}
                  InputProps={{
                    readOnly: isReadOnly === true
                  }}
                />
              )}
              {isReadOnly && (
                <ReadOnlyTextField label="Notification Title" value={this.state.title} />
              )}
            </div>

            <div>
              {!isReadOnly && (
                <TextField
                  label="Notification Description"
                  type="text"
                  margin="normal"
                  value={this.state.description}
                  onChange={e => this.updateField('description', e.target.value)}
                  fullWidth
                  required={isReadOnly !== true}
                  multiline
                  InputProps={{
                    readOnly: isReadOnly === true
                  }}
                />
              )}
              {isReadOnly && (
                <ReadOnlyTextField
                  label="Notification Description"
                  value={this.state.description}
                />
              )}
            </div>

            <div>
              {!isReadOnly && (
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="deepLink">Event Link</InputLabel>
                  <Select
                    value={this.state.deepLink}
                    onChange={e => this.updateField('deepLink', e.target.value)}
                    inputProps={{
                      name: 'eventDeepLink',
                      id: 'deepLink'
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {events.map(event => {
                      const agency = find(agencies, { id: event.agencyId });
                      return (
                        <MenuItem key={event.id} value={event.id}>
                          {`${event.name} ${agency && agency.owner.name}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {isReadOnly && (
                <ReadOnlyTextField
                  label="Event Link"
                  value={this.getEventName(this.state.deepLink)}
                />
              )}
            </div>

            <div>
              <Typography variant="subheading">Notification Image (optional)</Typography>
              {!isReadOnly && <ImageUploader handleImageUpdate={this.handleImageUpdate} />}
              {isReadOnly && (
                <Fragment>
                  {this.state.imageUrl ? (
                    <img
                      src={this.state.imageUrl}
                      alt="notification"
                      className={classes.imagePreview}
                    />
                  ) : (
                    <p>No image uploaded</p>
                  )}
                </Fragment>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.editorArea}>
              <div className={classes.editorWrapper}>
                <RichEditor
                  placeholder="Inbox Details"
                  editorState={this.state.descriptionHtml}
                  updateEditorState={editorState =>
                    this.updateField('descriptionHtml', editorState)
                  }
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {isReadOnly && (
          <Grid container spacing={32}>
            <Grid item xs={12}>
              <ReadOnlyTextField
                label="Sender"
                value={this.state.owner ? getOwner(this.state.owner) : ''}
              />
            </Grid>
            <Grid item xs={12} />
          </Grid>
        )}
      </div>
    );
  }
}

const styles = {
  spacerBottom: {
    marginBottom: '2rem'
  },
  editorArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  editorWrapper: {
    border: `1px dotted rgba(0, 0, 0, 0.43)`,
    width: '350px', // 215
    height: '650px', // 400
    overflow: 'auto'
  },
  imagePreview: {
    objectFit: 'contain',
    maxWidth: '100%'
  }
};

NotificationBasicInfoForm.defaultProps = {
  notification: null
};

NotificationBasicInfoForm.propTypes = {
  classes: PropTypes.object.isRequired,
  notification: PropTypes.object,
  isReadOnly: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,

  onFormUpdate: PropTypes.func.isRequired
};

export default withStyles(styles)(NotificationBasicInfoForm);
