import CloseIcon from '@material-ui/icons/Close';
import EventAvailableRoundedIcon from '@material-ui/icons/EventAvailableRounded';
import GroupIcon from '@material-ui/icons/GroupOutlined';
import MyLocationIcon from '@material-ui/icons/MyLocationOutlined';
import PlayIcon from '@material-ui/icons/ChangeHistoryRounded';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import {
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
  withWidth,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { compose } from 'recompose';
import { isWidthDown } from '@material-ui/core/withWidth';
import { withRouter } from 'react-router-dom';
// import NearMeRoundedIcon from '@material-ui/icons/NearMeRounded';

import Colors from '../../styles/colors';
import SFGPrimaryButton from '../../common/buttons/sfgPrimaryButton.component';
import eventsImg from '../../images/eventsHeader.png';
import logo from '../../images/logoHorizontalAgentEvents.png';
import { isStartDateAfterEndDate } from '../../utilities/dataUtils';

export const SEARCH_BY_ALL = 'SEARCH_BY_ALL';
export const SEARCH_BY_NEAR_ME = 'SEARCH_BY_NEAR_ME';
export const SEARCH_BY_ZIP = 'SEARCH_BY_ZIP';
export const SEARCH_BY_ONLINE = 'SEARCH_BY_ONLINE';
export const SEARCH_BY_CONFERENCES = 'SEARCH_BY_CONFERENCES';

const EVENT_SEARCH_ITEMS = [
  { name: 'All Events', value: SEARCH_BY_ALL, icon: <EventAvailableRoundedIcon /> },
  // { name: 'Events Near Me', value: SEARCH_BY_NEAR_ME, icon: <NearMeRoundedIcon /> },
  { name: 'Events Near Zip', value: SEARCH_BY_ZIP, icon: <MyLocationIcon /> },
  { name: 'Online Events', value: SEARCH_BY_ONLINE, icon: <PlayIcon /> },
  { name: 'Conferences', value: SEARCH_BY_CONFERENCES, icon: <GroupIcon /> },
];

const EventsHeader = ({
  classes,
  history,
  width,
  isLoggedIn,
  searchType,
  onSearchTypeChange,
  onZipCodeSearch,
  onUpdateStartDate,
  onUpdateEndDate,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isDateRangeError, setIsDateRangeError] = useState(false);

  const handleSearchTypeChange = useCallback(
    event => {
      setZipCode('');
      onSearchTypeChange(event);
    },
    [onSearchTypeChange]
  );

  const handleUpdateEndDate = newEndDate => {
    setEndDate(newEndDate);
    const error = isStartDateAfterEndDate(startDate, newEndDate);
    setIsDateRangeError(error);
    if (error) {
      onUpdateStartDate('');
      onUpdateEndDate('');
    } else {
      onUpdateStartDate(startDate);
      onUpdateEndDate(newEndDate);
    }
  };

  const handleUpdateStartDate = newStartDate => {
    setStartDate(newStartDate);
    const error = isStartDateAfterEndDate(newStartDate, endDate);
    setIsDateRangeError(error);
    if (error) {
      onUpdateStartDate('');
      onUpdateEndDate('');
    } else {
      onUpdateStartDate(newStartDate);
      onUpdateEndDate(endDate);
    }
  };

  const handleSearchGatheringsByZip = useCallback(
    event => {
      event.preventDefault();

      onZipCodeSearch(zipCode);
    },
    [zipCode, onZipCodeSearch]
  );

  const handleToggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

  return (
    <Grid className={classes.gridContainer} container spacing={16}>
      {!isLoggedIn && (
        <>
          <div className={classes.loginWrapper}>
            <img
              src={logo}
              className={classes.logo}
              alt="Quility"
              style={{ maxHeight: isWidthDown('sm', width) ? '1.5rem' : '3rem' }}
            />
            <SFGPrimaryButton onClick={() => history.push('/login')}>Login</SFGPrimaryButton>
          </div>
        </>
      )}
      <Grid container alignItems="center" item xs={12} className={classes.mainGridRow}>
        {isLoggedIn && (
          <Grid
            className={classes.searchContainer}
            container
            item
            xs={12}
            sm={6}
            style={isWidthDown('xs', width) ? { order: 2 } : {}}
          >
            <Grid item xs={12}>
              <Typography color="primary" variant="display1">
                Connect Through
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={16}>
              <Grid item xs={12} sm={searchType === SEARCH_BY_ZIP ? 6 : 9}>
                <Select
                  classes={{ select: classes.select }}
                  displayEmpty
                  fullWidth
                  open={isMenuOpen}
                  value={searchType}
                  onChange={handleSearchTypeChange}
                  onClose={handleToggleMenu}
                  onOpen={handleToggleMenu}
                >
                  {EVENT_SEARCH_ITEMS.map(searchItem => (
                    <MenuItem key={searchItem.value} value={searchItem.value}>
                      {(searchType !== searchItem.value || isMenuOpen) && (
                        <Icon
                          className={classNames(classes.itemIcon, {
                            [classes.rotateIcon]: searchItem.value === SEARCH_BY_ONLINE,
                          })}
                        >
                          {searchItem.icon}
                        </Icon>
                      )}
                      {searchItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {searchType === SEARCH_BY_ZIP && (
                <>
                  <Grid item xs={12} sm={6}>
                    <ValidatorForm
                      className={classes.search}
                      onSubmit={handleSearchGatheringsByZip}
                    >
                      <TextValidator
                        inputProps={{ className: classes.searchInput }}
                        fullWidth
                        InputProps={{
                          endAdornment: zipCode ? (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setZipCode('')} disableRipple>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        margin="none"
                        name="zipCode"
                        placeholder="Zip Code"
                        value={zipCode}
                        validators={['required', 'matchRegexp:^[\\-0-9]+$', 'minStringLength:5']}
                        errorMessages={[
                          'Zip code is required',
                          'A zip code may only contain numbers and dashes',
                          'A zip code must be at least 5 numbers',
                        ]}
                        onChange={event => setZipCode(event.target.value)}
                      />
                    </ValidatorForm>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container item xs={12} justify="flex-end" spacing={16}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="From"
                  helperText={
                    isDateRangeError
                      ? 'From date must be before To date'
                      : 'All dates are relative to EST'
                  }
                  type="date"
                  margin="normal"
                  value={startDate}
                  error={isDateRangeError}
                  onChange={event => handleUpdateStartDate(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="To"
                  helperText={
                    isDateRangeError
                      ? 'From date must be before To date'
                      : 'All dates are relative to EST'
                  }
                  type="date"
                  margin="normal"
                  value={endDate}
                  error={isDateRangeError}
                  onChange={event => handleUpdateEndDate(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={isLoggedIn ? 6 : 12}>
          <div className={classes.eventsImgWrapper}>
            <img src={eventsImg} className={classes.eventsImg} alt="Quility" />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  gridContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  mainGridRow: {
    backgroundColor: theme.palette.primary.main,
    padding: `0 !important`,
  },
  loginWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  logo: {
    maxHeight: '3rem',
  },
  itemIcon: {
    marginRight: '1rem',
  },
  rotateIcon: {
    transform: 'rotate(90deg)',
  },
  select: {
    padding: '.5rem',
  },

  searchContainer: {
    backgroundColor: Colors.grayLight,
    padding: '1rem 2rem',
  },
  searchInput: {
    paddingBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
  },
  eventsImgWrapper: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  eventsImg: {
    maxWidth: '100%',
    maxHeight: '175px',
  },
});

EventsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  width: PropTypes.string.isRequired,

  isLoggedIn: PropTypes.bool.isRequired,
  searchType: PropTypes.string.isRequired,

  onSearchTypeChange: PropTypes.func.isRequired,
  onZipCodeSearch: PropTypes.func.isRequired,
  onUpdateStartDate: PropTypes.func.isRequired,
  onUpdateEndDate: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withRouter, withWidth())(EventsHeader);
