import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { has, sortBy } from 'lodash';
import { setPageTitle } from '../../layout/layout.actions';
import LoadingOverlay from '../../layout/loadingOverlay.component';
import { lookupPurchases } from '../../layout/autocomplete/autocomplete.actions';
import PurchaseTransactions from './purchaseTransactions.container';

class HelpDeskManagePurchases extends Component {
  state = {
    purchases: [],
    isLoading: false
  };

  async componentWillMount() {
    this.props.setPageTitle('Purchases and Transactions');

    if (this.state.purchases.length === 0) {
      if (has(this.props.location.state, 'purchases')) {
        this.setState({ purchases: this.props.location.state.purchases });
      } else {
        this.getPurchases();
      }
    }
  }

  getPurchases = async () => {
    const { eventId, purchaseEmail } = this.props.match.params;
    this.setState({ isLoading: true });
    const purchasesSearch = await this.props.lookupPurchases(purchaseEmail, eventId);
    if (purchasesSearch.type === 'LOOKUP_PURCHASES_SUCCESS') {
      this.setState({
        purchases: purchasesSearch.response,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { purchases, isLoading } = this.state;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <div className="container">
        {purchases.length === 0 && (
          <div style={{ margin: '1rem', textAlign: 'center' }}>No purchases found.</div>
        )}
        {purchases.length > 0 &&
          sortBy(purchases, p => p.createdDate).map(purchase => (
            <PurchaseTransactions
              key={purchase.id}
              purchase={purchase}
              updatePurchases={this.getPurchases}
              readOnly={false}
            />
          ))}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  formControl: {
    display: 'block'
  }
});

HelpDeskManagePurchases.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  lookupPurchases: PropTypes.func.isRequired
};

export default connect(null, {
  setPageTitle,
  lookupPurchases
})(withStyles(styles)(withRouter(HelpDeskManagePurchases)));
