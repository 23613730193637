import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { find } from 'lodash';

import CustomSimpleMenu from '../layout/customSimpleMenu/customSimpleMenu.component';

const EventsDataTable = ({ events, agencies, rowActionMenuItems, isArchived, classes }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell width="45%">Event Name</TableCell>
        <TableCell width="20%">Agency</TableCell>
        <TableCell width="10%" style={{ textAlign: 'center' }}>
          Tickets Purchased
        </TableCell>
        <TableCell width="10%" style={{ textAlign: 'center' }}>
          Tickets Assigned
        </TableCell>
        <TableCell width="10%" style={{ textAlign: 'center' }}>
          Tickets Canceled
        </TableCell>
        <TableCell width="5%" style={{ textAlign: 'center' }}>
          Menu
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {events.map((event, i) => {
        const agency = find(agencies, { id: event.agencyId });
        const actionMenuItems = rowActionMenuItems(event, i);
        return (
          <TableRow key={event.id}>
            <TableCell>
              <Typography variant="body1">
                {event.isDraft && <em className={classes.statusIndicator}>(Draft) </em>}
                {isArchived && <em className={classes.statusIndicator}>(Archived) </em>}
                {event.name}
              </Typography>
            </TableCell>
            <TableCell>{agency ? agency.owner.name : ''}</TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              {'ticketsPurchased' in event ? event.ticketsPurchased : ''}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              {'ticketsAssigned' in event ? event.ticketsAssigned : ''}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              {'ticketsCanceled' in event ? event.ticketsCanceled : ''}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <CustomSimpleMenu menuItems={actionMenuItems} icon={<MoreVertIcon />} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

const styles = theme => ({
  statusIndicator: {
    color: theme.palette.secondary.dark,
  },
});

EventsDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,
  rowActionMenuItems: PropTypes.func.isRequired,
  isArchived: PropTypes.bool.isRequired,
};

export default withStyles(styles)(EventsDataTable);
