import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { findIndex, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import EventFormHelper from '../../utilities/eventFormHelper';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { EMAIL_TEMPLATE_LABELS, EMAIL_TEMPLATE_TOOLTIPS } from '../../utilities/emailTemplates';

const EventEmailTemplatesForm = ({ classes, emailTemplates, selectedEvent, onSubmit }) => {
  const [event, setEvent] = useState({
    ...EventFormHelper.initEventState(selectedEvent),
    emailTemplates: {},
  });

  useEffect(() => {
    setEvent({ ...EventFormHelper.initEventState(selectedEvent) });
  }, [selectedEvent]);

  const handleSubmit = e => {
    e.preventDefault();
    const eventData = EventFormHelper.prepareEventData(event);

    onSubmit(eventData);
  };

  const handleUpdateEmailTemplate = (field, value) => {
    const updatedEvent = { ...event };

    const updatedObject = { label: field, slug: value };

    if (event.emailTemplates) {
      // see if the entry for this field already exists
      // if it does not create one
      // if it does replace the 'color' attribute with value
      const idx = findIndex(event.emailTemplates, { label: field });
      if (idx === -1) {
        updatedEvent.emailTemplates.push(updatedObject);
      } else if (value === '') {
        updatedEvent.emailTemplates.splice(idx, 1);
      } else {
        updatedEvent.emailTemplates[idx] = updatedObject;
      }
    } else {
      // if there is no emailTemplates array, create it
      updatedEvent.emailTemplates = [updatedObject];
    }

    setEvent(updatedEvent);
  };

  if (Object.keys(emailTemplates).every(templateKey => isEmpty(emailTemplates[templateKey])))
    return (
      <div className={classes.empty}>
        <Typography>No Template Options Available</Typography>
      </div>
    );

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      {Object.keys(emailTemplates).map(emailTemplateKey => {
        const emailTemplate = emailTemplates[emailTemplateKey];

        const selectedTemplate =
          (!isEmpty(event.emailTemplates) &&
            event.emailTemplates.find(template => template.label === emailTemplateKey)) ||
          '';

        return (
          <Fragment key={emailTemplateKey}>
            <Typography color="primary">{EMAIL_TEMPLATE_LABELS[emailTemplateKey]}</Typography>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel classes={{ root: classes.inputLabelRoot }} shrink>
                {EMAIL_TEMPLATE_TOOLTIPS[emailTemplateKey]}

                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={`Options labeled in Mandrill with ${emailTemplateKey}: ${emailTemplate.length}`}
                >
                  <InfoRounded className={classes.infoIcon} />
                </Tooltip>
              </InputLabel>
              <Select
                placeholder={EMAIL_TEMPLATE_LABELS[emailTemplateKey]}
                value={selectedTemplate.slug || ''}
                onChange={e => handleUpdateEmailTemplate(emailTemplateKey, e.target.value)}
              >
                {emailTemplate.map(eventTemplate => (
                  <MenuItem key={eventTemplate.slug} value={eventTemplate.slug}>
                    {eventTemplate.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Fragment>
        );
      })}

      <div className={classes.actionsWrapper}>
        <SFGOutlinedButton color="primary" type="submit" onClick={handleSubmit}>
          Save
        </SFGOutlinedButton>
      </div>
    </form>
  );
};

const styles = {
  form: {
    marginTop: '3rem',
  },
  empty: {
    marginTop: '2rem',
    textAlign: 'center',
  },
  actionsWrapper: {
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  colorButton: {
    color: 'white',
  },
  formControl: {
    marginBottom: '2rem',
  },
  inputLabelRoot: {
    alignItems: 'center',
    display: 'flex',
    pointerEvents: 'auto',
  },
  infoIcon: {
    fontSize: '1.25rem',
    marginLeft: '.25rem',
  },
  tooltip: {
    fontSize: '.75rem',
  },
};

EventEmailTemplatesForm.defaultProps = {
  selectedEvent: null,
};

EventEmailTemplatesForm.propTypes = {
  classes: PropTypes.object.isRequired,

  emailTemplates: PropTypes.object.isRequired,
  selectedEvent: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(EventEmailTemplatesForm);
