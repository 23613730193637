import EMAIL_ADDRESSES from '../../types/emailAddresses';
import { IS_AGENCY_ADMIN, IS_AGENCY_OWNER, IS_KEY_LEADER } from '../login/permissions';

const EVENTS = {
  route: '/events',
  name: 'Events',
};

const MY_TICKETS = {
  route: '/myTickets',
  name: 'My Tickets',
};

const MY_PURCHASES = {
  route: '/myPurchases',
  name: 'My Purchases',
};

const ADMINISTER_AGENCY = {
  route: '/toggleView',
  name: 'Event Administration',
  permissions: [IS_AGENCY_ADMIN],
  highlight: true,
};

const DOWNLINE = {
  route: '/downline',
  name: 'Downline',
  permissions: [IS_AGENCY_OWNER, IS_KEY_LEADER], // at least one of these
};

const PROFILE = {
  route: '/profile',
  name: 'My Profile',
};

const CONTACT = {
  route: `mailto:${EMAIL_ADDRESSES.SUPPORT}?subject="Contact Request"`,
  name: 'Contact Us',
};

const LOGOUT = {
  route: '/logout',
  name: 'Logout',
};

export default [
  EVENTS,
  MY_TICKETS,
  MY_PURCHASES,
  DOWNLINE,
  ADMINISTER_AGENCY,
  PROFILE,
  CONTACT,
  LOGOUT,
];
