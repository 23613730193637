import { CALL_API } from '../../middleware/api';

export const GET_SCHEDULE_SLOTS_REQUEST = 'GET_SCHEDULE_SLOTS_REQUEST';
export const GET_SCHEDULE_SLOTS_SUCCESS = 'GET_SCHEDULE_SLOTS_SUCCESS';
export const GET_SCHEDULE_SLOTS_FAILURE = 'GET_SCHEDULE_SLOTS_FAILURE';

export const CREATE_SCHEDULE_SLOT_REQUEST = 'CREATE_SCHEDULE_SLOT_REQUEST';
export const CREATE_SCHEDULE_SLOT_SUCCESS = 'CREATE_SCHEDULE_SLOT_SUCCESS';
export const CREATE_SCHEDULE_SLOT_FAILURE = 'CREATE_SCHEDULE_SLOT_FAILURE';

export const UPDATE_SCHEDULE_SLOT_REQUEST = 'UPDATE_SCHEDULE_SLOT_REQUEST';
export const UPDATE_SCHEDULE_SLOT_SUCCESS = 'UPDATE_SCHEDULE_SLOT_SUCCESS';
export const UPDATE_SCHEDULE_SLOT_FAILURE = 'UPDATE_SCHEDULE_SLOT_FAILURE';

export const DELETE_SCHEDULE_SLOT_REQUEST = 'DELETE_SCHEDULE_SLOT_REQUEST';
export const DELETE_SCHEDULE_SLOT_SUCCESS = 'DELETE_SCHEDULE_SLOT_SUCCESS';
export const DELETE_SCHEDULE_SLOT_FAILURE = 'DELETE_SCHEDULE_SLOT_FAILURE';

export const GET_SCHEDULE_SLOT_SESSIONS_REQUEST = 'GET_SCHEDULE_SLOT_SESSIONS_REQUEST';
export const GET_SCHEDULE_SLOT_SESSIONS_SUCCESS = 'GET_SCHEDULE_SLOT_SESSIONS_SUCCESS';
export const GET_SCHEDULE_SLOT_SESSIONS_FAILURE = 'GET_SCHEDULE_SLOT_SESSIONS_FAILURE';

export const CREATE_SCHEDULE_SLOT_SESSION_REQUEST = 'CREATE_SCHEDULE_SLOT_SESSION_REQUEST';
export const CREATE_SCHEDULE_SLOT_SESSION_SUCCESS = 'CREATE_SCHEDULE_SLOT_SESSION_SUCCESS';
export const CREATE_SCHEDULE_SLOT_SESSION_FAILURE = 'CREATE_SCHEDULE_SLOT_SESSION_FAILURE';

export const UPDATE_SCHEDULE_SLOT_SESSION_REQUEST = 'UPDATE_SCHEDULE_SLOT_SESSION_REQUEST';
export const UPDATE_SCHEDULE_SLOT_SESSION_SUCCESS = 'UPDATE_SCHEDULE_SLOT_SESSION_SESSION';
export const UPDATE_SCHEDULE_SLOT_SESSION_FAILURE = 'UPDATE_SCHEDULE_SLOT_SESSION_FAILURE';

export const DELETE_SCHEDULE_SLOT_SESSION_REQUEST = 'DELETE_SCHEDULE_SLOT_SESSION_REQUEST';
export const DELETE_SCHEDULE_SLOT_SESSION_SUCCESS = 'DELETE_SCHEDULE_SLOT_SESSION_SUCCESS';
export const DELETE_SCHEDULE_SLOT_SESSION_FAILURE = 'DELETE_SCHEDULE_SLOT_SESSION_FAILURE';

export const CLEAR_SCHEDULE_SLOTS = 'CLEAR_SCHEDULE_SLOTS';
export const CLEAR_SCHEDULE_SLOT_SESSIONS = 'CLEAR_SCHEDULE_SLOT_SESSIONS';

export const getScheduleSlots = eventId => {
  return {
    [CALL_API]: {
      types: [GET_SCHEDULE_SLOTS_REQUEST, GET_SCHEDULE_SLOTS_SUCCESS, GET_SCHEDULE_SLOTS_FAILURE],
      authenticated: true,
      endpoint: `admin/conferenceslots?eventId=${eventId}&relations=sessions`,
      method: 'GET',
    },
  };
};

export const createScheduleSlot = (eventId, scheduleSlotData) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_SCHEDULE_SLOT_REQUEST,
        CREATE_SCHEDULE_SLOT_SUCCESS,
        CREATE_SCHEDULE_SLOT_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/conferenceslots`,
      method: 'POST',
      payload: {
        eventId,
        date: scheduleSlotData.date,
        startTime: scheduleSlotData.startTime,
        endTime: scheduleSlotData.endTime,
      },
    },
  };
};

export const updateScheduleSlot = (slotId, eventId, scheduleSlotData) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_SCHEDULE_SLOT_REQUEST,
        UPDATE_SCHEDULE_SLOT_SUCCESS,
        UPDATE_SCHEDULE_SLOT_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/conferenceslots/${slotId}`,
      method: 'PUT',
      payload: {
        eventId,
        date: scheduleSlotData.date,
        startTime: scheduleSlotData.startTime,
        endTime: scheduleSlotData.endTime,
      },
      actionMetadata: {
        slotId,
      },
    },
  };
};

export const deleteScheduleSlot = slotId => {
  return {
    [CALL_API]: {
      types: [
        DELETE_SCHEDULE_SLOT_REQUEST,
        DELETE_SCHEDULE_SLOT_SUCCESS,
        DELETE_SCHEDULE_SLOT_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/conferenceslots/${slotId}`,
      method: 'DELETE',
      actionMetadata: {
        slotId,
      },
    },
  };
};

export const getScheduleSlotSessions = slotId => {
  return {
    [CALL_API]: {
      types: [
        GET_SCHEDULE_SLOT_SESSIONS_REQUEST,
        GET_SCHEDULE_SLOT_SESSIONS_SUCCESS,
        GET_SCHEDULE_SLOT_SESSIONS_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/conferencesessions?slotId=${slotId}&relations=registrations`,
      method: 'GET',
    },
  };
};

export const createScheduleSlotSession = (slotId, sessionData) => {
  const payload = {
    slotId,
    title: sessionData.title,
    location: sessionData.location,
    description: sessionData.description,
    speakers: [sessionData.speakers],
    exclusive: sessionData.exclusive,
  };

  if (sessionData.seats > 0) {
    payload.seats = sessionData.seats;
  }

  return {
    [CALL_API]: {
      types: [
        CREATE_SCHEDULE_SLOT_SESSION_REQUEST,
        CREATE_SCHEDULE_SLOT_SESSION_SUCCESS,
        CREATE_SCHEDULE_SLOT_SESSION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/conferencesessions`,
      method: 'POST',
      payload,
    },
  };
};

export const updateScheduleSlotSession = (sessionId, slotId, sessionData) => {
  const payload = {
    slotId,
    title: sessionData.title,
    location: sessionData.location,
    description: sessionData.description,
    speakers: [sessionData.speakers],
    exclusive: sessionData.exclusive,
  };

  if (sessionData.seats > 0) {
    payload.seats = sessionData.seats;
  }

  return {
    [CALL_API]: {
      types: [
        UPDATE_SCHEDULE_SLOT_SESSION_REQUEST,
        UPDATE_SCHEDULE_SLOT_SESSION_SUCCESS,
        UPDATE_SCHEDULE_SLOT_SESSION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/conferencesessions/${sessionId}`,
      method: 'PUT',
      payload,
      actionMetadata: {
        sessionId,
      },
    },
  };
};

export const deleteScheduleSlotSession = sessionId => {
  return {
    [CALL_API]: {
      types: [
        DELETE_SCHEDULE_SLOT_FAILURE,
        DELETE_SCHEDULE_SLOT_SESSION_SUCCESS,
        DELETE_SCHEDULE_SLOT_SESSION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/conferencesessions/${sessionId}`,
      method: 'DELETE',
      actionMetadata: {
        sessionId,
      },
    },
  };
};

export const clearScheduleSlots = () => {
  return {
    type: CLEAR_SCHEDULE_SLOTS,
  };
};

export const clearScheduleSlotSessions = () => {
  return {
    type: CLEAR_SCHEDULE_SLOT_SESSIONS,
  };
};
