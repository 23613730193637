import Immutable from 'immutable';
import { findIndex, isEmpty } from 'lodash';
import {
  GET_PURCHASEPLANS_REQUEST,
  GET_PURCHASEPLANS_SUCCESS,
  GET_PURCHASEPLANS_FAILURE,
  CREATE_PURCHASEPLAN_SUCCESS,
  GET_PURCHASEPLAN_REQUEST,
  GET_PURCHASEPLAN_SUCCESS,
  GET_PURCHASEPLAN_FAILURE,
  SET_SELECTED_PURCHASEPLAN,
  UPDATE_PURCHASEPLAN_SUCCESS,
  DELETE_PURCHASEPLAN_SUCCESS,
  CLEAR_SELECTED_PURCHASEPLAN
} from './purchasePlans.actions';
import { GET_PRODUCTS_SUCCESS } from '../products/products.actions';

const initialState = Immutable.Map({
  purchasePlans: [],
  selectedPurchasePlan: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    /* For the event registration, purchase plans are defined when products are fetched */
    case GET_PRODUCTS_SUCCESS: {
      /* Get a master list of all plans */
      if (
        state.get('purchasePlans') &&
        state.get('purchasePlans').length === 0 &&
        action.response.data &&
        action.response.data.length > 0
      ) {
        const purchasePlans = action.response.data.reduce((plansList, product) => {
          /* Create a list of plans for each product */
          if (product.purchasePlans) {
            const plans = product.purchasePlans.reduce((newPlans, plan) => {
              newPlans.push({ ...plan, productId: product.id });
              return newPlans;
            }, []);
            return [...plansList, ...plans];
          }
          return [...plansList];
        }, []);
        return state.set('purchasePlans', purchasePlans);
      }
      return state;
    }

    case GET_PURCHASEPLANS_SUCCESS: {
      return state.set('purchasePlans', action.response.data).set('isLoading', false);
    }

    case GET_PURCHASEPLANS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_PURCHASEPLANS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CREATE_PURCHASEPLAN_SUCCESS: {
      const purchasePlans = [...state.get('purchasePlans')];
      const newPurchasePlan = { ...action.response };
      purchasePlans.push(newPurchasePlan);

      return state.set('purchasePlans', purchasePlans).set('isLoading', false);
    }

    case GET_PURCHASEPLAN_SUCCESS:
      return state.set('selectedPurchasePlan', action.response).set('isLoading', false);

    case GET_PURCHASEPLAN_FAILURE:
      return state.set('isLoading', false);

    case GET_PURCHASEPLAN_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_PURCHASEPLAN:
      return state.set('selectedPurchasePlan', action.response);

    case CLEAR_SELECTED_PURCHASEPLAN:
      return state.set('selectedPurchasePlan', null);

    case UPDATE_PURCHASEPLAN_SUCCESS: {
      let selectedPurchasePlan = { ...state.get('selectedPurchasePlan') };
      const purchasePlans = [...state.get('purchasePlans')];
      const purchasePlanId = action.actionMetadata;

      if (!isEmpty(selectedPurchasePlan) && selectedPurchasePlan.id === purchasePlanId) {
        selectedPurchasePlan = Object.assign(selectedPurchasePlan, action.payload);
      }

      const idx = findIndex(purchasePlans, { id: purchasePlanId });
      if (idx > -1) {
        purchasePlans[idx] = Object.assign(purchasePlans[idx], action.payload);
      }

      return state
        .set('selectedPurchasePlan', selectedPurchasePlan)
        .set('purchasePlans', purchasePlans)
        .set('isLoading', false);
    }

    case DELETE_PURCHASEPLAN_SUCCESS: {
      const purchasePlans = [...state.get('purchasePlans')];

      const idx = findIndex(purchasePlans, { id: action.actionMetadata.purchasePlanId });
      if (idx > -1) {
        purchasePlans.splice(idx, 1);
      }

      return state.set('purchasePlans', purchasePlans).set('isLoading', false);
    }

    default:
      return state;
  }
}
