import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import LoadingOverlay from '../layout/loadingOverlay.component';
import TicketAssignmentsList from './ticketAssignmentsList.component';
import { clearSelectedEvent, getEvent, setSelectedEvent } from '../events/events.actions';
import { getRegistration } from '../registrations/registrations.actions';
import { getTicketsAdmin as getTickets, updateTicket } from './tickets.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class TicketsByPurchaserContainer extends Component {
  componentDidMount() {
    const { match, selectedEvent } = this.props;
    const eventId = match.params.id;
    const { purchaserId } = match.params;
    this.props.setPageTitle(`Tickets for Purchaser`);

    if (isEmpty(selectedEvent) || selectedEvent.id !== eventId) {
      this.props.getEvent(eventId);
    }
    this.props.getTickets(eventId, purchaserId);
    this.props.getRegistration(purchaserId);
    this.removeAction = this.removeAction.bind(this);
  }

  removeAction = ticket => {
    const newTicket = { ...ticket };
    const { id } = newTicket;
    delete newTicket.id;
    delete newTicket.createdDate;
    delete newTicket.updatedDate;
    delete newTicket.delegateStatus;
    delete newTicket.delegateUrl;
    /* If the product exists, set the product to the ID to clear. */
    if (newTicket.product) newTicket.product = newTicket.product.id;
    // Clear the assignee so it can be reset.
    newTicket.assignee = null;
    this.props.updateTicket(id, newTicket);
  };

  render() {
    if (this.props.isLoading) {
      return <LoadingOverlay />;
    }

    const eventId = this.props.match.params.id;
    return (
      <TicketAssignmentsList
        createUrl={`/events/${eventId}/assignments`}
        registration={this.props.registration}
        tickets={this.props.tickets}
        updateUrl="/eventAttendees"
        permissions={this.props.permissions}
        removeAction={this.removeAction}
      />
    );
  }
}

TicketsByPurchaserContainer.defaultProps = {
  registration: null,
  selectedEvent: null,
};

TicketsByPurchaserContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

  registration: PropTypes.object,
  tickets: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object,
  getRegistration: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  updateTicket: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    registration: state.registrations.get('selectedRegistration'),
    tickets: state.tickets.get('tickets'),
    permissions: state.login.get('permissions'),
    isLoading: state.tickets.get('isLoading'),
    errors: state.tickets.get('errors'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    clearSelectedEvent,
    setSelectedEvent,
    getRegistration,
    getTickets,
    getEvent,
    updateTicket,
  })(TicketsByPurchaserContainer)
);
