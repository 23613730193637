import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty, orderBy } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import AgencyAdminTable from './agencyAdminTable.component';
import AgentAutocomplete from '../layout/autocomplete/agentAutocomplete.container';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import agentSearchTypes from '../../types/agentSearchTypes';

class BigFiveForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agencyId: null,
      agencyOwner: null,
      agencyAdmins: [],
      email: '',
      newAgent: {},
      newRole: '',
    };

    if (props.agency) {
      this.state = {
        ...this.state,
        agencyId: props.agency.id,
        agencyOwner: props.agency.owner,
      };
    }
    if (props.agencyAdmins) {
      this.state = {
        ...this.state,
        agencyAdmins: this.sortAgents(props.agencyAdmins),
      };
    }
    this.emailAddress = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agencyAdmins !== this.props.agencyAdmins) {
      this.setState({ agencyAdmins: this.sortAgents(nextProps.agencyAdmins) });
    }
  }

  handleAutocompleteSelection = suggestion => {
    this.setState({
      newAgent: suggestion,
    });
  };

  sortAgents = agents => {
    return orderBy(agents, ['role', 'email'], ['desc', 'asc']);
  };

  addAgencyAdmin = () => {
    const { newAgent, newRole } = this.state;
    let { email } = this.state;

    if (!('email' in newAgent) && isEmpty(email)) {
      this.props.handleToastMessage('An agent to add must be specified!', true);
      return;
    }
    if ('email' in newAgent && newAgent.email.length && email.length) {
      this.props.handleToastMessage('Specify an agent or an email address, not both', true);
      return;
    }
    if (newRole === '') {
      this.props.handleToastMessage('An agent role must be specified!', true);
      return;
    }
    if (email.length && !this.emailAddress.current.isValid()) {
      this.props.handleToastMessage('Please enter a valid email address!', true);
      return;
    }

    /* If the user used the autocomplete feature to select an agency admin, use that email address. Otherwise,
       the email address entered in the field will be used. */
    if ('email' in newAgent && newAgent.email.length > 0) {
      email = newAgent.email;
    }

    this.setState(prevState => ({
      agencyAdmins: this.sortAgents([...prevState.agencyAdmins, { email, role: newRole }]),
      newAgent: { name: '' },
      email: '',
      newRole: '',
    }));
  };

  updateField = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };

  render() {
    const { agencyAdminsLoading, classes } = this.props;
    const { agencyOwner, agencyAdmins, email, newAgent, newRole } = this.state;

    return (
      <ValidatorForm onSubmit={this.handleSubmit} style={{ textAlign: 'center' }}>
        <div className="container">
          <Grid container spacing={32}>
            <Grid item xs>
              <FormControl className={classes.textField}>
                <TextField
                  label="Big 5 Agency Owner"
                  onChange={() => {}}
                  disabled
                  value={agencyOwner ? agencyOwner.name : ''}
                  style={{ width: '100%' }}
                />
              </FormControl>
              <br />
              <Typography type="headline" className={classes.heading}>
                Add Agent to Adminster this Agency
              </Typography>
              <FormControl className={classes.textField}>
                <AgentAutocomplete
                  initialValue={newAgent && newAgent.name ? newAgent.name : ''}
                  label="Agency Administrator"
                  helperText="(Autocomplete)"
                  placeholder="Enter an agent's name"
                  onSelect={suggestion => this.handleAutocompleteSelection(suggestion)}
                  searchType={agentSearchTypes.ALL}
                  clearSelectionOnEdit
                  clearInvalidSelectionOnBlur
                  clearInputOnSelect={false}
                  onInputClear={() => this.setState({ newAgent: { name: '' } })}
                />
              </FormControl>
              <div style={{ marginTop: '0.5rem', textAlign: 'left' }}>- OR -</div>
              <FormControl className={classes.textField}>
                <TextValidator
                  ref={this.emailAddress}
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.updateField}
                  label="Email Address"
                  style={{ width: '100%' }}
                  validators={['isEmail']}
                  errorMessages={['Must enter a valid email address.']}
                />
              </FormControl>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor="newRole">Agent Role</InputLabel>
                <Select
                  name="newRole"
                  value={newRole || ''}
                  onChange={this.updateField}
                  className={classes.select}
                  inputProps={{
                    id: 'newRole',
                  }}
                >
                  <MenuItem value="AGENCY_ADMIN">Admin</MenuItem>
                  <MenuItem value="AGENCY_OWNER">Owner</MenuItem>
                </Select>
              </FormControl>
              <p style={{ display: 'block', width: '100%', textAlign: 'left', fontSize: '90%' }}>
                <strong>Note: </strong>
                The difference between an agency owner role and an agency admin role is that an
                agency owner can setup Stripe where an agency admin can not.
              </p>
              <SFGOutlinedButton className={classes.button} onClick={this.addAgencyAdmin}>
                Add Agent
              </SFGOutlinedButton>
            </Grid>
            <Grid item xs>
              <AgencyAdminTable
                headingText="Agency Administrator"
                inviteList={agencyAdmins}
                onListUpdated={agentsList => this.setState({ agencyAdmins: agentsList })}
                isBulkSelectionMade={false}
                isReadOnly={false}
                canDeleteEntries
                onlyShowName={false}
                isLoading={agencyAdminsLoading}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <SFGOutlinedButton
                className={classes.submitButton}
                type="submit"
                onClick={this.addAgencyAdmin}
              >
                Submit
              </SFGOutlinedButton>
            </Grid>
          </Grid>
        </div>
      </ValidatorForm>
    );
  }
}

const styles = (/* theme */) => ({
  heading: {
    marginTop: '1rem',
    textAlign: 'left',
  },
  select: {
    minWidth: 140,
    maxWidth: 150,
    display: 'block',
    paddingTop: '15px',
    textAlign: 'left',
  },
  textField: {
    minWidth: 140,
    maxWidth: 350,
    width: '100%',
    display: 'block',
  },
  button: {
    float: 'left',
    marginTop: '2rem',
  },
  submitButton: {
    float: 'right',
    marginTop: '1rem',
  },
  subheading: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
  },
});

BigFiveForm.propTypes = {
  classes: PropTypes.object.isRequired,

  agencyAdminsLoading: PropTypes.bool.isRequired,
  agencyAdmins: PropTypes.array.isRequired,
  agency: PropTypes.object.isRequired,

  onSubmit: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

export default withStyles(styles)(BigFiveForm);
