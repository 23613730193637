import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import ProductForm from './productForm.component';
import { clearSelectedProduct, createProduct, CREATE_PRODUCT_FAILURE } from './products.actions';

class ProductNewContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('New Product');
    this.props.clearSelectedProduct();
  }

  handleCreate = async productData => {
    const response = await this.props.createProduct(productData);

    if (response.type === CREATE_PRODUCT_FAILURE) {
      this.props.handleToastMessage('Failed to create product.', true);
    } else {
      this.props.handleToastMessage('Product Created.');
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <div className="container">
        <ProductForm submitAction={this.handleCreate} />
      </div>
    );
  }
}

ProductNewContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  clearSelectedProduct: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, {
    createProduct,
    handleToastMessage,
    setPageTitle,
    clearSelectedProduct
  })(ProductNewContainer)
);
