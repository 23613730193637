import { CALL_API } from '../../middleware/api';

export const GET_SPECIAL_EVENTS_REQUEST = 'GET_SPECIAL_EVENTS_REQUEST';
export const GET_SPECIAL_EVENTS_SUCCESS = 'GET_SPECIAL_EVENTS_SUCCESS';
export const GET_SPECIAL_EVENTS_FAILURE = 'GET_SPECIAL_EVENTS_FAILURE';

export const GET_SPECIAL_EVENT_REQUEST = 'GET_SPECIAL_EVENT_REQUEST';
export const GET_SPECIAL_EVENT_SUCCESS = 'GET_SPECIAL_EVENT_SUCCESS';
export const GET_SPECIAL_EVENT_FAILURE = 'GET_SPECIAL_EVENT_FAILURE';

export const CREATE_SPECIAL_EVENT_REQUEST = 'CREATE_SPECIAL_EVENT_REQUEST';
export const CREATE_SPECIAL_EVENT_SUCCESS = 'CREATE_SPECIAL_EVENT_SUCCESS';
export const CREATE_SPECIAL_EVENT_FAILURE = 'CREATE_SPECIAL_EVENT_FAILURE';

export const UPDATE_SPECIAL_EVENT_REQUEST = 'UPDATE_SPECIAL_EVENT_REQUEST';
export const UPDATE_SPECIAL_EVENT_SUCCESS = 'UPDATE_SPECIAL_EVENT_SUCCESS';
export const UPDATE_SPECIAL_EVENT_FAILURE = 'UPDATE_SPECIAL_EVENT_FAILURE';

export const DELETE_SPECIAL_EVENT_REQUEST = 'DELETE_SPECIAL_EVENT_REQUEST';
export const DELETE_SPECIAL_EVENT_SUCCESS = 'DELETE_SPECIAL_EVENT_SUCCESS';
export const DELETE_SPECIAL_EVENT_FAILURE = 'DELETE_SPECIAL_EVENT_FAILURE';

export const UNINVITE_FROM_SPECIAL_EVENT_REQUEST = 'UNINVITE_FROM_SPECIAL_EVENT_REQUEST';
export const UNINVITE_FROM_SPECIAL_EVENT_SUCCESS = 'UNINVITE_FROM_SPECIAL_EVENT_SUCCESS';
export const UNINVITE_FROM_SPECIAL_EVENT_FAILURE = 'UNINVITE_FROM_SPECIAL_EVENT_FAILURE';

export const CLEAR_SPECIAL_EVENTS = 'CLEAR_SPECIAL_EVENTS';

export const getSpecialEvents = eventId => {
  return {
    [CALL_API]: {
      types: [GET_SPECIAL_EVENTS_REQUEST, GET_SPECIAL_EVENTS_SUCCESS, GET_SPECIAL_EVENTS_FAILURE],
      authenticated: true,
      endpoint: `v1/events/${eventId}/specialevents`,
      method: 'GET'
    }
  };
};

export const getSpecialEvent = (eventId, specialEventId) => {
  return {
    [CALL_API]: {
      types: [GET_SPECIAL_EVENT_REQUEST, GET_SPECIAL_EVENT_SUCCESS, GET_SPECIAL_EVENT_FAILURE],
      authenticated: true,
      endpoint: `v1/events/${eventId}/specialevents/${specialEventId}`,
      method: 'GET'
    }
  };
};

export const createSpecialEvent = (eventId, specialEventData) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_SPECIAL_EVENT_REQUEST,
        CREATE_SPECIAL_EVENT_SUCCESS,
        CREATE_SPECIAL_EVENT_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/events/${eventId}/specialevents`,
      method: 'POST',
      payload: {
        ...specialEventData
      }
    }
  };
};

export const updateSpecialEvent = (specialEventId, eventId, specialEventData) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_SPECIAL_EVENT_REQUEST,
        UPDATE_SPECIAL_EVENT_SUCCESS,
        UPDATE_SPECIAL_EVENT_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/events/${eventId}/specialevents/${specialEventId}`,
      method: 'PUT',
      payload: {
        ...specialEventData
      }
    }
  };
};

export const deleteSpecialEvent = (eventId, specialEventId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_SPECIAL_EVENT_REQUEST,
        DELETE_SPECIAL_EVENT_SUCCESS,
        DELETE_SPECIAL_EVENT_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/events/${eventId}/specialevents/${specialEventId}`,
      method: 'DELETE',
      actionMetadata: {
        specialEventId
      }
    }
  };
};

export const uninviteFromSpecialEvent = (eventId, specialEventId, inviteeEmails) => {
  return {
    [CALL_API]: {
      types: [
        UNINVITE_FROM_SPECIAL_EVENT_REQUEST,
        UNINVITE_FROM_SPECIAL_EVENT_SUCCESS,
        UNINVITE_FROM_SPECIAL_EVENT_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/events/${eventId}/specialevents/${specialEventId}/uninvite`,
      method: 'POST',
      payload: {
        inviteeEmails
      }
    }
  };
};

export const clearSpecialEvents = () => {
  return {
    type: CLEAR_SPECIAL_EVENTS
  };
};
