import firebase from 'firebase/app';

export const getLoginRedirectUrl = (desiredURL, isLoggedIn) => {
  if (isLoggedIn) {
    // go to view the event
    return desiredURL;
  }
  return `/login?redirectURL=${desiredURL}`;
};

/*
We need to check the claims and we need to check the current user object
because of the way the claims will or will not be able to be set, depending on if 
this is auth0 or firebase user
*/
export const getIsUserEmailVerified = async () => {
  try {
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();

    // console.log('id token result claims', idTokenResult.claims);

    return idTokenResult.claims.email_verified || firebase.auth().currentUser.emailVerified;
  } catch (e) {
    return false;
  }
};
