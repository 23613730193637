import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { lookupAttendees, LOOKUP_ATTENDEES_SUCCESS } from './autocomplete.actions';
import CustomAutocomplete from './customAutocomplete.component';

class AttendeeAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: []
    };
  }

  getSuggestionValue = suggestion => {
    return suggestion.name;
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();

    if (inputValue.length > 2) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }

      this.searchTimer = setTimeout(async () => {
        const result = await this.props.lookupAttendees(value, this.props.eventId);
        if (result.type === LOOKUP_ATTENDEES_SUCCESS) {
          // console.log('SUGGESTIONS!', result.response.data);
          this.setState({
            suggestions: result.response.data.slice(0, 5)
          });
        }
      }, 300);
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  // the object in this signature can also have "query"
  renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {`${suggestion.name} ${suggestion.email ? `(${suggestion.email})` : ''}`}
      </MenuItem>
    );
  };

  // the object in this signature can also have "suggestionValue", "suggestionIndex", and "sectionIndex"
  handleSelection = (event, { suggestion, method }) => {
    if (method === 'enter') {
      event.preventDefault();
    }

    this.props.onSelect(suggestion);
  };

  render() {
    return (
      <CustomAutocomplete
        getSuggestionValue={this.getSuggestionValue}
        handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestion={this.renderSuggestion}
        handleSelection={this.handleSelection}
        suggestions={this.state.suggestions}
        initialValue={this.props.initialValue}
        label={this.props.label}
        placeholder={this.props.placeholder}
        clearInputOnSelect={this.props.clearInputOnSelect}
      />
    );
  }
}

AttendeeAutocomplete.defaultProps = {
  clearInputOnSelect: false
};

AttendeeAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  clearInputOnSelect: PropTypes.bool,
  initialValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,

  lookupAttendees: PropTypes.func.isRequired
};

export default connect(null, {
  lookupAttendees
})(AttendeeAutocomplete);
