import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Select, MenuItem, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

import { setPageTitle } from '../layout/layout.actions';
import { getDownlineAgents } from './agents.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import DownlineAgent from './downlineAgent.container';

const ALL_EVENTS_ID = 99;

class DownlineAgents extends PureComponent {
  state = {
    selectedEventId: ALL_EVENTS_ID
  };

  componentDidMount() {
    this.props.setPageTitle('Downline');
    this.props.getDownlineAgents();
  }

  handleSelectEvent = eventId => {
    this.setState({ selectedEventId: eventId });
    this.props.getDownlineAgents(eventId);
  };

  render() {
    const { classes, events, agents, isLoading, profile } = this.props;
    const { selectedEventId } = this.state;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <div className="container">
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.root}>
            <div className={classes.headingAreaWrapper}>
              {!isEmpty(profile) ? (
                <h2>{`Downline for ${profile.name}`}</h2>
              ) : (
                <h2>My Downline</h2>
              )}
              <div className={classes.filterWrapper}>
                <Select
                  value={selectedEventId}
                  onChange={e => this.handleSelectEvent(e.target.value)}
                  inputProps={{
                    name: 'eventDeepLink',
                    id: 'deepLink'
                  }}
                  style={{ minWidth: '16rem' }}
                >
                  <MenuItem value={ALL_EVENTS_ID}>All Events</MenuItem>
                  {events.map(event => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {agents.map((agent, i) => (
              <DownlineAgent
                key={agent.code}
                agent={agent}
                isFirstTier
                isEvenRow={i % 2 === 0}
                isEventSelected={selectedEventId !== ALL_EVENTS_ID}
              />
            ))}
          </Paper>
          </Grid>
        </Grid>

      </div>
    );
  }
}

const sortAgents = agents => {
  agents.sort((a1, a2) => {
    if (a1.name < a2.name) {
      return -1;
    }
    if (a1.name > a2.name) {
      return 1;
    }
    return 0;
  });
  agents.forEach(agent => {
    if (agent.children) {
      sortAgents(agent.children);
    }
  });
  return agents;
};

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing.unit * 2
  },
  headingAreaWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filterWrapper: {
    marginLeft: '1rem'
  }
});

DownlineAgents.propTypes = {
  classes: PropTypes.object.isRequired,

  events: PropTypes.array.isRequired,
  agents: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,

  getDownlineAgents: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const agents = sortAgents([...state.agents.get('agents')]);

  return {
    events: state.events.get('events'),
    agents,
    isLoading: state.agents.get('isLoading'),
    profile: state.profile.get('profile')
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, { getDownlineAgents, setPageTitle })(DownlineAgents)
);
