import { createSelector } from 'reselect';
import moment from 'moment';

const selectNotifications = state => state.notifications.get('notifications');

const selectSortedNotifications = createSelector(selectNotifications, notifications => {
  return notifications.sort((a, b) => {
    return moment(b.created).diff(moment(a.created));
  });
});

export default { selectSortedNotifications };
