import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import Colors from '../../../styles/colors';
import EventInviteTableHead from './eventInviteTableHead.component';
import EventInviteTableToolbar from './eventInviteTableToolbar.component';
import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';
import inviteeFormTypes from '../../../types/inviteeFormTypes';
import { getSorting, stableSort } from '../../../utilities/sort';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EventInviteTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      inviteList: this.props.inviteList,
      page: 0,
      rowsPerPage: 5,
      dialogOpen: false,
      searchText: '',
    };
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      inviteList: nextProps.inviteList,
    });
  };

  search = value => {
    this.setState({ searchText: value });
    const toMatch = new RegExp(value, 'i');
    if (value.length >= 2) {
      const inviteList = this.props.inviteList.filter(
        m =>
          (!isEmpty(m.name) && !isEmpty(m.name.match(toMatch))) ||
          (!isEmpty(m.email) && !isEmpty(m.email.match(toMatch)))
      );
      this.setState({ inviteList });
    } else {
      this.setState({ inviteList: this.props.inviteList });
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(prevState => ({
        selected: prevState.inviteList.map(n => (n.code ? n.code : n.email)),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = id => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    const { value } = event.target;
    this.setState({ rowsPerPage: value, page: 0 });
  };

  handleShowDeleteDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };

  handleDeleteItems = itemsToDelete => {
    let updateList = [...this.props.inviteList];
    updateList = updateList.filter(
      item => itemsToDelete.indexOf(item.code ? item.code : item.email) === -1
    );
    this.setState({ dialogOpen: false, selected: [], searchText: '', page: 0 });
    this.props.onListUpdated(updateList);
  };

  getResponseIcon = response => {
    switch (response) {
      case inviteeFormTypes.INVITEE_RESPONSE_YES:
        return (
          <i style={{ color: Colors.green, verticalAlign: 'middle' }} className="material-icons">
            done
          </i>
        );

      case inviteeFormTypes.INVITEE_RESPONSE_PENDING:
        return (
          <i style={{ color: Colors.grayDark, verticalAlign: 'middle' }} className="material-icons">
            help_outline
          </i>
        );

      case inviteeFormTypes.INVITEE_RESPONSE_NO:
        return (
          <i style={{ color: Colors.error, verticalAlign: 'middle' }} className="material-icons">
            close
          </i>
        );

      default:
        return null;
    }
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  getAcceptedMaybeCount = () => {
    const { inviteList } = this.state;
    return inviteList.filter(invitee => {
      return (
        invitee.response === inviteeFormTypes.INVITEE_RESPONSE_YES ||
        invitee.response === inviteeFormTypes.INVITEE_RESPONSE_PENDING
      );
    }).length;
  };

  render() {
    const {
      classes,
      headingText,
      isReadOnly,
      isBulkSelectionMade,
      canDeleteEntries,
      isAllowMessageDialog,
      onlyShowName,
    } = this.props;
    const {
      inviteList,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      dialogOpen,
      searchText,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, inviteList.length - page * rowsPerPage);
    const numRespondedYesMaybe = this.getAcceptedMaybeCount();

    return (
      <Paper className={classes.root}>
        <EventInviteTableToolbar
          numSelected={selected.length}
          handleShowDeleteDialog={this.handleShowDeleteDialog}
          onSearchChange={this.search}
          searchText={searchText}
          inviteList={inviteList}
          headingText={headingText.concat('s')}
          isReadOnly={isReadOnly}
          canDeleteEntries={canDeleteEntries}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {inviteList.length > 0 && (
              <EventInviteTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={inviteList.length}
                isReadOnly={isReadOnly}
                onlyShowName={onlyShowName}
              />
            )}

            <TableBody>
              {stableSort(inviteList, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(invitee => {
                  const code = invitee.code ? invitee.code : invitee.email;
                  const isSelected = this.isSelected(code);
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        if (!isReadOnly) this.handleClick(code);
                      }}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={invitee.email}
                      selected={isSelected}
                    >
                      {!isReadOnly && (
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} style={{ color: Colors.grayDark }} />
                        </TableCell>
                      )}
                      <TableCell padding="default">{invitee.name}</TableCell>
                      {!onlyShowName && <TableCell padding="default">{invitee.email}</TableCell>}
                      {!onlyShowName && (
                        <TableCell padding="default">
                          {this.getResponseIcon(invitee.response)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}

              {inviteList.length <= 0 && (
                <TableRow>
                  <TableCell>
                    {isBulkSelectionMade === true && (
                      <Typography style={{ padding: '1rem' }}>
                        {`A group of ${headingText.toLowerCase()}s has been selected.`}
                      </Typography>
                    )}

                    {!isBulkSelectionMade === true && (
                      <Typography style={{ padding: '1rem' }}>
                        {`No ${headingText.toLowerCase()}s. Please add ${headingText.toLowerCase()}s or update your search.`}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {inviteList.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={inviteList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
        {isAllowMessageDialog === true && numRespondedYesMaybe > 0 && (
          <SFGOutlinedButton
            size="small"
            style={{ float: 'left', marginTop: '0.5rem' }}
            onClick={this.props.handleSendMessage}
          >
            {`Send Message to All Accepted/Maybe (${numRespondedYesMaybe})`}
          </SFGOutlinedButton>
        )}
        <Dialog
          open={dialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Remove ${headingText.toLowerCase()}${selected.length > 1 ? 's' : ''}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`You are about to remove ${selected.length} ${headingText.toLowerCase()}${
                selected.length > 1 ? 's' : ''
              } from the list.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SFGOutlinedButton autoFocus onClick={() => this.handleDeleteItems(selected)}>
              OKAY
            </SFGOutlinedButton>
            <SFGOutlinedButton onClick={this.handleCloseDialog}>CANCEL</SFGOutlinedButton>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

EventInviteTable.propTypes = {
  classes: PropTypes.object.isRequired,
  inviteList: PropTypes.array.isRequired,
  onListUpdated: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  isBulkSelectionMade: PropTypes.bool,
  handleSendMessage: PropTypes.func.isRequired,
  isAllowMessageDialog: PropTypes.bool.isRequired,
  onlyShowName: PropTypes.bool,
  canDeleteEntries: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

EventInviteTable.defaultProps = {
  onlyShowName: false,
  isReadOnly: false,
  canDeleteEntries: false,
  isBulkSelectionMade: false,
};

export default withStyles(styles)(EventInviteTable);
