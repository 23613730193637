import PrintIcon from '@material-ui/icons/Print';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import { isEmpty, isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import LoadingInlay from '../layout/loadingInlay.component';
import ScheduleListItem from './scheduleListItem.component';
import {
  doesDayHaveSessions,
  hasScheduledEvents,
  sortScheduleByDateTime,
} from '../../utilities/scheduleHelper';
import { getEventSchedule, getMySchedule } from './mySchedule.actions';
import {
  selectEventScheduleByDay,
  selectMyScheduleByDay,
  selectMyScheduleIsLoading,
} from './mySchedule.selectors';
import { selectEvents } from '../events/events.selector';

const ScheduleList = ({ classes, eventId, eventName, isBreakouts }) => {
  const dispatch = useDispatch();

  const refScheduleList = useRef();
  const [isPrinting, setIsPrinting] = useState(false);

  const myScheduleByDay = useSelector(selectMyScheduleByDay);
  const eventScheduleByDay = useSelector(selectEventScheduleByDay);
  const isLoading = useSelector(selectMyScheduleIsLoading);
  const events = useSelector(selectEvents);

  const [isWithinRegistrationWindow, setIsWithinRegistrationWindow] = useState(true);
  const [hasScheduledBreakoutEvents, setHasScheduledBreakoutEvents] = useState(true);

  useEffect(() => {
    if (isBreakouts && events) {
      (async () => {
        const response = await dispatch(getEventSchedule(eventId));

        const eventSchedule = sortScheduleByDateTime(response.response.data);
        // eslint-disable-next-line no-shadow
        const hasScheduledBreakoutEvents = hasScheduledEvents(eventSchedule, true);

        const eventFull = events.find(event => event.id === eventId);

        const { daysBeforeRegistrationOpens } = eventFull;
        const daysUntilEventStart = moment(eventFull.startDate).diff(moment(new Date()), 'days');

        setIsWithinRegistrationWindow(daysUntilEventStart <= daysBeforeRegistrationOpens);
        setHasScheduledBreakoutEvents(hasScheduledBreakoutEvents);
      })();
    }

    dispatch(getMySchedule(eventId));
  }, [dispatch, eventId, events, isBreakouts]);

  const scheduleByDayWithSessions = useMemo(() => {
    if (isBreakouts) {
      return eventScheduleByDay.filter(day => doesDayHaveSessions(day.slots, isBreakouts));
    }

    return myScheduleByDay.filter(day => doesDayHaveSessions(day.slots));
  }, [eventScheduleByDay, isBreakouts, myScheduleByDay]);

  /*
  Using this method with a timeout to allow for the expansion panels to animate open if needed
  before the print executes. We can't use the onBeforeGetContent attribute because we can't 
  deterministically know that the panels have finished animated open
  */
  const printSchedule = () => {
    setIsPrinting(true);
    setTimeout(() => {
      handlePrint();
    }, 300);
  };

  /* 
    Found this example from a github issue within their repo. pageStyle provides a way to override
    default styles that they use: https://github.com/gregnb/react-to-print/issues/264
  */
  const handlePrint = useReactToPrint({
    pageStyle:
      '@page { size: auto; margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    content: () => refScheduleList.current,
    onAfterPrint: () => setIsPrinting(false),
  });

  if (
    (isBreakouts && isNull(eventScheduleByDay)) ||
    (!isBreakouts && isNull(myScheduleByDay)) ||
    isLoading
  )
    return <LoadingInlay />;

  if (isBreakouts && (!isWithinRegistrationWindow || !hasScheduledBreakoutEvents))
    return (
      <Typography variant="subheading" align="center">
        Registration has not started.
      </Typography>
    );

  if ((isBreakouts && isEmpty(eventScheduleByDay)) || (!isBreakouts && isEmpty(myScheduleByDay)))
    return (
      <Typography variant="subheading" align="center">
        There are no sessions for this event.
      </Typography>
    );

  return (
    <div ref={refScheduleList}>
      <div className={classes.header}>
        {/* TODO: Maybe add an expand all option */}
        {eventName && (
          <Typography align="center" color="primary" variant="headline">
            {eventName}
          </Typography>
        )}
        {!isBreakouts && !isPrinting && (
          <Tooltip title="Print Schedule">
            <IconButton onClick={printSchedule} className={classes.buttonPrint}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {scheduleByDayWithSessions.map(day => (
        <ScheduleListItem
          key={day.date}
          className={classes.scheduleListItem}
          day={day}
          eventId={eventId}
          isBreakouts={isBreakouts}
          startOpen
          forceExpand={isPrinting}
        />
      ))}
    </div>
  );
};

const styles = theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    height: '3rem',
    justifyContent: 'center',
    marginBottom: theme.spacing.unit,
    position: 'relative',
  },
  buttonPrint: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  scheduleListItem: {
    marginBottom: theme.spacing.unit * 2,
  },
});

ScheduleList.defaultProps = {
  eventName: '',
  isBreakouts: false,
};

ScheduleList.propTypes = {
  classes: PropTypes.object.isRequired,

  eventId: PropTypes.string.isRequired,
  eventName: PropTypes.string,
  isBreakouts: PropTypes.bool,
};

export default withStyles(styles)(ScheduleList);
