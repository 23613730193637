import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EmptyState from '../layout/emptyState.component';
import EventsDataTable from './eventsDataTable.component';
import LoadingInlay from '../layout/loadingInlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { IS_ADMIN, hasPermission } from '../login/permissions';
import { UNARCHIVE_EVENT_SUCCESS, getEventsAdminArchived, unarchiveEvent } from './events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EventsContainer extends Component {
  componentDidMount() {
    this.props.getEvents();
    this.props.setPageTitle('Events (Archived)');
  }

  unarchiveEvent = async (id, index) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`Are you sure you want to unarchive event: ${this.props.events[index].name}?`)
    ) {
      const response = await this.props.unarchiveEvent(id);
      if (response.type === UNARCHIVE_EVENT_SUCCESS) {
        this.props.handleToastMessage('Event unarchived.');
      } else {
        this.props.handleToastMessage('Failed to unarchive event', true);
      }
    }
  };

  navigate = (pathname, state, newWindow = false) => {
    if (newWindow) {
      window.open(pathname);
    } else {
      this.props.history.push({
        pathname,
        state,
      });
    }
  };

  getRowActionMenuItems = (event, index) => {
    const menuItems = [
      {
        text: 'Ticket Purchases',
        action: () => this.navigate(`events/${event.id}/purchases`, { event }),
      },
      {
        text: 'Ticket Assignments',
        action: () => this.navigate(`events/${event.id}/assignments`, { event }),
        disabled: event.hasExternalTicketAssignments,
      },
      {
        text: 'Manage VIPs',
        action: () => this.navigate(`events/${event.id}/vips`, { event }),
        disabled: event.hasExternalTicketAssignments,
      },
      {
        isDivider: true,
      },
      {
        text: 'Accounts Receivable',
        action: () => this.navigate(`events/${event.id}/accountsReceivable`, { event }, false),
      },
      {
        text: 'Reports',
        action: () => this.navigate(`events/${event.id}/reports`, { event }),
      },
      {
        isDivider: true,
      },
      {
        text: 'Breakout Sessions',
        action: () => this.navigate(`events/${event.id}/scheduleSlots`),
      },
      {
        text: 'Special Events',
        action: () => this.navigate(`events/${event.id}/specialEvents`),
      },
      {
        text: 'Lanyard Colors',
        action: () => this.navigate(`events/${event.id}/badgeColors`),
      },
      {
        text: 'Help Desk',
        action: () => this.navigate(`events/${event.id}/helpDesk`),
        disabled: true,
      },
      {
        isDivider: true,
      },
      {
        text: 'Edit Event',
        action: () => this.navigate(`events/${event.id}`),
      },
      {
        text: 'Unarchive Event',
        action: () => this.unarchiveEvent(event.id, index),
        disabled: !hasPermission(IS_ADMIN, this.props.permissions),
      },
    ];

    return menuItems;
  };

  render() {
    const { classes, agencies, events, permissions, isLoading } = this.props;

    return (
      <div className="container">
        <Grid container>
          <Grid item xs={12}>
            <div className={`${classes.controlWrapperFlexFull} ${classes.controlsWrapper}`}>
              <SFGOutlinedButton
                color="primary"
                style={{ display: hasPermission(IS_ADMIN, permissions) ? 'inherit' : 'none' }}
                onClick={() => this.props.history.push('/events')}
              >
                View Events
              </SFGOutlinedButton>
            </div>

            {events.length === 0 && isLoading && <LoadingInlay />}

            {events.length === 0 && !isLoading && <EmptyState text="No Event Found" />}

            {events.length > 0 && agencies.length > 0 && (
              <EventsDataTable
                events={events}
                agencies={agencies}
                rowActionMenuItems={this.getRowActionMenuItems}
                isArchived
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = () => ({
  controlsWrapper: {
    display: 'flex',
    marginBottom: '0.5rem',
  },
  controlWrapperFlexFull: {
    flex: 1,
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

EventsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  classes: PropTypes.object.isRequired,

  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  unarchiveEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  let events = state.events.get('eventsAdminArchived');
  events = events.sort((a, b) => {
    const momentA = moment(a.startDate);
    const momentB = moment(b.startDate);
    if (momentA.isAfter(momentB)) {
      return 1;
    }
    if (momentB.isAfter(momentA)) {
      return -1;
    }

    return 0;
  });

  return {
    events,
    agencies: state.bigFive.get('agencies'),
    permissions: state.login.get('permissions'),
    isLoading: state.events.get('isLoading'),
  };
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      handleToastMessage,
      getEvents: getEventsAdminArchived,
      unarchiveEvent,
    })(EventsContainer)
  )
);
