import Immutable from 'immutable';
import {
  GET_BIG5_AGENCIES_REQUEST,
  GET_BIG5_AGENCIES_SUCCESS,
  GET_BIG5_AGENCIES_FAILURE,
  CREATE_BIG5_AGENCY_SUCCESS,
  CREATE_BIG5_AGENCY_FAILURE,
  CREATE_BIG5_AGENCY_REQUEST,
  GET_BIG5_AGENCY_ADMINS_REQUEST,
  GET_BIG5_AGENCY_ADMINS_SUCCESS,
  GET_BIG5_AGENCY_ADMINS_FAILURE,
  ADD_BIG5_AGENCY_ADMIN_REQUEST,
  ADD_BIG5_AGENCY_ADMIN_SUCCESS,
  ADD_BIG5_AGENCY_ADMIN_FAILURE,
  REMOVE_BIG5_AGENCY_ADMIN_REQUEST,
  REMOVE_BIG5_AGENCY_ADMIN_SUCCESS,
  REMOVE_BIG5_AGENCY_ADMIN_FAILURE,
  CLEAR_SELECTED_AGENCY_ADMINS
} from './bigFive.actions';

const initialState = Immutable.Map({
  agencies: [],
  agencyAdmins: [],
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BIG5_AGENCIES_SUCCESS:
      return state.set('agencies', action.response.data).set('isLoading', false);

    case GET_BIG5_AGENCIES_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_BIG5_AGENCIES_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CREATE_BIG5_AGENCY_SUCCESS:
      // Currently this only returns the new agency code.
      return state.set('isLoading', false);

    case CREATE_BIG5_AGENCY_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case CREATE_BIG5_AGENCY_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_BIG5_AGENCY_ADMINS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_BIG5_AGENCY_ADMINS_SUCCESS:
      return state.set('agencyAdmins', action.response.data).set('isLoading', false);

    case GET_BIG5_AGENCY_ADMINS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case ADD_BIG5_AGENCY_ADMIN_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case ADD_BIG5_AGENCY_ADMIN_SUCCESS:
      return state.set('isLoading', false);

    case ADD_BIG5_AGENCY_ADMIN_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case REMOVE_BIG5_AGENCY_ADMIN_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case REMOVE_BIG5_AGENCY_ADMIN_SUCCESS:
      return state.set('isLoading', false);

    case REMOVE_BIG5_AGENCY_ADMIN_FAILURE:
      return state.set('errors', []).set('isLoading', false);

    case CLEAR_SELECTED_AGENCY_ADMINS:
      return state.set('agencyAdmins', []);

    default:
      return state;
  }
}
