import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import 'firebase/auth';
import { Redirect, Route, Switch, matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './headerAdmin.container';
import getInitializingComponent from '../../utilities/initializing.component';
import { LOGIN_STATE } from '../login/login.actions';

import AccountsReceivablePage from '../events/accountsReceivable.container';
import EventAssignmentsPage from '../events/eventAssignments.container';
import EventBadgeLanyardColorsPage from '../events/eventBadgeLanyardColors.container';
import EventEditPage from '../events/eventEdit.container';
import EventNewPage from '../events/eventNew.container';
import EventPurchasesPage from '../events/eventPurchases.container';
import EventReportsPage from '../events/eventReports.container';
import EventsAdminArchivedPage from '../events/eventsAdminArchived.container';
import EventsAdminPage from '../events/eventsAdmin.container';
import HelpDeskManagePurchasesPage from '../events/helpDesk/helpDeskManagePurchases.container';
import LogoutPage from '../login/logout.container';

import HelpDeskPage from '../events/helpDesk/helpDesk.container';

import RegistrationEditPage from '../registrations/registrationEdit.container';

import CouponEditPage from '../coupons/couponEdit.container';
import CouponNewPage from '../coupons/couponNew.container';
import CouponsPage from '../coupons/coupons.container';

import NotificationEditPage from '../notifications/notificationEdit.container';
import NotificationNewPage from '../notifications/notificationNew.container';
import NotificationsPage from '../notifications/notifications.container';

import ProductEditPage from '../products/productEdit.container';
import ProductNewPage from '../products/productNew.container';
import ProductsPage from '../products/products.container';

import PurchasePlanEditPage from '../purchasePlans/purchasePlanEdit.container';
import PurchasePlanNewPage from '../purchasePlans/purchasePlanNew.container';
import PurchasePlansPage from '../purchasePlans/purchasePlans.container';

import EditTicketAssignmentAdmin from '../tickets/adminEditAssignment.container';
import NewTicketAssignmentAdmin from '../tickets/adminNewAssignment.container';
import TicketsByPurchaserContainer from '../tickets/ticketsByPurchaser.container';
import VipAssignmentsContainer from '../vips/vipAssignments.container';

import ScheduleSlotEditPage from '../schedule/scheduleSlotEdit.container';
import ScheduleSlotNewPage from '../schedule/scheduleSlotNew.container';
import ScheduleSlotSessionEditPage from '../schedule/scheduleSlotSessionEdit.container';
import ScheduleSlotSessionNewPage from '../schedule/scheduleSlotSessionNew.container';
import ScheduleSlotsPage from '../schedule/scheduleSlots.container';

import SpecialEventEditPage from '../specialEvents/specialEventEdit.container';
import SpecialEventNewPage from '../specialEvents/specialEventNew.container';
import SpecialEventsPage from '../specialEvents/specialEvents.container';

import StripePage from '../stripe/stripe.container';

import BigFiveEdit from '../bigFive/bigFiveEdit.container';
import BigFiveNew from '../bigFive/bigFiveNew.container';
import BigFivePage from '../bigFive/bigFive.container';

import EventEmailTemplates from '../events/eventEmailTemplates.container';
import ToggleView from '../login/ToggleView.component';
import { IS_ADMIN, IS_AGENCY_ADMIN, hasPermission } from '../login/permissions';

class RoutesAdmin extends Component {
  shouldComponentUpdate() {
    return true;
  }

  shouldShowBackButton = () => {
    const { pathname } = this.props.location;
    // console.log('PATHNAME!', pathname);
    const nonTerminalRoutes = [
      '/',
      '/assignAttendees',
      '/attendees',
      '/logout',
      '/events',
      '/registrations',
      '/big5',
      '/managers',
      '/products',
      '/purchasePlans',
      '/coupons',
      '/notifications',
    ];

    let toReturn = true;

    nonTerminalRoutes.forEach(route => {
      if (
        matchPath(pathname, {
          path: route,
          exact: true,
          strict: false,
        })
      ) {
        toReturn = false;
      }
    });

    return toReturn;
  };

  checkAuth = () => {
    if (this.props.loginStatus === LOGIN_STATE.SYSTEM_ADMIN) {
      // NOTE: Right here you can read from the user's profile to determine if they have what they need to proceed
      if (this.props.location.pathname === '/login') {
        console.log('HEADING TO HOME ROUTE FROM CHECK AUTH');
        this.props.history.replace('/');
      }
    } else if (this.props.loginStatus === LOGIN_STATE.ALTITUDE_USER) {
      if (this.props.location.pathname === '/login') {
        console.log('HEADING TO HOME ROUTE FROM CHECK AUTH');
        this.props.history.replace('/');
      }
    } else {
      console.log('NOT LOGGED IN!');
      if (this.props.location.pathname !== '/login') {
        this.props.history.replace('/login');
      }
    }
  };

  render() {
    const { permissions } = this.props;
    const shouldShowBackButton = this.shouldShowBackButton();

    return (
      <div>
        <Header shouldShowBackButton={shouldShowBackButton} />

        <Switch>
          <Route
            exact
            path="/events"
            component={getInitializingComponent(EventsAdminPage, this.checkAuth)}
          />
          <Route
            exact
            path="/eventsArchived"
            component={getInitializingComponent(EventsAdminArchivedPage, this.checkAuth)}
          />
          <Route
            exact
            path="/eventNew"
            component={getInitializingComponent(EventNewPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id"
            component={getInitializingComponent(EventEditPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id/purchases"
            component={getInitializingComponent(EventPurchasesPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id/assignments"
            component={getInitializingComponent(EventAssignmentsPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id/reports"
            component={getInitializingComponent(EventReportsPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id/accountsReceivable"
            component={getInitializingComponent(AccountsReceivablePage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id/tickets/:purchaserId"
            component={getInitializingComponent(TicketsByPurchaserContainer, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id/assignments/:ticketId"
            component={getInitializingComponent(NewTicketAssignmentAdmin, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/scheduleSlots"
            component={getInitializingComponent(ScheduleSlotsPage, this.checkAuth)}
          />
          <Route
            exact
            path="/scheduleSlotNew"
            component={getInitializingComponent(ScheduleSlotNewPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/scheduleSlots/:slotId"
            component={getInitializingComponent(ScheduleSlotEditPage, this.checkAuth)}
          />
          <Route
            exact
            path="/scheduleSlotSessionNew"
            component={getInitializingComponent(ScheduleSlotSessionNewPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/scheduleSlots/:slotId/sessions/:sessionId"
            component={getInitializingComponent(ScheduleSlotSessionEditPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/specialEvents"
            component={getInitializingComponent(SpecialEventsPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/specialEvents/new"
            component={getInitializingComponent(SpecialEventNewPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/specialEvents/:specialEventId"
            component={getInitializingComponent(SpecialEventEditPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/helpDesk/purchases/:purchaseEmail"
            component={getInitializingComponent(HelpDeskManagePurchasesPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/badgeColors"
            component={getInitializingComponent(EventBadgeLanyardColorsPage, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:eventId/emailTemplates"
            component={getInitializingComponent(EventEmailTemplates, this.checkAuth)}
          />
          <Route
            exact
            path="/events/:id/vips"
            component={getInitializingComponent(VipAssignmentsContainer, this.checkAuth)}
          />
          <Route
            exact
            path="/eventAttendees/:id/"
            component={getInitializingComponent(EditTicketAssignmentAdmin, this.checkAuth)}
          />
          <Route
            path="/events/:eventId/registrations/:id"
            component={getInitializingComponent(RegistrationEditPage, this.checkAuth)}
          />
          <Route
            exact
            path="/coupons"
            component={getInitializingComponent(CouponsPage, this.checkAuth)}
          />
          <Route
            exact
            path="/couponNew"
            component={getInitializingComponent(CouponNewPage, this.checkAuth)}
          />
          <Route
            path="/coupons/:id"
            component={getInitializingComponent(CouponEditPage, this.checkAuth)}
          />
          <Route
            exact
            path="/notifications"
            component={getInitializingComponent(NotificationsPage, this.checkAuth)}
          />
          <Route
            exact
            path="/notificationNew"
            component={getInitializingComponent(NotificationNewPage, this.checkAuth)}
          />
          <Route
            path="/notifications/:notificationId"
            component={getInitializingComponent(NotificationEditPage, this.checkAuth)}
          />
          {hasPermission(IS_ADMIN, permissions) && (
            <Route
              exact
              path="/big5/new"
              component={getInitializingComponent(BigFiveNew, this.checkAuth)}
            />
          )}
          {hasPermission(IS_ADMIN, permissions) && (
            <Route
              exact
              path="/big5/:agencyId"
              component={getInitializingComponent(BigFiveEdit, this.checkAuth)}
            />
          )}
          {hasPermission(IS_ADMIN, permissions) && (
            <Route
              exact
              path="/big5"
              component={getInitializingComponent(BigFivePage, this.checkAuth)}
            />
          )}

          {hasPermission(IS_ADMIN, permissions) && (
            <Route
              exact
              path="/products"
              component={getInitializingComponent(ProductsPage, this.checkAuth)}
            />
          )}
          {hasPermission(IS_ADMIN, permissions) && (
            <Route
              exact
              path="/productNew"
              component={getInitializingComponent(ProductNewPage, this.checkAuth)}
            />
          )}
          {hasPermission(IS_ADMIN, permissions) && (
            <Route
              path="/products/:id"
              component={getInitializingComponent(ProductEditPage, this.checkAuth)}
            />
          )}
          {hasPermission(IS_AGENCY_ADMIN, permissions) && (
            <Route
              path="/stripe"
              component={getInitializingComponent(StripePage, this.checkAuth)}
            />
          )}
          <Route
            exact
            path="/purchasePlans"
            component={getInitializingComponent(PurchasePlansPage, this.checkAuth)}
          />
          <Route
            exact
            path="/purchasePlanNew"
            component={getInitializingComponent(PurchasePlanNewPage, this.checkAuth)}
          />
          <Route
            path="/purchasePlans/:id"
            component={getInitializingComponent(PurchasePlanEditPage, this.checkAuth)}
          />

          <Route
            path="/helpDesk"
            component={getInitializingComponent(HelpDeskPage, this.checkAuth)}
          />

          <Route exact path="/toggleView" component={ToggleView} />
          <Route
            exact
            path="/logout"
            component={getInitializingComponent(LogoutPage, this.checkAuth)}
          />
          <Redirect from="*" to="/events" />
        </Switch>
      </div>
    );
  }
}

RoutesAdmin.defaultProps = {
  permissions: [],
};

RoutesAdmin.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  loginStatus: PropTypes.number.isRequired,
  permissions: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    loginStatus: state.login.get('loginStatus'),
    permissions: state.login.get('permissions'),
  };
};

export default withRouter(connect(mapStateToProps, {})(RoutesAdmin));
