import moment from 'moment';
import { findIndex } from 'lodash';

export const getDateTimeFromSlotData = (slot, time) => {
  const slotDatePieces = slot.date.split('-');
  const timePieces = time.split(':');
  return new Date(
    slotDatePieces[0],
    slotDatePieces[1] - 1,
    slotDatePieces[2],
    timePieces[0],
    timePieces[1]
  );
};

export const sortScheduleByDateTime = eventSchedule => {
  return eventSchedule.sort((a, b) => {
    const slotAStartDateTime = getDateTimeFromSlotData(a, a.startTime);
    const slotBStartDateTime = getDateTimeFromSlotData(b, b.startTime);
    const momentA = moment(slotAStartDateTime);
    const momentB = moment(slotBStartDateTime);
    if (momentA.isAfter(momentB)) {
      return 1;
    }
    if (momentB.isAfter(momentA)) {
      return -1;
    }

    return 0;
  });
};

export const getEventScheduleByDay = eventSchedule => {
  const toReturn = [];

  eventSchedule.forEach(slot => {
    const dateIdx = findIndex(toReturn, { date: slot.date });
    if (dateIdx === -1) {
      toReturn.push({
        date: slot.date,
        slots: [slot],
      });
    } else {
      toReturn[dateIdx].slots.push(slot);
    }
  });

  return toReturn;
};

export const DATE_FORMAT = 'ddd, MMM DD';
export const TIME_FORMAT = 'h:mm A';

export const isSessionInMySchedule = (session, mySchedule, slotId) => {
  let toReturn = false;
  const slotIdx = findIndex(mySchedule, { id: slotId });
  if (slotIdx > -1) {
    const sessionIdx = findIndex(mySchedule[slotIdx].sessions, {
      id: session.id,
    });

    if (sessionIdx > -1) {
      toReturn = true;
    }
  }

  return toReturn;
};

export const isSessionFull = session => {
  return session.seats !== null && session.seatsFilled >= session.seats;
};

export const isSlotInThePast = slot => {
  const now = moment();
  const slotDateTime = getDateTimeFromSlotData(slot, slot.startTime);
  return now.diff(slotDateTime) > 0;
};

export const getInitialDayIndex = eventScheduleByDay => {
  let toReturn = 0;
  const momentToday = moment(Date.now());

  eventScheduleByDay.forEach((day, i) => {
    const momentDay = moment(day.date);
    if (momentToday.isAfter(momentDay)) {
      toReturn = i;
    }
  });

  return toReturn;
};

/*
Takes sessions, filters out exclusive events, and sort them by session title ascending
*/
export const getFilteredSortedBreakoutSessions = sessions => {
  return sessions
    .filter(session => {
      return !session.exclusive;
    })
    .sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA > titleB) {
        return 1;
      }
      if (titleB > titleA) {
        return -1;
      }

      return 0;
    });
};

export const hasScheduledEvents = (eventSchedule, isExclusive) => {
  return eventSchedule.some(event => {
    return event.sessions.some(session => session.exclusive === isExclusive);
  });
};

// isBreakouts is used here inorder to filter out general sessions
export const doesDayHaveSessions = (daySlots, isBreakouts = false) => {
  let toReturn = false;

  daySlots.forEach(slot => {
    if (isBreakouts) {
      toReturn = slot.sessions.filter(session => !session.exclusive).length > 0;
    }

    if (!isBreakouts && slot.sessions.length > 0) {
      toReturn = true;
    }
  });

  return toReturn;
};

export default { getDateTimeFromSlotData, sortScheduleByDateTime, getEventScheduleByDay };
