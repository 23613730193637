import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ScheduleHelper from '../../utilities/scheduleHelper';

const DATE_FORMAT = 'ddd, MMM DD';
const TIME_FORMAT = 'h:mm A';

const getFormattedDateForScheduleSlot = slot => {
  return moment(slot.date)
    .utc()
    .format(DATE_FORMAT);
};

const getFormattedTimeForScheduleSlot = slot => {
  return moment(ScheduleHelper.getDateTimeFromSlotData(slot, slot.startTime)).format(TIME_FORMAT);
};

const BreakoutSessionContent = ({ sessions }) =>
  sessions.map(session => {
    return (
      <p key={session.id}>
        {`${getFormattedDateForScheduleSlot(session.slot)} ${getFormattedTimeForScheduleSlot(
          session.slot
        )} -  ${session.title}`}
      </p>
    );
  });

BreakoutSessionContent.propTypes = {
  sessions: PropTypes.array.isRequired
};

export default BreakoutSessionContent;
