import { isEmpty } from 'lodash';

import { LOGIN_STATE } from './login.actions';

export const CREATE_SPECIAL_EVENTS = 'create:special_events';
export const VIEW_SPECIAL_EVENTS = 'view:special_events';
export const EDIT_SPECIAL_EVENTS = 'edit:special_events';
export const MANAGE_TICKET_ASSIGNMENTS = 'tickets:manage_assignments';
export const IS_AGENCY_OWNER = 'user:agency_owner';
export const IS_KEY_LEADER = 'user:key_leader';
export const IS_VUE_USER = 'user:vue_user';
export const IS_FIREBASE_USER = 'user:firebase_user';
export const IS_ADMIN = 'user:admin';
export const IS_AGENCY_ADMIN = 'user:agencyAdmin';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export const hasPermission = (permission, userPermissions) => {
  return !!(userPermissions && userPermissions.indexOf(permission) > -1);
};

export const hasOneOfPermissions = (permissionsToCheck, userPermissions) => {
  let toReturn = false;

  if (userPermissions) {
    permissionsToCheck.forEach(permissionToCheck => {
      if (userPermissions.indexOf(permissionToCheck) > -1) {
        toReturn = true;
      }
    });
  }

  return toReturn;
};

export const definePermissions = (loginState, profile = {}) => {
  if (loginState > LOGIN_STATE.NOT_LOGGED_IN && loginState < LOGIN_STATE.SYSTEM_ADMIN) {
    const permissions = [];
    // If the profile information isn't available, return generic permissions.
    if (isEmpty(profile)) {
      return permissions;
    }
    if (profile.agencyOwner) {
      permissions.push(IS_AGENCY_OWNER);
      permissions.push(CREATE_SPECIAL_EVENTS);
      permissions.push(VIEW_SPECIAL_EVENTS);
      permissions.push(EDIT_SPECIAL_EVENTS);
    }
    if (profile.keyLeader) {
      permissions.push(IS_KEY_LEADER);
    }
    if ('permissions' in profile && Object.keys(profile.permissions).length > 0) {
      permissions.push(IS_AGENCY_ADMIN);
      permissions.push(MANAGE_TICKET_ASSIGNMENTS);
      permissions.push(CREATE_SPECIAL_EVENTS);
      permissions.push(VIEW_SPECIAL_EVENTS);
      permissions.push(EDIT_SPECIAL_EVENTS);
    }
    if ('vueEmail' in profile) {
      permissions.push(IS_VUE_USER);
    } else {
      permissions.push(IS_FIREBASE_USER);
    }
    return permissions;
  }
  if (loginState === LOGIN_STATE.SYSTEM_ADMIN) {
    return [
      CREATE_SPECIAL_EVENTS,
      VIEW_SPECIAL_EVENTS,
      EDIT_SPECIAL_EVENTS,
      MANAGE_TICKET_ASSIGNMENTS,
      IS_VUE_USER,
      IS_ADMIN,
    ];
  }
  // not logged in, no permissions given
  return [];
};

export const setUserPermissions = (type, profile = null) => {
  return (dispatch, getState) => {
    /* The profile can be null if this isn't a non_system_admin user. If it is, we handle fetching the profile before we get here */
    if (profile === null) profile = getState().profile.get('profile');
    const permissions = definePermissions(type, profile);
    dispatch(setPermissions(permissions));
  };
};

export const setPermissions = permissions => {
  return {
    type: SET_PERMISSIONS,
    payload: permissions,
  };
};
