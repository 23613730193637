import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import LoadingOverlay from '../layout/loadingOverlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import colors from '../../styles/colors';
import scheduleSlotsSelector, { isLoadingSelector } from './scheduleSlots.selector';
import {
  DELETE_SCHEDULE_SLOT_SUCCESS,
  clearScheduleSlots,
  deleteScheduleSlot,
  getScheduleSlots,
} from './schedule.actions';
import { getDateTimeFromSlotData } from '../../utilities/convertTimes';
import { getEvent, setSelectedEvent } from '../events/events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

const DATE_FORMAT = 'ddd, MMM DD';
const TIME_FORMAT = 'h:mm a';

class Schedule extends Component {
  componentDidMount() {
    this.props.setPageTitle('Schedule');
    const { match, selectedEvent } = this.props;
    const { eventId } = match.params;

    if (isEmpty(selectedEvent) || selectedEvent.id !== eventId) {
      this.props.getEvent(eventId);
    }
    this.props.getScheduleSlots(eventId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedEvent !== this.props.selectedEvent) {
      this.props.setPageTitle(`${this.props.selectedEvent.name} - Schedule`);
    }
  }

  removeSlot = async (id, index, scheduleSlotStartDate, scheduleSlotEndDate) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you want to remove slot: ${moment(scheduleSlotStartDate).format(
          DATE_FORMAT
        )} from ${moment(scheduleSlotStartDate).format(TIME_FORMAT)} to ${moment(
          scheduleSlotEndDate
        ).format(TIME_FORMAT)}?`
      )
    ) {
      const response = await this.props.deleteScheduleSlot(id);
      if (response.type === DELETE_SCHEDULE_SLOT_SUCCESS) {
        this.props.handleToastMessage('Schedule slot deleted.');
      } else {
        this.props.handleToastMessage('Failed to delete schedule slot', true);
      }
    }
  };

  editScheduleSlot = slot => {
    // console.log('NAVIGATING TO SCHEDULE SLOT EDIT!', slot);
    this.props.history.push({
      pathname: `/events/${this.props.selectedEvent.id}/scheduleSlots/${slot.id}`,
      state: { slot },
    });
  };

  renderTableRow = (day, dayIndex, slot, slotIndex) => {
    const { scheduleSlotsByDay } = this.props;
    const slotStartDateTime = getDateTimeFromSlotData(slot, slot.startTime);
    const slotEndDateTime = getDateTimeFromSlotData(slot, slot.endTime);

    return (
      <TableRow
        // eslint-disable-next-line react/no-array-index-key
        key={slotIndex}
        style={
          slotIndex === day.slots.length - 1 && dayIndex !== scheduleSlotsByDay.length - 1
            ? { borderBottom: `0.25rem solid ${colors.primary}` }
            : null
        }
      >
        <TableCell className="clickable" onClick={() => this.editScheduleSlot(slot)}>
          <Typography variant="body1">{moment(slot.date).format(DATE_FORMAT)}</Typography>
        </TableCell>
        <TableCell className="clickable" onClick={() => this.editScheduleSlot(slot)}>
          <Typography variant="body1">{moment(slotStartDateTime).format(TIME_FORMAT)}</Typography>
        </TableCell>
        <TableCell className="clickable" onClick={() => this.editScheduleSlot(slot)}>
          <Typography variant="body1">{moment(slotEndDateTime).format(TIME_FORMAT)}</Typography>
        </TableCell>
        <TableCell>
          {!isEmpty(slot.sessions) && (
            <ul>
              {slot.sessions.map((session, k) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={k}>
                    <Typography variant="body1">{session.title}</Typography>
                  </li>
                );
              })}
            </ul>
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <SFGTextButton
            onClick={() => this.removeSlot(slot.id, dayIndex, slotStartDateTime, slotEndDateTime)}
            color="primary"
          >
            <i className="material-icons">close</i>
          </SFGTextButton>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { scheduleSlotsByDay, isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <div className="container">
        <SFGOutlinedButton
          className="button"
          color="primary"
          onClick={() => this.props.history.push('/scheduleSlotNew')}
        >
          New Time Slot
        </SFGOutlinedButton>

        {scheduleSlotsByDay.length === 0 ? (
          <Typography variant="headline" style={{ margin: '1rem 0' }}>
            No Time Slots
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Date</TableCell>
                <TableCell width="15%">Start Time</TableCell>
                <TableCell width="15%">End Time</TableCell>
                <TableCell width="40%">Sessions</TableCell>
                <TableCell width="10%" style={{ textAlign: 'center' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduleSlotsByDay.map((day, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={i}>
                    {day.slots.map((slot, j) => {
                      return this.renderTableRow(day, i, slot, j);
                    })}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

Schedule.defaultProps = {
  selectedEvent: null,
};

Schedule.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  scheduleSlotsByDay: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  getScheduleSlots: PropTypes.func.isRequired,
  deleteScheduleSlot: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    scheduleSlotsByDay: scheduleSlotsSelector(state.schedule.get('scheduleSlots')),
    selectedEvent: state.events.get('selectedEvent'),
    isLoading: isLoadingSelector(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getEvent,
    setSelectedEvent,
    getScheduleSlots,
    deleteScheduleSlot,
    clearScheduleSlots,
  })(Schedule)
);
