/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const sortByDate = data => {
  const sortedData = data.sort((a, b) => {
    const momentA = moment(a.startDate);
    const momentB = moment(b.startDate);

    if (momentA.isAfter(momentB)) {
      return 1;
    }
    if (momentB.isAfter(momentA)) {
      return -1;
    }

    return 0;
  });

  return sortedData;
};

export const filterByDate = (data, startDate, endDate) => {
  return data.filter(item => {
    const isAfterStartDate = !startDate || moment(item.startDate).isAfter(startDate);
    const isBeforeEndDate = !endDate || moment(item.endDate).isBefore(endDate);
    const startIsWithinRange =
      startDate &&
      moment(startDate).isAfter(item.startDate) &&
      moment(startDate).isBefore(item.endDate);
    const endIsWithinRange =
      endDate && moment(endDate).isAfter(item.startDate) && moment(endDate).isBefore(item.endDate);
    return (isAfterStartDate && isBeforeEndDate) || startIsWithinRange || endIsWithinRange;
  });
};

export const isStartDateAfterEndDate = (startDate, endDate) => {
  return startDate !== '' && endDate !== '' && moment(startDate).isAfter(endDate);
};
