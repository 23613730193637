/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const styles = theme => ({
  underline: {
    'pointer-events': 'none',
    '&:before': {
      left: 0,
      bottom: '2px',
      content: '""',
      height: 2,
      position: 'absolute',
      right: 0,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shorter
      })
    }
  },
  input: {
    cursor: 'default'
  },
  inputLabel: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  }
});

const ReadOnlyTextField = ({
  classes,
  inputComponent,
  label,
  multiline,
  name,
  value,
  disableUnderline
}) => {
  return (
    <TextField
      inputProps={{
        readOnly: true
      }}
      InputProps={{
        classes: {
          underline: classes.underline,
          input: classes.input
        },
        inputComponent,
        disableUnderline
      }}
      InputLabelProps={{
        style: { width: 'max-content' }
      }}
      multiline={!!multiline}
      fullWidth
      label={label}
      name={name}
      margin="normal"
      value={!isEmpty(value) ? value : ''}
    />
  );
};

ReadOnlyTextField.defaultProps = {
  inputComponent: null,
  multiline: false,
  name: '',
  disableUnderline: false
};

ReadOnlyTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  inputComponent: PropTypes.element,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  disableUnderline: PropTypes.bool
};

export default withStyles(styles)(ReadOnlyTextField);
