import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormControl } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';

class EventHelpDeskLinkVueForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: '',
      confirmEmailAddress: '',
    };

    this.formRef = null;

    ['updateField'].forEach(k => {
      this[k] = this[k].bind(this);
    });

    ValidatorForm.addValidationRule('doesEmailMatch', value => value === this.state.emailAddress);
  }

  updateField(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      const isValid = this.formRef.isFormValid();
      this.props.onFormUpdate({ [name]: value, isValid });
    });
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <ValidatorForm
          onSubmit={() => {}}
          ref={r => {
            this.formRef = r;
          }}
        >
          <FormControl className={this.props.classes.formControl}>
            <TextValidator
              fullWidth
              label="Email Address"
              name="emailAddress"
              value={this.state.emailAddress}
              onChange={this.updateField}
              type="text"
              margin="normal"
              validators={['required', 'isEmail']}
              errorMessages={['Email address is required', 'Must enter a valid email address.']}
            />
          </FormControl>
          <FormControl className={this.props.classes.formControl}>
            <TextValidator
              fullWidth
              label="Confirm Email Address"
              name="confirmEmailAddress"
              value={this.state.confirmEmailAddress}
              onChange={this.updateField}
              type="text"
              margin="normal"
              validators={['required', 'isEmail', 'doesEmailMatch']}
              errorMessages={[
                'Email address is required',
                'Must enter a valid email address.',
                'The email addresses must match',
              ]}
            />
          </FormControl>
        </ValidatorForm>
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  formControl: {
    display: 'block',
  },
});

EventHelpDeskLinkVueForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onFormUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(EventHelpDeskLinkVueForm);
