import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ERROR_MESSAGES from '../../types/errorMessages';
import EventEmailTemplatesForm from './eventEmailTemplatesForm.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { EMAIL_TEMPLATES } from '../../utilities/emailTemplates';
import {
  UPDATE_EVENT_SUCCESS,
  getEvent,
  getEventEmailTemplates,
  updateEvent,
} from './events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import { selectEventsIsLoading, selectSelectedEvent } from './events.selectors';

const EventEmailTemplates = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [emailTemplates, setEmailTemplates] = useState(null);

  useEffect(() => {
    dispatch(setPageTitle('Email Templates'));
    dispatch(getEvent(params.eventId));

    (async () => {
      const templates = await Promise.all([
        dispatch(getEventEmailTemplates(EMAIL_TEMPLATES.PAYMENT_DECLINED)).then(response => ({
          [EMAIL_TEMPLATES.PAYMENT_DECLINED]: response.response,
        })),
        dispatch(getEventEmailTemplates(EMAIL_TEMPLATES.APP_INVITE)).then(response => ({
          [EMAIL_TEMPLATES.APP_INVITE]: response.response,
        })),
        dispatch(
          getEventEmailTemplates(EMAIL_TEMPLATES.ASSIGNMENT_CONFIRMATION)
        ).then(response => ({ [EMAIL_TEMPLATES.ASSIGNMENT_CONFIRMATION]: response.response })),
        dispatch(getEventEmailTemplates(EMAIL_TEMPLATES.EVENT_REMINDER)).then(response => ({
          [EMAIL_TEMPLATES.EVENT_REMINDER]: response.response,
        })),
        dispatch(getEventEmailTemplates(EMAIL_TEMPLATES.SEND_TICKET)).then(response => ({
          [EMAIL_TEMPLATES.SEND_TICKET]: response.response,
        })),
      ]);

      /* Convers array of objects into object with template labels as keys */
      const templateObj = templates.reduce((acc, cur) => ({ ...acc, ...cur }), {});

      setEmailTemplates(templateObj);
    })();
  }, [dispatch, params]);

  const selectedEvent = useSelector(selectSelectedEvent);
  const isLoading = useSelector(selectEventsIsLoading);

  const handleSaveTemplates = useCallback(
    async eventData => {
      console.log('EVENT DATA!', eventData);
      const response = await dispatch(updateEvent(selectedEvent.id, eventData));

      if (response.type === UPDATE_EVENT_SUCCESS) {
        dispatch(handleToastMessage('Event email templates updated.'));
      } else {
        dispatch(handleToastMessage(ERROR_MESSAGES.EMAIL_TEMPLATES_UPDATE_FAILURE, true));
      }
    },
    [dispatch, selectedEvent]
  );

  if (isLoading || isEmpty(selectedEvent) || isNil(emailTemplates))
    return (
      <div className="container">
        <LoadingOverlay />
      </div>
    );

  return (
    <div className="container">
      <Grid container justify="center">
        <Grid item xs={6}>
          <Typography align="center" color="primary" variant="title">
            Event Email Templates
          </Typography>
          <EventEmailTemplatesForm
            emailTemplates={emailTemplates}
            selectedEvent={selectedEvent}
            onSubmit={handleSaveTemplates}
          />
        </Grid>
      </Grid>
    </div>
  );
};

EventEmailTemplates.propTypes = {};

export default EventEmailTemplates;
