import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import 'firebase/auth';
import { Redirect, Route, Switch, matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LogoutPage from '../login/logout.container';
import getInitializingComponent from '../../utilities/initializing.component';
import {
  CREATE_SPECIAL_EVENTS,
  EDIT_SPECIAL_EVENTS,
  IS_AGENCY_ADMIN,
  VIEW_SPECIAL_EVENTS,
  hasPermission,
} from '../login/permissions';
import { getProfile } from '../profile/profile.actions';

import AttendeesListPage from '../attendees/attendeesList.container';
import CheckInPage from '../gatherings/gatheringsCheckIn.container';
import DownlineAgentsPage from '../agents/downlineAgents.container';
import EventRegistrationPage from '../events/eventRegistration.container';
import EventsUserPage from '../events/eventsUser.container';
import Header from './headerUser.container';
import MyPurchasesPage from '../purchases/myPurchases.container';
import MyTicketsPage from '../purchases/myTickets.container';
import NewAttendeePage from '../attendees/attendeeNew.container';
import ProfilePage from '../profile/profile.container';
import PurchaseDetails from '../purchases/purchaseDetails.container';
import SpecialEventEditPage from '../specialEvents/specialEventEdit.container';
import SpecialEventNewPage from '../specialEvents/specialEventNew.container';
import SpecialEventsPage from '../specialEvents/specialEvents.container';
import ToggleView from '../login/ToggleView.component';

class RoutesUser extends Component {
  shouldShowBackButton = () => {
    const { pathname } = this.props.location;
    const nonTerminalRoutes = [
      '/',
      '/logout',
      '/events',
      '/profile',
      '/myTickets',
      '/myPurchases',
      '/downline',
    ];

    let toReturn = true;
    nonTerminalRoutes.forEach(route => {
      if (
        matchPath(pathname, {
          path: route,
          exact: true,
          strict: false,
        })
      ) {
        toReturn = false;
      }
    });

    return toReturn;
  };

  render() {
    const { permissions } = this.props;

    return (
      <>
        <Header shouldShowBackButton={this.shouldShowBackButton()} />
        <Switch>
          <Route
            exact
            path="/attendees/:eventId/new/:ticketId"
            component={getInitializingComponent(NewAttendeePage)}
          />
          <Route
            path="/attendees/:eventId"
            component={getInitializingComponent(AttendeesListPage)}
          />
          <Route
            exact
            path="/events/:id/registration"
            component={getInitializingComponent(EventRegistrationPage)}
          />
          {hasPermission(CREATE_SPECIAL_EVENTS, permissions) && (
            <Route
              exact
              path="/events/:eventId/specialEvents/new"
              component={getInitializingComponent(SpecialEventNewPage)}
            />
          )}
          {hasPermission(EDIT_SPECIAL_EVENTS, permissions) && (
            <Route
              exact
              path="/events/:eventId/specialEvents/:specialEventId"
              component={getInitializingComponent(SpecialEventEditPage)}
            />
          )}
          {hasPermission(VIEW_SPECIAL_EVENTS, permissions) && (
            <Route
              exact
              path="/events/:eventId/specialEvents"
              component={getInitializingComponent(SpecialEventsPage)}
            />
          )}
          {/* {hasPermission(VIEW_SPECIAL_EVENTS, permissions) && ( */}
          <Route
            exact
            path="/events/:eventId/checkIn"
            component={getInitializingComponent(CheckInPage)}
          />
          {/* )} */}
          <Route exact path="/events" component={getInitializingComponent(EventsUserPage)} />
          <Route exact path="/downline" component={getInitializingComponent(DownlineAgentsPage)} />
          <Route exact path="/profile" component={getInitializingComponent(ProfilePage)} />
          <Route exact path="/myPurchases" component={getInitializingComponent(MyPurchasesPage)} />
          <Route
            exact
            path="/purchases/:purchaseId"
            component={getInitializingComponent(PurchaseDetails)}
          />
          <Route exact path="/myTickets" component={getInitializingComponent(MyTicketsPage)} />

          {hasPermission(IS_AGENCY_ADMIN, permissions) && (
            <Route exact path="/toggleView" component={getInitializingComponent(ToggleView)} />
          )}
          <Route exact path="/logout" component={LogoutPage} />

          <Redirect from="*" to="/events" />
        </Switch>
      </>
    );
  }
}

RoutesUser.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  permissions: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    permissions: state.login.get('permissions'),
    profile: state.profile.get('profile'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getProfile,
  })(RoutesUser)
);
