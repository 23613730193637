import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { ExpandMore } from '@material-ui/icons';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles,
} from '@material-ui/core';

import Colors from '../../styles/colors';
import SessionSlot from './sessionSlot.component';
import { DATE_FORMAT } from '../../utilities/scheduleHelper';

const ScheduleListItem = ({
  classes,
  className,
  day,
  eventId,
  isBreakouts,
  startOpen,
  forceExpand,
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(startOpen);

  const handleToggleExpansion = useCallback(() => setIsPanelOpen(!isPanelOpen), [
    isPanelOpen,
    setIsPanelOpen,
  ]);

  const numOfSessions = useMemo(
    () =>
      // eslint-disable-next-line no-shadow
      day.slots.reduce((numOfSessions, slot) => {
        if (isBreakouts) {
          return numOfSessions + slot.sessions.filter(session => !session.exclusive).length;
        }

        return numOfSessions + slot.sessions.length;
      }, 0),
    [day.slots, isBreakouts]
  );

  return (
    <ExpansionPanel
      key={day.date}
      className={classNames(className, classes.panel)}
      expanded={forceExpand || isPanelOpen}
    >
      <ExpansionPanelSummary
        className={classes.panelSummary}
        expandIcon={<ExpandMore />}
        onClick={handleToggleExpansion}
      >
        <Typography color="inherit" variant="headline">
          {`${moment(day.date).format(DATE_FORMAT)} ${
            !isBreakouts ? `- (${numOfSessions} Session${numOfSessions !== 1 ? 's' : ''})` : ''
          }`}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        {day.slots.map((slot, slotIndex) => (
          <SessionSlot
            key={slot.id}
            eventId={eventId}
            isBreakouts={isBreakouts}
            isLastSlot={day.slots.length === slotIndex + 1}
            slot={slot}
            slotIndex={slotIndex}
          />
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const styles = theme => ({
  panel: {
    border: `${Colors.grayMediumLight} solid 1px`,
    borderRadius: 0,
    boxShadow: 'none',
  },
  panelSummary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  panelDetails: {
    display: 'block',
    padding: 0,
  },
});

ScheduleListItem.defaultProps = {
  className: '',
  isBreakouts: false,
  startOpen: false,
};

ScheduleListItem.propTypes = {
  classes: PropTypes.object.isRequired,

  className: PropTypes.string,
  day: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  isBreakouts: PropTypes.bool,
  startOpen: PropTypes.bool,
  forceExpand: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ScheduleListItem);
