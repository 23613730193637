import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TOGGLE_USER_VIEW } from './login.actions';

class ToggleView extends Component {
  componentWillMount() {
    this.props.toggleView();
  }

  render() {
    return <Redirect to="/" />;
  }
}

ToggleView.propTypes = {
  toggleView: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  toggleView: () => dispatch({ type: TOGGLE_USER_VIEW })
});

export default connect(null, mapDispatchToProps)(ToggleView);
