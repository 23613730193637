const timezoneOffset = new Date().getTimezoneOffset();

export const utcToLocal = timestamp => {
  let newTime = new Date(timestamp).getTime();
  newTime -= timezoneOffset * 60 * 1000;
  return newTime;
};

export const formatDate = (timestamp, timezone = true) => {
  const date = new Date(timestamp);
  /* Set to EST offset so all dates remain the same for EST */
  date.setUTCHours(5);
  /* This automatically converts from local time to UTC */
  const isoString = date.toISOString();
  if (timezone) {
    return isoString;
  }
  return isoString.split('T')[0];
};

export const getDateTimeFromSlotData = (slot, time) => {
  const slotDatePieces = slot.date.split('-');
  const timePieces = time.split(':');
  return new Date(
    slotDatePieces[0],
    slotDatePieces[1] - 1,
    slotDatePieces[2],
    timePieces[0],
    timePieces[1]
  );
};
