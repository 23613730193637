import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import ProductForm from './productForm.component';
import { getProduct, updateProduct, UPDATE_PRODUCT_SUCCESS } from './products.actions';
import LoadingInlay from '../layout/loadingInlay.component';

class ProductEditContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('Edit Product');
    if (
      isEmpty(this.props.selectedProduct) ||
      this.props.selectedProduct.id !== this.props.match.params.id
    ) {
      this.props.getProduct(this.props.match.params.id);
    }
  }

  handleEdit = async productData => {
    const response = await this.props.updateProduct(this.props.selectedProduct.id, productData);

    if (response.type === UPDATE_PRODUCT_SUCCESS) {
      this.props.handleToastMessage('Product updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update product.', true);
    }
  };

  render() {
    const { selectedProduct, isLoading } = this.props;
    if (isLoading) {
      return <LoadingInlay />;
    }
    return (
      <div className="container">
        <ProductForm product={selectedProduct} submitAction={this.handleEdit} />
      </div>
    );
  }
}

ProductEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    selectedProduct: state.products.get('selectedProduct'),
    isLoading: state.products.get('isLoading')
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    getProduct,
    updateProduct,
    handleToastMessage
  })(ProductEditContainer)
);
