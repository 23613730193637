import Immutable from 'immutable';
import { isEmpty } from 'lodash';
import {
  GET_CART_FAILURE,
  GET_CART_SUCCESS,
  CREATE_PRECHECKOUT_SUCCESS,
  CREATE_PRECHECKOUT_FAILURE,
  UPDATE_CART_SUCCESS,
  COMPLETE_CHECKOUT_REQUEST,
  COMPLETE_CHECKOUT_SUCCESS,
  COMPLETE_CHECKOUT_FAILURE,
  CREATE_PRECHECKOUT_REQUEST
} from './cart.actions';

const initialState = Immutable.Map({
  cart: null,
  preCheckoutComplete: false,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CART_SUCCESS:
      if (!isEmpty(action.response.purchaser)) {
        return state.set('cart', action.response).set('preCheckoutComplete', true);
      }
      return state.set('cart', action.response);

    case GET_CART_FAILURE:
      return state.set('errors', action.messages);

    case UPDATE_CART_SUCCESS:
      return state.set('cart', action.response);

    case COMPLETE_CHECKOUT_SUCCESS:
      console.log('CHECKOUT SUCCESS!');
      console.log(action);
      return state;

    case COMPLETE_CHECKOUT_REQUEST:
      return state.set('isLoading', true);

    case COMPLETE_CHECKOUT_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case CREATE_PRECHECKOUT_REQUEST:
      return state.set('isLoading', true);

    case CREATE_PRECHECKOUT_SUCCESS:
      return state.set('preCheckoutComplete', true).set('isLoading', false);

    case CREATE_PRECHECKOUT_FAILURE:
      if (
        action.messages.reduce((acc, m) => {
          if (acc) return true;
          return m.match(new RegExp(/Precheckout already occurred/)); // returns boolean
        }, false)
      ) {
        return state.set('preCheckoutComplete', true);
      }
      console.log('PRECHECKOUT FAILURE!');
      return state.set('errors', action.messages).set('preCheckoutComplete', false);

    default:
      return state;
  }
}
