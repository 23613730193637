export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_RESPONSIVE_BREAKPOINT = 'SET_RESPONSIVE_BREAKPOINT';

export const SHOW_UPDATE_TOAST_MESSAGE = 'SHOW_UPDATE_TOAST_MESSAGE';
export const HIDE_UPDATE_TOAST_MESSAGE = 'HIDE_UPDATE_TOAST_MESSAGE';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';
export const SET_TOAST_POSITION = 'SET_TOAST_POSITION';

export const TOGGLE_DRAWER_MENU = 'TOGGLE_DRAWER_MENU';

const TOAST_DURATION = 5000;

export const setPageTitle = title => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
  };
};

export const setResponsiveBreakpoint = breakpoint => {
  return {
    type: SET_RESPONSIVE_BREAKPOINT,
    payload: breakpoint,
  };
};

export const showUpdateToast = () => {
  return {
    type: SHOW_UPDATE_TOAST_MESSAGE,
  };
};

export const hideUpdateToast = () => {
  return {
    type: HIDE_UPDATE_TOAST_MESSAGE,
  };
};

export const showToast = (message, isFailure) => {
  return {
    type: SHOW_TOAST_MESSAGE,
    message,
    isFailure,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

let toastTimer;
export const handleToastMessage = (message, isFailure, duration = TOAST_DURATION) => {
  return dispatch => {
    clearTimeout(toastTimer);
    dispatch(showToast(message, isFailure));
    toastTimer = setTimeout(() => {
      dispatch(hideToast());
    }, duration);
  };
};

export const setToastPosition = position => {
  return {
    type: SET_TOAST_POSITION,
    position,
  };
};

export const toggleDrawerMenu = () => {
  return {
    type: TOGGLE_DRAWER_MENU,
  };
};
