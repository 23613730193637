import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingInlay = ({ classes }) => (
  <div className={classes.progressIndicator}>
    <CircularProgress size={100} />
  </div>
);

const styles = (/* theme */) => ({
  progressIndicator: {
    width: '100%',
    margin: '1rem 0',
    textAlign: 'center'
  }
});

LoadingInlay.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingInlay);
