import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';

import states from '../../utilities/states';

/* Create list of months and years. The years dropdown is the current year to
   20 years in the future. */
const currYear = new Date().getFullYear();
const months = Array.from({ length: 12 }, (v, k) => (k < 9 ? `0${k + 1}` : k + 1));
const years = Array.from({ length: 21 }, (v, k) => k + currYear);

const BillingInfo = ({
  state,
  updateField,
  classes,
  allRequired,
  warning,
  hideHeader,
  hideCouponCode,
  fullscreen,
  disableAddress,
}) => {
  return (
    <div>
      <Typography
        variant="subheading"
        className={classes.subheading}
        style={{ display: hideHeader ? 'none' : 'inherit' }}
      >
        Billing Information
      </Typography>
      {warning && warning.length && (
        <Typography style={{ marginTop: '0.5rem' }} variant="body1">
          <strong>Note:</strong> {warning}
        </Typography>
      )}
      <Grid container>
        <Grid item container xs={fullscreen ? 12 : 9} spacing={16}>
          <Grid item xs={12} sm={4}>
            <TextValidator
              label="Name on Card"
              name="Name on Card"
              type="text"
              margin="normal"
              value={state.nameOnCard}
              onChange={e => updateField('nameOnCard', e.target.value)}
              validators={allRequired ? ['required', 'minStringLength:5'] : []}
              errorMessages={['Name is required', 'This must be at least five characters']}
              className={classes.formControl}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextValidator
              label="Address"
              name="Address"
              type="text"
              margin="normal"
              value={state.address}
              onChange={e => updateField('address', e.target.value)}
              validators={allRequired ? ['required', 'minStringLength:5'] : []}
              errorMessages={['Address is required', 'This must be at least five characters']}
              className={classes.formControl}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextValidator
              label="Address Line 2"
              name="Address Line 2"
              type="text"
              margin="normal"
              value={state.address2}
              onChange={e => updateField('address2', e.target.value)}
              className={classes.formControl}
              disabled={disableAddress}
            />
          </Grid>
        </Grid>

        <Grid item container xs={fullscreen ? 12 : 9} spacing={16}>
          <Grid item xs={12} sm={4}>
            <TextValidator
              label="City"
              name="City"
              type="text"
              margin="normal"
              value={state.city}
              onChange={e => updateField('city', e.target.value)}
              validators={allRequired ? ['required', 'minStringLength:2'] : []}
              errorMessages={['City is required', 'This must be at least two characters']}
              className={classes.formControl}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} style={{ marginTop: '1rem' }}>
              <SelectValidator
                label="State"
                value={state.state}
                name="state"
                onChange={e => updateField('state', e.target.value)}
                validators={allRequired ? ['required'] : []}
                errorMessages={['State is required']}
                disabled={disableAddress}
              >
                {Object.keys(states).map(s => (
                  <MenuItem key={states[s]} value={states[s]}>
                    {s}
                  </MenuItem>
                ))}
              </SelectValidator>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextValidator
              label="Zip"
              name="Zip"
              type="text"
              margin="normal"
              value={state.zip}
              onChange={e => updateField('zip', e.target.value)}
              validators={
                allRequired ? ['required', 'matchRegexp:^[\\-0-9]+$', 'minStringLength:5'] : []
              }
              errorMessages={[
                'Zip code is required',
                'A zip code may only contain numbers and dashes',
                'A zip code must be at least 5 numbers',
              ]}
              className={classes.formControl}
              disabled={disableAddress}
            />
          </Grid>
        </Grid>

        <Grid item container xs={fullscreen ? 12 : 9} spacing={16}>
          <Grid item xs={12} sm={4}>
            <TextValidator
              label="Credit Card Number"
              name="Credit Card Number"
              type="text"
              margin="normal"
              value={state.creditCardNumber}
              onChange={e => updateField('creditCardNumber', e.target.value)}
              validators={['required']}
              errorMessages={['Credit Cart Number is required']}
              className={classes.formControl}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.expirationDate}>
              <SelectValidator
                id="expirationMonth"
                label="Exp Month"
                name="Exp Month"
                type="date"
                margin="normal"
                value={state.ccExpirationMonth}
                onChange={e => updateField('ccExpirationMonth', e.target.value)}
                validators={['required']}
                errorMessages={['Expiration Month is required']}
                className={classes.selectValidator}
              >
                {months.map(m => (
                  <MenuItem key={m} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </SelectValidator>
              <SelectValidator
                id="expirationYear"
                label="Exp Year"
                name="Exp Year"
                type="number"
                margin="normal"
                value={state.ccExpirationYear}
                onChange={e => updateField('ccExpirationYear', e.target.value)}
                validators={['required']}
                errorMessages={['Expiration Year is required']}
                className={classes.selectValidator}
              >
                {years.map(y => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </SelectValidator>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextValidator
              label="CVC Number"
              name="CVC Number"
              type="text"
              margin="normal"
              value={state.cvcNumber}
              onChange={e => updateField('cvcNumber', e.target.value)}
              validators={['required', 'isNumber']}
              errorMessages={['CVC is required', 'CVC must be a number']}
              className={classes.formControl}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {hideCouponCode === false && (
              <TextField
                label="Coupon Code"
                type="text"
                margin="normal"
                value={state.couponCode}
                onChange={e => updateField('couponCode', e.target.value)}
                className={classes.formControl}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (/* theme */) => ({
  formControl: {
    // marginRight: theme.spacing.unit,
    minWidth: 140,
    width: '100%',
  },
  expirationDate: {
    minWidth: 140,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectValidator: {
    minWidth: 70,
    maxWidth: '45%',
    flex: 1,
  },
  subheading: {
    // marginBottom: '0.5rem'
  },
});

BillingInfo.defaultProps = {
  allRequired: true,
  hideCouponCode: false,
  hideHeader: false,
  warning: '',
  fullscreen: false,
  disableAddress: false,
};

BillingInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,

  allRequired: PropTypes.bool,
  hideCouponCode: PropTypes.bool,
  hideHeader: PropTypes.bool,
  warning: PropTypes.string,

  updateField: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool,
  disableAddress: PropTypes.bool,
};

export default withStyles(styles)(BillingInfo);
