import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import PurchasePlanForm from './purchasePlanForm.component';
import {
  getPurchasePlan,
  setSelectedPurchasePlan,
  updatePurchasePlan,
  UPDATE_PURCHASEPLAN_SUCCESS
} from './purchasePlans.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

class PurchasePlanEditContainer extends Component {
  constructor(props) {
    super(props);

    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    this.props.setPageTitle('Edit Purchase Plan');
    const { match, selectedPurchasePlan } = this.props;
    const purchasePlanId = match.params.id;
    if (isEmpty(selectedPurchasePlan) || selectedPurchasePlan.id !== purchasePlanId) {
      this.props.getPurchasePlan(purchasePlanId);
    }
  }

  async handleEdit(purchasePlanData) {
    if (purchasePlanData.cost) {
      /* Product prices are sent to the server in cents, not dollars */
      purchasePlanData.cost = Math.round(purchasePlanData.cost * 100);
    }
    const response = await this.props.updatePurchasePlan(
      this.props.selectedPurchasePlan.id,
      purchasePlanData
    );

    if (response.type === UPDATE_PURCHASEPLAN_SUCCESS) {
      this.props.handleToastMessage('Purchase Plan updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update Purchase Plan.', true);
    }
  }

  render() {
    const { agencies, events, products, selectedPurchasePlan, isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <div className="container">
        <PurchasePlanForm
          agencies={agencies}
          events={events}
          products={products}
          purchasePlan={selectedPurchasePlan}
          submitAction={this.handleEdit}
        />
      </div>
    );
  }
}

PurchasePlanEditContainer.defaultProps = {
  selectedPurchasePlan: null
};

PurchasePlanEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  agencies: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  selectedPurchasePlan: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getPurchasePlan: PropTypes.func.isRequired,
  updatePurchasePlan: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    agencies: state.bigFive.get('agencies'),
    events: state.events.get('eventsAdmin'),
    products: state.products.get('products'),
    selectedPurchasePlan: state.purchasePlans.get('selectedPurchasePlan'),
    isLoading: state.purchasePlans.get('isLoading')
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    getPurchasePlan,
    setSelectedPurchasePlan,
    updatePurchasePlan,
    handleToastMessage
  })(PurchasePlanEditContainer)
);
