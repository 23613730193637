import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const EventCheckInQuestions = ({ questions, responses, handleCheckInQuestionResponse }) => (
  <FormGroup row>
    {questions.map(question => {
      return (
        <FormControlLabel
          key={question.id}
          control={
            <Checkbox
              value="response"
              onChange={e => handleCheckInQuestionResponse(question.id, e.target.checked)}
              checked={responses[question.id] === true}
              indeterminate={responses[question.id] === undefined}
            />
          }
          label={question.text}
        />
      );
    })}
  </FormGroup>
);

const styles = (/* theme */) => ({
  formControl: {
    display: 'block'
  }
});

EventCheckInQuestions.defaultProps = {
  responses: {}
};

EventCheckInQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
  handleCheckInQuestionResponse: PropTypes.func.isRequired,
  responses: PropTypes.object
};

export default withStyles(styles)(EventCheckInQuestions);
