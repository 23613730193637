import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import routeLinks from './routeLinksUser';
import { hasOneOfPermissions } from '../login/permissions';

const styles = theme => ({
  root: {
    width: '100%',
    minWidth: 240,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  activeRoute: {
    color: theme.palette.primary.main,
  },
  flex: {
    flex: 1,
  },
  bottomText: {
    paddingLeft: 16,
    marginBottom: 16,
  },
});

class DrawerMenu extends Component {
  isActiveRoute = route => {
    const { pathname } = this.props.location;

    // explicitly return true/false because matchpath doesn't return a boolean
    if (
      matchPath(pathname, {
        path: route,
        exact: false,
        strict: false,
      })
    ) {
      return true;
    }

    return false;
  };

  viewPage = route => {
    if (!this.isActiveRoute(route)) {
      this.props.toggleDrawerMenu();

      if (route.indexOf('http') > -1) {
        window.open(route, '_blank');
      } else if (route.match(/^mailto/)) {
        window.location.href = route;
      } else {
        this.props.history.push(route);
      }
    }
  };

  render() {
    const { classes, isDrawerMenuOpen, toggleDrawerMenu, permissions, userEmail } = this.props;

    let emailToDisplay = '';

    if (userEmail) {
      emailToDisplay = userEmail;
    } else if (firebase.auth().currentUser) {
      emailToDisplay = firebase.auth().currentUser.email;
    }

    return (
      <SwipeableDrawer
        open={isDrawerMenuOpen}
        onClose={this.props.toggleDrawerMenu}
        onOpen={this.props.toggleDrawerMenu}
      >
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="title" color="inherit" className={classes.flex}>
              Quility Agent Events
            </Typography>

            <IconButton color="inherit" aria-label="Close" onClick={toggleDrawerMenu}>
              <i className="material-icons">close</i>
            </IconButton>
          </Toolbar>
        </AppBar>

        <List component="nav" className={classNames(classes.root, classes.flex)}>
          {routeLinks.map(routeLink => {
            /* If a required permission is defined, make sure the user has it before displaying the link. */
            if (
              'permissions' in routeLink &&
              !hasOneOfPermissions(routeLink.permissions, permissions)
            ) {
              return <span key={routeLink.route} />;
            }
            return (
              <ListItem button onClick={() => this.viewPage(routeLink.route)} key={routeLink.route}>
                <ListItemText
                  primary={
                    <Typography
                      variant="subheading"
                      className={this.isActiveRoute(routeLink.route) ? classes.activeRoute : ''}
                    >
                      {routeLink.name}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
        <Typography variant="body1" color="inherit" className={classes.bottomText}>
          {emailToDisplay}
        </Typography>
      </SwipeableDrawer>
    );
  }
}

DrawerMenu.defaultProps = {
  permissions: [],
  userEmail: null,
};

DrawerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  toggleDrawerMenu: PropTypes.func.isRequired,
  isDrawerMenuOpen: PropTypes.bool.isRequired,
  permissions: PropTypes.array,
  userEmail: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    userEmail: state.login.get('userEmail'),
  };
};

const withRouterDrawerMenu = withRouter(connect(mapStateToProps)(DrawerMenu));

export default withStyles(styles)(withRouterDrawerMenu);
