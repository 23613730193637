import { CALL_API } from '../../middleware/api';
import { setUserPermissions } from '../login/permissions';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const LINK_VUE_EMAIL_REQUEST = 'LINK_VUE_EMAIL_REQUEST';
export const LINK_VUE_EMAIL_SUCCESS = 'LINK_VUE_EMAIL_SUCCESS';
export const LINK_VUE_EMAIL_FAILURE = 'LINK_VUE_EMAIL_FAILURE';

export const ADMIN_LINK_EMAIL_REQUEST = 'ADMIN_LINK_EMAIL_REQUEST';
export const ADMIN_LINK_EMAIL_SUCCESS = 'ADMIN_LINK_EMAIL_SUCCESS';
export const ADMIN_LINK_EMAIL_FAILURE = 'ADMIN_LINK_EMAIL_FAILURE';

export const updateProfile = () => {
  return {
    [CALL_API]: {
      types: [GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE],
      authenticated: true,
      endpoint: `v1/profile`,
      method: 'GET',
    },
  };
};

export const getProfile = () => {
  return async (dispatch, getState) => {
    const result = await dispatch(updateProfile());
    const type = getState().login.get('loginStatus');
    dispatch(setUserPermissions(type, result.response));
    return result;
  };
};

export const linkVueEmail = (email, password) => {
  return {
    [CALL_API]: {
      types: [LINK_VUE_EMAIL_REQUEST, LINK_VUE_EMAIL_SUCCESS, LINK_VUE_EMAIL_FAILURE],
      authenticated: true,
      endpoint: `v1/auth/linkVueEmail`,
      method: 'POST',
      payload: { email, password },
    },
  };
};

export const adminLinkEmail = (email1, email2) => {
  return {
    [CALL_API]: {
      types: [ADMIN_LINK_EMAIL_REQUEST, ADMIN_LINK_EMAIL_SUCCESS, ADMIN_LINK_EMAIL_FAILURE],
      authenticated: true,
      endpoint: `admin/linkEmails`,
      method: 'POST',
      payload: { email1, email2 },
    },
  };
};
