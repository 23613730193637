import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty, omit } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AdminInviteeForm from '../layout/inviteeForm/adminInviteeForm.container';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SpecialEventBasicInfoForm from './specialEventsForms/specialEventBasicInfoForm.component';
import UserInviteeForm from '../layout/inviteeForm/userInviteeForm.container';
import inviteeFormTypes from '../../types/inviteeFormTypes';
import { LOGIN_STATE } from '../login/login.actions';
import { formatDate } from '../../utilities/convertTimes';

class SpecialEventForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      specialEvent: {
        title: '',
        description: '',
        place: '',
        location: '',
        date: '',
        startTime: '',
        endTime: '',
        inviteeEmails: [],
      },
    };
  }

  componentDidMount() {
    const { specialEvent } = this.props;

    if (!isEmpty(specialEvent)) {
      this.setState({
        specialEvent: {
          title: specialEvent.title ? specialEvent.title : '',
          description: specialEvent.description ? specialEvent.description : '',
          place: specialEvent.place ? specialEvent.place : '',
          location: specialEvent.location ? specialEvent.location : '',
          date: specialEvent.date ? specialEvent.date : '',
          startTime: specialEvent.startTime ? specialEvent.startTime : '',
          endTime: specialEvent.endTime ? specialEvent.endTime : '',
          inviteeEmails: specialEvent.invitees || [],
        },
      });
    }
  }

  onFormUpdate = specialEvent => {
    let updateSpecialEvent = { ...specialEvent };
    // map emails to invitee emails
    if (updateSpecialEvent.emails) {
      updateSpecialEvent.inviteeEmails = updateSpecialEvent.emails;
      updateSpecialEvent = omit(updateSpecialEvent, 'emails');
    }

    this.setState(prevState => ({
      specialEvent: {
        ...prevState.specialEvent,
        ...updateSpecialEvent,
      },
    }));
  };

  isFormValid = () => {
    const requiredFields = [
      'title',
      'description',
      'place',
      'date',
      'startTime',
      'endTime',
      'location',
    ];
    const requiredSelectionFields = ['inviteeEmails'];

    const invalidFields = requiredFields
      .filter(field => {
        if (isEmpty(this.state.specialEvent[field])) {
          return true;
        }
        return false;
      })
      .concat(
        requiredSelectionFields.filter(field => {
          // return no errors if a bulk selection has been made
          if (
            this.state.specialEvent.isIncludeAllCheckedIn === true ||
            this.state.specialEvent.isIncludeAllRegistrants === true
          ) {
            return false;
          }
          return this.state.specialEvent[field].length <= 0;
        })
      );
    return !(invalidFields.length > 0);
  };

  handleSubmit = e => {
    e.preventDefault();
    const isUserAdmin = this.props.loginStatus === LOGIN_STATE.SYSTEM_ADMIN;
    const { specialEvent } = this.state;
    specialEvent.date = formatDate(this.state.specialEvent.date);
    this.props.submitAction(specialEvent, isUserAdmin);
  };

  render() {
    const { specialEvent, selectedEvent, loginStatus } = this.props;

    const isUserAdmin = loginStatus === LOGIN_STATE.SYSTEM_ADMIN;

    return (
      <ValidatorForm onSubmit={this.handleSubmit}>
        <div className="container">
          <SpecialEventBasicInfoForm specialEvent={specialEvent} onFormUpdate={this.onFormUpdate} />
          {isUserAdmin === true && (
            <AdminInviteeForm
              emails={specialEvent ? specialEvent.invitees : []}
              headingText={inviteeFormTypes.INVITEE}
              onFormUpdate={this.onFormUpdate}
              canDeleteEntries
              selectedEvent={selectedEvent}
              isShowAttendeeAutocomplete
              isAllowMessageDialog={!isEmpty(specialEvent)}
            />
          )}
          {!isUserAdmin === true && (
            <UserInviteeForm
              emails={specialEvent ? specialEvent.invitees : []}
              headingText={inviteeFormTypes.INVITEE}
              onFormUpdate={this.onFormUpdate}
              canDeleteEntries
              selectedEvent={selectedEvent}
              isAllowMessageDialog={!isEmpty(specialEvent)}
            />
          )}

          <div style={{ marginTop: '4rem' }}>
            <SFGOutlinedButton
              className="button expanded"
              color="primary"
              style={{ marginRight: '1rem' }}
              onClick={() => this.props.history.goBack()}
            >
              Cancel
            </SFGOutlinedButton>
            <SFGOutlinedButton
              className="button expanded"
              color="primary"
              disabled={!this.isFormValid()}
              type="submit"
              onClick={this.handleSubmit}
            >
              Submit
            </SFGOutlinedButton>
          </div>
        </div>
      </ValidatorForm>
    );
  }
}

const styles = {
  spacerBottom: {
    marginBottom: '2rem',
  },
};

SpecialEventForm.defaultProps = {
  specialEvent: null,
  selectedEvent: null,
};

SpecialEventForm.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  specialEvent: PropTypes.object,
  selectedEvent: PropTypes.object,
  loginStatus: PropTypes.number.isRequired,

  submitAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(SpecialEventForm));
