import PropTypes from 'prop-types';
import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import loginHandler from '../../utilities/loginHandler';
import { setPageTitle } from '../layout/layout.actions';

class LogoutContainer extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.setPageTitle('Logout');
  }

  async componentDidMount() {
    await firebase.auth().signOut();
    loginHandler.handleLogout();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="title">Logging out...</Typography>
      </div>
    );
  }
}

const styles = {
  container: {
    width: '100%',
    height: '80vh',
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',
  },
};

LogoutContainer.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const withRouterLogoutContainer = withRouter(
  connect(null, {
    setPageTitle,
  })(LogoutContainer)
);

export default withStyles(styles)(withRouterLogoutContainer);
