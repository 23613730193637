/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import CustomAutocomplete from './customAutocomplete.component';
import useDebounce from '../../../hooks/useDebounce';
import { getAgents } from '../../agents/agents.actions';

const AllAgentsAutocomplete = ({
  error,
  label,
  placeholder,
  value,
  onChange,
  onInputClear,
  ...props
}) => {
  const dispatch = useDispatch();

  const [agencyOwners, setAgencyOwners] = useState([]);
  const [searchTerm, setSearchTerm] = useState(value);

  const debouncedValue = useDebounce(searchTerm || value, 300);

  useEffect(() => {
    (async () => {
      if (debouncedValue && debouncedValue.length > 2) {
        const response = await dispatch(getAgents(debouncedValue));

        setAgencyOwners(response.response);
      }
    })();
  }, [dispatch, debouncedValue, setAgencyOwners]);

  const handleSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const handleSuggestionsClearRequested = useCallback(() => {
    setAgencyOwners([]);
  }, [setAgencyOwners]);

  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.name}
        &nbsp;-&nbsp;
        {suggestion.email}
      </MenuItem>
    );
  };

  const getSuggestionValue = suggestion => {
    return `${suggestion.name} - ${suggestion.email}`;
  };

  const handleSelection = (event, { suggestion, method }) => {
    if (method === 'enter') {
      event.preventDefault();
    }

    onChange(suggestion.code);
  };

  const handleInputClear = useCallback(() => {
    setSearchTerm('');

    if (onInputClear) onInputClear();
  }, [setSearchTerm, onInputClear]);

  return (
    <CustomAutocomplete
      error={error}
      /* 
      SFGCR-1711 - debounced value has a delay for when the value gets updated, so when it changes back
      to empty string, there seems to be a time when it will be null and can't be "trimmed" by the autosuggest library.
      The change was to provide an empty string if debounced value doesn't exist
      */
      initialValue={debouncedValue || ''}
      label={label}
      placeholder={placeholder}
      suggestions={agencyOwners}
      getSuggestionValue={getSuggestionValue}
      handleSelection={handleSelection}
      handleSuggestionsClearRequested={handleSuggestionsClearRequested}
      handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onInputClear={handleInputClear}
      renderSuggestion={renderSuggestion}
      {...props}
    />
  );
};

AllAgentsAutocomplete.defaultProps = {
  error: false,
  placeholder: '',
  value: '',

  onInputClear: null,
};

AllAgentsAutocomplete.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,

  onChange: PropTypes.func.isRequired,
  onInputClear: PropTypes.func,
};

export default AllAgentsAutocomplete;
