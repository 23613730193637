import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Typography, withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';

import AttendeeInfoPage from './attendeeInfoPage.component';
import ConfirmationInformationPage from './confirmationInformationPage.component';
import EMAIL_ADDRESSES from '../../types/emailAddresses';
import LoadingInlay from '../layout/loadingInlay.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import OrderingPage from './orderingPage.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import calculateTotal, { areTicketsChosen } from '../../utilities/calculateTotal';
import {
  COMPLETE_CHECKOUT_SUCCESS,
  CREATE_PRECHECKOUT_SUCCESS,
  UPDATE_CART_PURCHASER_SUCCESS,
  UPDATE_CART_SUCCESS,
  completeCheckout,
  createPrecheckout,
  updateCart,
  updateCartPurchaser,
} from '../cart/cart.actions';
import { clearSelectedEvent, getEvent, setSelectedEvent } from './events.actions';
import { getProducts } from '../products/products.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EventRegistrationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      agreeToTerms: false,
      isLoading: false,
      overwriteBadgeName: true,
      currentTotal: '',
      totalAmount: 0,
      purchaser: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        alsoKnownAs: '',
        type: 'agent',
        agencyManagerId: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        confirmEmail: '',
      },
      billing: {
        nameOnCard: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        creditCardNumber: '',
        ccExpirationMonth: '',
        ccExpirationYear: '',
        cvcNumber: '',
        couponCode: '',
      },
      purchasePlan: '',
      assignTicket: false,
      tickets: {},
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { match, selectedEvent } = this.props;
    this.props.setPageTitle('Event Registration');

    if (isEmpty(selectedEvent) || selectedEvent.id !== match.params.id) {
      this.props.getEvent(match.params.id);
    }
    /* This gets both the products and the purchase plans */
    this.props.getProducts(match.params.id);

    /* Bind imported functions to this scope so they can access props and state */
    this.calculateTotal = calculateTotal.bind(this);
  }

  /* Add a tickets validation rule to allow the user to continue as long as one ticket is selected */
  componentDidMount() {
    ValidatorForm.addValidationRule('minTickets', (/* value */) => {
      return areTicketsChosen(this.state.tickets);
    });
    ValidatorForm.addValidationRule('maxTickets', value => {
      return this.props.products.reduce((currentStatus, product) => {
        /* Go through the products and find the one with this quanitity. If more than one matches, we'll check it too. */
        if (this.state.tickets[product.id] !== value) return currentStatus;
        /* Find the purchase plan matching this product and number of installments. */
        const purchasePlan = find(product.purchasePlans, { installments: this.state.purchasePlan });
        /* If the purchase plan was found and the limit is set, make sure we are under that limit. */
        if (purchasePlan && Number.isInteger(purchasePlan.limit)) {
          return value <= purchasePlan.limit ? currentStatus : false;
        }
        return currentStatus;
      }, true);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.products !== prevProps.products) {
      const state = { ...this.state };

      /* Setup the tickets object for each product */
      this.props.products.forEach(p => {
        state.tickets[p.id] = 0;
      });
      this.setState(state);
    }

    if (this.props.currentCart && this.props.currentCart !== prevProps.currentCart) {
      /* Set the purchaser info from the cart if it's available */
      if (!isEmpty(this.props.currentCart.purchaser)) {
        const newState = {};
        Object.keys(this.props.currentCart.purchaser).forEach(key => {
          if (key && this.props.currentCart.purchaser[key] !== null) {
            newState[key] = this.props.currentCart.purchaser[key];
          }
        });
        this.setState(prevState => ({
          purchaser: prevState.purchaser,
          ...newState,
        }));
      }
      /* Set the items to purchase from the cart if it's available */
      if (!isEmpty(this.props.currentCart.items)) {
        // JM: I disabled lint here because the change necessary would be overly complicated
        // eslint-disable-next-line react/no-access-state-in-setstate
        const tickets = { ...this.state.tickets };
        this.props.currentCart.items.map(item => {
          tickets[item.id] = item.quantity;
          return true;
        });

        /* Find the number of installments based on the subtotal for the item.
        We don't have to check all items because all items have to have a
        purchase plan with the same number of installments. */
        const item = this.props.currentCart.items[0];
        const selectedPlan = item.purchasePlans.filter(plan => {
          return item.subtotal / item.quantity === plan.cost;
        })[0];
        this.setState({ tickets, purchasePlan: selectedPlan.installments }, this.calculateTotal);
      }
    }
  }

  getStripeToken = () => {
    this.setState({ errorMessageStripe: null, loadingStripe: true });
    function stripeResponseHandler(responseCode, responseObject) {
      this.setState({ loadingStripe: false });
      if (responseCode === 200) {
        // success - proceed to complete registration, sending along the stripe token
        this.preCheckout(responseObject.id);
      } else {
        // failure - set error message in form, do not complete registration
        this.setState({ errorMessageStripe: responseObject.error.message, isLoading: false });
        this.props.handleToastMessage(responseObject.error.message, true);
      }
    }
    const stripeInfo = {
      number: this.state.billing.creditCardNumber,
      cvc: this.state.billing.cvcNumber,
      exp_month: this.state.billing.ccExpirationMonth,
      exp_year: this.state.billing.ccExpirationYear,
      // optional fields but want them stored in stripe
      name: this.state.billing.nameOnCard,
      address_line1: this.state.billing.address,
      address_line2: this.state.billing.address2,
      address_city: this.state.billing.city,
      address_state: this.state.billing.state,
      address_zip: this.state.billing.zip,
      address_country: 'USA',
    };
    window.Stripe.card.createToken(stripeInfo, stripeResponseHandler.bind(this));
  };

  // JM: I'm not sure why this eslint issue exists here. Ignoring for now
  // eslint-disable-next-line consistent-return
  preCheckout = async stripeToken => {
    const registrationData = {
      ...this.state.purchaser,
    };

    if (stripeToken) {
      registrationData.source = stripeToken;
    }

    /* Remove all empty fields before sending them to the server */
    Object.keys(registrationData).forEach(field => {
      if (registrationData[field] === '') delete registrationData[field];
    });
    // Remove the confirm email field
    delete registrationData.confirmEmail;
    // console.log('REGISTRATION DATA!', registrationData);

    const response = await this.props.createPrecheckout(registrationData);
    if (response.type === CREATE_PRECHECKOUT_SUCCESS) {
      const result = await this.syncCart();
      if (!result) {
        this.setState({ isLoading: false });
        return false;
      }
      this.setState({ currentPage: 2, isLoading: false });
    } else {
      this.setState({ isLoading: false });
      let errorMessage = 'Error during registration!';
      if (response.messages && response.messages[0]) {
        errorMessage += ` ${response.messages[0]}`;
      }
      this.props.handleToastMessage(errorMessage, true);
    }
  };

  updateAttendee = (field, value) => {
    const state = {
      ...this.state,
    };
    state.purchaser[field] = value;
    /* Only runn the following if the user hasn't entered anything into the badge name field. */
    if (state.overwriteBadgeName) {
      /* If they are currently entering something in the badge name, don't automatically enter anything in the field again. */
      if (field === 'alsoKnownAs') {
        state.overwriteBadgeName = false;
      } else if (field === 'firstName') {
        /* If the user is clearing out the firstName field and has already cleared out the lastName field, clear out the badge name field. */
        if (state.purchaser.lastName.length === 0 && value.length === 0) {
          state.purchaser.alsoKnownAs = '';
        } else {
          /* If the user is entering a first name, copy it into the badge name */
          state.purchaser.alsoKnownAs = `${value} ${state.purchaser.lastName}`;
        }
      } else if (field === 'lastName') {
        /* If the user is clearing out the lastName field and has already cleared out the firstName field, clear out the badge name field. */
        if (state.purchaser.firstName.length === 0 && value.length === 0) {
          state.purchaser.alsoKnownAs = '';
        } else {
          /* If the user is entering a last name, copy it into the badge name */
          state.purchaser.alsoKnownAs = `${state.purchaser.firstName} ${value}`;
        }
      }
    }
    this.setState(state);
  };

  updateBilling = (field, value) => {
    const state = {
      ...this.state,
    };
    state.billing[field] = value;
    this.setState(state);
  };

  updateField = (field, value) => {
    const state = { ...this.state };
    state[field] = value;
    this.setState(state, () => {
      if (field === 'purchasePlan' || field === 'couponCode') {
        this.calculateTotal();
        this.syncCart();
      }
    });
  };

  updateTickets = (field, value, callback) => {
    this.setState(
      state => {
        const tickets = { ...state.tickets };
        tickets[field] = value;
        return { tickets };
      },
      () => {
        callback();
        this.calculateTotal();
        this.syncCart();
      }
    );
  };

  continueRegistration = () => {
    this.setState({ currentPage: 1 });
  };

  syncCart = async purchaser => {
    if (!this.state.purchasePlan || !areTicketsChosen(this.state.tickets)) {
      // No need to sync the cart since no purchase plan or tickets have been
      // selected.
      return 1;
    }
    const cartData = {
      couponCode: this.state.billing.couponCode,
      installments: this.state.purchasePlan,
      items: Object.keys(this.state.tickets).reduce((items, id) => {
        if (Number(this.state.tickets[id]) > 0)
          items.push({
            id,
            quantity: Number(this.state.tickets[id]),
            eventId: this.props.selectedEvent.id,
          });
        return items;
      }, []),
    };
    if (!cartData.couponCode) {
      delete cartData.couponCode;
    }
    const updateResult = await this.props.updateCart(cartData);
    if (updateResult.type !== UPDATE_CART_SUCCESS) {
      try {
        let message = 'Error processing registration data!';
        console.log('UPDATE RESULT!', updateResult);
        if (!isEmpty(updateResult.messages) && updateResult.messages.length > 0) {
          try {
            const results = /quantity" must be less than or equal to ([0-9]*)/.exec(
              updateResult.messages[0]
            );
            if (results.length > 0) {
              message = `The max quantity of tickets is ${results[1]}!`;
            }
          } catch (e) {
            console.log('Caught execption processing update cart error message');
            // eslint-disable-next-line prefer-destructuring
            message = updateResult.messages[0];
          }
        }

        this.props.handleToastMessage(message, true);
      } catch (e) {
        console.log('Caught exception processing error message', e);
      }
      return 0;
    }
    if (purchaser) {
      const purchaserData = { ...purchaser };
      Object.keys(purchaserData).forEach(key => {
        if (purchaserData[key] === '') {
          delete purchaserData[key];
        }
      });
      const updatePurchaserResult = await this.props.updateCartPurchaser(purchaserData);
      if (updatePurchaserResult.type !== UPDATE_CART_PURCHASER_SUCCESS) {
        console.log('Error updating purchaser!');
        console.log(updatePurchaserResult);
        this.props.handleToastMessage('Error during registration!', true);
        return 0;
      }
    }
    return 1;
  };

  confirmRegistration = e => {
    e.preventDefault();

    this.setState({ isLoading: true });
    /* If the total is zero, don't get a Stripe token. */
    if (Number(this.state.totalAmount) === 0) {
      this.preCheckout();
    } else {
      this.getStripeToken();
    }
  };

  completeRegistration = async () => {
    this.setState({ isLoading: true });
    const response = await this.props.completeCheckout(
      this.props.currentCart.total,
      this.state.assignTicket
    );
    if (response.type === COMPLETE_CHECKOUT_SUCCESS) {
      this.setState({ isLoading: false });
      this.props.handleToastMessage('Registration Complete!', false);

      const isInApp = new URLSearchParams(this.props.location.search).get('inApp');
      if (isInApp) {
        // Go to confirmation screen if in webview in app
        // This keeps user from ending up on other pages
        // while in the webview
        this.setState({ currentPage: 3 });
      } else {
        this.props.history.push(`/attendees/${this.props.selectedEvent.id}`);
      }
    } else {
      this.setState({ isLoading: false });
      this.props.handleToastMessage('Error completing checkout!', true);
    }
  };

  pageControl = () => {
    switch (this.state.currentPage) {
      case 1:
        return (
          // eslint-disable-next-line react/no-string-refs
          <ValidatorForm ref="form" onSubmit={this.confirmRegistration}>
            <OrderingPage
              event={this.props.selectedEvent}
              state={this.state}
              products={this.props.products}
              purchasePlans={this.props.purchasePlans}
              updateField={this.updateField}
              updateAttendee={this.updateAttendee}
              updateBilling={this.updateBilling}
              updateTickets={this.updateTickets}
            />
            <div className="form-buttons-wrapper">
              <SFGOutlinedButton onClick={() => this.setState({ currentPage: 0 })}>
                Go To Previous Page
              </SFGOutlinedButton>
              <SFGOutlinedButton color="primary" type="submit" style={{ marginLeft: '1rem' }}>
                Continue Registration
              </SFGOutlinedButton>
            </div>
          </ValidatorForm>
        );
      case 2:
        return (
          <div>
            <ConfirmationInformationPage state={this.state} cart={this.props.currentCart} />
            <div className="row">
              <Typography variant="subheading" style={{ marginBottom: '1rem' }}>
                Due to event &amp; hotel negotiations, all registration payments on Owners Packets
                &amp; Single Tickets are nonrefundable. Registrations for this event cannot be
                transferred to other individuals or events.
              </Typography>
              <Typography variant="subheading" style={{ marginBottom: '1rem' }}>
                <input
                  name="agreeToTerms"
                  type="checkbox"
                  checked={this.state.agreeToTerms}
                  onChange={() => {
                    this.setState(prevState => ({
                      agreeToTerms: !prevState.agreeToTerms,
                    }));
                  }}
                  style={{ marginRight: '0.5rem' }}
                />
                {/*  eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="agreeToTerms">
                  I accept the policy for refunds, cancellations and ticket transfers.
                </label>
              </Typography>
            </div>
            <div className="form-buttons-wrapper">
              <SFGOutlinedButton onClick={() => this.setState({ currentPage: 1 })}>
                Go To Previous Page
              </SFGOutlinedButton>
              <SFGOutlinedButton
                color="primary"
                disabled={!this.state.agreeToTerms}
                style={{ marginLeft: '1rem' }}
                onClick={this.completeRegistration}
              >
                Finish Registration
              </SFGOutlinedButton>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            Registration successful. Thanks for registering for {this.props.selectedEvent.name}!
          </div>
        );
      default:
        return (
          <div>
            {/* <Typography variant="subheading">Purchaser Information</Typography> */}
            <AttendeeInfoPage
              event={this.props.selectedEvent}
              managers={this.props.managers}
              onSubmit={this.continueRegistration}
              state={this.state.purchaser}
              updateField={this.updateAttendee}
            />
          </div>
        );
    }
  };

  render() {
    const { classes, selectedEvent, isLoading } = this.props;
    if (isLoading) {
      return <LoadingInlay />;
    }

    let style = null;
    if (!isEmpty(selectedEvent)) {
      const supportEmail = selectedEvent.supportEmail || EMAIL_ADDRESSES.SUPPORT;
      /* If the event doesn't have tickets available, prevent the user from
         registering by redirecting to the main events page. */
      if (!selectedEvent.ticketsAvailable) {
        return <Redirect to="/events" />;
      }
      style = { backgroundImage: `url('${selectedEvent.headerGraphicUrl}')` };

      return (
        <div>
          <div className="event-banner" style={style} />

          <div className="container">
            {this.state.isLoading && <LoadingOverlay />}
            <Typography color="primary" variant="title">
              Register for&nbsp;
              {selectedEvent.name}
            </Typography>

            {this.pageControl()}

            <div className="page-padding-bottom page-padding-top">
              <Typography
                variant="subheading"
                style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
              >
                Need help?{' '}
                <a
                  className={classes.contactUs}
                  href={`mailto:${supportEmail}?subject="Help Registering for ${selectedEvent.name}"`}
                >
                  Contact us
                </a>{' '}
                for assistance!
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const styles = theme => ({
  contactUs: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

EventRegistrationContainer.defaultProps = {
  currentCart: null,
  selectedEvent: null,
};

EventRegistrationContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  currentCart: PropTypes.object,
  managers: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  purchasePlans: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  createPrecheckout: PropTypes.func.isRequired,
  completeCheckout: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updateCartPurchaser: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    currentCart: state.cart.get('cart'),
    managers: state.managers.get('managers'),
    products: state.products.get('products'),
    purchasePlans: state.purchasePlans.get('purchasePlans'),
    selectedEvent: state.events.get('selectedEvent'),
    isLoading: state.events.get('isLoading'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    getEvent,
    getProducts,
    clearSelectedEvent,
    setSelectedEvent,
    createPrecheckout,
    completeCheckout,
    updateCart,
    updateCartPurchaser,
    handleToastMessage,
  })(withStyles(styles)(EventRegistrationContainer))
);
