import { CALL_API } from '../../middleware/api';

export const GET_MANAGERS_REQUEST = 'GET_MANAGERS_REQUEST';
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS';
export const GET_MANAGERS_FAILURE = 'GET_MANAGERS_FAILURE';

export const getManagers = () => {
  return {
    [CALL_API]: {
      types: [GET_MANAGERS_REQUEST, GET_MANAGERS_SUCCESS, GET_MANAGERS_FAILURE],
      authenticated: true,
      endpoint: `v1/agencymanagers`,
      method: 'GET'
    }
  };
};
