import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';

class ProductForm extends Component {
  constructor(props) {
    super(props);

    let state = {
      name: '',
      description: '',
      count: 1,
    };

    if (!isEmpty(props.product)) {
      state = {
        name: props.product.name,
        description: props.product.description,
        count: props.product.count,
      };
    }

    this.state = state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product !== this.props.product) {
      this.setState({
        name: nextProps.product.name,
        description: nextProps.product.description,
        count: nextProps.product.count,
      });
    }
  }

  isFormValid = () => {
    const requiredFields = ['name', 'description'];
    const invalidFields = requiredFields.filter(field => {
      if (isEmpty(this.state[field])) {
        return true;
      }
      return false;
    });
    return !(invalidFields.length > 0);
  };

  handleSubmit = e => {
    e.preventDefault();

    const productData = {
      ...this.state,
    };
    console.log('PRODUCT DATA!', productData);

    this.props.submitAction(productData);
  };

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
  };

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={classes.spacerBottom}>
          <TextField
            label="Product Name"
            type="text"
            margin="normal"
            value={this.state.name}
            onChange={e => this.updateField('name', e.target.value)}
          />
        </div>
        <div className={classes.spacerBottom}>
          <TextField
            label="Product Description"
            type="text"
            margin="normal"
            value={this.state.description}
            onChange={e => this.updateField('description', e.target.value)}
          />
        </div>
        <div className={classes.spacerBottom}>
          <TextField
            label="Quantity"
            type="number"
            margin="normal"
            value={this.state.count}
            onChange={e => this.updateField('count', e.target.value)}
            helperText="This is the number of tickets per product pack."
          />
        </div>

        <div style={{ marginTop: '4rem' }}>
          <SFGOutlinedButton
            className="button expanded"
            disabled={!this.isFormValid()}
            type="submit"
            color="primary"
            onClick={this.handleSubmit}
          >
            Submit
          </SFGOutlinedButton>
        </div>
      </form>
    );
  }
}

const styles = {
  spacerBottom: {
    marginBottom: '2rem',
  },
};

ProductForm.defaultProps = {
  product: null,
};

ProductForm.propTypes = {
  classes: PropTypes.object.isRequired,

  product: PropTypes.object,

  submitAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProductForm);
