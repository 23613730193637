import Immutable from 'immutable';

import {
  GET_EVENT_SCHEDULE_FAILURE,
  GET_EVENT_SCHEDULE_REQUEST,
  GET_EVENT_SCHEDULE_SUCCESS,
  GET_MY_SCHEDULE_CURRENT_FAILURE,
  GET_MY_SCHEDULE_CURRENT_REQUEST,
  GET_MY_SCHEDULE_CURRENT_SUCCESS,
  GET_MY_SCHEDULE_FAILURE,
  GET_MY_SCHEDULE_REQUEST,
  GET_MY_SCHEDULE_SUCCESS,
  RESET_MY_SCHEDULE,
} from './mySchedule.actions';
import { getEventScheduleByDay, sortScheduleByDateTime } from '../../utilities/scheduleHelper';

const initialState = Immutable.Map({
  eventSchedule: [],
  eventScheduleByDay: [],
  mySchedule: [],
  myScheduleByDay: [],
  myScheduleCurrent: [],
  myScheduleCurrentByDay: [],
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_SCHEDULE_SUCCESS:
      // alert(`EVENT SCHEDULE --- ${JSON.stringify(action.response.data)}`);
      return state
        .set('eventSchedule', sortScheduleByDateTime(action.response.data))
        .set('eventScheduleByDay', getEventScheduleByDay(action.response.data))
        .set('isLoading', false);

    case GET_EVENT_SCHEDULE_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_EVENT_SCHEDULE_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_MY_SCHEDULE_SUCCESS:
      // aler
      return state
        .set('mySchedule', sortScheduleByDateTime(action.response.data))
        .set('myScheduleByDay', getEventScheduleByDay(action.response.data))
        .set('isLoading', false);

    case GET_MY_SCHEDULE_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_MY_SCHEDULE_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_MY_SCHEDULE_CURRENT_SUCCESS:
      return state
        .set('myScheduleCurrent', sortScheduleByDateTime(action.response.data))
        .set('myScheduleCurrentByDay', getEventScheduleByDay(action.response.data))
        .set('isLoading', false);

    case GET_MY_SCHEDULE_CURRENT_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_MY_SCHEDULE_CURRENT_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case RESET_MY_SCHEDULE:
      return state
        .set('errors', [])
        .set('mySchedule', [])
        .set('myScheduleByDay', []);

    default:
      return state;
  }
}
