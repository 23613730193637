import { CALL_API } from '../../middleware/api';

export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';

export const getReports = () => {
  return {
    [CALL_API]: {
      types: [GET_REPORTS_REQUEST, GET_REPORTS_SUCCESS, GET_REPORTS_FAILURE],
      authenticated: true,
      endpoint: `v1/reports`,
      method: 'GET'
    }
  };
};
