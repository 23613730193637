// Add the polyfills so IE will support all of the ES6 features we use. Need to
// use this first so all of the following imports will have access to it.
import QueryString from 'query-string';
import React from 'react';
import firebase from 'firebase/app';
import 'babel-polyfill';
import 'url-polyfill';
import * as Sentry from '@sentry/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { render } from 'react-dom';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';

// These import specific font weights
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/900.css';

import Root from './root.container';
import configureStore from './store/configureStore';
import loginHandler, {
  QUERY_PARAMETER_EMAIL_TO_VERIFY,
  QUERY_PARAMETER_NON_ADMIN_EMAIL,
  QUERY_PARAMETER_SHOW_VERIFY_ACTION,
} from './utilities/loginHandler';
import theme from './styles/theme';
import './index.css';
import {
  LOGIN_STATE,
  setEmailToVerify,
  setInitialLoadComplete,
  setLoggedIn,
  setNonAdminEmail,
  setShowVerifyAction,
  setUserEmail,
} from './modules/login/login.actions';
import { getCart } from './modules/cart/cart.actions';
import { getIsUserEmailVerified } from './utilities/loginUtils';
import { setUserPermissions } from './modules/login/permissions';
import { unregister } from './registerServiceWorker';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_KEY });
Sentry.setTag('environment', process.env.REACT_APP_ENVIRONMENT_NAME);

const store = configureStore();

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAEjleIwdsmjYB8_q6sDTdH7Quf6uWp8q0',
  authDomain: 'symmetry-events.firebaseapp.com',
  databaseURL: 'https://symmetry-events.firebaseio.com',
  projectId: 'symmetry-events',
  storageBucket: 'symmetry-events.appspot.com',
  messagingSenderId: '823933753460',
};

firebase.initializeApp(FIREBASE_CONFIG);

window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY);

let appStarted = false;

console.log('USER BASE URL?', process.env.REACT_APP_USER_BASE_URL);

function bindFirebase() {
  console.log('BINDING TO FIREBASE');

  function setLoggedOut() {
    // Clear the user's email
    store.dispatch(setUserEmail(null));
    // User is signed out.
    store.dispatch(setLoggedIn(LOGIN_STATE.NOT_LOGGED_IN));
  }

  firebase.auth().onAuthStateChanged(async user => {
    console.log('AUTH STATE CHANGED', user);

    // Prevent login if not verified
    if (user) {
      const isEmailVerified = await getIsUserEmailVerified();
      const idTokenResult = await user.getIdTokenResult();

      if (isEmailVerified) {
        store.dispatch(setInitialLoadComplete(false));

        // If the user's email address exists, save that so it can be used later.
        if (idTokenResult.claims.email) {
          store.dispatch(setUserEmail(idTokenResult.claims.email));
        }

        if (idTokenResult.claims.admin) {
          console.log('SYSTEM ADMIN');
          store.dispatch(setLoggedIn(LOGIN_STATE.SYSTEM_ADMIN));
          store.dispatch(setUserPermissions(LOGIN_STATE.SYSTEM_ADMIN));
          store.dispatch(getCart());
        } else {
          console.log('NON SYSTEM ADMIN');
          // SFGCR-1742: No longer allowing non-admins to log in
          // getCart is an authenticated endpoint, so commenting out
          // store.dispatch(setLoggedIn(LOGIN_STATE.STANDARD_USER));
          // store.dispatch(getCart());

          // SFGCR-1742: Sign out non-admin users and trigger to show message
          await firebase.auth().signOut();
          const nonAdminEmail = user.email !== null ? user.email : idTokenResult.claims.email;
          loginHandler.handleLogout({ nonAdminEmail }); // this means we will return to base route with a non-admin email query parameter
        }
      } else {
        // We need to properly handle a logout so that additional login requests don't return a cached jwt from auth0 or firebase
        const emailToVerify = user.email !== null ? user.email : idTokenResult.claims.email;

        // sign out and send the email to verify as well as if the email was part of the firebase user
        // the latter determines if we can / should allow them to trigger a reverification

        await firebase.auth().signOut();
        loginHandler.handleLogout({ emailToVerify, showVerifyAction: user.email !== null }); // this means we will return to base route with a email verification query parameter

        // setLoggedOut(); -- doesn't seem necessary since that will get handled via the handleLogout call above
      }
    } else {
      // this just sets some flags in redux store to make sure we treat the user as logged out
      setLoggedOut();

      // If there is an email to verify that means we arrived via a logout
      // after someone logged in with out a verified email address
      // Set this emailToVerify, which will open a emailVerification modal
      const parsed = QueryString.parse(window.location.search);

      if (parsed && parsed[QUERY_PARAMETER_SHOW_VERIFY_ACTION] === 'true') {
        store.dispatch(setShowVerifyAction(true));
      }

      if (parsed && parsed[QUERY_PARAMETER_EMAIL_TO_VERIFY]) {
        store.dispatch(setEmailToVerify(parsed[QUERY_PARAMETER_EMAIL_TO_VERIFY]));
      }

      // SFGCR-1742: If query param contains non admin email, set
      // non admin email on the store
      if (parsed && parsed[QUERY_PARAMETER_NON_ADMIN_EMAIL]) {
        store.dispatch(setNonAdminEmail(parsed[QUERY_PARAMETER_NON_ADMIN_EMAIL]));
      }
    }

    if (!appStarted) {
      startApp();
      appStarted = true;
    }
  });
}

bindFirebase();

loginHandler.handleSSO(store);

function startApp() {
  console.log(`STARTING APP - version ${process.env.REACT_APP_VERSION}`);
  render(
    <MuiThemeProvider theme={theme}>
      <Router>
        <Root store={store} />
      </Router>
    </MuiThemeProvider>,
    document.getElementById('root')
  );
}

unregister();
