import moment from 'moment';

export const isEventLive = event => {
  const momentEventStart = moment(event.startDate).utc();
  const momentEventEnd = moment(event.endDate)
    .utc()
    .add(1, 'd');
  const momentNow = moment(Date.now());

  return momentNow.isAfter(momentEventStart) && momentNow.isBefore(momentEventEnd);
};

const DAYS_SOON = 15;

export const isEventSoon = event => {
  const momentEventStart = moment(event.startDate).utc();
  const momentNow = moment(Date.now());

  return momentNow.isBefore(momentEventStart) && momentEventStart.diff(momentNow, 'd') < DAYS_SOON;
};

export const isEventFinished = event => {
  const momentEventEnd = moment(event.endDate)
    .utc()
    .add(1, 'd');
  const momentNow = moment(Date.now());

  return momentNow.isAfter(momentEventEnd);
};

export const getEventStatus = event => {
  let status = '';

  if (isEventLive(event)) {
    status = 'LIVE';
  } else if (isEventSoon(event)) {
    status = 'SOON';
  } else if (isEventFinished(event)) {
    status = 'FINISHED';
  }

  return status;
};
