import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AttendeeAutocomplete from '../layout/autocomplete/attendeeAutocomplete.container';
import LoadingOverlay from '../layout/loadingOverlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import VIPAssignmentsList from './eventStatusAssignmentsList.component';
import eventStatusTypes from '../../types/eventStatusTypes';
import {
  SET_ATTENDEE_EVENT_STATUS_SUCCESS,
  clearAttendeeEventStatuses,
  getAttendeeEventStatuses,
  removeAttendeeEventStatus,
  setAttendeeEventStatus,
} from './vips.actions';
import { clearSelectedEvent, getEvent } from '../events/events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class VipAssignmentsContainer extends Component {
  state = { isAddDialogOpen: false, eventId: null, searchTerm: '' };

  componentWillMount() {
    const { match, selectedEvent, attendeeEventStatuses } = this.props;
    const eventId = match.params.id;

    // determine if we should clear the attendeeEventStatuses
    if (attendeeEventStatuses.length > 0) {
      if (attendeeEventStatuses[0].eventId !== eventId) {
        this.props.clearAttendeeEventStatuses();
      }
    }

    this.setState({
      eventId,
    });
    this.props.setPageTitle(`VIP Attendees`);

    if (isEmpty(selectedEvent) || selectedEvent.id !== eventId) {
      this.props.clearSelectedEvent();
      this.props.getEvent(eventId);
    }
    this.props.getAttendeeEventStatuses(eventId);
    this.removeAction = this.removeAction.bind(this);
  }

  removeAction = async ticket => {
    await this.props.removeAttendeeEventStatus(ticket.id);
    this.props.handleToastMessage(`${ticket.email} removed successfully.`, false);
  };

  handleAutocompleteSelection = async suggestion => {
    const hasAttendeeEventStatus = this.props.attendeeEventStatuses.some(
      attendeeEventStatus => attendeeEventStatus.email === suggestion.email
    );

    if (hasAttendeeEventStatus) {
      this.props.handleToastMessage('That email address is already a VIP', true);
    } else {
      const response = await this.props.setAttendeeEventStatus({
        eventId: this.state.eventId,
        email: suggestion.email,
        status: eventStatusTypes.VIP,
      });
      if (response.type === SET_ATTENDEE_EVENT_STATUS_SUCCESS) {
        await this.props.getAttendeeEventStatuses(this.state.eventId);
        this.props.handleToastMessage(`Added ${suggestion.email} to VIP list`);
      }
    }
  };

  render() {
    const { classes, selectedEvent, attendeeEventStatuses, permissions, isLoading } = this.props;

    if (isLoading && attendeeEventStatuses.length === 0) {
      return <LoadingOverlay />;
    }

    return (
      <div className="container">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography color="primary" variant="title">
              {this.props.selectedEvent && `${selectedEvent.name} - VIP List`}
            </Typography>
            <div className={classes.controlsWrapper}>
              <SFGOutlinedButton
                className="button"
                color="primary"
                onClick={() => {
                  this.setState({ isAddDialogOpen: true });
                }}
              >
                Add VIP
              </SFGOutlinedButton>

              <div className={classes.controlWrapperFlexFull}>
                <Typography variant="subheading">
                  {attendeeEventStatuses ? attendeeEventStatuses.length : 0}
                  {` ${attendeeEventStatuses.length !== 1 ? 'VIP Attendees' : 'VIP Attendee'}`}
                </Typography>
              </div>
            </div>

            <VIPAssignmentsList
              attendeeEventStatuses={attendeeEventStatuses}
              updateUrl="/eventAttendees"
              permissions={permissions}
              removeAction={this.removeAction}
              status={eventStatusTypes.VIP}
            />

            <Dialog
              open={this.state.isAddDialogOpen}
              onClose={() => this.setState({ isAddDialogOpen: false })}
            >
              <DialogTitle>{selectedEvent && `Add VIP to ${selectedEvent.name}`}</DialogTitle>
              <DialogContent>
                <div style={{ minWidth: '30rem', minHeight: '20rem' }}>
                  <AttendeeAutocomplete
                    initialValue={this.state.searchTerm}
                    label="Add VIP Attendee (autocomplete)"
                    placeholder="Enter an attendee's name or email"
                    onSelect={suggestion => this.handleAutocompleteSelection(suggestion)}
                    clearInputOnSelect
                    eventId={this.state.eventId}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <SFGOutlinedButton onClick={() => this.setState({ isAddDialogOpen: false })}>
                  Close
                </SFGOutlinedButton>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = () => ({
  controlsWrapper: {
    display: 'flex',
    margin: '1.5rem 1rem',
  },
  controlWrapperFlexFull: {
    flex: 1,
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

VipAssignmentsContainer.defaultProps = {
  selectedEvent: null,
};

VipAssignmentsContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

  classes: PropTypes.object.isRequired,

  attendeeEventStatuses: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object,
  getEvent: PropTypes.func.isRequired,
  getAttendeeEventStatuses: PropTypes.func.isRequired,
  setAttendeeEventStatus: PropTypes.func.isRequired,
  removeAttendeeEventStatus: PropTypes.func.isRequired,
  clearAttendeeEventStatuses: PropTypes.func.isRequired,
  clearSelectedEvent: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    attendeeEventStatuses: state.vips.get('attendeeEventStatuses'),
    permissions: state.login.get('permissions'),
    isLoading: state.vips.get('isLoading'),
    errors: state.vips.get('errors'),
    selectedEvent: state.events.get('selectedEvent'),
  };
};

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, {
      setPageTitle,
      getEvent,
      getAttendeeEventStatuses,
      setAttendeeEventStatus,
      removeAttendeeEventStatus,
      clearAttendeeEventStatuses,
      clearSelectedEvent,
      handleToastMessage,
    })(VipAssignmentsContainer)
  )
);
