import Immutable from 'immutable';
import { findIndex } from 'lodash';

import {
  CLEAR_SCHEDULE_SLOTS,
  CLEAR_SCHEDULE_SLOT_SESSIONS,
  DELETE_SCHEDULE_SLOT_FAILURE,
  DELETE_SCHEDULE_SLOT_REQUEST,
  DELETE_SCHEDULE_SLOT_SESSION_SUCCESS,
  DELETE_SCHEDULE_SLOT_SUCCESS,
  GET_SCHEDULE_SLOTS_FAILURE,
  GET_SCHEDULE_SLOTS_REQUEST,
  GET_SCHEDULE_SLOTS_SUCCESS,
  GET_SCHEDULE_SLOT_SESSIONS_FAILURE,
  GET_SCHEDULE_SLOT_SESSIONS_REQUEST,
  GET_SCHEDULE_SLOT_SESSIONS_SUCCESS,
} from './schedule.actions';

const initialState = Immutable.Map({
  scheduleSlots: [],
  scheduleSlotSessions: [],
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SCHEDULE_SLOTS_SUCCESS:
      return state.set('scheduleSlots', action.response.data).set('isLoading', false);

    case GET_SCHEDULE_SLOTS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_SCHEDULE_SLOTS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case DELETE_SCHEDULE_SLOT_SUCCESS: {
      const scheduleSlots = [...state.get('scheduleSlots')];

      const idx = findIndex(scheduleSlots, { id: action.actionMetadata.slotId });
      if (idx > -1) {
        scheduleSlots.splice(idx, 1);
      }

      return state.set('scheduleSlots', scheduleSlots).set('isLoading', false);
    }

    case DELETE_SCHEDULE_SLOT_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case DELETE_SCHEDULE_SLOT_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_SCHEDULE_SLOT_SESSIONS_SUCCESS:
      return state.set('scheduleSlotSessions', action.response.data).set('isLoading', false);

    case GET_SCHEDULE_SLOT_SESSIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_SCHEDULE_SLOT_SESSIONS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case DELETE_SCHEDULE_SLOT_SESSION_SUCCESS: {
      const scheduleSlotSessions = [...state.get('scheduleSlotSessions')];

      const idx = findIndex(scheduleSlotSessions, { id: action.actionMetadata.sessionId });
      if (idx > -1) {
        scheduleSlotSessions.splice(idx, 1);
      }

      return state.set('scheduleSlotSessions', scheduleSlotSessions).set('isLoading', false);
    }

    case CLEAR_SCHEDULE_SLOTS:
      return state.set('scheduleSlots', []);

    case CLEAR_SCHEDULE_SLOT_SESSIONS:
      return state.set('scheduleSlotSessions', []);

    default:
      return state;
  }
}
