import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import LoginForm from './loginForm.container';
import logo from '../../images/logoHorizontalAgentEvents.png';

/* SFGCR-1742: No longer allow users to create account */
// const FORMS = {
//   CREATE_ACCOUNT: 'CREATE_ACCOUNT',
//   LOGIN: 'LOGIN',
// };

// const QUERY_PARAMATER_SHOW_CREATE_ACCOUNT = 'showCreateAccount';

/*
We are using a query parameter to allow for direct linking to the create account flow, since users may be guided to that
via an email, or someone may want to copy and send that url to a user. It is not strictly necessary to have that query
parameter toggled when toggling between form states, but we are using doing so for the aforementioned purposes
*/

const LoginContainer = ({ classes }) => {
  /* SFGCR-1742: No longer allow users to create account */
  // const params = useQueryParams();
  // const history = useHistory();

  // const [currentForm, setCurrentForm] = useState(
  //   params.get(QUERY_PARAMATER_SHOW_CREATE_ACCOUNT) === 'true' ? FORMS.CREATE_ACCOUNT : FORMS.LOGIN
  // );
  const [email, setEmail] = useState('');

  return (
    <div className={`${classes.pageContent} registration-form-wrapper`}>
      <div className="container">
        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
            <img src={logo} className={classes.logo} alt="Quility Logo" />
            <LoginForm showCreateAccount={() => {}} email={email} setEmail={setEmail} />
            {/* SFGCR-1742: No longer allow users to create account */}
            {/* {currentForm === FORMS.LOGIN ? (
              <LoginForm
                showCreateAccount={() => {
                  setCurrentForm(FORMS.CREATE_ACCOUNT);
                  history.replace(`/login?${QUERY_PARAMATER_SHOW_CREATE_ACCOUNT}=true`);
                }}
                email={email}
                setEmail={setEmail}
              />
            ) : (
              <CreateAccountForm
                showLogin={() => {
                  setCurrentForm(FORMS.LOGIN);
                  history.replace('/login');
                }}
                email={email}
                setEmail={setEmail}
              />
            )} */}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const styles = (/* theme */) => ({
  pageContent: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spacerBottom: {
    marginBottom: '2rem',
  },
  logo: {
    maxWidth: '100%',
  },
});

LoginContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const loginContainerWithRouter = withRouter(LoginContainer);

export default withStyles(styles)(loginContainerWithRouter);
