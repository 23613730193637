import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import GooglePlacesAutoComplete from '../../layout/autocomplete/googlePlacesAutocomplete.container';
import { Grid } from '@material-ui/core';

class SpecialEventBasicInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      place: '',
      location: '',
      date: '',
      startTime: '',
      endTime: ''
    };
  }

  componentDidMount() {
    const { specialEvent } = this.props;
    if (!isEmpty(specialEvent)) {
      this.setState({
        title: specialEvent.title ? specialEvent.title : '',
        description: specialEvent.description ? specialEvent.description : '',
        place: specialEvent.place ? specialEvent.place : '',
        location: specialEvent.location ? specialEvent.location : '',
        date: specialEvent.date ? specialEvent.date : '',
        startTime: specialEvent.startTime ? specialEvent.startTime : '',
        endTime: specialEvent.endTime ? specialEvent.endTime : ''
      });
    }
  }

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
    this.props.onFormUpdate(state);
  };

  clearEventPlace = () => {
    this.setState({
      place: ''
    });
    this.props.onFormUpdate(this.state);
  };

  render() {
    return (
      <Grid container spacing={32}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subheading">Special Event Info</Typography>
          <TextField
            label="Special Event Name"
            type="text"
            margin="normal"
            value={this.state.title}
            onChange={e => this.updateField('title', e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Special Event Description"
            type="text"
            margin="normal"
            value={this.state.description}
            onChange={e => this.updateField('description', e.target.value)}
            fullWidth
            required
          />
          <GooglePlacesAutoComplete
            initialValue={this.state.place}
            label="Special Event Address (autocomplete) *"
            placeholder="Enter an address and select from the list"
            onSelect={suggestion => {
              this.updateField('place', suggestion.description);
            }}
            onInputEdit={this.clearEventPlace}
          />
            <TextField
              label="Special Event Location Details"
              type="text"
              margin="normal"
              value={this.state.location}
              onChange={e => this.updateField('location', e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              placeholder="i.e. First Floor Lobby"
              required
            />
        </Grid>
        <Grid item container xs={12} sm={6} alignContent={"flex-start"}>
          <Grid item xs={12}>
            <Typography variant="subheading">Date & Time</Typography>
            <TextField
              label="Date"
              type="date"
              margin="normal"
              value={this.state.date}
              onChange={e => this.updateField('date', e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              helperText="All dates are relative to EST"
              required
            />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm>
              <TextField
                label="Start Time"
                type="time"
                margin="normal"
                value={this.state.startTime}
                onChange={e => this.updateField('startTime', e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
                helperText="All dates are relative to EST"
                required
              />
            </Grid>
            <Grid item xs={12} sm>
              <TextField
                label="End Time"
                type="time"
                margin="normal"
                value={this.state.endTime}
                onChange={e => this.updateField('endTime', e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
                helperText="All dates are relative to EST"
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

SpecialEventBasicInfoForm.defaultProps = {
  specialEvent: null
};

SpecialEventBasicInfoForm.propTypes = {
  onFormUpdate: PropTypes.func.isRequired,
  specialEvent: PropTypes.object
};

export default SpecialEventBasicInfoForm;
