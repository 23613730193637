import { isEmpty } from 'lodash';

// validation functions for text input fields
// return error text if validator function fails
// return false if no error

export const getValidationErrorMessages = (value, fieldLabel, validators) => {
  const toReturn = [];

  validators.forEach(validator => {
    /* Validator could be
        - function name
        - a message provided
        - combination of a function name and condition (.ie maxStringLength:256) https://www.npmjs.com/package/react-form-validator-core
    */
    let newValidator;
    let validatorName;
    let validatorCondition;

    if (validator.indexOf(':') !== -1) {
      newValidator = validator.split(':');
      [validatorName, validatorCondition] = newValidator;
    }

    if (validatorName && fieldValidators[validatorName]) {
      toReturn.push(fieldValidators[validatorName](value, fieldLabel, validatorCondition));
    } else if (fieldValidators[validator]) {
      toReturn.push(fieldValidators[validator](value, fieldLabel));
    } else {
      toReturn.push(validator);
    }
  });

  return toReturn;
};

export const required = (value, fieldLabel) => {
  /*
    Checkout for the following:
    - If is number
    - If object and is not an empty object
    - If is string and isn't just empty white space
  */
  return typeof value === 'number' ||
    (typeof value === 'object' && !isEmpty(value)) ||
    (!!value && !isEmpty(value.trim()))
    ? false
    : `${fieldLabel ? `${fieldLabel} is ` : ''}required`;
};

// note: regex applied is for format (999) 999-999
export const validPhoneNumber = value => {
  const regex = new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
  return value && !regex.test(value) ? 'Invalid phone number' : false;
};

export const fieldValidators = {
  required,
  validPhoneNumber,
};
