import { has, isEmpty } from 'lodash';

const isVip = ticket => {
  return (
    ticket.vip === true ||
    (has(ticket, 'spouse') && !isEmpty(ticket.spouse) && ticket.spouse.vip === true)
  );
};

export default { isVip };
