import Immutable from 'immutable';
import { findIndex, isEmpty } from 'lodash';

import {
  CLEAR_PURCHASER_FOR_ATTENDEE,
  CLEAR_SELECTED_ASSIGNEE,
  CLEAR_SELECTED_TICKET,
  DELEGATE_TICKET_FAILURE,
  DELEGATE_TICKET_REQUEST,
  DELEGATE_TICKET_SUCCESS,
  DELETE_TICKET_SUCCESS,
  GET_ASSIGNEE_FAILURE,
  GET_ASSIGNEE_REQUEST,
  GET_ASSIGNEE_SUCCESS,
  GET_PURCHASER_FOR_ATTENDEE_SUCCESS,
  GET_TICKETS_FAILURE,
  GET_TICKETS_REQUEST,
  GET_TICKETS_SUCCESS,
  GET_TICKET_FAILURE,
  GET_TICKET_REQUEST,
  GET_TICKET_SUCCESS,
  RESEND_DELEGATE_TICKET_FAILURE,
  RESEND_DELEGATE_TICKET_REQUEST,
  RESEND_DELEGATE_TICKET_SUCCESS,
  SET_SELECTED_ASSIGNEE,
  SET_SELECTED_TICKET,
  UPDATE_ASSIGNEE_FAILURE,
  UPDATE_ASSIGNEE_REQUEST,
  UPDATE_ASSIGNEE_SUCCESS,
  UPDATE_DELEGATE_TICKET_FAILURE,
  UPDATE_DELEGATE_TICKET_REQUEST,
  UPDATE_DELEGATE_TICKET_SUCCESS,
  UPDATE_TICKET_SUCCESS,
} from './tickets.actions';

const initialState = Immutable.Map({
  tickets: [],
  selectedTicket: null,
  selectedAssignee: null,
  selectedAssigneePurchaser: null,
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS_SUCCESS:
      return state.set('tickets', action.response.data).set('isLoading', false);

    case GET_TICKETS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_TICKETS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_TICKET_SUCCESS:
      return state.set('selectedTicket', action.response).set('isLoading', false);

    case GET_TICKET_FAILURE:
      return state.set('isLoading', false);

    case GET_TICKET_REQUEST:
      return state.set('isLoading', true);

    case GET_ASSIGNEE_SUCCESS:
      return state.set('selectedAssignee', action.response).set('isLoading', false);

    case GET_ASSIGNEE_FAILURE:
      return state.set('isLoading', false);

    case GET_ASSIGNEE_REQUEST:
      return state.set('isLoading', true);

    case UPDATE_ASSIGNEE_REQUEST:
      return state.set('isLoading', true);

    case UPDATE_ASSIGNEE_SUCCESS:
      return state.set('isLoading', false);

    case UPDATE_ASSIGNEE_FAILURE:
      return state.set('isLoading', false);

    case SET_SELECTED_ASSIGNEE:
      return state.set('selectedAssignee', action.response);

    case CLEAR_SELECTED_ASSIGNEE:
      return state.set('selectedAssignee', null);

    case SET_SELECTED_TICKET:
      return state.set('selectedTicket', action.response);

    case CLEAR_SELECTED_TICKET:
      return state.set('selectedTicket', null);

    case UPDATE_TICKET_SUCCESS: {
      let selectedTicket = { ...state.get('selectedTicket') };
      const tickets = [...state.get('tickets')];
      const { ticketId } = action.actionMetadata;

      if (!isEmpty(selectedTicket) && selectedTicket.id === ticketId) {
        selectedTicket = Object.assign(selectedTicket, action.payload);
      }

      const idx = findIndex(tickets, { id: ticketId });
      if (idx > -1) {
        tickets[idx] = Object.assign(tickets[idx], action.payload);
      }

      return state
        .set('selectedTicket', selectedTicket)
        .set('tickets', tickets)
        .set('isLoading', false);
    }

    case DELETE_TICKET_SUCCESS: {
      const tickets = [...state.get('tickets')];

      const idx = findIndex(tickets, { id: action.actionMetadata.ticketId });
      if (idx > -1) {
        tickets.splice(idx, 1);
      }

      return state.set('tickets', tickets).set('isLoading', false);
    }

    case GET_PURCHASER_FOR_ATTENDEE_SUCCESS: {
      // check to see that all of the relations are received before assigning
      if (
        action.response.ticket &&
        action.response.ticket.cart &&
        action.response.ticket.cart.purchaser
      ) {
        return state.set('selectedAssigneePurchaser', action.response.ticket.cart.purchaser);
      }
      return null;
    }

    case CLEAR_PURCHASER_FOR_ATTENDEE:
      return state.set('selectedAssigneePurchaser', null);

    case DELEGATE_TICKET_SUCCESS:
    case DELEGATE_TICKET_FAILURE:
    case RESEND_DELEGATE_TICKET_SUCCESS:
    case RESEND_DELEGATE_TICKET_FAILURE:
    case UPDATE_DELEGATE_TICKET_SUCCESS:
    case UPDATE_DELEGATE_TICKET_FAILURE:
      return state.set('isLoading', false);

    case DELEGATE_TICKET_REQUEST:
    case RESEND_DELEGATE_TICKET_REQUEST:
    case UPDATE_DELEGATE_TICKET_REQUEST:
      return state.set('isLoading', true);

    default:
      return state;
  }
}
