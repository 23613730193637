import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LoadingOverlay from './loadingOverlay.component';

const LoadingOverlayWithText = ({ classes }) => (
  <div className={classes.loadingContainer}>
    <LoadingOverlay />
    <div className={classes.loadingText}>This may take a while...</div>
  </div>
);

const styles = (/* theme */) => ({
  loadingContainer: {
    position: 'relative',
    height: '100vh',
    width: '100%'
  },
  loadingText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 101,
    fontSize: '150%',
    fontWeight: 'bold',
    transform: 'translateX(-50%) translateY(-50%)',
    paddingTop: '2rem',
    opacity: 0.8
  }
});

LoadingOverlayWithText.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingOverlayWithText);
