import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog, DialogContent, TextField, Typography } from '@material-ui/core';

import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';
import StyledDialogActions from '../../layout/dialogs/dialogActions';

class PurchaseRefundDialog extends Component {
  state = {
    refundInputValue: this.props.maxRefund,
  };

  updateRefundAmount = e => {
    const { maxRefund } = this.props;

    if (e.target.value <= maxRefund) {
      this.setState({ refundInputValue: e.target.value });
    }
  };

  // Has to be at least one cent and don't allow more than two decimal places
  amountInvalid = amount => {
    const amountCents = amount * 100;
    return amountCents < 1 || amountCents - Math.floor(amountCents) !== 0;
  };

  render() {
    const { show, closeDialog, transaction, handleSubmit, maxRefund } = this.props;
    const { refundInputValue } = this.state;

    return (
      <Dialog open={show} onClose={closeDialog}>
        <DialogContent>
          <Typography variant="subheading">Refund Transaction</Typography>
          <TextField
            label="Refund amount"
            type="number"
            margin="normal"
            value={refundInputValue}
            onChange={this.updateRefundAmount}
            fullWidth
            required
            InputProps={{ inputProps: { max: maxRefund } }}
          />
        </DialogContent>
        <StyledDialogActions>
          <SFGOutlinedButton
            disabled={this.amountInvalid(refundInputValue)}
            onClick={() =>
              handleSubmit({ transactionId: transaction.id, amount: refundInputValue })
            }
          >
            Refund
          </SFGOutlinedButton>
          <SFGOutlinedButton onClick={closeDialog}>Close</SFGOutlinedButton>
        </StyledDialogActions>
      </Dialog>
    );
  }
}

PurchaseRefundDialog.defaultProps = {
  maxRefund: 0,
};

PurchaseRefundDialog.propTypes = {
  transaction: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  maxRefund: PropTypes.number,
};

export default PurchaseRefundDialog;
