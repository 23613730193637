import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Input, MenuItem, Select, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { find, isNil } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Colors from '../../../styles/colors';
import EventHelpDeskResultTable from './eventHelpDeskResultTable.component';
import LoadingInlay from '../../layout/loadingInlay.component';
import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';
import { ADMIN_LINK_EMAIL_SUCCESS, adminLinkEmail } from '../../profile/profile.actions';
import { CHECKIN_TO_EVENT_SUCCESS, checkinToEvent } from '../events.actions';
import {
  GET_TICKET_FOR_ATTENDEE_SUCCESS,
  RESEND_ASSIGNMENT_EMAIL_SUCCESS,
  UPDATE_TICKET_SUCCESS,
  getTicketForAttendee,
  resendConfirmationEmail,
  updateTicket,
} from '../../tickets/tickets.actions';
import {
  LOOKUP_ATTENDEES_SUCCESS,
  LOOKUP_PURCHASES_SUCCESS,
  lookupAttendees,
  lookupPurchases,
} from '../../layout/autocomplete/autocomplete.actions';
import { handleToastMessage, setPageTitle } from '../../layout/layout.actions';

const ALL_EVENTS_ID = 99;
const ZONE_HELP_DESK = 'HELP_DESK';

class HelpDeskContainer extends Component {
  state = {
    selectedEventId: ALL_EVENTS_ID,
    currentSearchResultsText: '',
    searchText: '',
    results: {},
    isSearchComplete: false,
    isSearching: false,
  };

  componentDidMount() {
    this.props.setPageTitle('Help Desk');

    const { location } = this.props;
    const parsed = QueryString.parse(location.search);
    const stateVars = {};

    if (parsed && parsed.searchText) {
      stateVars.searchText = parsed.searchText;
    }

    if (parsed && parsed.eventId) {
      stateVars.selectedEventId = parsed.eventId;
    }

    this.setState(stateVars, () => {
      if (parsed && parsed.searchText) {
        this.handleHelpDeskSearch(null, parsed.searchText);
      }
    });
  }

  handleSelectEvent = e => {
    this.setState({ selectedEventId: e.target.value });
  };

  renderSelectItem = event => {
    const { agencies } = this.props;

    const agency = agencies.find(a => a.id === event.agencyId);
    if (agency) {
      return (
        <MenuItem key={event.id} value={event.id}>
          {`${event.name} (${agency.owner.name})`}
        </MenuItem>
      );
    }

    return (
      <MenuItem key={event.id} value={event.id}>
        {event.name}
      </MenuItem>
    );
  };

  handleHelpDeskSearch = async (e, searchText) => {
    if (e) {
      e.preventDefault();
    }

    const { events } = this.props;
    const { selectedEventId } = this.state;

    this.setState({ isSearching: true, results: {} });

    let eventsToUse = [];
    if (selectedEventId === ALL_EVENTS_ID) {
      eventsToUse = [...events];
      this.props.history.replace({ search: QueryString.stringify({ searchText }) });
    } else {
      eventsToUse.push(find(events, { id: selectedEventId }));
      this.props.history.replace({
        search: QueryString.stringify({ searchText, eventId: selectedEventId }),
      });
    }

    const requests = [];
    const results = {};

    eventsToUse.forEach(event => {
      results[event.id] = {
        event,
        errors: [],
      };

      try {
        requests.push(
          this.props.lookupAttendees(searchText, event.id).then(response => {
            if (response.type !== LOOKUP_ATTENDEES_SUCCESS) {
              results[event.id].errors.push(
                `There was an error getting attendees for ${event.name}`
              );
            } else {
              results[event.id].attendeesSearchResults = response.response.data;
            }
          })
        );

        requests.push(
          this.props.lookupPurchases(searchText, event.id).then(response => {
            if (response.type !== LOOKUP_PURCHASES_SUCCESS) {
              results[event.id].errors.push(
                `There was an error getting purchases for ${event.name}`
              );
            } else {
              results[event.id].purchasesSearchResults = response.response; // note: the response is not an array like attendees
            }
          })
        );
      } catch (error) {
        results[event.id].errors.push(error);
      }
    });

    await Promise.all([...requests]);

    const resultsArray = Object.keys(results).map(eventId => {
      return {
        event: results[eventId].event,
        attendeesSearchResults: results[eventId].attendeesSearchResults,
        purchasesSearchResults: results[eventId].purchasesSearchResults,
        errors: results[eventId].errors,
      };
    });

    const sortedResultsArray = resultsArray.sort((a, b) => {
      let totalA = 0;
      let totalB = 0;

      if (!isNil(a.attendeesSearchResults)) {
        totalA += a.attendeesSearchResults.length;
      }

      if (!isNil(a.purchasesSearchResults)) {
        totalA += a.purchasesSearchResults.length;
      }

      if (!isNil(b.attendeesSearchResults)) {
        totalB += b.attendeesSearchResults.length;
      }

      if (!isNil(b.purchasesSearchResults)) {
        totalB += b.purchasesSearchResults.length;
      }

      if (a.errors.length > 0) {
        totalA = 0;
      }

      if (b.errors.length > 0) {
        totalB = 0;
      }

      return totalB - totalA;
    });

    this.setState(prevState => ({
      currentSearchResultsText: prevState.searchText,
      isSearching: false,
      isSearchComplete: true,
      results: sortedResultsArray,
    }));
  };

  // TODO: delete the old eventHelpdesk file and routes

  handleLinkToVue = async (email1, email2) => {
    const result = await this.props.adminLinkEmail(email1, email2);
    if (result.type === ADMIN_LINK_EMAIL_SUCCESS) {
      this.props.handleToastMessage('Successfully linked Quility Account', false);
    } else {
      this.props.handleToastMessage(
        `Failed to link Quility Account! ${result.messages.length > 0 ? result.messages[0] : ''}`,
        true
      );
    }
  };

  // responses = responses to check in questions
  handleCheckIn = async (eventId, responses, attendeeEmail) => {
    const response = await this.props.checkinToEvent(
      eventId,
      ZONE_HELP_DESK,
      responses,
      attendeeEmail
    );
    if (response.type === CHECKIN_TO_EVENT_SUCCESS) {
      this.props.handleToastMessage('Successfully checked in user', false);
    } else {
      this.props.handleToastMessage(
        'Failed to check in user. Please check your connection and try again.',
        true
      );
    }
  };

  handleClearTicketAssignment = async attendee => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`Are you sure you want to clear the ticket assignment for ${attendee.name}?`)
    ) {
      try {
        const ticketResponse = await this.props.getTicketForAttendee(attendee);
        if (ticketResponse.type === GET_TICKET_FOR_ATTENDEE_SUCCESS) {
          const newTicket = { ...ticketResponse.response.ticket };

          // console.log('TICKET INFO?', newTicket);
          const { id } = newTicket;
          delete newTicket.id;
          delete newTicket.createdDate;
          delete newTicket.updatedDate;
          delete newTicket.delegateStatus;
          /* If the product exists, set the product to the ID to clear. */
          if (newTicket.product) newTicket.product = newTicket.product.id;
          // Clear the assignee so it can be reset.
          newTicket.assignee = null;
          const updateResponse = await this.props.updateTicket(id, newTicket);

          if (updateResponse.type === UPDATE_TICKET_SUCCESS) {
            this.props.handleToastMessage('Ticket assignment successfully cleared.');
          }

          this.handleHelpDeskSearch(null, this.state.currentSearchResultsText);
        }
      } catch (e) {
        this.props.handleToastMessage(
          'Failed to resend email. Please contact an administrator.',
          true
        );
      }
    }
  };

  handleResendTicketEmail = async attendee => {
    try {
      const ticketResponse = await this.props.getTicketForAttendee(attendee);
      if (ticketResponse.type === GET_TICKET_FOR_ATTENDEE_SUCCESS) {
        const response = await this.props.resendConfirmationEmail(
          ticketResponse.response.ticket.id
        );
        if (response.type === RESEND_ASSIGNMENT_EMAIL_SUCCESS) {
          this.props.handleToastMessage('Email resent.');
        } else {
          this.props.handleToastMessage('Failed to resend email.', true);
        }
      }
    } catch (e) {
      this.props.handleToastMessage(
        'Failed to resend email. Please contact an administrator.',
        true
      );
    }
  };

  render() {
    const { events, classes } = this.props;
    const {
      selectedEventId,
      isSearchComplete,
      currentSearchResultsText,
      isSearching,
      searchText,
      results,
    } = this.state;

    const selectedEvent = find(events, { id: selectedEventId });
    const selectedEventName = selectedEvent ? selectedEvent.name : 'All Events';

    return (
      <div className="container">
        <div className={classes.controlsWrapper}>
          <form onSubmit={e => this.handleHelpDeskSearch(e, this.state.searchText)}>
            <Input
              autoFocus
              placeholder={`Search in ${selectedEventName}`}
              disableUnderline={false}
              inputProps={{
                'aria-label': 'Filter',
              }}
              variant="outlined"
              onChange={e => this.setState({ searchText: e.target.value })}
              style={{
                float: 'left',
                minWidth: '20rem',
                padding: '0.15rem 0.25rem',
                marginRight: '1rem',
              }}
              value={this.state.searchText}
            />
            <SFGOutlinedButton color="primary" disabled={searchText === ''} type="submit">
              Search
            </SFGOutlinedButton>
          </form>

          <Select
            value={selectedEventId}
            onChange={this.handleSelectEvent}
            inputProps={{
              name: 'eventDeepLink',
              id: 'deepLink',
            }}
            style={{ minWidth: '16rem' }}
          >
            <MenuItem value={ALL_EVENTS_ID}>All Events</MenuItem>
            {events.map(event => this.renderSelectItem(event))}
          </Select>
        </div>

        {isSearching && <LoadingInlay />}

        {!isSearching && !isSearchComplete && (
          <Typography variant="subheading" className={classes.headingWithMargin}>
            Search for a registrant by name or email to get started
          </Typography>
        )}

        {!isSearching && isSearchComplete && (
          <Fragment>
            <Typography variant="title" className={classes.headingWithMargin}>
              {`Search Results for "${currentSearchResultsText}"`}
            </Typography>

            {results.map((result, i) => {
              return (
                <div
                  className={classes.resultsTableArea}
                  style={{ backgroundColor: i % 2 !== 0 ? Colors.grayLight : 'inherit' }}
                  key={result.event.id}
                >
                  <Fragment>
                    <Typography variant="subheading" className={classes.tableContentPadded}>
                      {`Results in ${result.event.name}`}
                    </Typography>
                    {result.errors.length === 0 ? (
                      <EventHelpDeskResultTable
                        attendees={result.attendeesSearchResults}
                        purchases={result.purchasesSearchResults}
                        event={result.event}
                        linkVueEmail={this.handleLinkToVue}
                        handleCheckIn={this.handleCheckIn}
                        resendTicketEmailAction={this.handleResendTicketEmail}
                        clearTicketAction={this.handleClearTicketAssignment}
                      />
                    ) : (
                      <Typography variant="body2" className={classes.tableContentPadded}>
                        There were errors retrieving results for this event. Please contact an
                        administrator for assistance.
                      </Typography>
                    )}
                  </Fragment>
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    );
  }
}

HelpDeskContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  classes: PropTypes.object.isRequired,

  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  lookupAttendees: PropTypes.func.isRequired,
  lookupPurchases: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getTicketForAttendee: PropTypes.func.isRequired,
  resendConfirmationEmail: PropTypes.func.isRequired,
  updateTicket: PropTypes.func.isRequired,
  checkinToEvent: PropTypes.func.isRequired,
  adminLinkEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    events: state.events.get('events'),
    agencies: state.bigFive.get('agencies'),
  };
};

const styles = (/* theme */) => ({
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headingWithMargin: {
    margin: '1rem 0',
  },
  resultsTableArea: {
    padding: '1rem 0 2rem 0',
  },
  tableContentPadded: {
    paddingLeft: '0.5rem',
  },
});

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      lookupAttendees,
      lookupPurchases,
      handleToastMessage,
      getTicketForAttendee,
      resendConfirmationEmail,
      updateTicket,
      checkinToEvent,
      adminLinkEmail,
    })(HelpDeskContainer)
  )
);
