import { CALL_API } from '../../middleware/api';

export const GET_PURCHASEPLANS_REQUEST = 'GET_PURCHASEPLANS_REQUEST';
export const GET_PURCHASEPLANS_SUCCESS = 'GET_PURCHASEPLANS_SUCCESS';
export const GET_PURCHASEPLANS_FAILURE = 'GET_PURCHASEPLANS_FAILURE';

export const CREATE_PURCHASEPLAN_REQUEST = 'CREATE_PURCHASEPLAN_REQUEST';
export const CREATE_PURCHASEPLAN_SUCCESS = 'CREATE_PURCHASEPLAN_SUCCESS';
export const CREATE_PURCHASEPLAN_FAILURE = 'CREATE_PURCHASEPLAN_FAILURE';

export const GET_PURCHASEPLAN_REQUEST = 'GET_PURCHASEPLAN_REQEUST';
export const GET_PURCHASEPLAN_SUCCESS = 'GET_PURCHASEPLAN_SUCCESS';
export const GET_PURCHASEPLAN_FAILURE = 'GET_AGER_FAILURE';

export const SET_SELECTED_PURCHASEPLAN = 'SET_SELECTED_PURCHASEPLAN';
export const CLEAR_SELECTED_PURCHASEPLAN = 'CLEAR_SELECTED_PURCHASEPLAN';

export const UPDATE_PURCHASEPLAN_REQUEST = 'UPDATE_PURCHASEPLAN_REQUEST';
export const UPDATE_PURCHASEPLAN_SUCCESS = 'UPDATE_PURCHASEPLAN_SUCCES';
export const UPDATE_PURCHASEPLAN_FAILURE = 'UPDATE_PURCHASEPLAN_FAILURE';

export const DELETE_PURCHASEPLAN_REQUEST = 'DELETE_PURCHASEPLAN_REQUEST';
export const DELETE_PURCHASEPLAN_SUCCESS = 'DELETE_PURCHASEPLAN_SUCCESS';
export const DELETE_PURCHASEPLAN_FAILURE = 'DELETE_PURCHASEPLAN_FAILURE';

export const getPurchasePlans = () => {
  return {
    [CALL_API]: {
      types: [GET_PURCHASEPLANS_REQUEST, GET_PURCHASEPLANS_SUCCESS, GET_PURCHASEPLANS_FAILURE],
      authenticated: true,
      endpoint: `admin/purchaseplans`,
      method: 'GET'
    }
  };
};

export const createPurchasePlan = purchasePlanData => {
  return {
    [CALL_API]: {
      types: [
        CREATE_PURCHASEPLAN_REQUEST,
        CREATE_PURCHASEPLAN_SUCCESS,
        CREATE_PURCHASEPLAN_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/purchaseplans`,
      method: 'POST',
      payload: purchasePlanData
    }
  };
};

export const getPurchasePlan = purchaseplanId => {
  return {
    [CALL_API]: {
      types: [GET_PURCHASEPLAN_REQUEST, GET_PURCHASEPLAN_SUCCESS, GET_PURCHASEPLAN_FAILURE],
      authenticated: true,
      endpoint: `admin/purchaseplans/${purchaseplanId}`,
      method: 'GET',
      actionMetadata: {
        purchaseplanId
      }
    }
  };
};

export const setSelectedPurchasePlan = purchaseplan => {
  return {
    type: SET_SELECTED_PURCHASEPLAN,
    response: purchaseplan
  };
};

export const clearSelectedPurchasePlan = () => {
  return {
    type: CLEAR_SELECTED_PURCHASEPLAN
  };
};

export const updatePurchasePlan = (purchaseplanId, purchasePlanData) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_PURCHASEPLAN_REQUEST,
        UPDATE_PURCHASEPLAN_SUCCESS,
        UPDATE_PURCHASEPLAN_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/purchaseplans/${purchaseplanId}`,
      method: 'PUT',
      payload: purchasePlanData,
      actionMetadata: {
        purchaseplanId
      }
    }
  };
};

export const deletePurchasePlan = purchasePlanId => {
  return {
    [CALL_API]: {
      types: [
        DELETE_PURCHASEPLAN_REQUEST,
        DELETE_PURCHASEPLAN_SUCCESS,
        DELETE_PURCHASEPLAN_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/purchaseplans/${purchasePlanId}`,
      method: 'DELETE',
      actionMetadata: {
        purchasePlanId
      }
    }
  };
};
