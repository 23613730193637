import { createSelector } from 'reselect';
import moment from 'moment';
import transactionStatusTypes from '../../types/transactionStatusTypes';

const selectSelectedPurchase = state => state.purchases.get('selectedPurchase');
const selectPurchases = state => state.purchases.get('myPurchases');

const selectSortedTransactions = createSelector(selectSelectedPurchase, purchase => {
  return purchase && purchase.transactions
    ? purchase.transactions.sort((a, b) => {
        if (a.number > b.number) {
          return 1;
        }
        if (a.number < b.number) {
          return -1;
        }
        return 0;
      })
    : [];
});

const selectPurchasesSortedByMostRecent = createSelector(selectPurchases, purchases => {
  return purchases
    ? purchases.sort((a, b) => {
        return moment(b.createdDate).diff(moment(a.createdDate));
      })
    : [];
});

const selectIncompleteTransactions = createSelector(selectSelectedPurchase, purchase => {
  return purchase && purchase.transactions
    ? purchase.transactions.filter(transaction => {
        return transaction.status !== transactionStatusTypes.COMPLETE;
      })
    : [];
});

export default {
  selectSortedTransactions,
  selectPurchasesSortedByMostRecent,
  selectIncompleteTransactions
};
