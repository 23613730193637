import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { getDateTimeFromSlotData } from '../../../utilities/convertTimes';

const DATE_FORMAT = 'ddd, MMM DD';
const TIME_FORMAT = 'h:mm a';

class EventHelpDeskBreakoutSessions extends Component {
  render() {
    let { schedule } = this.props;

    if (!isEmpty(schedule) && schedule.length > 0) {
      schedule = schedule.sort((a, b) => {
        return moment(`${a.date}T${a.startTime}`).diff(moment(`${b.date}T${b.startTime}`));
      });
    }

    return (
      <div className="container">
        {isEmpty(schedule) || schedule.length === 0 ? (
          <Typography variant="headline" style={{ margin: '1rem 0' }}>
            No Sessions Scheduled
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="26%">Session</TableCell>
                <TableCell width="20%">Date</TableCell>
                <TableCell width="17%">Start</TableCell>
                <TableCell width="17%">End</TableCell>
                <TableCell width="20%">Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule.map(item => {
                const slotStartDateTime = getDateTimeFromSlotData(item, item.startTime);
                const slotEndDateTime = getDateTimeFromSlotData(item, item.endTime);

                return (
                  <TableRow key={item.slotId}>
                    <TableCell>
                      <Typography variant="body1">{item.title}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {moment(item.date).format(DATE_FORMAT)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {moment(slotStartDateTime).format(TIME_FORMAT)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {moment(slotEndDateTime).format(TIME_FORMAT)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{item.location}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

EventHelpDeskBreakoutSessions.defaultProps = {
  schedule: [],
};

EventHelpDeskBreakoutSessions.propTypes = {
  schedule: PropTypes.array,
};

export default EventHelpDeskBreakoutSessions;
