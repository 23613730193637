import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core';

import MaxWidthDialog from './dialogs/maxWidthDialog.component';

class ImageUploader extends Component {
  state = {
    imagePreview: null,
    image: null,
    isShowImagePreviewModal: false,
  };

  handleDropImage = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      this.setState({
        imagePreview: { fileData: file, uri: URL.createObjectURL(file) },
        isShowImagePreviewModal: true,
      });
    }
  };

  handleConfirmImage = () => {
    this.setState(
      prevState => ({ image: prevState.imagePreview }),
      () => {
        this.props.handleImageUpdate(this.state.image);
        this.setState({ isShowImagePreviewModal: false });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { isShowImagePreviewModal } = this.state;

    return (
      <Fragment>
        <MaxWidthDialog
          open={isShowImagePreviewModal}
          headingText="Uploaded Image"
          actionText="Update Image"
          cancelText="Cancel"
          dialogContentElem={
            <div className={classes.dialogContentWrapper}>
              {this.state.imagePreview && (
                <img
                  src={this.state.imagePreview.uri}
                  className={classes.imagePreview}
                  alt="uploaded graphic"
                />
              )}
            </div>
          }
          handleAction={this.handleConfirmImage}
          onDialogClose={() =>
            this.setState({ isShowImagePreviewModal: false, imagePreview: null })
          }
        />
        <Dropzone multiple={false} accept="image/jpeg, image/png" onDrop={this.handleDropImage}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: classes.dropzoneWrapper,
                style: { position: 'relative' },
              })}
            >
              <input {...getInputProps()} />
              {!isEmpty(this.state.image) &&
              typeof this.state.image === 'string' &&
              this.state.image.indexOf('https') > -1 ? (
                <div>
                  <img src={this.state.image} alt="" className={classes.imagePreview} />
                </div>
              ) : (
                <div>
                  {!isEmpty(this.state.image) && !isEmpty(this.state.image.uri) ? (
                    <img src={this.state.image.uri} alt="" className={classes.imagePreview} />
                  ) : (
                    <p>Notification Image</p>
                  )}
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </Fragment>
    );
  }
}

const styles = {
  dropzoneWrapper: {
    margin: '1rem 0',
    border: '0.25rem dashed rgba(0, 0, 0, 0.75)',
    textAlign: 'center',
    cursor: 'pointer',
  },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagePreview: {
    objectFit: 'contain',
    maxWidth: '100%',
  },
};

ImageUploader.propTypes = {
  classes: PropTypes.object.isRequired,

  handleImageUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(ImageUploader);
