import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoadingInlay from '../layout/loadingInlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import { DELETE_PRODUCT_FAILURE, deleteProduct, getProducts } from './products.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class ProductsContainer extends Component {
  componentDidMount() {
    this.props.setPageTitle('Products');
    this.props.getProducts();
  }

  removeProduct = async (id, index) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`Are you sure you want to remove product: ${this.props.products[index].name}?`)
    ) {
      const response = await this.props.deleteProduct(id);
      if (response.type === DELETE_PRODUCT_FAILURE) {
        this.props.handleToastMessage('Failed to delete product', true);
      } else {
        this.props.handleToastMessage('Product deleted.');
      }
    }
  };

  handleEdit = product => {
    this.props.history.push({
      pathname: `products/${product.id}`,
    });
  };

  render() {
    const { products, isLoading } = this.props;

    if (products.length === 0 && isLoading) {
      return <LoadingInlay />;
    }

    return (
      <div className="container">
        <SFGOutlinedButton
          className="button"
          color="primary"
          onClick={() => this.props.history.push('/productNew')}
        >
          New Product
        </SFGOutlinedButton>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="40%">Product Name</TableCell>
              <TableCell width="40%">Product Description</TableCell>
              <TableCell width="10%" style={{ textAlign: 'center' }}>
                Quantity
              </TableCell>
              <TableCell width="10%" style={{ textAlign: 'center' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, i) => {
              return (
                <TableRow key={product.id}>
                  <TableCell className="clickable" onClick={() => this.handleEdit(product)}>
                    <Typography variant="body1">{product.name}</Typography>
                  </TableCell>
                  <TableCell className="clickable" onClick={() => this.handleEdit(product)}>
                    {product.description}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center' }}
                    className="clickable"
                    onClick={() => this.handleEdit(product)}
                  >
                    {product.count}
                  </TableCell>
                  <TableCell>
                    <SFGTextButton
                      color="primary"
                      onClick={() => this.removeProduct(product.id, i)}
                    >
                      <i className="material-icons">close</i>
                    </SFGTextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

ProductsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  products: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    products: state.products.get('products'),
    isLoading: state.products.get('isLoading'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getProducts,
    deleteProduct,
  })(ProductsContainer)
);
