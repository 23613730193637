import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty, omit, uniqBy } from 'lodash';
import { withRouter } from 'react-router-dom';

import AgentAutocomplete from '../autocomplete/agentAutocomplete.container';
import AgentBulkInviteButton from '../autocomplete/agentBulkInviteButton.component';
import AttendeeAutocomplete from '../autocomplete/attendeeAutocomplete.container';
import Colors from '../../../styles/colors';
import CustomValidatedTextEntry from '../customValidatedTextEntry/customValidatedTextEntry.component';
import EventInviteTable from '../eventInviteList/eventInviteTable.component';
import MaxWidthDialog from '../dialogs/maxWidthDialog.component';
import ReadOnlyTextField from '../readOnlyTextField/readOnlyTextField.component';
import agentSearchTypes from '../../../types/agentSearchTypes';
import inviteeFormTypes from '../../../types/inviteeFormTypes';
import { LOOKUP_AGENTS_SUCCESS, lookupAgents } from '../autocomplete/autocomplete.actions';
import { createNotification } from '../../notifications/notifications.actions';
import { handleToastMessage } from '../layout.actions';

class AdminInviteeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agent: '',
      agencyOwner: '',
      attendee: '',
      emails: [],
      manualEmailEntry: '',
      bulkInvitationEventId: '',
      isIncludeAllRegistrants: false,
      isIncludeAllCheckedIn: false,
      isShowMessageDialog: false,
      messageDialogTitle: '',
      messageDialogText: '',
      messageDialogUrl: '',
      messageDialogUrlText: '',
    };
  }

  componentDidMount() {
    const stateFields = [
      'emails',
      'eventId',
      'isIncludeAllRegistrants',
      'isIncludeAllCheckedIn',
      'selectedEventId',
    ];
    const updateState = {};

    stateFields.forEach(field => {
      const val = this.props[field];
      if (!isEmpty(val)) {
        updateState[field] = val;
      }
    });

    this.setState(updateState);
  }

  updateField = (field, value) => {
    let updateState = { ...this.state };
    updateState[field] = value;
    // if deselecting an event,
    // clear bulk invitation id from lifted state
    // and clear checkboxes

    if (field === 'bulkInvitationEventId' && value === '') {
      // clear lifted state
      this.props.onFormUpdate({
        bulkInvitationEventId: value,
        isIncludeAllCheckedIn: false,
        isIncludeAllRegistrants: false,
      });

      // clear local form state
      updateState.isIncludeAllCheckedIn = false;
      updateState.isIncludeAllRegistrants = false;
    }

    this.setState(updateState);

    // omit local state from being lifted
    updateState = omit(
      updateState,
      'bulkInvitationEventId',
      'manualEmailEntry',
      'agent',
      'agencyOwner',
      'attendee',
      'isShowMessageDialog',
      'messageDialogTitle',
      'messageDialogText',
      'messageDialogUrl',
      'messageDialogUrlText',
      'isIncludeAllRegistrants',
      'isIncludeAllCheckedIn'
    );

    this.props.onFormUpdate(updateState);
  };

  handleAutocompleteSelection = suggestion => {
    const { emails } = this.state;

    if (emails.findIndex(item => item.email === suggestion.email) === -1) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const updateEmails = [...this.state.emails, suggestion];
      this.setState({
        emails: updateEmails,
      });
      this.props.onFormUpdate({ emails: updateEmails });
    }
  };

  handleAddManualEmail = email => {
    const { emails } = this.state;
    if (emails.findIndex(item => item.email === email) === -1) {
      const updateEmails = [
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.emails,
        {
          name: '',
          email,
        },
      ];

      this.setState({
        manualEmailEntry: '',
        emails: updateEmails,
      });
      this.props.onFormUpdate({ emails: updateEmails });
    }
  };

  handleAgencyOwnerSelect = async suggestion => {
    // get downline of selected agent
    const agencyMembersResponse = await this.props.lookupAgents(
      agentSearchTypes.DOWNLINE,
      null,
      suggestion.code
    );

    if (agencyMembersResponse.type === LOOKUP_AGENTS_SUCCESS) {
      let { data } = agencyMembersResponse.response;
      // include agency owner with data
      data = [suggestion, ...data];
      if (data.length > 0) {
        if (
          // eslint-disable-next-line no-alert
          window.confirm(
            `Are you sure you want to add ${data.length} ${this.props.headingText.toLowerCase()}${
              data.length > 1 ? 's' : ''
            }?`
          )
        ) {
          this.handleAddBulkEmails(data);
        }
      }
    }
  };

  handleAddBulkEmails = emails => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const updateEmails = uniqBy([...this.state.emails, ...emails], 'email');

    this.setState({
      emails: updateEmails,
    });

    this.props.onFormUpdate({ emails: updateEmails });
  };

  handleInviteListUpdate = emails => {
    this.setState({ emails });
    this.props.onFormUpdate({ emails });
  };

  handleBulkInvitation = key => {
    const updateValue = !this.state[key];
    const updateState = {};

    // if checking one, reset other values
    // allows only one selected at a time
    if (updateValue === true) {
      updateState.isIncludeAllCheckedIn = false;
      updateState.isIncludeAllRegistrants = false;
    }

    updateState[key] = updateValue;

    this.setState(updateState);

    this.props.onFormUpdate({
      ...updateState,
      bulkInvitationEventId: this.state.bulkInvitationEventId,
    });
  };

  handleShowMessageDialog = () => {
    this.setState({
      isShowMessageDialog: true,
    });
  };

  handleSendMessage = async () => {
    const notificationEmails = this.state.emails.filter(
      emailItem =>
        emailItem.response === inviteeFormTypes.INVITEE_RESPONSE_YES ||
        emailItem.response === inviteeFormTypes.INVITEE_RESPONSE_PENDING
    );
    if (notificationEmails.length > 0) {
      const {
        messageDialogText,
        messageDialogTitle,
        messageDialogUrl,
        messageDialogUrlText,
      } = this.state;
      const mappedEmails = notificationEmails.map(emailItem => emailItem.email);
      const notificationObject = {
        title: messageDialogTitle,
        description: messageDialogText,
        recipientEmails: mappedEmails,
      };

      if (!isEmpty(messageDialogUrl)) notificationObject.url = messageDialogUrl;
      if (!isEmpty(messageDialogUrlText)) notificationObject.urlText = messageDialogUrlText;

      const response = await this.props.createNotification(notificationObject);

      if (response.type === 'CREATE_NOTIFICATION_SUCCESS') {
        this.props.handleToastMessage('The notification has been sent successfully.');
        this.handleMessageDialogClose();
      } else {
        this.props.handleToastMessage(
          'Failed to send the notification. Please check your connection and try again.',
          true
        );
      }
    }
  };

  handleMessageDialogClose = () => {
    this.setState({
      messageDialogTitle: '',
      messageDialogText: '',
      messageDialogUrl: '',
      messageDialogUrlText: '',
      isShowMessageDialog: false,
    });
  };

  getMessageDialogElement = () => {
    return (
      <div>
        <TextField
          label="Notification Title"
          type="text"
          margin="normal"
          value={this.state.messageDialogTitle}
          onChange={e => this.updateField('messageDialogTitle', e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Notification Text"
          type="text"
          margin="normal"
          value={this.state.messageDialogText}
          onChange={e => this.updateField('messageDialogText', e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="URL"
          type="text"
          margin="normal"
          value={this.state.messageDialogUrl}
          onChange={e => this.updateField('messageDialogUrl', e.target.value)}
          fullWidth
          required={false}
        />
        <TextField
          label="URL Text"
          type="text"
          margin="normal"
          value={this.state.messageDialogUrlText}
          onChange={e => this.updateField('messageDialogUrlText', e.target.value)}
          fullWidth
          required={false}
        />
      </div>
    );
  };

  render() {
    const {
      headingText,
      isReadOnly,
      events,
      selectedEvent,
      isShowAttendeeAutocomplete,
      canDeleteEntries,
      isAllowMessageDialog,
    } = this.props;
    const {
      isIncludeAllRegistrants,
      isIncludeAllCheckedIn,
      bulkInvitationEventId,
      isShowMessageDialog,
      messageDialogTitle,
      messageDialogText,
    } = this.state;

    return (
      <Grid container spacing={32}>
        {!isReadOnly && (
          <Grid item xs={12} md={6}>
            <Typography variant="subheading" style={{ marginTop: '1rem' }}>
              {headingText.concat('s')}
            </Typography>

            <AgentAutocomplete
              initialValue={this.state.agent}
              label="Agent (autocomplete)"
              placeholder="Enter an agent's name"
              onSelect={suggestion => this.handleAutocompleteSelection(suggestion)}
              searchType={agentSearchTypes.ALL}
              clearInputOnSelect
            />

            <AgentAutocomplete
              initialValue={this.state.agencyOwner}
              label="Add an Agency by Agency Owner (autocomplete)"
              placeholder="Enter an agency owner's name"
              onSelect={suggestion => this.handleAgencyOwnerSelect(suggestion)}
              searchType={agentSearchTypes.ALL}
              isOwnerSearch
              clearInputOnSelect
            />

            {isShowAttendeeAutocomplete === true && (
              <AttendeeAutocomplete
                initialValue={this.state.attendee}
                label="Attendee (autocomplete)"
                placeholder="Enter an attendee's name"
                onSelect={suggestion => this.handleAutocompleteSelection(suggestion)}
                clearInputOnSelect
                eventId={!isEmpty(selectedEvent) ? selectedEvent.id : ''}
              />
            )}

            {isEmpty(selectedEvent) && (
              <FormControl style={{ width: '100%' }}>
                <Select
                  value={bulkInvitationEventId}
                  displayEmpty
                  onChange={e => this.updateField('bulkInvitationEventId', e.target.value)}
                  inputProps={{
                    name: 'eventName',
                  }}
                  style={{
                    marginTop: '1rem',
                    color: bulkInvitationEventId === '' ? Colors.grayDark : 'unset',
                  }}
                  color="primary"
                >
                  <MenuItem key="" value="">
                    <em>Select Event</em>
                  </MenuItem>

                  {!isEmpty(events) &&
                    events.map(evt => (
                      <MenuItem key={evt.id} value={evt.id}>
                        {evt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            {!isEmpty(selectedEvent) && (
              <ReadOnlyTextField label="Event" value={selectedEvent.name} />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={isIncludeAllCheckedIn}
                  onChange={() => this.handleBulkInvitation('isIncludeAllCheckedIn')}
                  value="exclusive"
                  color="primary"
                  disabled={
                    isEmpty(selectedEvent) ? isEmpty(this.state.bulkInvitationEventId) : false
                  }
                />
              }
              label="Include All Checked In"
              style={{ width: '100%' }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isIncludeAllRegistrants}
                  onChange={() => this.handleBulkInvitation('isIncludeAllRegistrants')}
                  value="exclusive"
                  color="primary"
                  disabled={
                    isEmpty(selectedEvent) ? isEmpty(this.state.bulkInvitationEventId) : false
                  }
                />
              }
              label="Include All Registrants"
              style={{ width: '100%' }}
            />

            <Grid item sm={6}>
              <AgentBulkInviteButton
                searchType={agentSearchTypes.ALL}
                buttonText="Add All Agency Owners"
                onBulkInvite={this.handleAddBulkEmails}
                dialogHeaderText="Add All Agency Owners"
                dialogContentText={`You are about to add all agency owners to the ${headingText.toLowerCase()}s list.`}
                isOwnerSearch
              />
            </Grid>

            <CustomValidatedTextEntry
              label="Manual E-Mail Entry"
              name="manualEmailEntry"
              value={this.state.manualEmailEntry}
              onChange={e => this.updateField('manualEmailEntry', e.target.value.toLowerCase())}
              validators={['required', 'isEmail']}
              errorMessages={['Required', 'Must be a valid e-mail address']}
              fullWidth
              actionText="ADD"
              handleAction={email => this.handleAddManualEmail(email)}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <EventInviteTable
            headingText={headingText}
            inviteList={this.state.emails}
            onListUpdated={this.handleInviteListUpdate}
            isBulkSelectionMade={isIncludeAllCheckedIn === true || isIncludeAllRegistrants === true}
            isReadOnly={isReadOnly}
            canDeleteEntries={canDeleteEntries}
            handleSendMessage={this.handleShowMessageDialog}
            isAllowMessageDialog={isAllowMessageDialog}
          />
        </Grid>

        {isAllowMessageDialog === true && (
          <MaxWidthDialog
            open={isShowMessageDialog}
            headingText="Send Message to All Accepted/Maybe"
            actionText="Send"
            cancelText="Cancel"
            dialogContentElem={this.getMessageDialogElement()}
            handleAction={this.handleSendMessage}
            onDialogClose={this.handleMessageDialogClose}
            isActionDisabled={isEmpty(messageDialogTitle) || isEmpty(messageDialogText)}
          />
        )}
      </Grid>
    );
  }
}

AdminInviteeForm.defaultProps = {
  isReadOnly: false,
  events: [],
  selectedEvent: null,
  isShowAttendeeAutocomplete: false,
  isAllowMessageDialog: false,
  canDeleteEntries: false,
};

AdminInviteeForm.propTypes = {
  onFormUpdate: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  events: PropTypes.array,
  selectedEvent: PropTypes.object,
  isShowAttendeeAutocomplete: PropTypes.bool,
  isAllowMessageDialog: PropTypes.bool,
  canDeleteEntries: PropTypes.bool,

  createNotification: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  lookupAgents: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, {
    createNotification,
    handleToastMessage,
    lookupAgents,
  })(AdminInviteeForm)
);
