import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import axios from 'axios';
import firebase from 'firebase/app';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import 'firebase/auth';

import CustomSimpleMenu from '../layout/customSimpleMenu/customSimpleMenu.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import inviteeFormTypes from '../../types/inviteeFormTypes';
import { BASE_URL } from '../../middleware/api';
import { CREATE_SPECIAL_EVENTS, EDIT_SPECIAL_EVENTS, hasPermission } from '../login/permissions';
import {
  DELETE_SPECIAL_EVENT_SUCCESS,
  GET_SPECIAL_EVENTS_SUCCESS,
  clearSpecialEvents,
  deleteSpecialEvent,
  getSpecialEvents,
} from './specialEvents.actions';
import { GET_EVENT_SUCCESS, getEvent, setSelectedEvent } from '../events/events.actions';
import { getOwner } from '../../utilities/notificationHelper';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class SpecialEvents extends Component {
  async componentDidMount() {
    this.props.setPageTitle('Special Events');
    const { match, selectedEvent } = this.props;
    const { eventId } = match.params;
    if (isEmpty(selectedEvent) || selectedEvent.id !== eventId) {
      const [getEventResponse, getSpecialEventsResponse] = await Promise.all([
        this.props.getEvent(eventId),
        this.props.getSpecialEvents(eventId),
      ]);
      if (
        getEventResponse.type !== GET_EVENT_SUCCESS ||
        getSpecialEventsResponse.type !== GET_SPECIAL_EVENTS_SUCCESS
      ) {
        this.props.handleToastMessage('Failed to load event information', true);
      }
    } else {
      const getSpecialEventsResponse = await this.props.getSpecialEvents(eventId);
      if (getSpecialEventsResponse.type !== GET_SPECIAL_EVENTS_SUCCESS) {
        this.props.handleToastMessage('Failed to load event information', true);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedEvent !== this.props.selectedEvent) {
      this.props.setPageTitle(`${nextProps.selectedEvent.name} - Special Events`);
    }
  }

  removeSpecialEvent = async (id, index) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you want to remove special event: ${this.props.specialEvents[index].title}?`
      )
    ) {
      const response = await this.props.deleteSpecialEvent(this.props.match.params.eventId, id);
      if (response.type === DELETE_SPECIAL_EVENT_SUCCESS) {
        this.props.handleToastMessage('Special Event deleted.');
      } else {
        this.props.handleToastMessage('Failed to delete special event', true);
      }
    }
  };

  createSpecialEvent = () => {
    const { selectedEvent } = this.props;
    this.props.history.push({
      pathname: `/events/${selectedEvent.id}/specialEvents/new`,
      state: { selectedEvent },
    });
  };

  editSpecialEvent = specialEvent => {
    this.props.history.push({
      pathname: `/events/${this.props.selectedEvent.id}/specialEvents/${specialEvent.id}`,
    });
  };

  handleExport = async specialEvent => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      // Set the authorization header for this request instance;
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await instance({
        url: `${BASE_URL}v1/reports/SPECIAL_EVENT_RESPONSES?specialEventId=${specialEvent.id}`,
        method: 'GET',
        responseType: 'blob', // important
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', `${specialEvent.title} Responses.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.props.handleToastMessage('Failed to get breakout sessions', true);
      console.error(e);
    }
  };

  getRowActionMenuItems = (specialEvent, index) => {
    return [
      {
        text: 'Export Responses',
        action: () => this.handleExport(specialEvent),
      },
      {
        text: 'Delete Special Event',
        action: () => this.removeSpecialEvent(specialEvent.id, index),
      },
    ];
  };

  render() {
    const { specialEvents, permissions, isLoading } = this.props;

    const hasEditPermissions = hasPermission(EDIT_SPECIAL_EVENTS, permissions);
    const hasCreatePermissions = hasPermission(CREATE_SPECIAL_EVENTS, permissions);

    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <div className="container">
        {hasCreatePermissions === true && (
          <SFGOutlinedButton className="button" color="primary" onClick={this.createSpecialEvent}>
            New Special Event
          </SFGOutlinedButton>
        )}

        {specialEvents.length === 0 ? (
          <Typography variant="headline" style={{ margin: '1rem 0' }}>
            No Special Events
          </Typography>
        ) : (
          <Table style={{ minWidth: '0px' }}>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Title</TableCell>
                <TableCell width="15%">Date</TableCell>
                <TableCell width="25%">Time</TableCell>
                <TableCell width="10%">Sender</TableCell>
                <TableCell style={{ textAlign: 'center' }} width="10%">
                  Number Invited
                </TableCell>
                <TableCell style={{ textAlign: 'center' }} width="20%">
                  Responses (Y/N/?)
                </TableCell>
                <TableCell width="10%" style={{ textAlign: 'center' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {specialEvents.map((specialEvent, index) => {
                const date = moment(specialEvent.date).format('M/D/YYYY');
                const startTime = moment(specialEvent.startTime, 'HH:mm:ss').format('h:mm a');
                const endTime = moment(specialEvent.endTime, 'HH:mm:ss').format('h:mm a');
                // Determine the invitee responses for this special event
                const responses = [0, 0, 0];
                specialEvent.invitees.forEach(invitee => {
                  switch (invitee.response) {
                    case inviteeFormTypes.INVITEE_RESPONSE_YES:
                      // eslint-disable-next-line no-plusplus
                      responses[0]++;
                      break;

                    case inviteeFormTypes.INVITEE_RESPONSE_NO:
                      // eslint-disable-next-line no-plusplus
                      responses[1]++;
                      break;

                    case inviteeFormTypes.INVITEE_RESPONSE_PENDING:
                      // eslint-disable-next-line no-plusplus
                      responses[2]++;
                      break;

                    default:
                      break;
                  }
                });
                const actionMenuItems = this.getRowActionMenuItems(specialEvent, index);
                return (
                  <TableRow key={specialEvent.id}>
                    <TableCell
                      className="clickable"
                      onClick={
                        hasEditPermissions === true
                          ? () => this.editSpecialEvent(specialEvent)
                          : null
                      }
                    >
                      <Typography variant="body1">{specialEvent.title}</Typography>
                    </TableCell>
                    <TableCell
                      className="clickable"
                      onClick={
                        hasEditPermissions === true
                          ? () => this.editSpecialEvent(specialEvent)
                          : null
                      }
                    >
                      <Typography variant="body1">{date}</Typography>
                    </TableCell>
                    <TableCell
                      className="clickable"
                      onClick={
                        hasEditPermissions === true
                          ? () => this.editSpecialEvent(specialEvent)
                          : null
                      }
                    >
                      <Typography variant="body1">{`${startTime} - ${endTime}`}</Typography>
                    </TableCell>
                    <TableCell
                      className="clickable"
                      onClick={
                        hasEditPermissions === true
                          ? () => this.editSpecialEvent(specialEvent)
                          : null
                      }
                    >
                      <Typography variant="body1">{getOwner(specialEvent.owner)}</Typography>
                    </TableCell>
                    <TableCell
                      className="clickable"
                      style={{ textAlign: 'center' }}
                      onClick={
                        hasEditPermissions === true
                          ? () => this.editSpecialEvent(specialEvent)
                          : null
                      }
                    >
                      <Typography variant="body1">{specialEvent.invitees.length}</Typography>
                    </TableCell>
                    <TableCell
                      className="clickable"
                      style={{ textAlign: 'center' }}
                      onClick={
                        hasEditPermissions === true
                          ? () => this.editSpecialEvent(specialEvent)
                          : null
                      }
                    >
                      <Typography variant="body1">{`${responses[0]}/${responses[1]}/${responses[2]}`}</Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <CustomSimpleMenu menuItems={actionMenuItems} icon={<MoreVertIcon />} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

SpecialEvents.defaultProps = {
  selectedEvent: null,
};

SpecialEvents.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  specialEvents: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedEvent: PropTypes.object,
  permissions: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  getSpecialEvents: PropTypes.func.isRequired,
  deleteSpecialEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  let specialEvents = state.specialEvents.get('specialEvents');
  // sort by earliest to latest start date+time
  specialEvents = specialEvents.sort((a, b) => {
    return moment(`${b.date}T${b.startTime}`).diff(moment(`${a.date}T${a.startTime}`));
  });

  return {
    specialEvents,
    isLoading: state.specialEvents.get('isLoading'),
    selectedEvent: state.events.get('selectedEvent'),
    permissions: state.login.get('permissions'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getEvent,
    setSelectedEvent,
    getSpecialEvents,
    clearSpecialEvents,
    deleteSpecialEvent,
  })(SpecialEvents)
);
