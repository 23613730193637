import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Input, Toolbar, Tooltip, IconButton, Typography } from '@material-ui/core';
import inviteeFormTypes from '../../../types/inviteeFormTypes';
import Colors from '../../../styles/colors';

const EventInviteTableToolbar = props => {
  const {
    numSelected,
    classes,
    handleShowDeleteDialog,
    searchText,
    onSearchChange,
    headingText,
    isReadOnly,
    canDeleteEntries,
    inviteList
  } = props;
  const responses = [0, 0, 0];
  inviteList.forEach(i => {
    switch (i.response) {
      case inviteeFormTypes.INVITEE_RESPONSE_YES:
        responses[0] += 1;
        break;

      case inviteeFormTypes.INVITEE_RESPONSE_NO:
        responses[1] += 1;
        break;

      case inviteeFormTypes.INVITEE_RESPONSE_PENDING:
        responses[2] += 1;
        break;

      default:
        break;
    }
  });
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography>{`${numSelected} Selected`}</Typography>
        ) : (
          <div>
            <Typography id="tableTitle" variant="body2">
              {headingText}
            </Typography>
            {/* Only show responses if there are invitees */}
            {responses[0] + responses[1] + responses[2] > 0 && (
              <Typography variant="body1" style={{ marginTop: '0.25rem' }}>
                Responses (Y/N/?):&nbsp;
                {`${responses[0]}/${responses[1]}/${responses[2]}`}
              </Typography>
            )}
          </div>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && canDeleteEntries && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={handleShowDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {numSelected <= 0 && !isReadOnly && (
          <Input
            placeholder="Search"
            disableUnderline={false}
            inputProps={{
              'aria-label': 'Search'
            }}
            variant="outlined"
            onChange={e => onSearchChange(e.target.value)}
            style={{ float: 'right', minWidth: '20rem', padding: '0.15rem 0.25rem' }}
            value={searchText}
          />
        )}
      </div>
    </Toolbar>
  );
};

const styles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight: {
    color: Colors.white,
    backgroundColor: Colors.grayLight
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: Colors.white
  },
  title: {
    flex: '0 0 auto'
  }
});

EventInviteTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  handleShowDeleteDialog: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  canDeleteEntries: PropTypes.bool,
  inviteList: PropTypes.array.isRequired
};

EventInviteTableToolbar.defaultProps = {
  canDeleteEntries: false,
  isReadOnly: false
};

export default withStyles(styles)(EventInviteTableToolbar);
