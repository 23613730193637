import { createMuiTheme } from '@material-ui/core';

import Colors from './colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.tealPrimary,
      light: Colors.teal80,
      contrastText: '#fff',
    },
    secondary: {
      main: Colors.forestGreenPrimary,
      contrastText: '#fff',
    },
    success: {
      main: Colors.greenPrimary,
      contrastText: '#fff',
    },
    error: {
      main: Colors.redPrimary,
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Montserrat'],
  },
});

theme.typography.display1 = {
  fontWeight: 700,
};

theme.typography.title = {
  fontWeight: 700,
};

export default theme;
