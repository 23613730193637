import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { withRouter } from 'react-router-dom';

import AppStoreLinks from '../../utilities/appStoreLinks';
import EventsHeader, {
  SEARCH_BY_ALL,
  SEARCH_BY_CONFERENCES,
  SEARCH_BY_NEAR_ME,
  SEARCH_BY_ZIP,
} from './eventsHeader.component';
import EventsList from './eventsList.component';
import GatheringsList from '../gatherings/gatheringsList.container';
import LoadingInlay from '../layout/loadingInlay.component';
import { LOGIN_STATE } from '../login/login.actions';
import { filterByDate, sortByDate } from '../../utilities/dataUtils';
import {
  getGatheringsByLocation as reducerGetGatheringsByLocation,
  getGatheringsByZip as reducerGetGatheringsByZip,
} from '../gatherings/gatherings.actions';
import { setPageTitle as reducerSetPageTitle } from '../layout/layout.actions';

const EventsContainer = ({
  classes,
  events,
  gatherings,
  isLoading,
  loginStatus,
  permissions,
  profile,
  getGatheringsByLocation,
  getGatheringsByZip,
  setPageTitle,
}) => {
  const [searchType, setSearchType] = useState(SEARCH_BY_ALL);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setPageTitle('Events');
  }, [setPageTitle]);

  const filteredGatherings = useMemo(() => {
    return filterByDate(
      // Have to rename startTime and endTime to startDate / endDate
      gatherings.map(gathering => ({
        ...gathering,
        startDate: gathering.startTime,
        endDate: gathering.endTime,
      })),
      startDate,
      endDate
    );
  }, [gatherings, startDate, endDate]);

  const filteredEvents = useMemo(() => {
    return filterByDate(events, startDate, endDate);
  }, [events, startDate, endDate]);

  const renderLists = useCallback(() => {
    if (
      ((events.length === 0 || gatherings.length === 0) && isLoading) ||
      (isNil(currentPosition) && searchType === SEARCH_BY_NEAR_ME)
    )
      return <LoadingInlay />;

    if (filteredEvents.length === 0 && filteredGatherings.length === 0)
      return (
        <Typography align="center" variant="display1">
          No Events
        </Typography>
      );

    return (
      <>
        {searchType !== SEARCH_BY_NEAR_ME && searchType !== SEARCH_BY_ZIP && (
          <EventsList
            events={filteredEvents}
            isLoggedIn={loginStatus !== LOGIN_STATE.NOT_LOGGED_IN}
            permissions={permissions}
          />
        )}
        {searchType !== SEARCH_BY_CONFERENCES && (
          <GatheringsList
            gatherings={filteredGatherings}
            isSearch={searchType === SEARCH_BY_NEAR_ME || searchType === SEARCH_BY_ZIP}
            loginStatus={loginStatus}
            profile={profile}
          />
        )}
      </>
    );
  }, [
    currentPosition,
    events,
    gatherings,
    isLoading,
    loginStatus,
    permissions,
    profile,
    searchType,
    filteredEvents,
    filteredGatherings,
  ]);

  const handleSearchGatheringsByLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setCurrentPosition(position);
        getGatheringsByLocation(position.coords.latitude, position.coords.longitude);
      },
      err => {
        console.log('ERROR', err);
      }
    );
  }, [getGatheringsByLocation]);

  const handleSearchTypeChange = useCallback(
    event => {
      setSearchType(event.target.value);
      setCurrentPosition(null);

      if (event.target.value === SEARCH_BY_NEAR_ME) {
        handleSearchGatheringsByLocation();
      }
    },
    [handleSearchGatheringsByLocation]
  );

  return (
    <div className="events-list-section">
      <div className="container">
        <EventsHeader
          isLoggedIn={loginStatus !== LOGIN_STATE.NOT_LOGGED_IN}
          searchType={searchType}
          onSearchTypeChange={handleSearchTypeChange}
          onZipCodeSearch={getGatheringsByZip}
          setCurrentPosition={setCurrentPosition}
          onUpdateStartDate={setStartDate}
          onUpdateEndDate={setEndDate}
        />
        <Grid container className={classes.gridContainer} spacing={16}>
          <Grid item xs={12}>
            {renderLists()}
          </Grid>
        </Grid>
        <AppStoreLinks />
      </div>
    </div>
  );
};

const styles = theme => ({
  gridContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
});

EventsContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  events: PropTypes.array.isRequired,
  gatherings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loginStatus: PropTypes.number.isRequired,
  permissions: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,

  getGatheringsByLocation: PropTypes.func.isRequired,
  getGatheringsByZip: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    events: sortByDate(state.events.get('events')),
    gatherings: sortByDate(state.gatherings.get('gatherings')),
    isLoading: state.events.get('isLoading') || state.gatherings.get('isLoading'),
    loginStatus: state.login.get('loginStatus'),
    permissions: state.login.get('permissions'),
    profile: state.profile.get('profile'),
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    getGatheringsByLocation: reducerGetGatheringsByLocation,
    getGatheringsByZip: reducerGetGatheringsByZip,
    setPageTitle: reducerSetPageTitle,
  })
)(EventsContainer);
