import firebase from 'firebase';
import { isEmpty, isNil } from 'lodash';

import { CALL_API } from '../../middleware/api';
import { LOGIN_STATE } from '../login/login.actions';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const GET_EVENTS_ADMIN_REQUEST = 'GET_EVENTS_ADMIN_REQUEST';
export const GET_EVENTS_ADMIN_SUCCESS = 'GET_EVENTS_ADMIN_SUCCESS';
export const GET_EVENTS_ADMIN_FAILURE = 'GET_EVENTS_ADMIN_FAILURE';

export const GET_EVENTS_ADMIN_ARCHIVED_REQUEST = 'GET_EVENTS_ADMIN_ARCHIVED_REQUEST';
export const GET_EVENTS_ADMIN_ARCHIVED_SUCCESS = 'GET_EVENTS_ADMIN_ARCHIVED_SUCCESS';
export const GET_EVENTS_ADMIN_ARCHIVED_FAILURE = 'GET_EVENTS_ADMIN_ARCHIVED_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const GET_EVENT_REQUEST = 'GET_EVENT_REQEUST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';

export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const CLEAR_SELECTED_EVENT = 'CLEAR_SELECTED_EVENT';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCES';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const ARCHIVE_EVENT_REQUEST = 'ARCHIVE_EVENT_REQUEST';
export const ARCHIVE_EVENT_SUCCESS = 'ARCHIVE_EVENT_SUCCESS';
export const ARCHIVE_EVENT_FAILURE = 'ARCHIVE_EVENT_FAILURE';

export const UNARCHIVE_EVENT_REQUEST = 'UNARCHIVE_EVENT_REQUEST';
export const UNARCHIVE_EVENT_SUCCESS = 'UNARCHIVE_EVENT_SUCCESS';
export const UNARCHIVE_EVENT_FAILURE = 'UNARCHIVE_EVENT_FAILURE';

export const EXPORT_REGISTRATIONS_TO_CSV_REQUEST = 'EXPORT_REGISTRATIONS_TO_CSV_REQUEST';
export const EXPORT_REGISTRATIONS_TO_CSV_SUCCESS = 'EXPORT_REGISTRATIONS_TO_CSV_SUCCESS';
export const EXPORT_REGISTRATIONS_TO_CSV_FAILURE = 'EXPORT_REGISTRATIONS_TO_CSV_FAILURE';

export const CHECKIN_TO_EVENT_REQUEST = 'CHECKIN_TO_EVENT_REQUEST';
export const CHECKIN_TO_EVENT_SUCCESS = 'CHECKIN_TO_EVENT_SUCCESS';
export const CHECKIN_TO_EVENT_FAILURE = 'CHECKIN_TO_EVENT_FAILURE';

export const UPDATE_PURCHASE_CREDIT_CARD_REQUEST = 'UPDATE_PURCHASE_CREDIT_CARD_REQUEST';
export const UPDATE_PURCHASE_CREDIT_CARD_SUCCESS = 'UPDATE_PURCHASE_CREDIT_CARD_SUCCESS';
export const UPDATE_PURCHASE_CREDIT_CARD_FAILURE = 'UPDATE_PURCHASE_CREDIT_CARD_FAILURE';

export const REFUND_PURCHASE_TRANSACTION_REQUEST = 'REFUND_PURCHASE_TRANSACTION_REQUEST';
export const REFUND_PURCHASE_TRANSACTION_SUCCESS = 'REFUND_PURCHASE_TRANSACTION_SUCCESS';
export const REFUND_PURCHASE_TRANSACTION_FAILURE = 'REFUND_PURCHASE_TRANSACTION_FAILURE';

export const RETRY_PURCHASE_TRANSACTION_REQUEST = 'RETRY_PURCHASE_TRANSACTION_REQUEST';
export const RETRY_PURCHASE_TRANSACTION_SUCCESS = 'RETRY_PURCHASE_TRANSACTION_SUCCESS';
export const RETRY_PURCHASE_TRANSACTION_FAILURE = 'RETRY_PURCHASE_TRANSACTION_FAILURE';

export const UPDATE_PURCHASE_TRANSACTIONS_REQUEST = 'UPDATE_PURCHASE_TRANSACTIONS_REQUEST';
export const UPDATE_PURCHASE_TRANSACTIONS_SUCCESS = 'UPDATE_PURCHASE_TRANSACTIONS_SUCCESS';
export const UPDATE_PURCHASE_TRANSACTIONS_FAILURE = 'UPDATE_PURCHASE_TRANSACTIONS_FAILURE';

export const GET_PURCHASE_REQUEST = 'GET_PURCHASE_REQUEST';
export const GET_PURCHASE_SUCCESS = 'GET_PURCHASE_SUCCESS';
export const GET_PURCHASE_FAILURE = 'GET_PURCHASE_FAILURE';

export const GET_EVENT_EMAIL_TEMPLATES_REQUEST = 'GET_EVENT_EMAIL_TEMPLATES_REQUEST';
export const GET_EVENT_EMAIL_TEMPLATES_SUCCESS = 'GET_EVENT_EMAIL_TEMPLATES_SUCCESS';
export const GET_EVENT_EMAIL_TEMPLATES_FAILURE = 'GET_EVENT_EMAIL_TEMPLATES_FAILURE';

export const getEvents = () => {
  return {
    [CALL_API]: {
      types: [GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE],
      authenticated: !isNil(firebase.auth().currentUser),
      endpoint: `v1/events`,
      method: 'GET',
    },
  };
};

export const getEventsAdmin = () => {
  return {
    [CALL_API]: {
      types: [GET_EVENTS_ADMIN_REQUEST, GET_EVENTS_ADMIN_SUCCESS, GET_EVENTS_ADMIN_FAILURE],
      authenticated: true,
      endpoint: `admin/events`, // NOTE: can add ?archived=true to get archived events as well
      method: 'GET',
    },
  };
};

export const getEventsAdminArchived = () => {
  return {
    [CALL_API]: {
      types: [
        GET_EVENTS_ADMIN_ARCHIVED_REQUEST,
        GET_EVENTS_ADMIN_ARCHIVED_SUCCESS,
        GET_EVENTS_ADMIN_ARCHIVED_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/events?archived=true`,
      method: 'GET',
    },
  };
};

export const createEvent = eventData => {
  return {
    [CALL_API]: {
      types: [CREATE_EVENT_REQUEST, CREATE_EVENT_SUCCESS, CREATE_EVENT_FAILURE],
      authenticated: true,
      endpoint: `admin/events`,
      method: 'POST',
      payload: eventData,
    },
  };
};

const getEventUser = eventId => {
  return {
    [CALL_API]: {
      types: [GET_EVENT_REQUEST, GET_EVENT_SUCCESS, GET_EVENT_FAILURE],
      authenticated: true,
      endpoint: `v1/events/${eventId}`,
      method: 'GET',
      actionMetadata: {
        eventId,
      },
    },
  };
};

const getEventAdmin = eventId => {
  return {
    [CALL_API]: {
      types: [GET_EVENT_REQUEST, GET_EVENT_SUCCESS, GET_EVENT_FAILURE],
      authenticated: true,
      endpoint: `admin/events/${eventId}`,
      method: 'GET',
      actionMetadata: {
        eventId,
      },
    },
  };
};

export const getEvent = eventId => {
  return async (dispatch, getState) => {
    let result;
    if (getState().login.get('loginStatus') === LOGIN_STATE.SYSTEM_ADMIN) {
      result = await dispatch(getEventAdmin(eventId));
    } else {
      result = await dispatch(getEventUser(eventId));
    }
    return result;
  };
};

export const setSelectedEvent = event => {
  return {
    type: SET_SELECTED_EVENT,
    response: event,
  };
};

export const clearSelectedEvent = () => {
  return {
    type: CLEAR_SELECTED_EVENT,
  };
};

export const updateEvent = (eventId, eventData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_EVENT_REQUEST, UPDATE_EVENT_SUCCESS, UPDATE_EVENT_FAILURE],
      authenticated: true,
      endpoint: `admin/events/${eventId}`,
      method: 'PUT',
      payload: eventData,
      actionMetadata: {
        eventId,
      },
    },
  };
};

export const deleteEvent = eventId => {
  return {
    [CALL_API]: {
      types: [DELETE_EVENT_REQUEST, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE],
      authenticated: true,
      endpoint: `admin/events/${eventId}`,
      method: 'DELETE',
      actionMetadata: {
        eventId,
      },
    },
  };
};

export const archiveEvent = eventId => {
  return {
    [CALL_API]: {
      types: [ARCHIVE_EVENT_REQUEST, ARCHIVE_EVENT_SUCCESS, ARCHIVE_EVENT_FAILURE],
      authenticated: true,
      endpoint: `admin/events/${eventId}/archive`,
      method: 'POST',
      actionMetadata: {
        eventId,
      },
    },
  };
};

export const unarchiveEvent = eventId => {
  return {
    [CALL_API]: {
      types: [UNARCHIVE_EVENT_REQUEST, UNARCHIVE_EVENT_SUCCESS, UNARCHIVE_EVENT_FAILURE],
      authenticated: true,
      endpoint: `admin/events/${eventId}/unarchive`,
      method: 'POST',
      actionMetadata: {
        eventId,
      },
    },
  };
};

export const checkinToEvent = (eventId, zone, responses, attendeeEmail) => {
  const payload = { zone, email: attendeeEmail };
  if (!isEmpty(responses)) {
    payload.responses = responses;
  }

  return {
    [CALL_API]: {
      types: [CHECKIN_TO_EVENT_REQUEST, CHECKIN_TO_EVENT_SUCCESS, CHECKIN_TO_EVENT_FAILURE],
      authenticated: true,
      endpoint: `v1/events/${eventId}/checkin`,
      method: 'POST',
      payload,
    },
  };
};

export const updatePurchaseCreditCard = (purchaseId, token) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_PURCHASE_CREDIT_CARD_REQUEST,
        UPDATE_PURCHASE_CREDIT_CARD_SUCCESS,
        UPDATE_PURCHASE_CREDIT_CARD_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/purchasers/${purchaseId}/updateCard`,
      method: 'POST',
      payload: { token },
    },
  };
};

export const refundTransaction = ({ transactionId, amount }) => {
  return {
    [CALL_API]: {
      types: [
        REFUND_PURCHASE_TRANSACTION_REQUEST,
        REFUND_PURCHASE_TRANSACTION_SUCCESS,
        REFUND_PURCHASE_TRANSACTION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/transactions/${transactionId}/refund`,
      method: 'POST',
      payload: { amount },
    },
  };
};

export const retryTransaction = transactionId => {
  return {
    [CALL_API]: {
      types: [
        RETRY_PURCHASE_TRANSACTION_REQUEST,
        RETRY_PURCHASE_TRANSACTION_SUCCESS,
        RETRY_PURCHASE_TRANSACTION_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/transactions/${transactionId}/retry`,
      method: 'POST',
    },
  };
};

export const updateTransactions = (purchaseId, transactions, state) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_PURCHASE_TRANSACTIONS_REQUEST,
        UPDATE_PURCHASE_TRANSACTIONS_SUCCESS,
        UPDATE_PURCHASE_TRANSACTIONS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/purchases/${purchaseId}/updateTransactions`,
      method: 'POST',
      payload: {
        transactions,
        state,
      },
    },
  };
};

export const getPurchase = purchaseId => {
  return {
    [CALL_API]: {
      types: [GET_PURCHASE_REQUEST, GET_PURCHASE_SUCCESS, GET_PURCHASE_FAILURE],
      authenticated: true,
      endpoint: `v1/purchases/${purchaseId}`,
      method: 'GET',
    },
  };
};

export const getEventEmailTemplates = template => {
  return {
    [CALL_API]: {
      types: [
        GET_EVENT_EMAIL_TEMPLATES_REQUEST,
        GET_EVENT_EMAIL_TEMPLATES_SUCCESS,
        GET_EVENT_EMAIL_TEMPLATES_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/emailtemplates?label=${template}`,
      method: 'GET',
    },
  };
};
