import Immutable from 'immutable';
import { findIndex, isEmpty } from 'lodash';
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  SET_SELECTED_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_SUCCESS,
  CLEAR_SELECTED_NOTIFICATION
} from './notifications.actions';

const initialState = Immutable.Map({
  notifications: [],
  selectedNotification: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS: {
      return state.set('notifications', action.response.data).set('isLoading', false);
    }

    case GET_NOTIFICATIONS_FAILURE:
      return state.set('errors', action.notifications).set('isLoading', false);

    case GET_NOTIFICATIONS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_NOTIFICATION_SUCCESS:
      return state.set('selectedNotification', action.response).set('isLoading', false);

    case GET_NOTIFICATION_FAILURE:
      return state.set('isLoading', false);

    case GET_NOTIFICATION_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_NOTIFICATION:
      return state.set('selectedNotification', action.response);

    case CLEAR_SELECTED_NOTIFICATION:
      return state.set('selectedNotification', null);

    case UPDATE_NOTIFICATION_SUCCESS: {
      let selectedNotification = { ...state.get('selectedNotification') };
      const notifications = [...state.get('notifications')];
      const notificationId = action.actionMetadata;

      if (!isEmpty(selectedNotification) && selectedNotification.id === notificationId) {
        selectedNotification = Object.assign(selectedNotification, action.payload);
      }

      const idx = findIndex(notifications, { id: notificationId });
      if (idx > -1) {
        notifications[idx] = Object.assign(notifications[idx], action.payload);
      }

      return state
        .set('selectedNotification', selectedNotification)
        .set('notifications', notifications)
        .set('isLoading', false);
    }

    case DELETE_NOTIFICATION_SUCCESS: {
      const notifications = [...state.get('notifications')];

      const idx = findIndex(notifications, { id: action.actionMetadata.notificationId });
      if (idx > -1) {
        notifications.splice(idx, 1);
      }

      return state.set('notifications', notifications).set('isLoading', false);
    }

    default:
      return state;
  }
}
