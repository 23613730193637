import Immutable from 'immutable';
import * as Sentry from '@sentry/react';

import { GET_PROFILE_FAILURE, GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS } from './profile.actions';

const initialState = Immutable.Map({
  profile: {},
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_SUCCESS: {
      Sentry.setUser({ email: action.response.email });
      return state.set('profile', action.response).set('isLoading', false);
    }

    case GET_PROFILE_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_PROFILE_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    default:
      return state;
  }
}
