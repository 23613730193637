import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';

import AppleAppStore from '../images/appleAppStoreBadge.svg';

const ANDROID_MIN_VERSION = 9.0;
const IOS_MIN_VERSION = 12.3;

const AppStoreLinks = () => (
  <Fragment>
    <Typography variant="subheading" style={{ fontWeight: 500 }}>
      Download our mobile app below:
    </Typography>
    <Grid container alignItems="center">
      <Grid item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.apple.com/us/app/sfg-events/id1449744204?ls=1"
        >
          <img alt="Download on the App Store" src={AppleAppStore} />
        </a>
      </Grid>
      <Grid item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.sfgconference&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            style={{ maxHeight: '58px', marginLeft: 5 }}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
          />
        </a>
      </Grid>
    </Grid>
    <Typography variant="caption">{`Requires iOS ${IOS_MIN_VERSION} or Android ${ANDROID_MIN_VERSION} (or newer)`}</Typography>
  </Fragment>
);

export default AppStoreLinks;
