import firebase from 'firebase/app';
import 'firebase/storage';

// const MAX_IMAGE_WIDTH = 1024;
// const MAX_IMAGE_HEIGHT = 768;

export const getMediaRefFull = fullPath => {
  return firebase.storage().ref(fullPath);
};

// Cache
const CACHE_TTL = 5 * 60 * 1000; // 5 Minutes
const cache = {};
const promises = {};

export const getMedia = (mediaPath, defaultRef) => {
  if (promises[mediaPath]) {
    return promises[mediaPath];
  }

  if (cache[mediaPath]) {
    if (cache[mediaPath].timestamp + CACHE_TTL > Date.now()) {
      // It has not expired, so use the existing value!
      const val = cache[mediaPath];
      return Promise.resolve(val.url);
    }

    // It has expired, so delete our cached object
    delete cache[mediaPath];
    // Re-fetch!
  }

  const promise = new Promise((resolve /* , reject */) => {
    const doReject = () => {
      // If we have a defaultRef, resolve with that
      if (defaultRef) {
        cache[mediaPath] = {
          timestamp: Date.now(),
          url: defaultRef
        };
        resolve(defaultRef);
      } else {
        cache[mediaPath] = {
          timestamp: Date.now(),
          url: null
        };
        resolve();
      }
    };

    const mediaRef = getMediaRefFull(mediaPath);
    return mediaRef.getMetadata().then(
      (/* data */) => {
        return mediaRef.getDownloadURL().then(downloadURL => {
          delete promises[mediaPath];
          cache[mediaPath] = {
            timestamp: Date.now(),
            url: downloadURL
          };
          resolve(downloadURL);
        }, doReject);
      },
      doReject
    );
  });
  promises[mediaPath] = promise;
  return promise;
};

/* This was taken from the mobile app, and can still be used, but is oriented as file urls than JS File objects */
export const uploadMedia = (uri, mediaPath, mime = 'image/jpg') => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    const mediaRef = getMediaRefFull(mediaPath);

    // if (mime.indexOf('video') === -1) {
    //   let response = await ImageResizer.createResizedImage(uri, MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT, 'JPEG', 90);
    //   uri = response.path;
    // }

    try {
      await mediaRef.put(uri, { contentType: mime });
      delete promises[mediaPath];
      delete cache[mediaPath];
      resolve(mediaRef.fullPath);
    } catch (e) {
      console.log('ERROR WITH FIREBASE FILE UPLOAD', e);
    }
  });
};

/* This enables uploading media using JS File objects for the web */
export const uploadMediaAsFile = (fileData, mediaPath, mime = 'image/jpg') => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    const mediaRef = getMediaRefFull(mediaPath);

    // if (mime.indexOf('video') === -1) {
    //   let response = await ImageResizer.createResizedImage(uri, MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT, 'JPEG', 90);
    //   uri = response.path;
    // }

    try {
      await mediaRef.put(fileData, { contentType: mime });
      delete promises[mediaPath];
      delete cache[mediaPath];
      resolve(mediaRef.fullPath);
    } catch (e) {
      console.log('ERROR WITH FIREBASE FILE UPLOAD', e);
    }
  });
};
