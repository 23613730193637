import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import BillingInfo from './billingInfo.component';
import PurchaseTransactions from '../events/helpDesk/purchaseTransactions.container';
import PurchaserInfo from './purchaserInfo.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import TicketPurchasing from './ticketPurchasing.component';
import calculateTotal, { areTicketsChosen } from '../../utilities/calculateTotal';

class RegistrationForm extends Component {
  constructor(props) {
    super(props);

    let state = {
      selectedEvent: {},
      overwriteBadgeName: true,
      warning: '',
      formFields: {
        eventId: '',
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        alsoKnownAs: '',
        type: 'agent',
        agencyManagerId: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        confirmEmail: '',
      },
      billingFields: {
        nameOnCard: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        creditCardNumber: '',
        ccExpirationMonth: '',
        ccExpirationYear: '',
        cvcNumber: '',
        couponCode: '',
      },
      tickets: {},
      purchasePlan: 0,
    };

    /* Setup the tickets object for each product */
    props.products.forEach(p => {
      state.tickets[p.id] = 0;
    });

    /* Set the state here and overwrite anything necessary below. */
    this.state = state;

    /* Bind imported function calculateTotal to this scope so it can access props and state */
    this.calculateTotal = calculateTotal.bind(this);

    if (!isEmpty(props.registration)) {
      state = this.buildNewState(props);
    }
    state.selectedEvent = props.events.find(e => e.id === props.eventId);
    this.state = state;
  }

  componentDidMount() {
    /* Add a tickets validation rule to allow the user to continue as long as one ticket is selected */
    ValidatorForm.addValidationRule('minTickets', (/* value */) => {
      return areTicketsChosen(this.state.tickets);
    });

    /* Add an email confirmation validation rule to be sure the user enters the correct email address */
    ValidatorForm.addValidationRule('isSameEmail', (/* value */) => {
      return this.state.formFields.email === this.state.formFields.confirmEmail;
    });

    ValidatorForm.addValidationRule('maxTickets', value => {
      // check the global max of 20 first
      if (parseInt(value, 10) > 20) {
        return false;
      }
      const today = moment();
      return this.props.products.reduce((currentStatus, product) => {
        /* Go through the products and find the one with this quanitity. If more than one matches, we'll check it too. */
        if (parseInt(this.state.tickets[product.id], 10) !== parseInt(value, 10)) {
          return currentStatus;
        }
        /* Find the purchase plan matching this product and number of installments. */

        /* Get the first purchase plan matching all of the requirements since there can only be one. */
        const [purchasePlan] = this.props.purchasePlans.filter(plan => {
          /* If today's date isn't within the range of valid dates for the
          purchase plan, skip it. */
          if (moment(plan.endDate).isAfter(today) && moment(plan.startDate).isBefore(today))
            return (
              plan.installments === this.state.purchasePlan &&
              plan.productId === product.id &&
              plan.eventId === this.props.eventId
            );
          return false;
        });

        /* If the purchase plan was found and the limit is set, make sure we are under that limit. */
        if (purchasePlan && Number.isInteger(purchasePlan.limit)) {
          return value <= purchasePlan.limit ? currentStatus : false;
        }
        return currentStatus;
      }, true);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.registration && this.props.registration.id !== prevProps.registration.id) {
      const newState = this.buildNewState(this.props);
      this.setStateCalculateTotal(newState);
    }

    if (this.props.events !== prevProps.events) {
      const selectedEvent = { ...this.props.events.find(e => e.id === this.props.eventId) };
      this.setStateCalculateTotal({ selectedEvent });
    }

    if (!isEmpty(this.props.products) && !isEmpty(this.props.registration)) {
      const tickets = {};
      this.props.products.forEach(p => {
        tickets[p.id] = 0;
      });

      if (this.props.registration.cart) {
        this.props.registration.cart.items.forEach(item => {
          tickets[item.productId] = item.quantity;
        });

        this.setStateCalculateTotal({ tickets });
      }

      // JM: What is this? This look like what we would do in a render method or something
      // For now, I replaced with the above
      //
      // nextProps.registration.cart &&
      //   nextProps.registration.cart.items.forEach(item => {
      //     tickets[item.productId] = item.quantity;
      //   });
      // this.setState({ tickets }, this.calculateTotal);
    }
  }

  setStateCalculateTotal(stateUpdates) {
    this.setState(stateUpdates, this.calculateTotal);
  }

  buildNewState = props => {
    const newState = {
      formFields: {
        ...props.registration,
      },
      tickets: {},
      purchaseDate: props.registration.createdDate,
      purchasePlan: !isEmpty(props.registration.cart) ? props.registration.cart.installments : null,
      selectedEvent: !isEmpty(props.registration.cart)
        ? { ...props.events.find(e => e.id === props.registration.cart.items[0].eventId) }
        : null,
    };
    if (props.registration.cart && props.registration.items) {
      props.registration.cart.items.forEach(item => {
        newState.tickets[item.productId] = item.quantity;
      });
    }
    /* Set non-required values to an empty string if they are null */
    if (props.registration.alsoKnownAs === null) newState.formFields.alsoKnownAs = '';
    if (props.registration.middleName === null) newState.formFields.middleName = '';
    if (props.registration.suffix === null) newState.formFields.suffix = '';
    /* Remove cart info. */
    delete newState.formFields.id;
    delete newState.formFields.cart;
    delete newState.formFields.createdDate;
    delete newState.formFields.updatedDate;
    return newState;
  };

  handleSubmit = e => {
    e.preventDefault();

    const registrationData = {
      purchaser: {
        ...this.state.formFields,
      },
      paymentSource: {
        ...this.state.billingFields,
      },
      items: { ...this.state.tickets },
      installments: this.state.purchasePlan,
    };

    delete registrationData.purchaser.agencyManager;

    /* If the alsoKnownAs and middleName fields are an empty string, either empty the field if 
    the registration is being edited, or don't resubmit it so it won't be set. */
    const updateString = field => {
      if (registrationData.purchaser[field] === '') {
        const { registration } = this.props;
        if (registration && registration[field] && registration[field].length > 0) {
          registrationData.purchaser[field] = null;
        } else {
          delete registrationData.purchaser[field];
        }
      }
      return registrationData;
    };
    ['alsoKnownAs', 'middleName', 'suffix'].forEach(field => updateString(field));

    /* Delete the additional fields that are not allowed to be saved */
    delete registrationData.purchaser.confirmEmail;
    delete registrationData.purchaser.discount;
    delete registrationData.purchaser.coupon;
    delete registrationData.purchaser.price;

    // console.log('REGISTRATION DATA!', registrationData);

    this.props.submitAction(registrationData);
  };

  checkBillingFields = state => {
    const items = Object.keys(state.billingFields).filter(field => {
      return state.billingFields[field].length;
    });
    if (items.length) {
      return 'Updating billing information will overwrite data previously entered!';
    }
    return '';
  };

  updateBillingField = async (field, value) => {
    const state = { ...this.state };
    state.billingFields[field] = value;

    if (this.props.showBillingInfo === false) {
      state.warning = await this.checkBillingFields(state);
    }

    this.setState(state);
  };

  // JM: Why is there an updateField and an updateFormField? What is the difference?

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state, () => {
      if (field === 'purchasePlan') this.calculateTotal();
    });
  };

  updateFormField = (field, value) => {
    const state = { ...this.state };

    state.formFields[field] = value;

    /* Only runn the following if the user hasn't entered anything into the badge name field. */
    if (state.overwriteBadgeName && this.props.showBillingInfo) {
      /* If they are currently entering something in the badge name, don't automatically enter anything in the field again. */
      if (field === 'alsoKnownAs') {
        state.overwriteBadgeName = false;
      } else if (field === 'firstName') {
        /* If the user is clearing out the firstName field and has already cleared out the lastName field, clear out the badge name field. */
        if (state.formFields.lastName.length === 0 && value.length === 0) {
          state.formFields.alsoKnownAs = '';
        } else {
          /* If the user is entering a first name, copy it into the badge name */
          state.formFields.alsoKnownAs = `${value} ${state.formFields.lastName}`;
        }
      } else if (field === 'lastName') {
        /* If the user is clearing out the lastName field and has already cleared out the firstName field, clear out the badge name field. */
        if (state.formFields.firstName.length === 0 && value.length === 0) {
          state.formFields.alsoKnownAs = '';
        } else {
          /* If the user is entering a last name, copy it into the badge name */
          state.formFields.alsoKnownAs = `${state.formFields.firstName} ${value}`;
        }
      }
    }

    this.setState(state);
  };

  updateTickets = (field, value, callback) => {
    const state = { ...this.state };

    state.tickets[field] = value;

    /* Update the allowable payment options and calulate the totals. */
    this.setState(state, () => {
      callback();
      this.calculateTotal();
    });
  };

  render() {
    const { events, products, classes, newRegistration, registration } = this.props;

    return (
      // eslint-disable-next-line react/jsx-fragments
      <div className="container">
        <Grid container spacing={16}>
          <Grid item xs>
            {/* 
              This is now disabled because we don't want to allow selecting a different event than the one
              we arrived at this page for
            */}
            <FormControl className={classes.formControl}>
              <Select
                value={this.props.eventId}
                onChange={e => this.updateFormField('eventId', e.target.value)}
                inputProps={{
                  name: 'eventName',
                }}
                disabled
                style={{ marginTop: '1rem' }}
              >
                {isEmpty(events) ? (
                  <MenuItem key="empty" value="" />
                ) : (
                  events.map(evt => (
                    <MenuItem key={evt.id} value={evt.id}>
                      {evt.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          {/*! isEmpty(this.state.event) && <Typography variant="title">{this.state.event.name}</Typography> */}
        </Grid>
        {/* eslint-disable-next-line react/no-string-refs */}
        <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
          <PurchaserInfo
            state={this.state.formFields}
            updateField={this.updateFormField}
            newRegistration={this.props.newRegistration}
          />
          {/* JM: I don't love this. It's a small hack to work around the margin issues since the billing info component is reused and sometimes
        doesn't need a margin */}
          {this.props.showBillingInfo ? (
            // eslint-disable-next-line react/jsx-fragments
            <Fragment>
              <div style={{ marginTop: '1.5rem' }}>
                <BillingInfo
                  state={this.state.billingFields}
                  updateField={this.updateBillingField}
                  allRequired={this.state.warning !== ''}
                  warning={this.state.warning}
                />
              </div>
              <TicketPurchasing
                currentTotal={this.state.currentTotal}
                displayOnly={!this.props.showBillingInfo}
                eventId={this.props.eventId}
                products={products}
                tickets={this.state.tickets}
                selectedPurchasePlan={this.state.purchasePlan}
                purchasePlans={this.props.purchasePlans}
                updateTickets={this.updateTickets}
                updatePurchasePlan={value => this.updateField('purchasePlan', value)}
              />
            </Fragment>
          ) : (
            <PurchaseTransactions
              registration={!newRegistration ? registration : null}
              purchase={this.props.purchaseDetails}
              updatePurchases={() => {}}
              readOnly
            />
          )}
          <div style={{ marginTop: '1rem' }}>
            <SFGOutlinedButton className="button expanded" type="submit">
              Submit
            </SFGOutlinedButton>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    // marginRight: theme.spacing.unit,
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  subheading: {
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
});

RegistrationForm.defaultProps = {
  newRegistration: false,
  registration: null,
  purchaseDetails: null,
};

RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  newRegistration: PropTypes.bool,
  showBillingInfo: PropTypes.bool.isRequired,
  registration: PropTypes.object,
  purchaseDetails: PropTypes.object,
  products: PropTypes.array.isRequired,
  purchasePlans: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegistrationForm);
