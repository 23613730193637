import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import { FormHelperText, withStyles } from '@material-ui/core';
import { ValidatorComponent } from 'react-material-ui-form-validator';

class SFGCustomValidator extends ValidatorComponent {
  handleBlur = () => {
    const { disableBlurValidation, value, onBlur } = this.props;

    if (!disableBlurValidation) this.validate(value, true);

    onBlur(value);
  };

  render() {
    const {
      children,
      classes,
      disableBlurValidation,
      errorMessages,
      label,
      requiredError,
      validatorListener,
      validators,
      onBlur,
      ...rest
    } = this.props;

    const { isValid } = this.state;

    return (
      <div className={classes.formControl}>
        {Children.map(children, child =>
          cloneElement(child, {
            error: isValid !== undefined && !isValid,
            ...rest,
            onBlur: this.handleBlur,
          })
        )}
        {isValid !== undefined && !isValid && (
          <FormHelperText classes={{ root: classes.helperText }} error>
            <span>{this.getErrorMessage()}</span>
          </FormHelperText>
        )}
      </div>
    );
  }
}

SFGCustomValidator.propTypes = {
  classes: PropTypes.object.isRequired,

  disableBlurValidation: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
};

SFGCustomValidator.defaultProps = {
  disableBlurValidation: false,
  label: '',
  validatorListener: () => {},
  value: null,
};

const styles = () => ({
  helperText: {
    margin: 0,
  },
});

export default withStyles(styles)(SFGCustomValidator);
