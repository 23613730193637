import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';

import CustomSimpleMenu from '../layout/customSimpleMenu/customSimpleMenu.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import PurchasesSelector from './purchases.selector';
import { getMyPurchases } from './purchases.actions';
import { setPageTitle } from '../layout/layout.actions';

const DATE_FORMAT = 'MMM DD, YYYY h:mm a';

class MyPurchases extends Component {
  componentDidMount() {
    this.props.setPageTitle('My Purchases');

    /* Only run the network requests if they aren't already in progress. 
    We check this because componentDidMount runs multiple times. */
    if (!this.props.isLoading) {
      this.props.getMyPurchases();
    }
  }

  renderTableCell = content => {
    return (
      <TableCell>
        <Typography variant="body1">{content}</Typography>
      </TableCell>
    );
  };

  viewPurchaseDetails = purchase => {
    this.props.history.push({
      pathname: `/purchases/${purchase.id}`,
    });
  };

  render() {
    const { isLoading, myPurchases } = this.props;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <div className="container">
        <Grid container justify="center">
          <Grid item xs={12}>
            {myPurchases.length === 0 && (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="headline">No purchases</Typography>
              </Grid>
            )}

            {myPurchases.length > 0 && (
              <>
                <Typography variant="headline">{`Total Purchases: ${myPurchases.length}`}</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Purchase Date</TableCell>
                      <TableCell>Purchased by</TableCell>
                      <TableCell>Event Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Tickets</TableCell>
                      <TableCell>Transactions</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {myPurchases.map(purchase => {
                      const purchaseDate = moment(purchase.createdDate).format(DATE_FORMAT);
                      return (
                        <TableRow key={purchase.id}>
                          {this.renderTableCell(purchaseDate)}
                          {this.renderTableCell(`${purchase.firstName} ${purchase.lastName}`)}
                          {this.renderTableCell(purchase.eventName)}
                          {this.renderTableCell(purchase.email)}
                          {this.renderTableCell(purchase.tickets.length)}
                          {this.renderTableCell(purchase.transactions.length)}
                          <TableCell>
                            <CustomSimpleMenu
                              menuItems={[
                                {
                                  text: 'View Purchase Details',
                                  action: () => {
                                    this.viewPurchaseDetails(purchase);
                                  },
                                },
                              ]}
                              icon={<MoreVertIcon />}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

MyPurchases.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  myPurchases: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getMyPurchases: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  myPurchases: PurchasesSelector.selectPurchasesSortedByMostRecent(state),
  isLoading: state.purchases.get('isLoading'),
});

export default connect(mapStateToProps, {
  setPageTitle,
  getMyPurchases,
})(MyPurchases);
