import { CALL_API } from '../../middleware/api';

export const GET_MY_TICKETS_REQUEST = 'GET_MY_TICKETS_REQUEST';
export const GET_MY_TICKETS_SUCCESS = 'GET_MY_TICKETS_SUCCESS';
export const GET_MY_TICKETS_FAILURE = 'GET_MY_TICKETS_FAILURE';

export const GET_MY_PURCHASES_REQUEST = 'GET_MY_PURCHASES_REQUEST';
export const GET_MY_PURCHASES_SUCCESS = 'GET_MY_PURCHASES_SUCCESS';
export const GET_MY_PURCHASES_FAILURE = 'GET_MY_PURCHASES_FAILURE';

export const GET_USER_PURCHASE_REQUEST = 'GET_USER_PURCHASE_REQUEST';
export const GET_USER_PURCHASE_SUCCESS = 'GET_USER_PURCHASE_SUCCESS';
export const GET_USER_PURCHASE_FAILURE = 'GET_USER_PURCHASE_FAILURE';

export const RETRY_TRANSACTION_REQUEST = 'RETRY_TRANSACTION_REQUEST';
export const RETRY_TRANSACTION_SUCCESS = 'RETRY_TRANSACTION_SUCCESS';
export const RETRY_TRANSACTION_FAILURE = 'RETRY_TRANSACTION_FAILURE';

export const UPDATE_CARD_REQUEST = 'UPDATE_CARD_REQUEST';
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS';
export const UPDATE_CARD_FAILURE = 'UPDATE_CARD_FAILURE';

export const CLEAR_USER_SELECTED_PURCHASE = 'CLEAR_SELECTED_PURCHASE';

export const getMyTickets = () => {
  return {
    [CALL_API]: {
      types: [GET_MY_TICKETS_REQUEST, GET_MY_TICKETS_SUCCESS, GET_MY_TICKETS_FAILURE],
      authenticated: true,
      endpoint: 'v1/mytickets',
      method: 'GET',
    },
  };
};

export const getMyPurchases = () => {
  return {
    [CALL_API]: {
      types: [GET_MY_PURCHASES_REQUEST, GET_MY_PURCHASES_SUCCESS, GET_MY_PURCHASES_FAILURE],
      authenticated: true,
      endpoint: 'v1/purchasehistory',
      method: 'GET',
    },
  };
};

export const getPurchase = purchaseId => {
  return {
    [CALL_API]: {
      types: [GET_USER_PURCHASE_REQUEST, GET_USER_PURCHASE_SUCCESS, GET_USER_PURCHASE_FAILURE],
      authenticated: true,
      endpoint: `v1/purchasehistory/${purchaseId}`,
      method: 'GET',
    },
  };
};

export const clearSelectedPurchase = () => {
  return {
    type: CLEAR_USER_SELECTED_PURCHASE,
  };
};

export const updateCard = ({ purchaseId, token }) => {
  return {
    [CALL_API]: {
      types: [UPDATE_CARD_REQUEST, UPDATE_CARD_SUCCESS, UPDATE_CARD_FAILURE],
      authenticated: true,
      endpoint: `v1/purchasehistory/${purchaseId}/updateCard`,
      method: 'POST',
      payload: { token },
    },
  };
};

export const retryTransaction = ({ purchaseId, transactionId }) => {
  return {
    [CALL_API]: {
      types: [RETRY_TRANSACTION_REQUEST, RETRY_TRANSACTION_SUCCESS, RETRY_TRANSACTION_FAILURE],
      authenticated: true,
      endpoint: `v1/purchasehistory/${purchaseId}/transactions/${transactionId}/retry`,
      method: 'POST',
    },
  };
};
