import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import SpecialEventForm from './specialEventForm.component';
import { createSpecialEvent, CREATE_SPECIAL_EVENT_SUCCESS } from './specialEvents.actions';
import inviteeFormTypes from '../../types/inviteeFormTypes';

class SpecialEventNew extends Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
  }

  componentWillMount() {
    if (isEmpty(this.props.selectedEvent)) {
      this.props.history.replace('/events');
    }
    this.props.setPageTitle('New Special Event');
  }

  async handleCreate(specialEventData /* , isAdmin = false */) {
    let mapSpecialEventData = { ...specialEventData };

    mapSpecialEventData.inviteeEmails = mapSpecialEventData.inviteeEmails.map(obj => obj.email);

    // map bulk invitation data
    const invitees = {};
    if (specialEventData.isIncludeAllRegistrants === true) {
      invitees.type = inviteeFormTypes.BULK_INVITATION_ALL_REGISTERED;
    }

    if (specialEventData.isIncludeAllCheckedIn === true) {
      invitees.type = inviteeFormTypes.BULK_INVITATION_CHECKED_IN;
    }

    if (!isEmpty(invitees)) {
      mapSpecialEventData.invitees = invitees;
    }

    // omit mapped fields
    mapSpecialEventData = omit(mapSpecialEventData, [
      'bulkInvitationEventId',
      'isIncludeAllCheckedIn',
      'isIncludeAllRegistrants'
    ]);

    const response = await this.props.createSpecialEvent(
      this.props.selectedEvent.id,
      mapSpecialEventData
    );

    if (response.type === CREATE_SPECIAL_EVENT_SUCCESS) {
      this.props.handleToastMessage('Special Event Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create special event.', true);
    }
  }

  render() {
    let { selectedEvent } = this.props;
    const { loginStatus } = this.props;

    if (isEmpty(selectedEvent)) {
      if (this.props.location.state && 'selectedEvent' in this.props.location.state) {
        selectedEvent = this.props.location.state.selectedEvent;
      } else {
        return null;
      }
    }

    return (
      <div className="container">
        <SpecialEventForm
          submitAction={this.handleCreate}
          selectedEvent={selectedEvent}
          loginStatus={loginStatus}
        />
      </div>
    );
  }
}

SpecialEventNew.defaultProps = {
  selectedEvent: null
};

SpecialEventNew.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  selectedEvent: PropTypes.object,
  loginStatus: PropTypes.number.isRequired,

  createSpecialEvent: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedEvent: state.events.get('selectedEvent'),
  loginStatus: state.login.get('loginStatus')
});

export default withRouter(
  connect(mapStateToProps, {
    createSpecialEvent,
    handleToastMessage,
    setPageTitle
  })(SpecialEventNew)
);
