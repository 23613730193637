import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import axios from 'axios';
import firebase from 'firebase/app';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import 'firebase/auth';

import LoadingOverlay from '../layout/loadingOverlay.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { BASE_URL } from '../../middleware/api';
import { getEvent, setSelectedEvent } from './events.actions';
import { getReports } from '../reports/reports.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EventReportsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportName: '',
    };

    ['handleSubmit', 'fieldChange'].forEach(k => {
      this[k] = this[k].bind(this);
    });
  }

  componentDidMount() {
    this.props.setPageTitle('Event Reports');
    const { match, event } = this.props;
    const eventId = match.params.id;

    if (isEmpty(event) || event.id !== eventId) {
      this.props.getEvent(eventId);
    }
    if (isEmpty(this.props.reports)) this.props.getReports();
  }

  async handleSubmit(e) {
    e.preventDefault();

    const report = find(this.props.reports, { name: this.state.reportName });
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      // Set the authorization header for this request instance;
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Create the URL
      let url = BASE_URL + report.path.substring(1);
      console.log('BASE URL!', BASE_URL, url);
      // Run through each URL parameter that is necessary and specify it.
      if (report.params) {
        report.params.forEach((param, i) => {
          let value = this.state[param.name];
          if (param.name === 'eventId') {
            value = this.props.event.id;
          }
          // Assume that the param is in state if we need it. As of now this is only the eventId.
          if (i === 0) {
            url += `?${param.name}=${value}`;
          } else {
            url += `&${param.name}=${value}`;
          }
        });
      }

      // Get the file.
      const response = await instance({
        url,
        method: 'GET',
        responseType: 'blob', // important
      });

      // Create a hidden link and virtually click it to download the file.
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', `${this.props.event.name} - ${report.name}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      this.props.handleToastMessage(`Failed to get ${report.name}`, true);
      console.error(err);
    }
  }

  fieldChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { classes, event, reports, isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <div className="container">
        {isEmpty(event) ? (
          <div>Event not found.</div>
        ) : (
          <div>
            <Typography color="primary" variant="title">{`${event.name} Reports`}</Typography>
            <form onSubmit={this.handleSubmit}>
              <FormControl className={classes.formControl}>
                <Select
                  name="reportName"
                  value={this.state.reportName}
                  onChange={this.fieldChange}
                  className={classes.select}
                >
                  {reports &&
                    reports.map(report => {
                      // Only show non-event specific admin reports or admin reports that only need the event information.
                      if (
                        report.admin &&
                        (!('params' in report) ||
                          (report.params.length === 1 && report.params[0].name === 'eventId'))
                      )
                        return (
                          <MenuItem key={report.name} value={report.name}>
                            {report.name}
                          </MenuItem>
                        );
                      // Return an empty item because a map has to return something for each item.
                      return <span key={report.name} />;
                    })}
                </Select>
              </FormControl>
              <SFGOutlinedButton color="primary" type="submit">
                Export
              </SFGOutlinedButton>
            </form>
          </div>
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  formControl: {
    display: 'block',
    marginBottom: '2rem',
  },
  select: {
    minWidth: 140,
  },
});

EventReportsContainer.defaultProps = {
  event: null,
};

EventReportsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  event: PropTypes.object,
  reports: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  getReports: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.events.get('isLoading') || state.reports.get('isLoading'),
  event: state.events.get('selectedEvent'),
  reports: state.reports.get('reports'),
});

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      handleToastMessage,
      getEvent,
      setSelectedEvent,
      getReports,
    })(EventReportsContainer)
  )
);
