import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import RegistrationForm from './registrationForm.component';
import {
  getRegistration,
  updateRegistration,
  UPDATE_REGISTRATION_SUCCESS
} from './registrations.actions';
import { getPurchase, GET_PURCHASE_SUCCESS } from '../events/events.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

class RegistrationEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchaseDetails: {}
    };
  }

  async componentDidMount() {
    this.props.setPageTitle(`Edit Registration`);
    this.props.getRegistration(this.props.match.params.id);
    /* Get the purchase information. */
    if (isEmpty(this.state.purchaseDetails)) {
      const result = await this.props.getPurchase(this.props.match.params.id);
      if (result.type === GET_PURCHASE_SUCCESS) {
        this.setState({ purchaseDetails: result.response });
      } else {
        this.props.handleToastMessage(
          `Failed to fetch purchase transactions. ${'message' in result ? result.message : ''}`,
          true
        );
      }
    }
  }

  handleEdit = async registrationData => {
    const response = await this.props.updateRegistration(
      this.props.selectedRegistration.id,
      registrationData.purchaser
    );

    if (response.type === UPDATE_REGISTRATION_SUCCESS) {
      this.props.handleToastMessage('Registration updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update registration.', true);
    }
  };

  render() {
    const {
      events,
      managers,
      products,
      purchasePlans,
      selectedRegistration,
      isLoading
    } = this.props;
    const { eventId } = this.props.match.params;

    if (isLoading) return <LoadingOverlay />;
    return (
      <RegistrationForm
        eventId={eventId}
        events={events}
        managers={managers}
        products={products}
        purchasePlans={purchasePlans}
        registration={selectedRegistration}
        showBillingInfo={false}
        submitAction={this.handleEdit}
        purchaseDetails={this.state.purchaseDetails}
      />
    );
  }
}

RegistrationEditContainer.defaultProps = {
  selectedRegistration: null
};

RegistrationEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  events: PropTypes.array.isRequired,
  managers: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  purchasePlans: PropTypes.array.isRequired,
  selectedRegistration: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  getPurchase: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getRegistration: PropTypes.func.isRequired,
  updateRegistration: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    events: state.events.get('eventsAdmin'),
    managers: state.managers.get('managers'),
    products: state.products.get('products'),
    purchasePlans: state.purchasePlans.get('purchasePlans'),
    selectedRegistration: state.registrations.get('selectedRegistration'),
    isLoading:
      state.registrations.get('isLoading') ||
      state.managers.get('isLoading') ||
      state.products.get('isLoading')
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPurchase,
    setPageTitle,
    getRegistration,
    updateRegistration,
    handleToastMessage
  })(RegistrationEditContainer)
);
