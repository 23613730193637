import Immutable from 'immutable';
import {
  CHECK_USER_FAILURE,
  CHECK_USER_REQUEST,
  CHECK_USER_SUCCESS,
  LOGIN_AUTH_0_FAILURE,
  LOGIN_AUTH_0_REQUEST,
  LOGIN_AUTH_0_SUCCESS,
  LOGIN_STATE,
  SET_EMAIL_TO_VERIFY,
  SET_INITIAL_LOAD_COMPLETE,
  SET_LOGGED_IN,
  SET_NON_ADMIN_EMAIL,
  SET_SHOW_VERIFY_ACTION,
  SET_USER_EMAIL,
  TOGGLE_USER_VIEW,
} from './login.actions';
import { SET_PERMISSIONS } from './permissions';

const initialState = Immutable.Map({
  loginStatus: LOGIN_STATE.NOT_LOGGED_IN,
  userEmail: null,
  permissions: [],
  isLoading: false,
  initialLoadComplete: false,
  emailToVerify: null,
  showVerifyAction: false,
  nonAdminEmail: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_USER_REQUEST:
      return state.set('isLoading', true);

    case CHECK_USER_SUCCESS:
      return state.set('isLoading', false);

    case CHECK_USER_FAILURE:
      return state.set('isLoading', false);

    case LOGIN_AUTH_0_REQUEST:
      return state.set('isLoading', true);

    case LOGIN_AUTH_0_SUCCESS:
      return state.set('isLoading', false);

    case LOGIN_AUTH_0_FAILURE:
      return state.set('isLoading', false);

    case SET_LOGGED_IN:
      return state.set('loginStatus', action.payload);

    case SET_INITIAL_LOAD_COMPLETE:
      return state.set('initialLoadComplete', action.payload);

    case SET_PERMISSIONS:
      return state.set('permissions', action.payload);

    case SET_USER_EMAIL:
      return state.set('userEmail', action.payload);

    case TOGGLE_USER_VIEW: {
      let loginStatus = state.get('loginStatus');
      loginStatus =
        loginStatus === LOGIN_STATE.STANDARD_USER
          ? LOGIN_STATE.ALTITUDE_USER
          : LOGIN_STATE.STANDARD_USER;
      return state.merge({
        loginStatus,
        initialLoadComplete: false,
      });
    }

    case SET_EMAIL_TO_VERIFY:
      return state.set('emailToVerify', action.payload);

    case SET_SHOW_VERIFY_ACTION:
      return state.set('showVerifyAction', action.payload);

    case SET_NON_ADMIN_EMAIL:
      return state.set('nonAdminEmail', action.payload);

    default:
      return state;
  }
};
