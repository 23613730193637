import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  withWidth,
} from '@material-ui/core';
import { has, isEmpty } from 'lodash';
import { isWidthDown } from '@material-ui/core/withWidth';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// Import used for exporting breakout sessions
import axios from 'axios';
import firebase from 'firebase/app';
import { compose } from 'recompose';

import 'firebase/auth';
import { BASE_URL } from '../../middleware/api';

import Colors from '../../styles/colors';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGPrimaryButton from '../../common/buttons/sfgPrimaryButton.component';
import {
  IS_AGENCY_OWNER,
  IS_VUE_USER,
  VIEW_SPECIAL_EVENTS,
  hasPermission,
} from '../login/permissions';
import { getLoginRedirectUrl } from '../../utilities/loginUtils';

const VIEW_DETAILS = 'View Details';
const REGISTER_NOW = 'Register Now';
const SPECIAL_EVENTS = 'Special Events';
const EXPORT_BREAKOUT_SESSIONS = 'Export Breakout Sessions';
const ASSIGN_MY_TICKETS = 'Assign Tickets';

const PREVIEW_GRAPHIC_DIAMETER_REM = '8rem';
const PREVIEW_GRAPHIC_DIAMETER = 128; // 8 * 16px
const MENU_OPTIONS = [
  VIEW_DETAILS,
  REGISTER_NOW,
  SPECIAL_EVENTS,
  EXPORT_BREAKOUT_SESSIONS,
  ASSIGN_MY_TICKETS,
];

const EventsListItem = ({ classes, history, width, event, isLoggedIn, permissions }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(isWidthDown('sm', width));
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const onResize = () => {
      setIsSmallScreen(isWidthDown('sm', width));
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [width]);

  const hasDetailsUrl = () =>
    has(event, 'eventDetailsUrl') &&
    !isEmpty(event.eventDetailsUrl) &&
    event.eventDetailsUrl.trim() !== '';

  const viewEvent = () => {
    if (hasDetailsUrl()) {
      window.open(event.eventDetailsUrl, '_blank');
    }
  };

  const viewEventRegistration = () => {
    if (
      has(event, 'externalRegistrationUrl') &&
      !isEmpty(event.externalRegistrationUrl) &&
      event.externalRegistrationUrl.trim() !== ''
    ) {
      window.open(event.externalRegistrationUrl, '_blank');
    } else {
      // go to view the event, redirect to login if needed
      history.push(getLoginRedirectUrl(`/events/${event.id}/registration`, isLoggedIn));
    }
  };

  const viewSpecialEvents = () => {
    // go to special events for this event
    history.push(`/events/${event.id}/specialEvents`);
  };

  const handleExportBreakoutSessions = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      // Set the authorization header for this request instance;
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await instance({
        url: `${BASE_URL}v1/reports/DOWNLINE_BREAKOUT_SESSIONS?eventId=${event.id}`,
        method: 'GET',
        responseType: 'blob', // important
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'BreakoutSessions.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      // this.props.handleToastMessage('Failed to get breakout sessions', true);
      console.error(e);
    }
  };

  const handleAssignTickets = () => {
    // go to assign tickets for the event, redirect if not logged in
    history.push(getLoginRedirectUrl(`/attendees/${event.id}`, isLoggedIn));
  };

  const getSubheader = () => {
    const isOneDayEvent = moment(event.startDate).isSame(event.endDate, 'day');

    let dateString = '';

    if (isOneDayEvent) {
      dateString = moment(event.startDate)
        .utc()
        .format('MMM DD YYYY');
    } else {
      const startYear = moment(event.startDate).format('YYYY');
      const endYear = moment(event.endDate).format('YYYY');
      const startMonth = moment(event.startDate).format('MMM');
      const endMonth = moment(event.endDate).format('MMM');

      dateString = `${moment(event.startDate)
        .utc()
        .format(startYear === endYear ? 'MMM DD' : 'MMM DD YYYY')} - 
        ${moment(event.endDate)
          .utc()
          .format(startYear === endYear && startMonth === endMonth ? 'DD YYYY' : 'MMM DD YYYY')}`;
    }

    return `${dateString} | ${event.locationName}`;
  };

  const handleOpenMenu = evt => setAnchorEl(evt.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleMenuItemClick = option => {
    switch (option) {
      case VIEW_DETAILS:
        viewEvent();
        break;

      case REGISTER_NOW:
        viewEventRegistration();
        break;

      case SPECIAL_EVENTS:
        viewSpecialEvents();
        break;

      case EXPORT_BREAKOUT_SESSIONS:
        handleExportBreakoutSessions();
        break;

      case ASSIGN_MY_TICKETS:
        handleAssignTickets();
        break;

      default:
        break;
    }

    handleCloseMenu();
  };

  const FILTERED_MENU_OPTIONS = MENU_OPTIONS.filter(option => {
    switch (option) {
      case VIEW_DETAILS:
        return hasDetailsUrl();

      case REGISTER_NOW:
        return event.ticketsAvailable;

      case SPECIAL_EVENTS:
        return hasPermission(VIEW_SPECIAL_EVENTS, permissions);

      case EXPORT_BREAKOUT_SESSIONS:
        return (
          event.hasExternalTicketAssignments !== true &&
          hasPermission(IS_AGENCY_OWNER, permissions) &&
          hasPermission(IS_VUE_USER, permissions)
        );

      case ASSIGN_MY_TICKETS:
        return event.hasExternalTicketAssignments !== true;

      default:
        return true;
    }
  });

  return (
    <Card className={classes.eventCard}>
      <CardHeader
        disableTypography={false}
        title={event.name}
        titleTypographyProps={{
          variant: 'title',
          className: classNames(classes.headerTitle, hasDetailsUrl() && 'clickable'),
          onClick: () => viewEvent(),
        }}
        subheader={getSubheader()}
        subheaderTypographyProps={{
          className: classes.headerSubtitle,
        }}
        avatar={
          <Avatar
            src={event.previewGraphicUrl}
            className={classNames(classes.avatar, hasDetailsUrl() && 'clickable')}
            onClick={() => viewEvent()}
          />
        }
        action={
          <div className={classes.headerActionsWrapper}>
            {isSmallScreen ? (
              <>
                <IconButton onClick={handleOpenMenu}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      width: 200,
                    },
                  }}
                >
                  {FILTERED_MENU_OPTIONS.map(option => (
                    <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <>
                {hasDetailsUrl() && (
                  <SFGOutlinedButton color="primary" onClick={viewEvent}>
                    {VIEW_DETAILS}
                  </SFGOutlinedButton>
                )}
                {event.ticketsAvailable && (
                  <SFGPrimaryButton
                    color="primary"
                    style={{ marginLeft: '0.5rem' }}
                    onClick={viewEventRegistration}
                  >
                    {REGISTER_NOW}
                  </SFGPrimaryButton>
                )}
              </>
            )}
          </div>
        }
      />

      <CardContent className={classNames({ [classes.summary]: !isSmallScreen })}>
        {event.summary}
      </CardContent>

      {!isSmallScreen && (
        <CardActions className={classes.cardActions}>
          {hasPermission(VIEW_SPECIAL_EVENTS, permissions) && (
            <SFGOutlinedButton color="primary" onClick={viewSpecialEvents}>
              {SPECIAL_EVENTS}
            </SFGOutlinedButton>
          )}

          {event.hasExternalTicketAssignments !== true &&
            hasPermission(IS_AGENCY_OWNER, permissions) &&
            hasPermission(IS_VUE_USER, permissions) && (
              <SFGOutlinedButton color="primary" onClick={handleExportBreakoutSessions}>
                {EXPORT_BREAKOUT_SESSIONS}
              </SFGOutlinedButton>
            )}

          {event.hasExternalTicketAssignments !== true && firebase.auth().currentUser && (
            <SFGOutlinedButton color="primary" onClick={handleAssignTickets}>
              {ASSIGN_MY_TICKETS}
            </SFGOutlinedButton>
          )}
        </CardActions>
      )}
    </Card>
  );
};

const styles = theme => ({
  eventCard: {
    marginBottom: theme.spacing.unit * 4,
    border: `${Colors.grayMediumLight} solid 1px`,
    borderRadius: 'unset',
    boxShadow: 'none',
  },
  avatar: {
    width: PREVIEW_GRAPHIC_DIAMETER_REM,
    height: PREVIEW_GRAPHIC_DIAMETER_REM,
    borderRadius: PREVIEW_GRAPHIC_DIAMETER,
    border: `${theme.spacing.unit / 2}px solid white`,
  },
  headerTitle: {
    fontSize: '2rem',
  },
  headerSubtitle: {
    fontSize: '1.25rem',
  },
  headerActionsWrapper: {
    marginRight: '0.5rem',
    marginTop: '0.5rem',
  },
  cardActions: {
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
    padding: '0.5rem',
  },
  summary: {
    /* 
      Calculation of:
      - avatar width
      - border radius
      - and 16px that is added to the avatar by MUI
    */
    marginLeft: `${PREVIEW_GRAPHIC_DIAMETER + theme.spacing.unit + 16}px`,
  },
});

EventsListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  width: PropTypes.string.isRequired,

  event: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default compose(withWidth(), withRouter, withStyles(styles))(EventsListItem);
