import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import {
  DELETE_SCHEDULE_SLOT_SESSION_SUCCESS,
  deleteScheduleSlotSession,
  getScheduleSlotSessions,
} from './schedule.actions';
import { handleToastMessage } from '../layout/layout.actions';

class ScheduleSlotSessions extends Component {
  componentWillMount() {
    this.props.getScheduleSlotSessions(this.props.match.params.slotId);
  }

  removeSession = async (id, index) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`Are you sure you want to remove session ${this.props.sessions[index].title}`)
    ) {
      const response = await this.props.deleteScheduleSlotSession(id);
      if (response.type === DELETE_SCHEDULE_SLOT_SESSION_SUCCESS) {
        this.props.handleToastMessage('Session deleted.');
      } else {
        this.props.handleToastMessage('Failed to delete session', true);
      }
    }
  };

  editSession = session => {
    // console.log('NAVIGATING TO SCHEDULE SLOT SESSION EDIT!', session);
    this.props.history.push({
      pathname: `/events/${this.props.selectedEvent.id}/scheduleSlots/${this.props.match.params.slotId}/sessions/${session.id}`,
      state: { session, slot: this.props.location.state.slot },
    });
  };

  render() {
    const { sessions } = this.props;

    return (
      <div className="page-padding-bottom">
        <Grid container justify="space-between" style={{ marginBottom: '0.75rem' }}>
          <Grid item>
            <Typography variant="headline">Sessions</Typography>
          </Grid>
          <Grid item>
            <SFGOutlinedButton
              className="button"
              onClick={() => {
                this.props.history.push({
                  pathname: `/scheduleSlotSessionNew`,
                  state: { slot: this.props.location.state.slot },
                });
              }}
            >
              New Session
            </SFGOutlinedButton>
          </Grid>
        </Grid>

        {sessions.length === 0 ? (
          <Typography variant="body2" style={{ margin: '1rem 0' }}>
            No Sessions
          </Typography>
        ) : (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="30%">Title</TableCell>
                  <TableCell width="25%">Location</TableCell>
                  <TableCell width="25%">Speakers</TableCell>
                  <TableCell width="5%">Seats Filled</TableCell>
                  <TableCell width="5%">Seats Available</TableCell>
                  <TableCell width="10%" style={{ textAlign: 'center' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.map((session, i) => {
                  return (
                    <TableRow key={session.id}>
                      <TableCell className="clickable" onClick={() => this.editSession(session)}>
                        <Typography variant="body1">{session.title}</Typography>
                      </TableCell>
                      <TableCell className="clickable" onClick={() => this.editSession(session)}>
                        <Typography variant="body1">{session.location}</Typography>
                      </TableCell>
                      <TableCell className="clickable" onClick={() => this.editSession(session)}>
                        <Typography variant="body1">{session.speakers.join()}</Typography>
                      </TableCell>
                      <TableCell className="clickable" onClick={() => this.editSession(session)}>
                        <Typography variant="body1">{session.registrations.length}</Typography>
                      </TableCell>
                      <TableCell className="clickable" onClick={() => this.editSession(session)}>
                        <Typography variant="body1">
                          {session.seats ? session.seats : ''}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <SFGTextButton
                          color="primary"
                          onClick={() => this.removeSession(session.id, i)}
                        >
                          <i className="material-icons">close</i>
                        </SFGTextButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        )}
      </div>
    );
  }
}

ScheduleSlotSessions.defaultProps = {
  selectedEvent: null,
};

ScheduleSlotSessions.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  selectedEvent: PropTypes.object,
  sessions: PropTypes.array.isRequired,

  handleToastMessage: PropTypes.func.isRequired,
  getScheduleSlotSessions: PropTypes.func.isRequired,
  deleteScheduleSlotSession: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedEvent: state.events.get('selectedEvent'),
    sessions: state.schedule.get('scheduleSlotSessions'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    handleToastMessage,
    getScheduleSlotSessions,
    deleteScheduleSlotSession,
  })(ScheduleSlotSessions)
);
