import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import TicketAssignment from '../tickets/ticketAssignment.component';

import {
  CREATE_ASSIGNEE_SUCCESS,
  CREATE_DELEGATED_ASSIGNEE_SUCCESS,
  GET_DELEGATED_TICKET_FAILURE,
  createAssignee,
  createDelegatedAssignee,
  getDelegatedTicket,
} from '../tickets/tickets.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

import ERROR_MESSAGES from '../../types/errorMessages';
import SUCCESS_MESSASGES from '../../types/successMessages';
import 'firebase/auth';

class NewAttendee extends Component {
  state = {
    delegateCode: null,
    assignee: null,
    ticketId: null,
  };

  componentDidMount() {
    const { match } = this.props;

    this.props.setPageTitle(`Ticket Assignment`);
    const { ticketId } = match.params;

    this.setState({ ticketId });

    this.initAttendeeCode();
  }

  initAttendeeCode = async () => {
    const { location } = this.props;
    /*
    We also need to handle the error case of if the code has changed. What do we show in that case
    if someone comes here with a bad delegate code? (this can be tested by just putting in a made up code)
    */

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    if (code) {
      this.setState({ delegateCode: code });
      const response = await this.props.getDelegatedTicket(code);

      if (response.type === GET_DELEGATED_TICKET_FAILURE) {
        this.props.handleToastMessage(ERROR_MESSAGES.GET_DELEGATED_TICKET_FAILURE, true);
        this.props.history.replace('/events');
        return;
      }

      this.setState({ assignee: response.response.assignee });
    }
  };

  submitForm = async formData => {
    if (!isEmpty(formData.spouseAgent)) {
      formData.spouseAgentCode = formData.spouseAgent.code;
    } else {
      delete formData.spouseAgentCode;
    }

    // remove spouseAgent and spouse from submission - only send spouseAgentCode
    delete formData.spouseAgent;

    // The formData has null values in the case of createdelegated assignee

    if (this.state.delegateCode) {
      const response = await this.props.createDelegatedAssignee(this.state.delegateCode, formData);
      if (response.type === CREATE_DELEGATED_ASSIGNEE_SUCCESS) {
        this.props.handleToastMessage(SUCCESS_MESSASGES.CREATED_TICKET_ASSIGNMENT_SUCCESSS, false);
        this.props.history.push('/events');
        return;
      }
      const message = response.messages && response.messages.length > 0 ? response.messages[0] : '';
      this.props.handleToastMessage(`Failed to create ticket assignment. ${message}`, true);
      return;
    }

    const response = await this.props.createAssignee(this.state.ticketId, formData);
    if (response.type === CREATE_ASSIGNEE_SUCCESS) {
      this.props.history.goBack();
      this.props.handleToastMessage(SUCCESS_MESSASGES.CREATED_TICKET_ASSIGNMENT_SUCCESSS, false);
      return;
    }

    const message = response.messages && response.messages.length > 0 ? response.messages[0] : '';
    this.props.handleToastMessage(`Failed to create ticket assignment. ${message}`, true);
  };

  render() {
    return (
      <div className="container">
        <Typography variant="body2" style={{ marginTop: '1rem', textAlign: 'center' }}>
          Please Note: All ticket assignments are non-refundable and non-transferrable.
        </Typography>
        <TicketAssignment assignee={this.state.assignee} handleSubmit={this.submitForm} />
      </div>
    );
  }
}

NewAttendee.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  createAssignee: PropTypes.func.isRequired,
  createDelegatedAssignee: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getDelegatedTicket: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    managers: state.managers.get('managers'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    createAssignee,
    createDelegatedAssignee,
    handleToastMessage,
    setPageTitle,
    getDelegatedTicket,
  })(NewAttendee)
);
