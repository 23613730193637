import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import CouponForm from './couponForm.component';
import { clearSelectedCoupon, createCoupon, CREATE_COUPON_SUCCESS } from './coupons.actions';

class CouponNewContainer extends Component {
  /*
  JM: This needs to be reassessed. Get Events and Get Agencies 
  should be called as part of the auth loading procedure when that gets implemented
  */

  componentWillMount() {
    this.props.setPageTitle('New Coupon');
    this.props.clearSelectedCoupon();
  }

  handleCreate = async couponData => {
    const response = await this.props.createCoupon(couponData);

    if (response.type === CREATE_COUPON_SUCCESS) {
      this.props.handleToastMessage('Coupon Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create coupon.', true);
    }
  };

  render() {
    return (
      <div className="container">
        <CouponForm
          agencies={this.props.agencies}
          events={this.props.events}
          submitAction={this.handleCreate}
        />
      </div>
    );
  }
}

CouponNewContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  agencies: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,

  createCoupon: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  clearSelectedCoupon: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  agencies: state.bigFive.get('agencies'),
  events: state.events.get('eventsAdmin')
});

export default withRouter(
  connect(mapStateToProps, {
    createCoupon,
    handleToastMessage,
    setPageTitle,
    clearSelectedCoupon
  })(CouponNewContainer)
);
