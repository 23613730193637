import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Button, withStyles } from '@material-ui/core';

const SFGTextButton = ({ classes, className, children, ...props }) => (
  <Button className={classNames(classes.button, classNames)} {...props}>
    {children}
  </Button>
);

const styles = {
  button: {
    borderRadius: 'unset',
    boxShadow: 'none',
  },
};

SFGTextButton.defaultProps = {
  className: '',
};

SFGTextButton.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(SFGTextButton);
