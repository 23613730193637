import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import SFGTextButton from '../../../common/buttons/sfgTextButton.component';

const ValidatorFormDialog = ({
  classes,
  actionText,
  formElements,
  maxWidth,
  open,
  title,
  onClose,
  onSubmit,
}) => {
  return (
    <Dialog fullWidth open={open} maxWidth={maxWidth} onClose={onClose}>
      <ValidatorForm onSubmit={onSubmit}>
        <DialogContent className={classes.dialog}>
          <DialogTitle align="center" disableTypography>
            <Typography color="primary" variant="title">
              {title}
            </Typography>
          </DialogTitle>
          {formElements}
        </DialogContent>
        <DialogActions>
          <SFGTextButton color="primary" type="submit">
            {actionText}
          </SFGTextButton>
          <SFGTextButton onClick={onClose} color="primary">
            Cancel
          </SFGTextButton>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

const styles = () => ({
  dialog: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
});

ValidatorFormDialog.defaultProps = {
  actionText: 'Submit',
  maxWidth: 'sm',
};

ValidatorFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,

  actionText: PropTypes.string,
  formElements: PropTypes.element.isRequired,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,

  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(ValidatorFormDialog);
