import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EventForm from './eventForm.component';
import { CREATE_EVENT_SUCCESS, clearSelectedEvent, createEvent } from './events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EventNewContainer extends Component {
  componentDidMount() {
    this.props.setPageTitle('New Event');
    this.props.clearSelectedEvent();
  }

  handleCreate = async eventData => {
    // need to remove email templates if the value is null because we want the server to set defaults
    if (eventData.emailTemplates === null) delete eventData.emailTemplates;
    const response = await this.props.createEvent(eventData);

    if (response.type === CREATE_EVENT_SUCCESS) {
      this.props.handleToastMessage('Event Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create event.', true);
    }
  };

  render() {
    return (
      <div className="container">
        <Grid container>
          <Grid item xs={12}>
            <EventForm submitAction={this.handleCreate} agencies={this.props.agencies} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

EventNewContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  agencies: PropTypes.array.isRequired,

  createEvent: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  clearSelectedEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  agencies: state.bigFive.get('agencies'),
});

export default withRouter(
  connect(mapStateToProps, {
    createEvent,
    handleToastMessage,
    setPageTitle,
    clearSelectedEvent,
  })(EventNewContainer)
);
