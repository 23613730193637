/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NextIcon from '@material-ui/icons/ChevronRight';
import DownIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';

import { getSessionsForAgent, getTicketsForAgent } from './agents.actions';
import LoadingInlay from '../layout/loadingInlay.component';
import Colors from '../../styles/colors';
import DownlineAgentTickets from './downlineAgentTickets.component';

class DownlineAgent extends Component {
  state = {
    isExpanded: false,
    isLoading: false,
    events: []
  };

  toggleExpansion = async () => {
    const { agent } = this.props;

    if (!this.state.isExpanded) {
      this.setState({ isExpanded: true });
      if (agent.hasTicket) {
        // need to load items here
        this.setState({ isLoading: true });
        const [ticketsResult, sessionsResult] = await Promise.all([
          this.props.getTickets(agent.email),
          this.props.getSessions(agent.email)
        ]);

        // console.log('TICKETS?', ticketsResult);
        // console.log('SESSIONS?', sessionsResult);

        if (ticketsResult.response.data) {
          const events = ticketsResult.response.data.map(ticket => {
            return { ...ticket.event, sessions: [] };
          });
          if (sessionsResult.response.data) {
            sessionsResult.response.data.forEach(session => {
              const event = events.find(e => e.id === session.slot.eventId);
              // if this fails it means you somehow have signed up for a breakout session
              // during an event that you don't have a ticket for
              if (event) {
                event.sessions.push(session);
              }
            });
          }

          this.setState({ events });
        }
      } else {
        agent.events = [];
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({ isExpanded: false });
    }
  };

  getAgentName = agent => {
    if (!agent.children || agent.children.length === 0) {
      return agent.name;
    }

    return `${agent.name} (${agent.children.length})`;
  };

  render() {
    const {
      classes,
      agent,
      isFirstTier,
      isEvenRow,
      getTickets,
      getSessions,
      isEventSelected
    } = this.props;
    const { isExpanded, isLoading, events } = this.state;

    const hasChildren = agent.children && agent.children.length > 0;
    const canToggleExpansion = hasChildren || isEventSelected;

    return (
      <div
        className={classNames(!isFirstTier && classes.container)}
        style={{ backgroundColor: isEvenRow ? Colors.grayLight : Colors.white }}
      >
        <div
          className={classes.expansionWrapper}
          onClick={canToggleExpansion ? this.toggleExpansion : null}
        >
          <IconButton color="inherit" aria-label="Back" disabled={!canToggleExpansion}>
            {isExpanded ? <DownIcon /> : <NextIcon />}
          </IconButton>
          <div
            className={classNames(
              isExpanded && classes.agentExpanded,
              (agent.hasTicket || agent.hasTicketInDownline) && classes.hasTicket
            )}
          >
            {this.getAgentName(agent)}
          </div>
        </div>

        {isExpanded && (
          <div className={classes.childrenWrapper}>
            {isLoading ? (
              <LoadingInlay classes={{ progressIndicator: classes.progressIndicator }} />
            ) : (
              <Fragment>
                <DownlineAgentTickets
                  events={events}
                  agent={agent}
                  isEventSelected={isEventSelected}
                />
                {hasChildren && <h5 className={classes.downlineHeading}>Downline</h5>}
                {agent.children &&
                  agent.children.map((child, i) => (
                    <div key={child.code} className={classes.childWrapper}>
                      <DownlineAgent
                        classes={classes}
                        agent={child}
                        isEvenRow={i % 2 === 0}
                        getTickets={getTickets}
                        getSessions={getSessions}
                        isEventSelected={isEventSelected}
                      />
                    </div>
                  ))}
              </Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

const styles = () => ({
  container: {
    paddingLeft: '1rem'
  },
  expansionWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '0.9rem'
  },
  agentExpanded: {
    fontWeight: 'bold'
  },
  hasTicket: {
    color: Colors.primary,
    fontWeight: 'bold'
  },
  childrenWrapper: {
    marginBottom: '1rem'
  },
  progressIndicator: {
    width: '25%'
  },
  downlineHeading: {
    paddingLeft: '3rem',
    marginBottom: '0.5rem'
  },
  childWrapper: {
    padding: '0 1rem 0.5rem 1rem'
  }
});

DownlineAgent.defaultProps = {
  isFirstTier: false
};

DownlineAgent.propTypes = {
  classes: PropTypes.object.isRequired,

  agent: PropTypes.object.isRequired,
  isFirstTier: PropTypes.bool,
  isEvenRow: PropTypes.bool.isRequired,
  getTickets: PropTypes.func.isRequired,
  getSessions: PropTypes.func.isRequired,
  isEventSelected: PropTypes.bool.isRequired
};

export default withStyles(styles)(
  connect(null, {
    getTickets: getTicketsForAgent,
    getSessions: getSessionsForAgent
  })(DownlineAgent)
);
