import { createSelector } from 'reselect';

const selectEventsAdmin = state => state.events.get('eventsAdmin');
const selectEventsAdminArchived = state => state.events.get('eventsAdminArchived');

// eslint-disable-next-line import/prefer-default-export
export const selectAllEventsAdmin = createSelector(
  selectEventsAdmin,
  selectEventsAdminArchived,
  (eventsAdmin, eventsAdminArchived) => {
    return [...eventsAdmin, ...eventsAdminArchived];
  }
);

export const selectEvents = state => state.events.get('events');
