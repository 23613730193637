import { isEmpty } from 'lodash';
import { CALL_API } from '../../../middleware/api';

export const GET_GOOGLE_PLACES_REQUEST = 'GET_GOOGLE_PLACES_REQUEST';
export const GET_GOOGLE_PLACES_SUCCESS = 'GET_GOOGLE_PLACES_SUCCESS';
export const GET_GOOGLE_PLACES_FAILURE = 'GET_GOOGLE_PLACES_FAILURE';

export const GET_GOOGLE_PLACE_REQUEST = 'GET_GOOGLE_PLACE_REQUEST';
export const GET_GOOGLE_PLACE_SUCCESS = 'GET_GOOGLE_PLACE_SUCCESS';
export const GET_GOOGLE_PLACE_FAILURE = 'GET_GOOGLE_PLACE_FAILURE';

export const LOOKUP_AGENTS_REQUEST = 'LOOKUP_AGENTS_REQUEST';
export const LOOKUP_AGENTS_SUCCESS = 'LOOKUP_AGENTS_SUCCESS';
export const LOOKUP_AGENTS_FAILURE = 'LOOKUP_AGENTS_FAILURE';

export const LOOKUP_ATTENDEES_REQUEST = 'LOOKUP_ATTENDEES_REQUEST';
export const LOOKUP_ATTENDEES_SUCCESS = 'LOOKUP_ATTENDEES_SUCCESS';
export const LOOKUP_ATTENDEES_FAILURE = 'LOOKUP_ATTENDEES_FAILURE';

export const LOOKUP_PURCHASES_REQUEST = 'LOOKUP_PURCHASES_REQUEST';
export const LOOKUP_PURCHASES_SUCCESS = 'LOOKUP_PURCHASES_SUCCESS';
export const LOOKUP_PURCHASES_FAILURE = 'LOOKUP_PURCHASES_FAILURE';

export const getGooglePlaces = (value, sessionToken) => {
  return {
    [CALL_API]: {
      types: [GET_GOOGLE_PLACES_REQUEST, GET_GOOGLE_PLACES_SUCCESS, GET_GOOGLE_PLACES_FAILURE],
      authenticated: true,
      endpoint: `v1/places?sessiontoken=${sessionToken}&input=${value.trim()}`,
      method: 'GET'
    }
  };
};

export const getGooglePlace = (id, sessionToken) => {
  return {
    [CALL_API]: {
      types: [GET_GOOGLE_PLACE_REQUEST, GET_GOOGLE_PLACE_SUCCESS, GET_GOOGLE_PLACE_FAILURE],
      authenticated: true,
      endpoint: `v1/places/${id}?sessiontoken=${sessionToken}`,
      method: 'GET'
    }
  };
};

// You can pass a *type* query param: "upline", "downline", "manager", "direct", "all"
// An optional *query* query param, which is the name or email you want to search on
// An optional *agentCode* query param, which is useful if you're an admin and want to search around a specific agency

export const lookupAgents = (searchType, query, agentCode, owner = false) => {
  let endpoint = `v1/agents?type=${searchType}`;

  if (!isEmpty(query)) {
    endpoint += `&query=${query}`;
  }

  if (!isEmpty(agentCode)) {
    endpoint += `&agentCode=${agentCode}`;
  }

  if (owner === true) {
    endpoint += `&owner=true`;
  }

  // default to list mode here
  endpoint += '&mode=list';

  return {
    [CALL_API]: {
      types: [LOOKUP_AGENTS_REQUEST, LOOKUP_AGENTS_SUCCESS, LOOKUP_AGENTS_FAILURE],
      authenticated: true,
      endpoint,
      method: 'GET'
    }
  };
};

export const lookupAttendees = (query, eventId) => {
  let endpoint = `v1/attendees`;

  if (!isEmpty(query)) {
    endpoint += `?query=${encodeURIComponent(query)}`;
  }

  if (!isEmpty(eventId)) {
    endpoint += `&eventId=${eventId}`;
  }

  return {
    [CALL_API]: {
      types: [LOOKUP_ATTENDEES_REQUEST, LOOKUP_ATTENDEES_SUCCESS, LOOKUP_ATTENDEES_FAILURE],
      authenticated: true,
      endpoint,
      method: 'GET'
    }
  };
};
export const lookupPurchases = (query = null, eventId) => {
  let endpoint = `v1/purchases`;

  if (!isEmpty(query)) {
    endpoint += `?query=${encodeURIComponent(query)}`;
    if (!isEmpty(eventId)) {
      endpoint += `&eventId=${eventId}`;
    }
  }

  return {
    [CALL_API]: {
      types: [LOOKUP_PURCHASES_REQUEST, LOOKUP_PURCHASES_SUCCESS, LOOKUP_PURCHASES_FAILURE],
      authenticated: true,
      endpoint,
      method: 'GET'
    }
  };
};
