import PropTypes from 'prop-types';
import React from 'react';

import EventListItem from './eventListItem.component';

const EventsList = ({ events, isLoggedIn, permissions }) =>
  events.map(event => (
    <EventListItem key={event.id} event={event} isLoggedIn={isLoggedIn} permissions={permissions} />
  ));

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool,
  permissions: PropTypes.array,
};

EventsList.defaultProps = {
  isLoggedIn: false,
  permissions: [],
};

export default EventsList;
