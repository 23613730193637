import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { setPageTitle } from '../layout/layout.actions';

class Stripe extends Component {
  componentWillMount() {
    this.props.setPageTitle(`Stripe Links`);
  }

  render() {
    const { agencies } = this.props;
    return (
      <div className="container">
        {agencies.map(a => (
          <div key={a.id} style={{ fontSize: '120%' }}>
            {'stripeSetupLink' in a && (
              <div>
                <p>
                  Click below to setup or manage your Stripe Connect account. This is how your
                  agency will receive payment for event registrations.
                </p>
                <SFGOutlinedButton href={a.stripeSetupLink} target="_blank">
                  Manage Stripe Connect
                </SFGOutlinedButton>
              </div>
            )}
            {'stripeLink' in a && (
              <div>
                <p>
                  Click below to view your Stripe Dashboard. This is where you can view and manage
                  payments.
                </p>
                <br />
                <SFGOutlinedButton href={a.stripeLink} target="_blank">
                  Stripe Dashboard
                </SFGOutlinedButton>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

Stripe.propTypes = {
  agencies: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    agencies: state.bigFive.get('agencies'),
  };
};

export default connect(mapStateToProps, {
  setPageTitle,
})(Stripe);
