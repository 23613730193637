import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import memoize from 'memoize-one';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import EventFormHelper from '../../utilities/eventFormHelper';
import GooglePlacesAutoComplete from '../layout/autocomplete/googlePlacesAutocomplete.container';
import RichEditor from '../layout/richEditor/richEditor.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import VenueMapUrlsInput from './venueMapUrlsInput.component';

const styles = {
  spacerBottom: {
    marginBottom: '2rem',
  },
  editorWrapper: {
    border: `1px solid rgba(0, 0, 0, 0.87)`,
    width: '350px',
    height: '650px',
    overflow: 'auto',
  },
};

class EventForm extends Component {
  constructor(props) {
    super(props);
    this.state = EventFormHelper.initEventState(props.event);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.setState(EventFormHelper.initEventState(this.props.event));
    }
  }

  getRegistrationURL = memoize(event => {
    if (!event) return '';

    return `${process.env.REACT_APP_USER_BASE_URL}/events/${event.id}/registration`;
  });

  isFormValid = () => {
    const requiredFields = [
      'name',
      'startDate',
      'endDate',
      'locationName',
      'summary',
      'headerGraphicUrl',
      'previewGraphicUrl',
      'daysBeforeRegistrationOpens',
    ];
    const positiveIntegerFields = ['daysBeforeRegistrationOpens'];
    const invalidFields = requiredFields
      .filter(field => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(this.state[field]) || this.state[field] === '') return isEmpty(this.state[field]);
        // If this is a number, it is valid.
        return false;
      })
      .concat(
        positiveIntegerFields.filter(field => {
          const val = this.state[field];
          // Check if the field is set
          if (!val) return true;
          // Check if the field is an integer
          if (!Number.isInteger(Number(val))) return true;
          // Check if the number is positive and greater than 0.
          return Number.parseFloat(val) <= 0;
        })
      );
    const numInvalidFields = invalidFields.length + this.venueMapUrlIsInvalid();

    return !(numInvalidFields > 0);
  };

  venueMapUrlIsInvalid = () => {
    // All urls need to have values
    const blankUrlIndex = this.state.venueMapUrls.findIndex(venueMapUrl => {
      return venueMapUrl.url === '';
    });
    return blankUrlIndex !== -1;
  };

  handleSubmit = e => {
    e.preventDefault();

    const eventData = EventFormHelper.prepareEventData(this.state);
    // console.log('EVENT DATA!', eventData);

    this.props.submitAction(eventData);
  };

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
  };

  render() {
    const { agencies, classes, event } = this.props;
    const eventRegistrationURL = this.getRegistrationURL(event);

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs>
            <div className={classes.spacerBottom}>
              <TextField
                label="Event Name"
                type="text"
                margin="normal"
                value={this.state.name}
                onChange={e => this.updateField('name', e.target.value)}
                fullWidth
                required
              />
            </div>
            <FormControl>
              <InputLabel htmlFor="agencyId">Agency</InputLabel>
              <Select
                inputProps={{ name: 'agencyId', id: 'agencyId' }}
                onChange={e => this.updateField('agencyId', e.target.value)}
                value={this.state.agencyId}
                required
              >
                {agencies &&
                  agencies.map(a => (
                    <MenuItem key={a.id} value={a.id}>
                      {a.owner.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>Select SFGAGENCY to create an event for all of SFG.</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs>
            {eventRegistrationURL !== '' && (
              // eslint-disable-next-line react/jsx-fragments
              <Fragment>
                <Typography style={{ paddingTop: '1rem', paddingBottom: '0.25rem' }}>
                  Event Registration URL
                </Typography>
                {eventRegistrationURL}
              </Fragment>
            )}

            {!isEmpty(event) && (
              // eslint-disable-next-line react/jsx-fragments
              <Fragment>
                <Typography style={{ marginTop: '1rem' }}>
                  {`Tickets Purchased: ${event.ticketsPurchased}`}
                </Typography>
                <Typography>{`Tickets Assigned: ${event.ticketsAssigned}`}</Typography>
                <Typography>{`Tickets Canceled: ${event.ticketsCanceled}`}</Typography>
              </Fragment>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs>
            <TextField
              label="Start Date"
              helperText="All dates are relative to EST"
              type="date"
              margin="normal"
              value={this.state.startDate}
              onChange={e => this.updateField('startDate', e.target.value)}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label="End Date"
              helperText="All dates are relative to EST"
              type="date"
              margin="normal"
              value={this.state.endDate}
              onChange={e => this.updateField('endDate', e.target.value)}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.isDraft}
                  onChange={() => {
                    this.setState(prevState => ({ isDraft: !prevState.isDraft }));
                  }}
                  value="isDraft"
                  color="primary"
                />
              }
              label="Event Is Draft"
            />
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs>
            <TextField
              label="Location Name"
              type="text"
              margin="normal"
              value={this.state.locationName}
              onChange={e => this.updateField('locationName', e.target.value)}
              fullWidth
              required
            />

            <TextField
              label="Header Graphic Url"
              type="text"
              margin="normal"
              value={this.state.headerGraphicUrl}
              onChange={e => this.updateField('headerGraphicUrl', e.target.value)}
              fullWidth
              required
            />

            <TextField
              label="Preview Graphic Url"
              type="text"
              margin="normal"
              value={this.state.previewGraphicUrl}
              onChange={e => this.updateField('previewGraphicUrl', e.target.value)}
              fullWidth
              required
            />

            <TextField
              label="Event Summary"
              type="text"
              margin="normal"
              value={this.state.summary}
              onChange={e => this.updateField('summary', e.target.value)}
              fullWidth
              required
            />

            <TextField
              label="Number of Days Before Event to Open Registration"
              type="number"
              margin="normal"
              value={this.state.daysBeforeRegistrationOpens}
              onChange={e => this.updateField('daysBeforeRegistrationOpens', e.target.value)}
              fullWidth
              helperText="Must be a positive integer"
              required
            />

            <TextField
              label="Booking Url"
              type="text"
              margin="normal"
              value={this.state.bookingUrl}
              onChange={e => this.updateField('bookingUrl', e.target.value)}
              fullWidth
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.allowGeoCheckin}
                  onChange={() => {
                    this.setState(prevState => ({ allowGeoCheckin: !prevState.allowGeoCheckin }));
                  }}
                  value="allowGeoCheckin"
                  color="primary"
                />
              }
              label="Allow Geo Location Checkin"
            />

            <GooglePlacesAutoComplete
              initialValue={this.state.address}
              label="Event Address (autocomplete)"
              placeholder="Enter an address and select from the list"
              onSelect={suggestion => {
                // console.log('SELECTION!', suggestion);
                this.updateField('address', suggestion.description);
              }}
              setCoordinates={coordinates => {
                this.updateField('coordinates', coordinates);
              }}
            />

            <TextField
              label="Location Description"
              placeholder="(e.g. first floor lobby)"
              type="text"
              margin="normal"
              value={this.state.locationDescription}
              onChange={e => this.updateField('locationDescription', e.target.value)}
              fullWidth
            />

            <TextField
              label="Location Graphic Url"
              type="text"
              margin="normal"
              value={this.state.locationGraphicUrl}
              onChange={e => this.updateField('locationGraphicUrl', e.target.value)}
              fullWidth
            />

            <VenueMapUrlsInput
              handleUpdate={this.updateField}
              venueMapUrls={this.state.venueMapUrls}
            />

            <TextField
              label="Event Details Url"
              type="text"
              margin="normal"
              value={this.state.eventDetailsUrl}
              onChange={e => this.updateField('eventDetailsUrl', e.target.value)}
              fullWidth
            />

            <TextField
              label="External Registration Url"
              type="text"
              margin="normal"
              value={this.state.externalRegistrationUrl}
              onChange={e => this.updateField('externalRegistrationUrl', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.hasExternalTicketAssignments}
                  onChange={() => {
                    this.setState(prevState => ({
                      hasExternalTicketAssignments: !prevState.hasExternalTicketAssignments,
                    }));
                  }}
                  value="hasExternalTicketAssignments"
                  color="primary"
                />
              }
              label="Event Has External Ticket Assignments"
            />

            <Typography variant="body2" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
              Welcome Message
            </Typography>
            <div className={classes.editorWrapper}>
              <RichEditor
                placeholder="Welcome Message"
                editorState={this.state.welcomeMessage}
                updateEditorState={editorState => this.setState({ welcomeMessage: editorState })}
              />
            </div>

            <Typography variant="body2" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
              Travel Details
            </Typography>
            <div className={classes.editorWrapper}>
              <RichEditor
                placeholder="Travel details"
                editorState={this.state.travelDetails}
                updateEditorState={editorState => this.setState({ travelDetails: editorState })}
              />
            </div>
          </Grid>
        </Grid>

        <div style={{ marginTop: '4rem' }}>
          <SFGOutlinedButton
            color="primary"
            disabled={!this.isFormValid()}
            type="submit"
            onClick={this.handleSubmit}
          >
            Submit
          </SFGOutlinedButton>
        </div>
      </form>
    );
  }
}

EventForm.defaultProps = {
  event: null,
};

EventForm.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object,
  agencies: PropTypes.array.isRequired,

  submitAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(EventForm);
