import { createSelector } from 'reselect';

export const selectSelectedEvent = createSelector(
  state => state.events.get('selectedEvent'),
  selectedEvent => selectedEvent
);

export const selectEventsIsLoading = createSelector(
  state => state.events.get('isLoading'),
  isLoading => isLoading
);
