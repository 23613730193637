import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TicketIcon from '@material-ui/icons/LocalPlay';
import moment from 'moment';
import { Card, CardActions, CardHeader, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import CheckinDialog from './checkinDialog.component';
import Colors from '../../styles/colors';
import FullScreenDialog from '../layout/dialogs/fullScreenDialog.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGPrimaryButton from '../../common/buttons/sfgPrimaryButton.component';
import ScheduleList from '../mySchedule/scheduleList.component';
import { isEventLive } from '../../utilities/eventStatus';
import { resetMySchedule } from '../mySchedule/mySchedule.actions';

const DATE_FORMAT = 'MMMM Do, YYYY';

class Ticket extends Component {
  state = {
    isSessionsDialogOpen: false,
    isMyScheduleDialogOpen: false,
    isCheckinDialogOpen: false,
  };

  toggleSessionsDialog = () => {
    this.setState(prevState => ({ isSessionsDialogOpen: !prevState.isSessionsDialogOpen }));
  };

  toggleMyScheduleDialog = () => {
    this.setState(
      prevState => ({ isMyScheduleDialogOpen: !prevState.isMyScheduleDialogOpen }),
      this.props.resetMySchedule
    );
  };

  toggleCheckinDialog = () => {
    this.setState(prevState => ({ isCheckinDialogOpen: !prevState.isCheckinDialogOpen }));
  };

  render() {
    const { ticket, classes } = this.props;

    const startDate = moment(ticket.event.startDate)
      .utc()
      .format(DATE_FORMAT);

    const endDate = moment(ticket.event.endDate)
      .utc()
      .format(DATE_FORMAT);

    const isLive = isEventLive(ticket.event);

    return (
      <>
        <Card className={classes.card}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
            }}
            title={
              <div className={classes.title}>
                <TicketIcon className={classes.ticketIcon} color="primary" />
                <Typography color="primary" variant="title" className={classes.titleText}>
                  {ticket.event.name}
                </Typography>
              </div>
            }
            subheader={
              <div className={classes.subheader}>
                <Typography variant="subheading" className={classes.subheaderText}>
                  {startDate}
                </Typography>
                <Typography variant="subheading" className={classes.subheaderText}>
                  {endDate}
                </Typography>
              </div>
            }
          />
          <CardActions className={classes.cardActions}>
            <SFGOutlinedButton color="primary" onClick={this.toggleSessionsDialog}>
              Breakout Sessions
            </SFGOutlinedButton>
            <SFGOutlinedButton color="primary" onClick={this.toggleMyScheduleDialog}>
              My Schedule
            </SFGOutlinedButton>
            <SFGPrimaryButton color="primary" onClick={this.toggleCheckinDialog} disabled={!isLive}>
              Check In
            </SFGPrimaryButton>
          </CardActions>
        </Card>

        <FullScreenDialog
          open={this.state.isSessionsDialogOpen}
          headingText={`Breakout Sessions - ${ticket.event.name}`}
          cancelText="Close"
          dialogContentElem={<ScheduleList eventId={ticket.event.id} isBreakouts />}
          onDialogClose={this.toggleSessionsDialog}
        />

        <FullScreenDialog
          open={this.state.isMyScheduleDialogOpen}
          headingText="My Schedule"
          cancelText="Close"
          dialogContentElem={
            <ScheduleList eventId={ticket.event.id} eventName={ticket.event.name} />
          }
          onDialogClose={this.toggleMyScheduleDialog}
        />

        <CheckinDialog
          isOpen={this.state.isCheckinDialogOpen}
          ticket={this.props.ticket}
          onClose={this.toggleCheckinDialog}
        />
      </>
    );
  }
}

const styles = theme => ({
  card: {
    cursor: 'pointer',
    marginBottom: theme.spacing.unit * 4,
    border: `${Colors.grayMediumLight} solid 1px`,
    borderRadius: 'unset',
    boxShadow: 'none',
  },
  cardHeaderRoot: {
    padding: theme.spacing.unit * 2,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  ticketIcon: {
    fontSize: '2.5rem',
  },
  titleText: {
    flex: '1',
    margin: '0 1rem',
    fontSize: '2rem',
  },
  subheader: {
    margin: '0.5rem -1rem',
    padding: '0.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: Colors.grayLight,
  },
  cardActions: {
    justifyContent: 'space-between',
  },
});

Ticket.propTypes = {
  classes: PropTypes.object.isRequired,

  ticket: PropTypes.object.isRequired,

  resetMySchedule: PropTypes.func.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const connectedTicketCard = connect(mapStateToProps, {
  resetMySchedule,
})(Ticket);

export default withStyles(styles)(connectedTicketCard);
