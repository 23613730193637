import * as Sentry from '@sentry/react';

import { CALL_API } from '../../middleware/api';

export const LOG_OUT = 'LOG_OUT';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_INITIAL_LOAD_COMPLETE = 'SET_INITIAL_LOAD_COMPLETE';

export const GET_HEALTH_REQUEST = 'GET_HEALTH_REQUEST';
export const GET_HEALTH_SUCCESS = 'GET_HEALTH_SUCCESS';
export const GET_HEALTH_FAILURE = 'GET_HEALTH_FAILURE';

export const CHECK_USER_REQUEST = 'CHECK_USER_REQUEST';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'CHECK_USER_FAILURE';

export const LOGIN_AUTH_0_REQUEST = 'LOGIN_AUTH_0_REQUEST';
export const LOGIN_AUTH_0_SUCCESS = 'LOGIN_AUTH_0_SUCCESS';
export const LOGIN_AUTH_0_FAILURE = 'LOGIN_AUTH_0_FAILURE';

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const TOGGLE_USER_VIEW = 'TOGGLE_USER_VIEW';
export const SET_NON_ADMIN_EMAIL = 'SET_NON_ADMIN_EMAIL';
export const SET_EMAIL_TO_VERIFY = 'SET_EMAIL_TO_VERIFY';
export const SET_SHOW_VERIFY_ACTION = 'SET_SHOW_VERIFY_ACTION';

export const RESEND_VERIFICATION_EMAIL_REQUEST = 'RESEND_VERIFICATION_EMAIL_REQUEST';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_FAILURE = 'RESEND_VERIFICATION_EMAIL_FAILURE';

export const IMPERSONATE_USER_REQUEST = 'IMPERSONATE_USER_REQUEST';
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS';
export const IMPERSONATE_USER_FAILURE = 'IMPERSONATE_USER_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

/* Define a login state to use across the app. */
export const LOGIN_STATE = {
  NOT_LOGGED_IN: 0,
  STANDARD_USER: 2,
  ALTITUDE_USER: 3,
  SYSTEM_ADMIN: 4,
};

export const logout = () => {
  Sentry.setUser(null);
  return {
    type: LOG_OUT,
  };
};

export const setInitialLoadComplete = initialLoadComplete => {
  return {
    type: SET_INITIAL_LOAD_COMPLETE,
    payload: initialLoadComplete,
  };
};

export const setUserEmail = email => {
  return {
    type: SET_USER_EMAIL,
    payload: email,
  };
};

export const defineLoggedIn = (status = LOGIN_STATE.NOT_LOGGED_IN) => {
  return {
    type: SET_LOGGED_IN,
    payload: status,
  };
};

export const setLoggedIn = status => {
  return dispatch => {
    return dispatch(defineLoggedIn(status));
  };
};

export const getHealth = () => {
  return {
    [CALL_API]: {
      types: [GET_HEALTH_REQUEST, GET_HEALTH_SUCCESS, GET_HEALTH_FAILURE],
      authenticated: true,
      endpoint: `health`,
      method: 'GET',
    },
  };
};

export const checkUser = email => {
  return {
    [CALL_API]: {
      types: [CHECK_USER_REQUEST, CHECK_USER_SUCCESS, CHECK_USER_FAILURE],
      authenticated: false,
      endpoint: 'v1/auth/checkUser',
      method: 'POST',
      payload: {
        email,
      },
    },
  };
};

export const loginAuth0 = accessToken => {
  return {
    [CALL_API]: {
      types: [LOGIN_AUTH_0_REQUEST, LOGIN_AUTH_0_SUCCESS, LOGIN_AUTH_0_FAILURE],
      authenticated: false,
      endpoint: 'v1/auth/login',
      method: 'POST',
      payload: {
        accessToken,
      },
    },
  };
};

export const createAccount = ({ email, password }) => {
  return {
    [CALL_API]: {
      types: [CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE],
      authenticated: false,
      endpoint: 'v1/auth/register',
      method: 'POST',
      payload: {
        email,
        password,
      },
    },
  };
};

export const setEmailToVerify = email => {
  return {
    type: SET_EMAIL_TO_VERIFY,
    payload: email,
  };
};

export const setShowVerifyAction = flag => {
  return {
    type: SET_SHOW_VERIFY_ACTION,
    payload: flag,
  };
};

export const resendVerification = ({ email }) => {
  return {
    [CALL_API]: {
      types: [
        RESEND_VERIFICATION_EMAIL_REQUEST,
        RESEND_VERIFICATION_EMAIL_SUCCESS,
        RESEND_VERIFICATION_EMAIL_FAILURE,
      ],
      authenticated: false,
      endpoint: 'v1/auth/resendverification',
      method: 'POST',
      payload: {
        email,
      },
    },
  };
};

export const impersonate = email => {
  return {
    [CALL_API]: {
      types: [IMPERSONATE_USER_REQUEST, IMPERSONATE_USER_SUCCESS, IMPERSONATE_USER_FAILURE],
      authenticated: false,
      endpoint: 'v1/auth/impersonate',
      method: 'POST',
      payload: {
        email,
      },
    },
  };
};

export const resetPassword = email => {
  return {
    [CALL_API]: {
      types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
      authenticated: false,
      endpoint: 'v1/auth/passwordreset',
      method: 'POST',
      payload: {
        email,
      },
    },
  };
};

export const setNonAdminEmail = email => {
  return {
    type: SET_NON_ADMIN_EMAIL,
    payload: email,
  };
};
