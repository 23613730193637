import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormControl, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { isEmpty, isNil } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import AllAgentsAutocomplete from '../layout/autocomplete/allAgentsAutocomplete.component';
import SFGAgentAutocompleteValidator from '../../common/formFields/sfgAgentAutocompleteValidator.component';
import states from '../../utilities/states';

const PurchaserInfo = ({ state, updateField, classes, newRegistration, isShowSpouseField }) => {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography variant="subheading" className={classes.subheading}>
          Personal Information
        </Typography>
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextValidator
          label="First Name"
          name="firstName"
          type="text"
          margin="normal"
          value={state.firstName}
          onChange={e => updateField('firstName', e.target.value)}
          className={classes.formControl}
          validators={['required', 'minStringLength:2']}
          errorMessages={['First Name is required', 'This must be at least two characters']}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          label="Middle Initial"
          name="middleName"
          type="text"
          inputProps={{
            maxLength: 1,
          }}
          margin="normal"
          value={state.middleName}
          onChange={e => updateField('middleName', e.target.value)}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextValidator
          label="Last Name"
          name="lastName"
          type="text"
          margin="normal"
          value={state.lastName}
          onChange={e => updateField('lastName', e.target.value)}
          className={classes.formControl}
          validators={['required', 'minStringLength:2']}
          errorMessages={['Last Name is required', 'This must be at least two characters']}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.formControl} style={{ marginTop: '1rem' }}>
          <SelectValidator
            label="Suffix"
            value={state.suffix}
            name="suffix"
            onChange={e => updateField('suffix', e.target.value)}
            inputProps={{
              name: 'suffix',
            }}
            className={classes.formControl}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Sr.">Sr.</MenuItem>
            <MenuItem value="Jr.">Jr.</MenuItem>
            <MenuItem value="III">III</MenuItem>
            <MenuItem value="IV">IV</MenuItem>
          </SelectValidator>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl} style={{ marginTop: '1rem' }}>
          <SFGAgentAutocompleteValidator
            className={classes.formControl}
            errorMessages={['Agent is required']}
            label="Agent"
            name="agencyManagerId"
            validators={['required']}
            value={
              state.agencyManager
                ? `${state.agencyManager.name} - ${state.agencyManager.email}`
                : state.agencyManagerId
            }
            onChange={value => updateField('agencyManagerId', value)}
            onInputClear={() => updateField('agencyManagerId', null)}
            helperText="Person who referred you to this event (any Quility agent). Type slowly and choose from the list."
          />
        </FormControl>
      </Grid>

      {isShowSpouseField === true && (
        // eslint-disable-next-line react/jsx-fragments
        <Fragment>
          <Grid item xs={12}>
            <Typography variant="subheading" className={classes.subheading}>
              Spouse Information
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <AllAgentsAutocomplete
              className={classes.formControl}
              helperText="Registrant Spouse's Name"
              label="Spouse Name"
              name="spouseAgentCode"
              value={
                !isEmpty(state.spouse) && !isNil(state.spouseAgentCode)
                  ? `${state.spouse.name} - ${state.spouse.email}`
                  : state.spouseAgentCode
              }
              onChange={value => updateField('spouseAgentCode', value)}
              onInputClear={() => updateField('spouseAgentCode', null)}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <Typography variant="subheading" className={classes.subheading}>
          Address &amp; Contact Information
        </Typography>
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextValidator
          label="Address"
          name="Address"
          type="text"
          margin="normal"
          value={state.address}
          onChange={e => updateField('address', e.target.value)}
          validators={['required', 'minStringLength:5']}
          errorMessages={['Address is required', 'An address must be at least 5 characters']}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextValidator
          label="Address Line 2"
          name="Address Line 2"
          type="text"
          margin="normal"
          value={state.address2}
          onChange={e => updateField('address2', e.target.value)}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextValidator
          label="City"
          name="City"
          type="text"
          margin="normal"
          value={state.city}
          onChange={e => updateField('city', e.target.value)}
          validators={['required']}
          errorMessages={['City is required']}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.formControl} style={{ marginTop: '1rem' }}>
          <SelectValidator
            value={state.state}
            label="State"
            name="state"
            onChange={e => updateField('state', e.target.value)}
            inputProps={{
              name: 'state',
            }}
            validators={['required']}
            errorMessages={['State is required']}
          >
            {Object.keys(states).map(s => (
              <MenuItem key={states[s]} value={states[s]}>
                {s}
              </MenuItem>
            ))}
          </SelectValidator>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextValidator
          label="Zip"
          name="Zip"
          type="text"
          margin="normal"
          value={state.zip}
          onChange={e => updateField('zip', e.target.value)}
          validators={['required', 'matchRegexp:^[\\-0-9]+$', 'minStringLength:5']}
          errorMessages={[
            'Zip code is required',
            'A zip code may only contain numbers and dashes',
            'A zip code must be at least 5 numbers',
          ]}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextValidator
          label="Phone"
          name="Phone"
          type="text"
          margin="normal"
          value={state.phone}
          onChange={e => updateField('phone', e.target.value)}
          validators={['required', 'minStringLength:9', 'matchRegexp:^[\\+\\-0-9]+$']}
          errorMessages={[
            'Phone number is required',
            'A phone number must be at least 9 digits',
            'Invalid characters in phone number',
          ]}
          className={classes.formControl}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextValidator
          label="Email"
          name="Email"
          type="email"
          helperText="Are you an agent? Please make sure to use your QuilityHQ email!"
          margin="normal"
          value={state.email}
          onChange={e => updateField('email', e.target.value.toLowerCase())}
          validators={['required', 'isEmail']}
          errorMessages={['Email is required', 'Please enter a valid email address.']}
          className={classes.formControl}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        {newRegistration && (
          <TextValidator
            label="Confirm Email"
            name="Confirm Email"
            type="email"
            margin="normal"
            value={state.confirmEmail}
            onChange={e => updateField('confirmEmail', e.target.value.toLowerCase())}
            validators={['required', 'isSameEmail']}
            errorMessages={['Email confirmation is required', "The email addresses don't match."]}
            className={classes.formControl}
          />
        )}
      </Grid>
    </Grid>
  );
};

const styles = (/* theme */) => ({
  formControl: {
    minWidth: 140,
    width: '100%',
  },
  subheading: {
    marginTop: '1.5rem',
    marginBottom: '0',
  },
});

PurchaserInfo.defaultProps = {
  newRegistration: false,
  isShowSpouseField: false,
};

PurchaserInfo.propTypes = {
  classes: PropTypes.object.isRequired,

  state: PropTypes.object.isRequired,
  newRegistration: PropTypes.bool,
  isShowSpouseField: PropTypes.bool,
  updateField: PropTypes.func.isRequired,
};

export default withStyles(styles)(PurchaserInfo);
