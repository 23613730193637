import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';

import Colors from '../../styles/colors';
import SFGPrimaryButton from '../../common/buttons/sfgPrimaryButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';

export const GatheringsListContainer = ({ classes, gatherings, isSearch, profile }) => {
  const history = useHistory();

  const formatDateAndTime = (startDate, endDate) => {
    const momentStart = moment(startDate);
    const momentEnd = moment(endDate);

    const startMonth = momentStart.format('MMMM');
    const startDay = momentStart.format('DD');
    const startTime = momentStart.format('h:mm');
    const endMonth = momentEnd.format('MMMM');
    const endDay = momentEnd.format('DD');
    const endTime = momentEnd.format('h:mm');
    const formattedEndDate =
      startDay === endDay && startMonth === endMonth
        ? endTime
        : `${endMonth} ${endDay} @ ${endTime}`;

    return `${startMonth} ${startDay} @ ${startTime} - ${formattedEndDate}`;
  };

  const handleMoreInfoClick = gathering => {
    window.open(gathering.url, '_blank');
  };

  const handleCreateClick = () => {
    window.open('https://calendars.sfglife.com/events/agent-event/add', '_blank');
  };

  const handleCheckIn = gatheringId => history.push(`/events/${gatheringId}/checkIn`);

  return (
    <>
      <Grid container spacing={16}>
        {gatherings.map(gathering => {
          let shouldShowCheckin = false;

          if (!isNil(profile))
            gathering.organizers.forEach(organizer => {
              if (organizer.email === profile.email) shouldShowCheckin = true;
            });

          return (
            <Grid key={gathering.id} className={classes.gridItem} item md={4} sm={6} xs={12}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  classes={{ img: classes.mediaImg }}
                  component="img"
                  image={gathering.image}
                />
                <CardContent className={classes.content}>
                  <Typography
                    color="inherit"
                    variant="subheading"
                    // These have HTML encoded characters in them, so need to have them appear as html and not regular text
                    dangerouslySetInnerHTML={{ __html: gathering.title }}
                  />
                  <Typography color="inherit" variant="body1">
                    Where: location
                  </Typography>
                  {/* TODO: Change timezone to abbreviations */}
                  <Typography color="inherit" variant="body1">
                    {`${formatDateAndTime(gathering.startTime, gathering.endTime)} ${
                      gathering.timezone
                    }`}
                  </Typography>
                </CardContent>
                <CardActions className={classes.actionsContainer}>
                  {shouldShowCheckin && (
                    <SFGTextButton
                      className={classes.action}
                      onClick={() => handleCheckIn(gathering.id)}
                    >
                      Check In
                    </SFGTextButton>
                  )}
                  {gathering.url && (
                    <SFGTextButton
                      className={classes.action}
                      color="primary"
                      onClick={() => handleMoreInfoClick(gathering)}
                    >
                      More Info?
                    </SFGTextButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
        {isSearch && gatherings.length === 0 && (
          <Grid item xs={12} className={classes.emptyContainer}>
            <Typography align="center" variant="headline">
              No gatherings found
            </Typography>
          </Grid>
        )}
      </Grid>
      <div className={classes.createPromptWrapper}>
        <SFGPrimaryButton color="primary" onClick={handleCreateClick}>
          Create Local Event
        </SFGPrimaryButton>
      </div>
    </>
  );
};

const styles = theme => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    border: `${Colors.grayMediumLight} solid 1px`,
    borderRadius: 'unset',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 345, // Abitrary and can be adjusted
  },
  media: {
    position: 'relative',
    '&:after': {
      display: 'block',
      content: '',
      /* 16:9 aspect ratio */
      paddingBottom: '56.25%',
    },
  },
  mediaImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  content: {
    flex: 1,
  },
  createPromptWrapper: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  actionsContainer: {
    justifyContent: 'space-between',
  },
  action: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  emptyContainer: {
    marginBottom: '2rem',
    marginTop: '2rem',
    textAlign: 'center',
  },
});

GatheringsListContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  gatherings: PropTypes.array.isRequired,
  isSearch: PropTypes.bool.isRequired,
  profile: PropTypes.object,
};

GatheringsListContainer.defaultProps = {
  profile: null,
};

export default withStyles(styles)(GatheringsListContainer);
