import { CALL_API } from '../../middleware/api';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE';

export const GET_COUPON_REQUEST = 'GET_COUPON_REQEUST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';

export const SET_SELECTED_COUPON = 'SET_SELECTED_COUPON';
export const CLEAR_SELECTED_COUPON = 'CLEAR_SELECTED_COUPON';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCES';
export const UPDATE_COUPON_FAILURE = 'UPDATE_COUPON_FAILURE';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';

export const getCoupons = () => {
  return {
    [CALL_API]: {
      types: [GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE],
      authenticated: true,
      endpoint: `admin/coupons`,
      method: 'GET'
    }
  };
};

export const createCoupon = couponData => {
  return {
    [CALL_API]: {
      types: [CREATE_COUPON_REQUEST, CREATE_COUPON_SUCCESS, CREATE_COUPON_FAILURE],
      authenticated: true,
      endpoint: `admin/coupons`,
      method: 'POST',
      payload: couponData
    }
  };
};

export const getCoupon = couponId => {
  return {
    [CALL_API]: {
      types: [GET_COUPON_REQUEST, GET_COUPON_SUCCESS, GET_COUPON_FAILURE],
      authenticated: true,
      endpoint: `admin/coupons/${couponId}`,
      method: 'GET',
      actionMetadata: {
        couponId
      }
    }
  };
};

export const setSelectedCoupon = coupon => {
  return {
    type: SET_SELECTED_COUPON,
    response: coupon
  };
};

export const clearSelectedCoupon = () => {
  return {
    type: CLEAR_SELECTED_COUPON
  };
};

export const updateCoupon = (couponId, couponData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_COUPON_REQUEST, UPDATE_COUPON_SUCCESS, UPDATE_COUPON_FAILURE],
      authenticated: true,
      endpoint: `admin/coupons/${couponId}`,
      method: 'PUT',
      payload: couponData,
      actionMetadata: {
        couponId
      }
    }
  };
};

export const deleteCoupon = couponId => {
  return {
    [CALL_API]: {
      types: [DELETE_COUPON_REQUEST, DELETE_COUPON_SUCCESS, DELETE_COUPON_FAILURE],
      authenticated: true,
      endpoint: `admin/coupons/${couponId}`,
      method: 'DELETE',
      actionMetadata: {
        couponId
      }
    }
  };
};
