import Person from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Colors from '../../styles/colors';

const ProfileCard = ({ classes, profile }) => (
  <Card className={classes.card}>
    <CardHeader
      title={<Typography variant="title">{profile.name}</Typography>}
      subheader={
        <Typography className={classes.subheading} variant="subheading">
          {profile.email}
        </Typography>
      }
      avatar={<Person />}
    />
    {(profile.agencyOwner === true ||
      profile.ownerCircle === true ||
      profile.altEmails.length > 0) && (
      <CardContent>
        <Grid container spacing={8}>
          {profile.agencyOwner === true && (
            <Grid item xs={12}>
              <Typography variant="display1" className={classes.display1}>
                Agency Owner
              </Typography>
            </Grid>
          )}
          {profile.ownerCircle === true && (
            <Grid item xs={12}>
              <Typography variant="display1" className={classes.display1}>
                Owners Circle Member
              </Typography>
            </Grid>
          )}
          {profile.altEmails.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="display1" className={classes.display1}>
                Alternate Email: whois@gmail.com
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    )}
  </Card>
);

const styles = (/* theme */) => ({
  card: {
    border: `${Colors.grayMediumLight} solid 1px`,
    borderRadius: 'unset',
    boxShadow: 'none',
  },
  subheading: {
    fontSize: '0.75rem',
  },
  display1: {
    fontSize: '1.1rem',
  },
});

ProfileCard.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileCard);
