import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, withStyles } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';

const TicketDelegateForm = ({ classes, email, onChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <TextValidator
        errorMessages={['Email address is required', 'Must enter a valid email address.']}
        fullWidth
        label="Email Address"
        margin="normal"
        name="email"
        validators={['required', 'isEmail']}
        value={email}
        onChange={onChange}
      />
    </FormControl>
  );
};

const styles = () => ({
  formControl: {
    maxWidth: '25rem',
    width: '100%',
  },
});

TicketDelegateForm.propTypes = {
  classes: PropTypes.object.isRequired,

  email: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TicketDelegateForm);
