import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Button, withStyles } from '@material-ui/core';

const SFGPrimaryButton = ({ classes, className, children, onClick, disabled, ...props }) => (
  <Button
    className={classNames(classes.button, className)}
    color="primary"
    variant="contained"
    disabled={disabled}
    onClick={disabled ? null : onClick}
    {...props}
  >
    {children}
  </Button>
);

const styles = {
  button: {
    borderRadius: 'unset',
    boxShadow: 'none',
  },
};

SFGPrimaryButton.defaultProps = {
  className: '',
  onClick: null,
  disabled: false,
};

SFGPrimaryButton.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(SFGPrimaryButton);
