import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import { DELETE_COUPON_SUCCESS, deleteCoupon, getCoupons } from './coupons.actions';
import { formatDate, utcToLocal } from '../../utilities/convertTimes';
import { getEvents } from '../events/events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class CouponsContainer extends Component {
  async componentDidMount() {
    this.props.setPageTitle('Coupons');
    // events could have been archived/unarchived after logging in so we need to
    // get the latest here
    await this.props.getEvents();
    this.props.getCoupons();
  }

  removeCoupon = async (id, index) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`Are you sure you want to remove coupon: ${this.props.coupons[index].code}?`)
    ) {
      const response = await this.props.deleteCoupon(id);
      if (response.type === DELETE_COUPON_SUCCESS) {
        this.props.handleToastMessage('Coupon deleted.');
      } else {
        this.props.handleToastMessage('Failed to delete coupon', true);
      }
    }
  };

  handleViewCoupon = coupon => {
    this.props.history.push({
      pathname: `coupons/${coupon.id}`,
    });
  };

  render() {
    const { coupons } = this.props;

    return (
      <div className="container">
        <SFGOutlinedButton
          color="primary"
          variant="outlined"
          onClick={() => this.props.history.push('/couponNew')}
        >
          New Coupon
        </SFGOutlinedButton>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Coupon Code</TableCell>
              <TableCell>Event Name</TableCell>
              <TableCell>Percent Off</TableCell>
              <TableCell>Valid Dates</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon, i) => {
              return (
                <TableRow key={coupon.id}>
                  <TableCell className="clickable" onClick={() => this.handleViewCoupon(coupon)}>
                    <Typography variant="body1">{coupon.code}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{coupon.eventName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{coupon.percentOff}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {coupon.startDate}
                      &nbsp;-&nbsp;
                      {coupon.endDate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <SFGTextButton color="primary" onClick={() => this.removeCoupon(coupon.id, i)}>
                      <i className="material-icons">close</i>
                    </SFGTextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

/*
JM: Need to make use of isLoading and the Loading Inlay
*/

CouponsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  coupons: PropTypes.array.isRequired,
  // isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  getCoupons: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const events = state.events.get('events');

  /*
    Do not return coupons for events that don't exist. This would mean that the event was archived.

    Note: There are potentially issues with an event being archived, and then being unarchived
    that could surface here, but that is a very strange and rare thing to do. 

    // TODO: Convert the below filter/map to a selector
  */
  const coupons = state.coupons
    .get('coupons')
    .filter(coupon => {
      const couponEvent = events.find(event => event.id === coupon.eventId);
      return !isEmpty(couponEvent);
    })
    .map(coupon => {
      const couponEvent = events.find(event => event.id === coupon.eventId);

      return {
        ...coupon,
        endDate: formatDate(utcToLocal(coupon.endDate), false),
        eventName: couponEvent.name,
        startDate: formatDate(utcToLocal(coupon.startDate), false),
      };
    });

  return {
    coupons,
    // isLoading: state.coupons.get('isLoading'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getEvents,
    getCoupons,
    deleteCoupon,
  })(CouponsContainer)
);
