import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { TablePagination } from '@material-ui/core';

import { getOwner } from '../../utilities/notificationHelper';

class NotificationsTable extends Component {
  state = {
    rowsPerPage: 10,
    page: 0,
  };

  renderTableCell = (notification, content) => {
    return (
      <TableCell className="clickable" onClick={() => this.props.editNotification(notification)}>
        <Typography variant="body1">{content}</Typography>
      </TableCell>
    );
  };

  render() {
    const { notifications } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="25%">Title</TableCell>
              <TableCell width="30%">Description</TableCell>
              <TableCell width="20%">Created</TableCell>
              <TableCell width="25%">Sender</TableCell>
              {/* REMOVED FOR SFGCR-1535 - <TableCell width="5%">Recipients</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(notification => {
                const created = moment(notification.created).format('M/D/YYYY h:mm a');
                return (
                  <TableRow key={notification.id} className="clickable">
                    {this.renderTableCell(notification, notification.title)}
                    {this.renderTableCell(notification, notification.description)}
                    {this.renderTableCell(notification, created)}
                    {this.renderTableCell(
                      notification,
                      notification.owner ? getOwner(notification.owner) : ''
                    )}
                    {/* REMOVED FOR SFGCR-1535 - {this.renderTableCell(notification, notification.recipients.length)} */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={notifications.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={(event, selectedPage) => this.setState({ page: selectedPage })}
          onChangeRowsPerPage={event => this.setState({ rowsPerPage: event.target.value })}
        />
      </Fragment>
    );
  }
}

NotificationsTable.propTypes = {
  notifications: PropTypes.array.isRequired,
  editNotification: PropTypes.func.isRequired,
};

export default NotificationsTable;
