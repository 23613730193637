import { CALL_API } from '../../middleware/api';

export const GET_TICKETS_REQUEST = 'GET_TICKETS_REQUEST';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILURE = 'GET_TICKETS_FAILURE';

export const GET_TICKET_FOR_ATTENDEE_REQUEST = 'GET_TICKET_FOR_ATTENDEE_REQUEST';
export const GET_TICKET_FOR_ATTENDEE_SUCCESS = 'GET_TICKET_FOR_ATTENDEE_SUCCESS';
export const GET_TICKET_FOR_ATTENDEE_FAILURE = 'GET_TICKET_FOR_ATTENDEE_FAILURE';

export const GET_PURCHASER_FOR_ATTENDEE_REQUEST = 'GET_PURCHASER_FOR_ATTENDEE_REQUEST';
export const GET_PURCHASER_FOR_ATTENDEE_SUCCESS = 'GET_PURCHASER_FOR_ATTENDEE_SUCCESS';
export const GET_PURCHASER_FOR_ATTENDEE_FAILURE = 'GET_PURCHASER_FOR_ATTENDEE_FAILURE';
export const CLEAR_PURCHASER_FOR_ATTENDEE = 'CLEAR_PURCHASER_FOR_ATTENDEE';

export const GET_PURCHASER_FOR_TICKET_REQUEST = 'GET_PURCHASER_FOR_TICKET_REQUEST';
export const GET_PURCHASER_FOR_TICKET_SUCCESS = 'GET_PURCHASER_FOR_TICKET_SUCCESS';
export const GET_PURCHASER_FOR_TICKET_FAILURE = 'GET_PURCHASER_FOR_TICKET_FAILURE';

export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';

export const ADD_UNASSIGNED_REQUEST = 'ADD_UNASSIGNED_REQUEST';
export const ADD_UNASSIGNED_SUCCESS = 'ADD_UNASSIGNED_SUCCESS';
export const ADD_UNASSIGNED_FAILURE = 'ADD_UNASSIGNED_FAILURE';

export const GET_TICKET_REQUEST = 'GET_TICKET_REQUEST';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const GET_TICKET_FAILURE = 'GET_TICKET_FAILURE';

export const SET_SELECTED_TICKET = 'SET_SELECTED_TICKET';
export const CLEAR_SELECTED_TICKET = 'CLEAR_SELECTED_TICKET';

export const SET_SELECTED_ASSIGNEE = 'SET_SELECTED_ASSIGNEE';
export const CLEAR_SELECTED_ASSIGNEE = 'CLEAR_SELECTED_ASSIGNEE';

export const UPDATE_TICKET_REQUEST = 'UPDATE_TICKET_REQUEST';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_FAILURE = 'UPDATE_TICKET_FAILURE';

export const DELETE_TICKET_REQUEST = 'DELETE_TICKET_REQUEST';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_FAILURE = 'DELETE_TICKET_FAILURE';

export const CREATE_ASSIGNEE_REQUEST = 'CREATE_ASSIGNEE_REQUEST';
export const CREATE_ASSIGNEE_SUCCESS = 'CREATE_ASSIGNEE_SUCCESS';
export const CREATE_ASSIGNEE_FAILURE = 'CREATE_ASSIGNEE_FAILURE';

export const GET_ASSIGNEE_REQUEST = 'GET_ASSIGNEE_REQUEST';
export const GET_ASSIGNEE_SUCCESS = 'GET_ASSIGNEE_SUCCESS';
export const GET_ASSIGNEE_FAILURE = 'GET_ASSIGNEE_FAILURE';

export const UPDATE_ASSIGNEE_REQUEST = 'UPDATE_ASSIGNEE_REQUEST';
export const UPDATE_ASSIGNEE_SUCCESS = 'UPDATE_ASSIGNEE_SUCCESS';
export const UPDATE_ASSIGNEE_FAILURE = 'UPDATE_ASSIGNEE_FAILURE';

export const CANCEL_TICKETS_REQUEST = 'CANCEL_TICKETS_REQUEST';
export const CANCEL_TICKETS_SUCCESS = 'CANCEL_TICKETS_SUCCESS';
export const CANCEL_TICKETS_FAILURE = 'CANCEL_TICKETS_FAILURE';
// currently there is no reducer for the resent email actions
export const RESEND_ASSIGNMENT_EMAIL_REQUEST = 'RESEND_ASSIGNMENT_EMAIL_REQUEST';
export const RESEND_ASSIGNMENT_EMAIL_SUCCESS = 'RESEND_ASSIGNMENT_EMAIL_SUCCESS';
export const RESEND_ASSIGNMENT_EMAIL_FAILURE = 'RESEND_ASSIGNMENT_EMAIL_FAILURE';

export const DELEGATE_TICKET_REQUEST = 'DELEGATE_TICKET_REQUEST';
export const DELEGATE_TICKET_SUCCESS = 'DELEGATE_TICKET_SUCCESS';
export const DELEGATE_TICKET_FAILURE = 'DELEGATE_TICKET_FAILURE';

export const RESEND_DELEGATE_TICKET_REQUEST = 'RESEND_DELEGATE_TICKET_REQUEST';
export const RESEND_DELEGATE_TICKET_SUCCESS = 'RESEND_DELEGATE_TICKET_SUCCESS';
export const RESEND_DELEGATE_TICKET_FAILURE = 'RESEND_DELEGATE_TICKET_FAILURE';

export const UPDATE_DELEGATE_TICKET_REQUEST = 'UPDATE_DELEGATE_TICKET_REQUEST';
export const UPDATE_DELEGATE_TICKET_SUCCESS = 'UPDATE_DELEGATE_TICKET_SUCCESS';
export const UPDATE_DELEGATE_TICKET_FAILURE = 'UPDATE_DELEGATE_TICKET_FAILURE';

export const CREATE_DELEGATED_ASSIGNEE_REQUEST = 'CREATE_DELEGATED_ASSIGNEE_REQUEST';
export const CREATE_DELEGATED_ASSIGNEE_SUCCESS = 'CREATE_DELEGATED_ASSIGNEE_SUCCESS';
export const CREATE_DELEGATED_ASSIGNEE_FAILURE = 'CREATE_DELEGATED_ASSIGNEE_FAILURE';

export const GET_DELEGATED_TICKET_REQUEST = 'GET_DELEGATED_TICKET_REQUEST';
export const GET_DELEGATED_TICKET_SUCCESS = 'GET_DELEGATED_TICKET_SUCCESS';
export const GET_DELEGATED_TICKET_FAILURE = 'GET_DELEGATED_TICKET_FAILURE';

export const CANCEL_DELEGATE_TICKET_REQUEST = 'CANCEL_DELEGATE_TICKET_REQUEST';
export const CANCEL_DELEGATE_TICKET_SUCCESS = 'CANCEL_DELEGATE_TICKET_SUCCESS';
export const CANCEL_DELEGATE_TICKET_FAILURE = 'CANCEL_DELEGATE_TICKET_FAILURE';

export const getTickets = email => {
  const endpoint = 'v1/tickets';
  return {
    [CALL_API]: {
      types: [GET_TICKETS_REQUEST, GET_TICKETS_SUCCESS, GET_TICKETS_FAILURE],
      authenticated: true,
      endpoint: email ? `${endpoint}?email=${email}` : endpoint,
      method: 'GET',
    },
  };
};

// This gets the ticket object for an attendee by ID. Used by the helpdesk when taking certain actions
// like the clear ticket assignment information and resend email action
export const getTicketForAttendee = attendee => {
  return {
    [CALL_API]: {
      types: [
        GET_TICKET_FOR_ATTENDEE_REQUEST,
        GET_TICKET_FOR_ATTENDEE_SUCCESS,
        GET_TICKET_FOR_ATTENDEE_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/attendees/${attendee.id}?relations=ticket`,
      method: 'GET',
    },
  };
};

// This gets the purchaser for an attendee record.
// Useful on the event attendee view/update page, particularly when arriving via the helpdesk
export const getPurchaserForAttendee = attendee => {
  return {
    [CALL_API]: {
      types: [
        GET_PURCHASER_FOR_ATTENDEE_REQUEST,
        GET_PURCHASER_FOR_ATTENDEE_SUCCESS,
        GET_PURCHASER_FOR_ATTENDEE_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/attendees/${attendee.id}?relations=ticket,ticket.cart,ticket.cart.purchaser,agencyManager`,
      method: 'GET',
    },
  };
};

export const clearPurchaserForAttendee = () => {
  return {
    type: CLEAR_PURCHASER_FOR_ATTENDEE,
  };
};

export const getPurchaserForTicket = ticket => {
  return {
    [CALL_API]: {
      types: [
        GET_PURCHASER_FOR_TICKET_REQUEST,
        GET_PURCHASER_FOR_TICKET_SUCCESS,
        GET_PURCHASER_FOR_TICKET_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/tickets/${ticket.id}?relations=cart,cart.purchaser`,
      method: 'GET',
    },
  };
};

export const getTicketsAdmin = (eventId, purchaserId) => {
  return {
    [CALL_API]: {
      types: [GET_TICKETS_REQUEST, GET_TICKETS_SUCCESS, GET_TICKETS_FAILURE],
      authenticated: true,
      endpoint:
        eventId && purchaserId
          ? `admin/events/${eventId}/registrations/${purchaserId}/tickets`
          : 'admin/tickets',
      method: 'GET',
    },
  };
};

export const addUnassignedTicketsToPurchaser = (purchaserId, numToAdd) => {
  return {
    [CALL_API]: {
      types: [ADD_UNASSIGNED_REQUEST, ADD_UNASSIGNED_SUCCESS, ADD_UNASSIGNED_FAILURE],
      authenticated: true,
      endpoint: `admin/purchases/${purchaserId}/addUnassigned/${numToAdd}`,
      method: 'POST',
    },
  };
};

export const createTicket = ticketData => {
  return {
    [CALL_API]: {
      types: [CREATE_TICKET_REQUEST, CREATE_TICKET_SUCCESS, CREATE_TICKET_FAILURE],
      authenticated: true,
      endpoint: `admin/tickets`,
      method: 'POST',
      payload: ticketData,
    },
  };
};

export const getTicket = ticketId => {
  return {
    [CALL_API]: {
      types: [GET_TICKET_REQUEST, GET_TICKET_SUCCESS, GET_TICKET_FAILURE],
      authenticated: true,
      endpoint: `admin/tickets/${ticketId}`,
      method: 'GET',
      actionMetadata: {
        ticketId,
      },
    },
  };
};

export const resendConfirmationEmail = ticketId => {
  return {
    [CALL_API]: {
      types: [
        RESEND_ASSIGNMENT_EMAIL_REQUEST,
        RESEND_ASSIGNMENT_EMAIL_SUCCESS,
        RESEND_ASSIGNMENT_EMAIL_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/tickets/${ticketId}/resendConfirmation`,
      method: 'POST',
      actionMetadata: {
        ticketId,
      },
    },
  };
};

export const setSelectedTicket = ticket => {
  return {
    type: SET_SELECTED_TICKET,
    response: ticket,
  };
};

export const setSelectedAssignee = assignee => {
  return {
    type: SET_SELECTED_ASSIGNEE,
    response: assignee,
  };
};

export const clearSelectedTicket = () => {
  return {
    type: CLEAR_SELECTED_TICKET,
  };
};

export const updateTicket = (ticketId, ticketData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_TICKET_REQUEST, UPDATE_TICKET_SUCCESS, UPDATE_TICKET_FAILURE],
      authenticated: true,
      endpoint: `admin/tickets/${ticketId}`,
      method: 'PUT',
      payload: ticketData,
      actionMetadata: {
        ticketId,
      },
    },
  };
};

export const deleteTicket = ticketId => {
  return {
    [CALL_API]: {
      types: [DELETE_TICKET_REQUEST, DELETE_TICKET_SUCCESS, DELETE_TICKET_FAILURE],
      authenticated: true,
      endpoint: `admin/tickets/${ticketId}`,
      method: 'DELETE',
      actionMetadata: {
        ticketId,
      },
    },
  };
};

export const createAssignee = (ticketId, assigneeData) => {
  return {
    [CALL_API]: {
      types: [CREATE_ASSIGNEE_REQUEST, CREATE_ASSIGNEE_SUCCESS, CREATE_ASSIGNEE_FAILURE],
      authenticated: true,
      endpoint: `v1/tickets/${ticketId}/assignee`,
      method: 'PUT',
      payload: assigneeData,
    },
  };
};

export const getAssignee = assigneeId => {
  return {
    [CALL_API]: {
      types: [GET_ASSIGNEE_REQUEST, GET_ASSIGNEE_SUCCESS, GET_ASSIGNEE_FAILURE],
      authenticated: true,
      endpoint: `admin/attendees/${assigneeId}?relations=spouse,agencyManager`,
      method: 'GET',
      actionMetadata: {
        assigneeId,
      },
    },
  };
};

export const updateAssignee = (assigneeId, assigneeData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_ASSIGNEE_REQUEST, UPDATE_ASSIGNEE_SUCCESS, UPDATE_ASSIGNEE_FAILURE],
      authenticated: true,
      endpoint: `admin/attendees/${assigneeId}`,
      method: 'PUT',
      payload: assigneeData,
      actionMetadata: {
        assigneeId,
      },
    },
  };
};

export const getAssigneeAdmin = assigneeId => {
  return {
    [CALL_API]: {
      types: [GET_ASSIGNEE_REQUEST, GET_ASSIGNEE_SUCCESS, GET_ASSIGNEE_FAILURE],
      authenticated: true,
      endpoint: `admin/attendees/${assigneeId}?relations=spouse`,
      method: 'GET',
      actionMetadata: {
        assigneeId,
      },
    },
  };
};

export const createAssigneeAdmin = assigneeData => {
  return {
    [CALL_API]: {
      types: [CREATE_ASSIGNEE_REQUEST, CREATE_ASSIGNEE_SUCCESS, CREATE_ASSIGNEE_FAILURE],
      authenticated: true,
      endpoint: `admin/attendees`,
      method: 'POST',
      payload: assigneeData,
    },
  };
};

export const updateAssigneeAdmin = assigneeData => {
  return {
    [CALL_API]: {
      types: [UPDATE_ASSIGNEE_REQUEST, UPDATE_ASSIGNEE_SUCCESS, UPDATE_ASSIGNEE_FAILURE],
      authenticated: true,
      endpoint: `admin/attendee`,
      method: 'PUT',
      payload: assigneeData,
    },
  };
};

export const cancelTickets = (purchaserId, tickets) => {
  return {
    [CALL_API]: {
      types: [CANCEL_TICKETS_REQUEST, CANCEL_TICKETS_SUCCESS, CANCEL_TICKETS_FAILURE],
      authenticated: true,
      endpoint: `v1/purchases/${purchaserId}/cancelTickets`,
      method: 'POST',
      payload: { tickets },
    },
  };
};

export const delegateTicket = (ticketId, payload, isAdmin = false) => {
  let endpoint;

  if (isAdmin) {
    endpoint = `admin/tickets/${ticketId}/delegate`;
  } else {
    endpoint = `v1/tickets/${ticketId}/delegate`;
  }

  return {
    [CALL_API]: {
      types: [DELEGATE_TICKET_REQUEST, DELEGATE_TICKET_SUCCESS, DELEGATE_TICKET_FAILURE],
      authenticated: true,
      endpoint,
      method: 'POST',
      payload,
    },
  };
};

export const resendDelegateTicket = (ticketId, payload, isAdmin = false) => {
  let endpoint;

  if (isAdmin) {
    endpoint = `admin/tickets/${ticketId}/delegate/resend`;
  } else {
    endpoint = `v1/tickets/${ticketId}/delegate/resend`;
  }

  return {
    [CALL_API]: {
      types: [
        RESEND_DELEGATE_TICKET_REQUEST,
        RESEND_DELEGATE_TICKET_SUCCESS,
        RESEND_DELEGATE_TICKET_FAILURE,
      ],
      authenticated: true,
      endpoint,
      method: 'POST',
      payload,
    },
  };
};

export const updateDelegateTicket = (ticketId, payload, isAdmin = false) => {
  let endpoint;

  if (isAdmin) {
    endpoint = `admin/tickets/${ticketId}/delegate`;
  } else {
    endpoint = `v1/tickets/${ticketId}/delegate`;
  }

  return {
    [CALL_API]: {
      types: [
        UPDATE_DELEGATE_TICKET_REQUEST,
        UPDATE_DELEGATE_TICKET_SUCCESS,
        UPDATE_DELEGATE_TICKET_FAILURE,
      ],
      authenticated: true,
      endpoint,
      method: 'PUT',
      payload,
    },
  };
};

export const createDelegatedAssignee = (code, assigneeData) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_DELEGATED_ASSIGNEE_REQUEST,
        CREATE_DELEGATED_ASSIGNEE_SUCCESS,
        CREATE_DELEGATED_ASSIGNEE_FAILURE,
      ],
      endpoint: `v1/delegate/assignee?code=${code}`,
      method: 'PUT',
      payload: assigneeData,
    },
  };
};

export const getDelegatedTicket = code => {
  return {
    [CALL_API]: {
      types: [
        GET_DELEGATED_TICKET_REQUEST,
        GET_DELEGATED_TICKET_SUCCESS,
        GET_DELEGATED_TICKET_FAILURE,
      ],
      endpoint: `v1/delegate?code=${code}`,
      method: 'GET',
    },
  };
};

export const cancelDelegateTicket = ticketId => {
  return {
    [CALL_API]: {
      types: [
        CANCEL_DELEGATE_TICKET_REQUEST,
        CANCEL_DELEGATE_TICKET_SUCCESS,
        CANCEL_DELEGATE_TICKET_FAILURE,
      ],
      authenticated: true,
      endpoint: `admin/tickets/${ticketId}/delegate/cancel`,
      method: 'POST',
    },
  };
};
