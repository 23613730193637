import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Typography, withStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import MaxWidthDialog from '../layout/dialogs/maxWidthDialog.component';
import SFGPrimaryButton from '../../common/buttons/sfgPrimaryButton.component';
import {
  REGISTER_FOR_BREAKOUT_SESSION_SUCCESS,
  UNREGISTER_FOR_BREAKOUT_SESSION_SUCCESS,
  getEventSchedule,
  getMySchedule,
  registerForBreakoutSession,
  unregisterForBreakoutSession,
} from './mySchedule.actions';
import {
  TIME_FORMAT,
  getDateTimeFromSlotData,
  isSessionFull,
  isSessionInMySchedule,
  isSlotInThePast,
} from '../../utilities/scheduleHelper';
import { selectMySchedule } from './mySchedule.selectors';
import { showToast } from '../layout/layout.actions';

const SPECIAL_EVENT_TYPE = 'specialevent';

const Session = ({ classes, theme, eventId, isBreakouts, isLast, session, slot }) => {
  const dispatch = useDispatch();

  const mySchedule = useSelector(selectMySchedule);

  const [hasNonExclusiveSessions, setHasNonExclusiveSessions] = useState(false);
  const [isUnselectModalOpen, setIsUnselectModalOpen] = useState(false);

  // eslint-disable-next-line no-shadow
  const getSessionType = session => {
    if (session.exclusive) {
      return 'General Session';
    }
    if (session.type === SPECIAL_EVENT_TYPE) {
      return `Special Event from ${!isEmpty(session.ownerName) ? session.ownerName : 'Symmetry'}`;
    }
    return 'Breakout Session';
  };

  const handleRegisterForBreakoutSession = useCallback(
    // eslint-disable-next-line no-shadow
    () => {
      (async () => {
        const response = await dispatch(registerForBreakoutSession(eventId, slot.id, session.id));

        if (response.type === REGISTER_FOR_BREAKOUT_SESSION_SUCCESS) {
          await dispatch(getEventSchedule(eventId));
          await dispatch(getMySchedule(eventId));
        } else {
          dispatch(showToast('Failed to register. Check the seating limits.', true));
        }
      })();
    },
    [dispatch, eventId, slot, session]
  );

  const checkRegisterForBreakoutSession = useCallback(() => {
    const slotIdx = mySchedule.findIndex(scheduleSlot => scheduleSlot.id === slot.id);

    if (slotIdx > -1) {
      // eslint-disable-next-line no-shadow
      const hasNonExclusiveSessions = mySchedule[slotIdx].sessions.some(
        scheduleSlot => !scheduleSlot.exclusive
      );

      if (hasNonExclusiveSessions) {
        setHasNonExclusiveSessions(true);
        return;
      }

      handleRegisterForBreakoutSession();
    }
  }, [mySchedule, slot, handleRegisterForBreakoutSession, setHasNonExclusiveSessions]);

  const handleUnregisterForBreakoutSession = useCallback(() => {
    (async () => {
      const response = await dispatch(unregisterForBreakoutSession(eventId, slot.id, session.id));

      if (response.type === UNREGISTER_FOR_BREAKOUT_SESSION_SUCCESS) {
        await dispatch(getEventSchedule(eventId));
        await dispatch(getMySchedule(eventId));
      } else {
        showToast('Failed to unregister.', true);
      }

      setIsUnselectModalOpen(false);
    })();
  }, [dispatch, eventId, session, slot]);

  const momentSlot = useMemo(() => moment(getDateTimeFromSlotData(slot, slot.startTime)), [slot]);

  const isSelected = useMemo(() => isSessionInMySchedule(session, mySchedule, slot.id), [
    mySchedule,
    session,
    slot,
  ]);
  const isPast = useMemo(() => isSlotInThePast(slot), [slot]);

  const borderColor = useMemo(() => {
    if (session.exclusive) {
      return theme.palette.success.main;
    }

    if (session.type === SPECIAL_EVENT_TYPE) {
      return theme.palette.secondary.main;
    }

    return theme.palette.primary.main;
  }, [theme, session]);

  return (
    <>
      <div
        className={classNames(
          classes.container,
          isBreakouts && classes.breakout,
          isLast && classes.isLast,
          isSelected && isBreakouts && classes.selected
        )}
        style={{ border: `${borderColor} solid 1px` }}
      >
        <Typography
          className={isSelected && isBreakouts ? classes.selectedText : ''}
          color="primary"
          variant="title"
        >
          {session.title}
        </Typography>
        <Typography
          className={isSelected && isBreakouts ? classes.selectedText : ''}
          variant="subheading"
        >
          {getSessionType(session)}
        </Typography>
        <Typography
          className={isSelected && isBreakouts ? classes.selectedText : ''}
          variant="subheading"
        >
          {momentSlot.format('MMM DD, ')}
          {momentSlot.format(TIME_FORMAT)}
        </Typography>
        <Typography
          className={classNames(
            classes.location,
            isSelected && isBreakouts && classes.selectedText
          )}
          variant="subheading"
        >
          {session.location}
        </Typography>

        <div>
          <Typography
            className={isSelected && isBreakouts ? classes.selectedText : ''}
            variant="subheading"
          >
            {session.description}
          </Typography>
        </div>
        {(!isSessionFull(session) || isSelected) && !isPast && isBreakouts && (
          <div className={classes.actionContainer}>
            {isSelected ? (
              <SFGPrimaryButton
                className={classNames(classes.actionButton, classes.selectedButton)}
                color="primary"
                onClick={() => setIsUnselectModalOpen(true)}
              >
                unselect
              </SFGPrimaryButton>
            ) : (
              <SFGPrimaryButton
                className={classes.actionButton}
                onClick={checkRegisterForBreakoutSession}
              >
                select
              </SFGPrimaryButton>
            )}
          </div>
        )}
      </div>
      <MaxWidthDialog
        actionText="Select"
        cancelText="Cancel"
        dialogContentElem={
          <Typography>
            {`Adding ${session.title} to your schedule will remove you from other sessions at this time slot. Are you sure you want to do this?`}
          </Typography>
        }
        headingText="Confirm Select"
        open={hasNonExclusiveSessions}
        handleAction={handleRegisterForBreakoutSession}
        onDialogClose={() => setHasNonExclusiveSessions(false)}
      />
      <MaxWidthDialog
        actionText="Unselect"
        cancelText="Cancel"
        dialogContentElem={
          <Typography>
            {`This will remove ${session.title} from your schedule. If there are seating restrictions, you may not be able to get a spot in this session later.`}
          </Typography>
        }
        headingText="Confirm Select"
        open={isUnselectModalOpen}
        handleAction={handleUnregisterForBreakoutSession}
        onDialogClose={() => setIsUnselectModalOpen(false)}
      />
    </>
  );
};

const styles = theme => ({
  container: {
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  breakout: {
    marginBottom: 0,
    marginRight: theme.spacing.unit * 3,
    maxWidth: '30rem',
    minWidth: '30rem',
  },
  isLast: {
    marginBottom: 0,
    marginRight: 0,
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main}CC`,
  },
  selectedButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  selectedText: {
    color: theme.palette.common.white,
  },
  location: {
    paddingBottom: theme.spacing.unit * 2,
  },
  actionContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing.unit,
  },
  actionButton: {
    width: '100%',
  },
});

Session.defaultProps = {
  isBreakouts: false,
  isLast: false,
};

Session.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  eventId: PropTypes.string.isRequired,
  isBreakouts: PropTypes.bool,
  isLast: PropTypes.bool,
  session: PropTypes.object.isRequired,
  slot: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Session);
