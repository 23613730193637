import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import EventsPage from '../events/events.container';
import ImpersonatePage from '../login/impersonate.container';
import LoginPage from '../login/login.container';
import NewAttendeePage from '../attendees/attendeeNew.container';
import getInitializingComponent from '../../utilities/initializing.component';

const RoutesPublic = () => (
  <Switch>
    <Route exact path="/events" component={getInitializingComponent(EventsPage)} />
    <Route
      exact
      path="/attendees/:eventId/new/:ticketId"
      component={getInitializingComponent(NewAttendeePage)}
    />
    <Route exact path="/login" component={LoginPage} />

    {process.env.REACT_APP_ENVIRONMENT_NAME !== 'production' && (
      <Route exact path="/impersonate" component={ImpersonatePage} />
    )}

    <Redirect from="*" to="/events" />
  </Switch>
);

export default RoutesPublic;
