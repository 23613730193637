import Immutable from 'immutable';
import {
  CHECK_IN_GATHERING_ATTENDEE_FAILURE,
  CHECK_IN_GATHERING_ATTENDEE_REQUEST,
  CHECK_IN_GATHERING_ATTENDEE_SUCCESS,
  GET_GATHERINGS_BY_LOCATION_FAILURE,
  GET_GATHERINGS_BY_LOCATION_REQUEST,
  GET_GATHERINGS_BY_LOCATION_SUCCESS,
  GET_GATHERINGS_BY_ZIP_FAILURE,
  GET_GATHERINGS_BY_ZIP_REQUEST,
  GET_GATHERINGS_BY_ZIP_SUCCESS,
  GET_GATHERINGS_FAILURE,
  GET_GATHERINGS_REQUEST,
  GET_GATHERINGS_SUCCESS,
} from './gatherings.actions';

const initialState = Immutable.Map({
  gatherings: [],
  isLoading: false,
  isCheckingIn: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GATHERINGS_SUCCESS:
    case GET_GATHERINGS_BY_LOCATION_SUCCESS:
    case GET_GATHERINGS_BY_ZIP_SUCCESS:
      return state.set('gatherings', action.response.data).set('isLoading', false);

    case GET_GATHERINGS_FAILURE:
    case GET_GATHERINGS_BY_LOCATION_FAILURE:
    case GET_GATHERINGS_BY_ZIP_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_GATHERINGS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_GATHERINGS_BY_LOCATION_REQUEST:
    case GET_GATHERINGS_BY_ZIP_REQUEST:
      return state
        .set('errors', [])
        .set('isLoading', true)
        .set('gatherings', []);

    case CHECK_IN_GATHERING_ATTENDEE_SUCCESS:
      return state.set('isCheckingIn', false);

    case CHECK_IN_GATHERING_ATTENDEE_REQUEST:
      return state.set('isCheckingIn', true);

    case CHECK_IN_GATHERING_ATTENDEE_FAILURE:
      return state.set('isCheckingIn', false);

    default:
      return state;
  }
};
