import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { FormControl, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import AgentAutocomplete from '../layout/autocomplete/agentAutocomplete.container';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import agentSearchTypes from '../../types/agentSearchTypes';
import { CREATE_BIG5_AGENCY_SUCCESS, createAgency } from './bigFive.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class NewBigFiveAgency extends Component {
  constructor(props) {
    super(props);

    this.state = { agencyOwner: null };
  }

  componentWillMount() {
    this.props.setPageTitle('New Big 5 Agency');
  }

  onSubmit = async e => {
    e.preventDefault();
    if (isEmpty(this.state.agencyOwner)) {
      this.props.handleToastMessage('An agency must have an agency owner.', true);
      return;
    }

    const agency = { ownerCode: this.state.agencyOwner.code };
    const result = await this.props.createAgency(agency);
    if (result.type === CREATE_BIG5_AGENCY_SUCCESS) {
      this.props.handleToastMessage('Successfully added Big 5 agency!', false);
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage(`Error adding Big 5 agency!`, true);
    }
  };

  render() {
    const { agencyOwner } = this.state;
    return (
      <div>
        <Typography
          variant="subheading"
          style={{
            marginTop: '1.5rem',
            marginBottom: '0.5rem',
            textAlign: 'center',
          }}
        >
          Add New Big 5 Agency
        </Typography>
        <form onSubmit={this.onSubmit}>
          <FormControl style={{ margin: '0 auto', display: 'block', maxWidth: 300 }}>
            <AgentAutocomplete
              initialValue={agencyOwner ? agencyOwner.name : ''}
              label="Agency Owner"
              helperText="(Autocomplete)"
              placeholder="Enter an agent's name"
              onSelect={suggestion => this.setState({ agencyOwner: suggestion })}
              searchType={agentSearchTypes.agency}
              clearInputOnSelect
              required
            />
          </FormControl>
          <SFGTextButton type="submit" style={{ margin: '0 auto', display: 'block' }}>
            Create Agency
          </SFGTextButton>
        </form>
      </div>
    );
  }
}

NewBigFiveAgency.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  createAgency: PropTypes.func.isRequired,
};

export default connect(null, {
  setPageTitle,
  handleToastMessage,
  createAgency,
})(NewBigFiveAgency);
