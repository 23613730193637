/* eslint-disable react/jsx-fragments */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Close } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import Colors from '../../../styles/colors';
import SFGTextButton from '../../../common/buttons/sfgTextButton.component';

const FullScreenDialog = ({
  actionText,
  cancelText,
  classes,
  dialogContentElem,
  headingText,
  isActionDisabled,
  open,
  handleAction,
  onDialogClose,
}) => (
  <Fragment>
    <Dialog fullScreen open={open} onClose={onDialogClose}>
      <DialogTitle className={classes.title} align="center">
        {headingText}
        <IconButton className={classes.closeIcon} onClick={onDialogClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>{dialogContentElem}</DialogContent>
      <DialogActions
        style={{ display: !isEmpty(actionText) || cancelText.length ? 'inherit' : 'none' }}
        className={classes.actions}
      >
        {!isEmpty(actionText) && (
          <SFGTextButton disabled={isActionDisabled} onClick={handleAction} color="primary">
            {actionText}
          </SFGTextButton>
        )}
        {cancelText.length > 0 && (
          <SFGTextButton onClick={onDialogClose} color="primary">
            {cancelText}
          </SFGTextButton>
        )}
      </DialogActions>
    </Dialog>
  </Fragment>
);

const styles = theme => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    position: 'relative',
  },
  content: {
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
  },
  closeIcon: {
    color: theme.palette.common.black,
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
  },
  actions: {
    backgroundColor: Colors.grayLight,
    margin: 0,
    padding: theme.spacing.unit,
  },
});

FullScreenDialog.defaultProps = {
  actionText: '',
  cancelText: 'Close',
  headingText: 'Dialog',
  isActionDisabled: false,
  handleAction: null,
  onDialogClose: null,
};

FullScreenDialog.propTypes = {
  actionText: PropTypes.string,
  cancelText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dialogContentElem: PropTypes.element.isRequired,
  headingText: PropTypes.string,
  isActionDisabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleAction: PropTypes.func,
  onDialogClose: PropTypes.func,
};

export default withStyles(styles)(FullScreenDialog);
