import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import {
  getGooglePlaces,
  GET_GOOGLE_PLACES_SUCCESS,
  getGooglePlace,
  GET_GOOGLE_PLACE_SUCCESS
} from './autocomplete.actions';
import CustomAutocomplete from './customAutocomplete.component';

class GooglePlacesAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      sessionToken: Date.now()
    };
  }

  getSuggestionValue = suggestion => {
    return suggestion.description;
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();

    if (inputValue.length > 2) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }

      this.searchTimer = setTimeout(async () => {
        const result = await this.props.getGooglePlaces(value, this.state.sessionToken);
        if (result.type === GET_GOOGLE_PLACES_SUCCESS) {
          // console.log('SUGGESTIONS!', result.response.data);
          this.setState({
            suggestions: result.response.data
          });
        }
      }, 300);
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  // the object in this signature can also have "query"
  renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.description}
      </MenuItem>
    );
  };

  // the object in this signature can also have "suggestionValue", "suggestionIndex", and "sectionIndex"
  handleSelection = async (event, { suggestion, method }) => {
    if (method === 'enter') {
      event.preventDefault();
    }

    const result = await this.props.getGooglePlace(suggestion.placeId, this.state.sessionToken);
    console.log('GOOGLE PLACE DETAILS', result);
    if (result.type === GET_GOOGLE_PLACE_SUCCESS) {
      this.props.onSelect(suggestion);

      if (this.props.setCoordinates) this.props.setCoordinates(result.response.geometry.location);
    }
  };

  render() {
    return (
      <CustomAutocomplete
        getSuggestionValue={this.getSuggestionValue}
        handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestion={this.renderSuggestion}
        handleSelection={this.handleSelection}
        suggestions={this.state.suggestions}
        initialValue={this.props.initialValue}
        label={this.props.label}
        placeholder={this.props.placeholder}
        onInputClear={this.props.onInputClear}
        onInputEdit={this.props.onInputEdit}
      />
    );
  }
}

GooglePlacesAutocomplete.defaultProps = {
  onInputClear: null,
  onInputEdit: null,
  setCoordinates: null
};

GooglePlacesAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onInputClear: PropTypes.func,
  onInputEdit: PropTypes.func,
  setCoordinates: PropTypes.func,

  initialValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

  getGooglePlaces: PropTypes.func.isRequired,
  getGooglePlace: PropTypes.func.isRequired
};

export default connect(null, {
  getGooglePlaces,
  getGooglePlace
})(GooglePlacesAutocomplete);
