/* eslint-disable */
// JM: Eslint is disabled at this point as this component set was pulled from a resource online
import React, { Component, createRef, Fragment } from 'react';
import { Editor, EditorState, Modifier, RichUtils, getDefaultKeyBinding } from 'draft-js';
import 'draft-js/dist/Draft.css';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import './richEditor.css';
import StyledDialogActions from '../dialogs/dialogActions';
import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';

class RichEditor extends Component {
  state = {
    openURLDialog: false,
  };

  constructor(props) {
    super(props);
    this.focus = () => this.refs.editor.focus();
    this.onChange = editorState => this.props.updateEditorState(editorState);
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }
  _handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };
  _mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, this.props.editorState, 4 /* maxDepth */);
      if (newEditorState !== this.props.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };
  _toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
  };
  _toggleInlineStyle = inlineStyle => {
    this.onChange(RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle));
  };

  handleCloseDialog = url => {
    this.setState({ openURLDialog: false });
    if (url) {
      this.setLink(url);
    }
  };

  setLink = url => {
    let editorState = this.props.editorState;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const contentStateWithEntity = content.createEntity('LINK', 'MUTABLE', {
      url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);
    editorState = EditorState.push(editorState, contentStateWithLink, 'apply-entity');
    editorState = RichUtils.toggleLink(editorState, selection, entityKey);
    this.onChange(editorState);
  };

  render() {
    const { editorState } = this.props;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return (
      <div className="RichEditor-root">
        {!this.props.readOnly && (
          <Fragment>
            <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
            <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
            <div className="RichEditor-controls">
              <span
                className="RichEditor-styleButton"
                onMouseDown={() => this.setState({ openURLDialog: true })}
              >
                Hyperlink
              </span>
            </div>
            <EnterURLDialog
              openURLDialog={this.state.openURLDialog}
              handleCloseDialog={this.handleCloseDialog}
            />
          </Fragment>
        )}
        <div
          className={className}
          onClick={this.focus}
          style={this.props.readOnly ? { borderTop: 'none', marginTop: 0 } : {}}
        >
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.onChange}
            placeholder={this.props.placeholder || 'Tell a story...'}
            ref="editor"
            spellCheck={true}
            readOnly={this.props.readOnly}
          />
        </div>
      </div>
    );
  }
}

RichEditor.defaultProps = {
  readOnly: false,
};

RichEditor.propTypes = {
  editorState: PropTypes.object.isRequired,
  updateEditorState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default RichEditor;

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Roboto", Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  // { label: 'Code Block', style: 'code-block' }
];
const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  // { label: 'Monospace', style: 'CODE' }
];
const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

class EnterURLDialog extends Component {
  state = {
    url: '',
    inputRef: createRef(),
  };

  listenForEnter = e => {
    if (e.key === 'Enter') {
      const elem = document.getElementById('setURL');
      if (elem) elem.click();
    }
  };

  handleCloseDialog(url) {
    this.props.handleCloseDialog(url);
    this.setState({ url: '' });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.openURLDialog && this.props.openURLDialog) {
      setTimeout(() => {
        this.state.inputRef.current.focus();
      });
    }
  }

  render() {
    return (
      <Dialog open={this.props.openURLDialog} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">Enter URL:</DialogTitle>
        <DialogContent>
          <TextField
            label="URL"
            type="text"
            value={this.state.url}
            inputRef={this.state.inputRef}
            onChange={e => this.setState({ url: e.target.value })}
            onKeyPress={this.listenForEnter}
          />
        </DialogContent>
        <StyledDialogActions>
          <SFGOutlinedButton id="setURL" onClick={() => this.handleCloseDialog(this.state.url)}>
            SET URL
          </SFGOutlinedButton>
          <SFGOutlinedButton onClick={() => this.handleCloseDialog(null)}>CANCEL</SFGOutlinedButton>
        </StyledDialogActions>
      </Dialog>
    );
  }
}
