import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import Colors from '../../../styles/colors';

const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: false, disablePadding: true, label: 'E-Mail' },
  { id: 'response', numeric: false, disablePadding: true, label: 'Response' }
];

class EventInviteTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      isReadOnly,
      onlyShowName
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {!isReadOnly && (
            <TableCell padding="checkbox">
              <Checkbox
                style={{ color: Colors.grayDark }}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}

          {rows.map(row => {
            if (row.id !== 'name' && onlyShowName) return null;
            return (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding="default"
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EventInviteTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  onlyShowName: PropTypes.bool,
  isReadOnly: PropTypes.bool
};
EventInviteTableHead.defaultProps = {
  order: 'desc',
  orderBy: '',
  onlyShowName: false,
  isReadOnly: false
};

export default EventInviteTableHead;
