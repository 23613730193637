/* eslint-disable react/jsx-fragments */
import BackIcon from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import firebase from 'firebase/app';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import 'firebase/auth';

import DrawerMenu from './drawerMenuAdmin.component';
import SFGTextButton from '../../common/buttons/sfgTextButton.component';
import colors from '../../styles/colors';
import marque from '../../images/marque_teal.png';
import responsiveBreakpoints from './responsiveBreakpoints';
import routeLinks from './routeLinksAdmin';
import { hasOneOfPermissions } from '../login/permissions';
import { toggleDrawerMenu as toggleDrawerMenuAction } from './layout.actions';

const Header = ({
  classes,
  history,
  location,
  shouldShowBackButton,
  isDrawerMenuOpen,
  pageTitle,
  permissions,
  userEmail,
  responsiveBreakpoint,
  toggleDrawerMenu,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const isActiveRoute = route => {
    const { pathname } = location;

    // explicitly return true/false because matchpath doesn't return a boolean
    if (
      matchPath(pathname, {
        path: route,
        exact: true,
        strict: false,
      })
    ) {
      return true;
    }

    return false;
  };

  const viewPage = route => {
    if (!isActiveRoute(route)) {
      if (route.indexOf('http') > -1) {
        window.open(route, '_blank');
      } else {
        history.push(route);
      }
    }

    if (anchorEl) handleCloseMenu();
  };

  const handleCloseMenu = () => setAnchorEl(null);

  let emailToDisplay = '';

  if (userEmail) {
    emailToDisplay = userEmail;
  } else if (firebase.auth().currentUser) {
    emailToDisplay = firebase.auth().currentUser.email;
  }

  return (
    <Fragment>
      <DrawerMenu
        isDrawerMenuOpen={isDrawerMenuOpen}
        toggleDrawerMenu={toggleDrawerMenu}
        permissions={permissions}
      />
      <AppBar position="static" className={classes.colorPrimary}>
        <Toolbar>
          {responsiveBreakpoint !== responsiveBreakpoints.LARGE ? (
            <IconButton color="inherit" aria-label="Close" onClick={toggleDrawerMenu} alt="menu">
              <i className="material-icons">menu</i>
            </IconButton>
          ) : (
            <div className={classes.marqueWrapper}>
              <img src={marque} className={classes.marque} alt="Quility Marque" />
              <div>
                <Typography className={classes.marqueText}>Quility</Typography>
              </div>
            </div>
          )}
          {shouldShowBackButton && (
            <IconButton color="inherit" aria-label="Back" onClick={history.goBack}>
              <BackIcon />
            </IconButton>
          )}
          <div className={classes.flex}>
            <Typography variant="title" color="inherit" className={classes.titleText}>
              {pageTitle}
            </Typography>
            <Typography variant="body1" color="inherit" className={classes.emailText}>
              {emailToDisplay}
            </Typography>
          </div>

          {responsiveBreakpoint === responsiveBreakpoints.LARGE &&
            routeLinks.map(routeLink => {
              if (
                'permissions' in routeLink &&
                !hasOneOfPermissions(routeLink.permissions, permissions)
              ) {
                return <span key={routeLink.route} />;
              }

              /* Define a gold highlight if a link should have it. */
              const setHighlight =
                'highlight' in routeLink && routeLink.highlight ? { color: 'ffcc00' } : {};
              return (
                <SFGTextButton
                  key={routeLink.route}
                  color={isActiveRoute(routeLink.route) ? 'primary' : 'inherit'}
                  size="small"
                  style={setHighlight}
                  onClick={() => viewPage(routeLink.route)}
                >
                  {routeLink.name}
                </SFGTextButton>
              );
            })}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  colorPrimary: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  },
  marqueWrapper: {
    marginRight: '1rem',
    textAlign: 'center',
  },
  marque: {
    maxHeight: '1.5rem',
  },
  marqueText: {
    fontSize: '0.65rem',
    color: theme.palette.primary.main,
  },
  flex: {
    flex: 1,
  },
  titleText: {
    fontSize: '1.15rem',
  },
  emailText: {
    fontSize: '0.75rem',
    color: colors.grayLight,
  },
});

Header.defaultProps = {
  shouldShowBackButton: false,
  userEmail: null,
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  shouldShowBackButton: PropTypes.bool,

  userEmail: PropTypes.string,
  responsiveBreakpoint: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  isDrawerMenuOpen: PropTypes.bool.isRequired,
  permissions: PropTypes.array.isRequired,

  toggleDrawerMenu: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    userEmail: state.login.get('userEmail'),
    responsiveBreakpoint: state.layout.get('responsiveBreakpoint'),
    pageTitle: state.layout.get('pageTitle'),
    isDrawerMenuOpen: state.layout.get('isDrawerMenuOpen'),
    permissions: state.login.get('permissions'),
  };
};

const withRouterHeader = withRouter(
  connect(mapStateToProps, {
    toggleDrawerMenu: toggleDrawerMenuAction,
  })(Header)
);

export default withStyles(styles)(withRouterHeader);
