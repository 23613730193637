import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';
import agentSearchTypes from '../../../types/agentSearchTypes';
import { lookupAgents } from './autocomplete.actions';

class AgentBulkInviteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowConfirmDialog: false,
    };
  }

  handleSearch = async () => {
    const response = await this.props.lookupAgents(
      this.props.searchType,
      null,
      null,
      this.props.isOwnerSearch
    );
    if (response.type === 'LOOKUP_AGENTS_SUCCESS') {
      if (!isEmpty(response.response.data)) {
        this.props.onBulkInvite(response.response.data);
      }
    }
    this.handleCloseDialog();
  };

  handleShowDialog = () => {
    this.setState({
      isShowConfirmDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      isShowConfirmDialog: false,
    });
  };

  render() {
    const { buttonText, dialogHeaderText, dialogContentText } = this.props;
    const { isShowConfirmDialog } = this.state;

    return (
      <div>
        <SFGOutlinedButton style={{ width: '100%', height: 50 }} onClick={this.handleShowDialog}>
          {buttonText}
        </SFGOutlinedButton>

        <Dialog
          open={isShowConfirmDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogHeaderText}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <SFGOutlinedButton onClick={this.handleSearch} autoFocus>
              ADD
            </SFGOutlinedButton>
            <SFGOutlinedButton onClick={this.handleCloseDialog}>CANCEL</SFGOutlinedButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AgentBulkInviteButton.defaultProps = {
  searchType: agentSearchTypes.ALL,
  isOwnerSearch: false,
};

AgentBulkInviteButton.propTypes = {
  searchType: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  dialogHeaderText: PropTypes.string.isRequired,
  dialogContentText: PropTypes.string.isRequired,
  isOwnerSearch: PropTypes.bool,
  onBulkInvite: PropTypes.func.isRequired,

  lookupAgents: PropTypes.func.isRequired,
};

export default connect(null, {
  lookupAgents,
})(AgentBulkInviteButton);
