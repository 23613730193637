import { CompositeDecorator } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  const styles = {
    color: '#3b5998',
    textDecoration: 'underline'
  };
  return (
    <a href={url} style={styles}>
      {props.children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.array.isRequired,
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired
};

export default new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
]);
