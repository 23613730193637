import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import EventBadgeLanyardColorsForm from './eventBadgeLanyardColorsForm.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { UPDATE_EVENT_SUCCESS, clearSelectedEvent, getEvent, updateEvent } from './events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

/*
Added a badgeColors field to the event entity, which can be set on a PUT or POST to /admin/events. It’s an array of objects with a status and color, e.g.
"badgeColors": [{"status": "VIP", "color": "red"}]
*/

class EventEditContainer extends Component {
  componentDidMount() {
    this.props.setPageTitle('Badge Lanyard Colors');
    this.props.clearSelectedEvent();
    this.props.getEvent(this.props.match.params.eventId);
  }

  handleEdit = async eventData => {
    const response = await this.props.updateEvent(this.props.selectedEvent.id, eventData);

    if (response.type === UPDATE_EVENT_SUCCESS) {
      this.props.handleToastMessage('Event updated.');
    } else {
      this.props.handleToastMessage('Failed to update event badge colors.', true);
    }
  };

  render() {
    const { selectedEvent, isLoading } = this.props;

    return (
      <div className="container">
        {isLoading && isEmpty(selectedEvent) && <LoadingOverlay />}

        {!isEmpty(selectedEvent) && (
          <Grid container justify="center">
            <Grid item xs={4}>
              <Typography color="primary" variant="title">
                Badge Lanyard Colors
              </Typography>
              <EventBadgeLanyardColorsForm event={selectedEvent} submitAction={this.handleEdit} />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

EventEditContainer.defaultProps = {
  selectedEvent: null,
};

EventEditContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

  selectedEvent: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  clearSelectedEvent: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedEvent: state.events.get('selectedEvent'),
    isLoading: state.events.get('isLoading'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    clearSelectedEvent,
    getEvent,
    updateEvent,
    handleToastMessage,
  })(EventEditContainer)
);
