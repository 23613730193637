import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty, omit, uniqBy } from 'lodash';
import { withRouter } from 'react-router-dom';

import AgentAutocomplete from '../autocomplete/agentAutocomplete.container';
import AgentBulkInviteButton from '../autocomplete/agentBulkInviteButton.component';
import CustomValidatedTextEntry from '../customValidatedTextEntry/customValidatedTextEntry.component';
import EventInviteTable from '../eventInviteList/eventInviteTable.component';
import MaxWidthDialog from '../dialogs/maxWidthDialog.component';
import agentSearchTypes from '../../../types/agentSearchTypes';
import { createNotification } from '../../notifications/notifications.actions';
import { handleToastMessage } from '../layout.actions';

import inviteeFormTypes from '../../../types/inviteeFormTypes';

class UserInviteeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      manualEmailEntry: '',
      agent: '',
      isShowMessageDialog: false,
      messageDialogTitle: '',
      messageDialogText: '',
      messageDialogUrl: '',
      messageDialogUrlText: '',
    };
  }

  componentDidMount() {
    const stateFields = ['emails'];
    const updateState = {};

    stateFields.forEach(field => {
      const val = this.props[field];
      if (!isEmpty(val)) {
        updateState[field] = val;
      }
    });

    this.setState(updateState);
  }

  updateField = (field, value) => {
    let updateState = { ...this.state };
    updateState[field] = value;
    // if deselecting an event,
    // clear bulk invitation id from lifted state
    // and clear checkboxes

    if (field === 'bulkInvitationEventId' && value === '') {
      // clear lifted state
      this.props.onFormUpdate({
        bulkInvitationEventId: value,
        isIncludeAllCheckedIn: false,
        isIncludeAllRegistrants: false,
      });

      // clear local form state
      updateState.isIncludeAllCheckedIn = false;
      updateState.isIncludeAllRegistrants = false;
    }

    this.setState(updateState);

    // omit local state from being lifted
    updateState = omit(
      updateState,
      'manualEmailEntry',
      'agent',
      'isShowMessageDialog',
      'messageDialogTitle',
      'messageDialogText',
      'messageDialogUrl',
      'messageDialogUrlText'
    );

    this.props.onFormUpdate(updateState);
  };

  handleAutocompleteSelection = suggestion => {
    const { emails } = this.state;

    if (emails.findIndex(item => item.email === suggestion.email) === -1) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const updateEmails = [...this.state.emails, suggestion];
      this.setState({
        emails: updateEmails,
      });
      this.props.onFormUpdate({ emails: updateEmails });
    }
  };

  handleAddManualEmail = email => {
    const { emails } = this.state;
    if (emails.findIndex(item => item.email === email) === -1) {
      const updateEmails = [
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.emails,
        {
          name: '',
          email,
        },
      ];

      this.setState({
        manualEmailEntry: '',
        emails: updateEmails,
      });
      this.props.onFormUpdate({ emails: updateEmails });
    }
  };

  handleSendMessage = async () => {
    const notificationEmails = this.state.emails.filter(
      emailItem =>
        emailItem.response === inviteeFormTypes.INVITEE_RESPONSE_YES ||
        emailItem.response === inviteeFormTypes.INVITEE_RESPONSE_PENDING
    );
    if (notificationEmails.length > 0) {
      const {
        messageDialogText,
        messageDialogTitle,
        messageDialogUrl,
        messageDialogUrlText,
      } = this.state;
      const mappedEmails = notificationEmails.map(emailItem => emailItem.email);
      const notificationObject = {
        title: messageDialogTitle,
        description: messageDialogText,
        recipientEmails: mappedEmails,
      };

      if (!isEmpty(messageDialogUrl)) notificationObject.url = messageDialogUrl;
      if (!isEmpty(messageDialogUrlText)) notificationObject.urlText = messageDialogUrlText;

      const response = await this.props.createNotification(notificationObject);

      if (response.type === 'CREATE_NOTIFICATION_SUCCESS') {
        this.props.handleToastMessage('The notification has been sent successfully.');
        this.handleMessageDialogClose();
      } else {
        this.props.handleToastMessage(
          'Failed to send the notification. Please check your connection and try again.',
          true
        );
      }
    }
  };

  handleInviteListUpdate = emails => {
    this.setState({ emails });
    this.props.onFormUpdate({ emails });
  };

  handleAddBulkEmails = emails => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const updateEmails = uniqBy([...this.state.emails, ...emails], 'email');

    this.setState({
      emails: updateEmails,
    });

    this.props.onFormUpdate({ emails: updateEmails });
  };

  handleShowMessageDialog = () => {
    this.setState({
      isShowMessageDialog: true,
    });
  };

  getMessageDialogElement = () => {
    return (
      <div>
        <TextField
          label="Notification Title"
          type="text"
          margin="normal"
          value={this.state.messageDialogTitle}
          onChange={e => this.updateField('messageDialogTitle', e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Notification Text"
          type="text"
          margin="normal"
          value={this.state.messageDialogText}
          onChange={e => this.updateField('messageDialogText', e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="URL"
          type="text"
          margin="normal"
          value={this.state.messageDialogUrl}
          onChange={e => this.updateField('messageDialogUrl', e.target.value)}
          fullWidth
          required={false}
        />
        <TextField
          label="URL Text"
          type="text"
          margin="normal"
          value={this.state.messageDialogUrlText}
          onChange={e => this.updateField('messageDialogUrlText', e.target.value)}
          fullWidth
          required={false}
        />
      </div>
    );
  };

  handleMessageDialogClose = () => {
    this.setState({
      messageDialogTitle: '',
      messageDialogText: '',
      messageDialogUrl: '',
      messageDialogUrlText: '',
      isShowMessageDialog: false,
    });
  };

  render() {
    const { headingText, isReadOnly, canDeleteEntries, isAllowMessageDialog } = this.props;
    const { isShowMessageDialog, messageDialogTitle, messageDialogText } = this.state;

    return (
      <Grid container spacing={32}>
        {!isReadOnly && (
          <Fragment>
            <Grid item container xs={12} sm={6} alignContent="flex-start">
              <Grid item xs={12}>
                <Typography variant="subheading" style={{ marginTop: '1rem' }}>
                  {headingText.concat('s')}
                </Typography>
              </Grid>

              <Grid item container spacing={16}>
                <Grid item xs={12} md={6}>
                  <AgentBulkInviteButton
                    searchType={agentSearchTypes.UPLINE}
                    buttonText="Add All Upline"
                    onBulkInvite={this.handleAddBulkEmails}
                    dialogHeaderText="Add All Upline"
                    dialogContentText="You are about to add your upline to the invitation list."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AgentBulkInviteButton
                    searchType={agentSearchTypes.MANAGER}
                    buttonText="Add Immediate Upline"
                    onBulkInvite={this.handleAddBulkEmails}
                    dialogHeaderText="Add Immediate Upline"
                    dialogContentText="You are about to add your immediate upline (managers) to the invitation list."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AgentBulkInviteButton
                    searchType={agentSearchTypes.DOWNLINE}
                    buttonText="Add All Downline"
                    onBulkInvite={this.handleAddBulkEmails}
                    dialogHeaderText="Add All Downline"
                    dialogContentText="You are about to add your downline to the invitation list."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AgentBulkInviteButton
                    searchType={agentSearchTypes.DIRECT}
                    buttonText="Add Immediate Downline"
                    onBulkInvite={this.handleAddBulkEmails}
                    dialogHeaderText="Add Immediate Downline"
                    dialogContentText="You are about to add your immediate downline to the invitation list."
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <AgentAutocomplete
                  initialValue={this.state.agent}
                  label="Downline Agent (autocomplete)"
                  placeholder="Enter an agent's name"
                  onSelect={suggestion => this.handleAutocompleteSelection(suggestion)}
                  searchType={agentSearchTypes.ALL}
                  clearInputOnSelect
                />
                <CustomValidatedTextEntry
                  label="Manual E-Mail Entry"
                  name="manualEmailEntry"
                  value={this.state.manualEmailEntry}
                  onChange={e => this.updateField('manualEmailEntry', e.target.value.toLowerCase())}
                  validators={['required', 'isEmail']}
                  errorMessages={['Required', 'Must be a valid e-mail address']}
                  fullWidth
                  actionText="ADD"
                  handleAction={email => this.handleAddManualEmail(email)}
                />
              </Grid>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12} sm={6}>
          <EventInviteTable
            headingText={headingText}
            inviteList={this.state.emails}
            onListUpdated={this.handleInviteListUpdate}
            isReadOnly={isReadOnly}
            canDeleteEntries={canDeleteEntries}
            handleSendMessage={this.handleShowMessageDialog}
            isAllowMessageDialog={isAllowMessageDialog}
          />
        </Grid>

        {isAllowMessageDialog === true && (
          <MaxWidthDialog
            open={isShowMessageDialog}
            headingText="Send Message to All Accepted/Maybe"
            actionText="Send"
            cancelText="Cancel"
            dialogContentElem={this.getMessageDialogElement()}
            handleAction={this.handleSendMessage}
            onDialogClose={this.handleMessageDialogClose}
            isActionDisabled={isEmpty(messageDialogTitle) || isEmpty(messageDialogText)}
          />
        )}
      </Grid>
    );
  }
}

UserInviteeForm.defaultProps = {
  isReadOnly: false,
  isAllowMessageDialog: false,
};

UserInviteeForm.propTypes = {
  onFormUpdate: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  isAllowMessageDialog: PropTypes.bool,
  createNotification: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  canDeleteEntries: PropTypes.bool.isRequired,
};

export default withRouter(
  connect(null, {
    createNotification,
    handleToastMessage,
  })(UserInviteeForm)
);
