import Immutable from 'immutable';
import { findIndex, isEmpty } from 'lodash';
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  CREATE_PRODUCT_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  SET_SELECTED_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  CLEAR_SELECTED_PRODUCT
} from './products.actions';

const initialState = Immutable.Map({
  products: [],
  selectedProduct: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS: {
      return state.set('products', action.response.data).set('isLoading', false);
    }

    case GET_PRODUCTS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_PRODUCTS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CREATE_PRODUCT_SUCCESS: {
      const products = [...state.get('products')];
      const newProduct = { ...action.response };
      products.push(newProduct);

      return state.set('products', products).set('isLoading', false);
    }

    case GET_PRODUCT_SUCCESS:
      return state.set('selectedProduct', action.response).set('isLoading', false);

    case GET_PRODUCT_FAILURE:
      return state.set('isLoading', false);

    case GET_PRODUCT_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_PRODUCT:
      return state.set('selectedProduct', action.response);

    case CLEAR_SELECTED_PRODUCT:
      return state.set('selectedProduct', null);

    case UPDATE_PRODUCT_SUCCESS: {
      let selectedProduct = { ...state.get('selectedProduct') };
      const products = [...state.get('products')];
      const productId = action.actionMetadata;

      if (!isEmpty(selectedProduct) && selectedProduct.id === productId) {
        selectedProduct = Object.assign(selectedProduct, action.payload);
      }

      const idx = findIndex(products, { id: productId });
      if (idx > -1) {
        products[idx] = Object.assign(products[idx], action.payload);
      }

      return state
        .set('selectedProduct', selectedProduct)
        .set('products', products)
        .set('isLoading', false);
    }

    case DELETE_PRODUCT_SUCCESS: {
      const products = [...state.get('products')];

      const idx = findIndex(products, { id: action.actionMetadata.productId });
      if (idx > -1) {
        products.splice(idx, 1);
      }

      return state.set('products', products).set('isLoading', false);
    }

    default:
      return state;
  }
}
