import Immutable from 'immutable';
import { findIndex } from 'lodash';
import {
  GET_SPECIAL_EVENTS_REQUEST,
  GET_SPECIAL_EVENTS_FAILURE,
  GET_SPECIAL_EVENTS_SUCCESS,
  DELETE_SPECIAL_EVENT_REQUEST,
  DELETE_SPECIAL_EVENT_SUCCESS,
  DELETE_SPECIAL_EVENT_FAILURE,
  CLEAR_SPECIAL_EVENTS,
  GET_SPECIAL_EVENT_SUCCESS,
  GET_SPECIAL_EVENT_FAILURE,
  GET_SPECIAL_EVENT_REQUEST
} from './specialEvents.actions';

const initialState = Immutable.Map({
  specialEvents: [],
  specialEventSessions: [],
  selectedEvent: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SPECIAL_EVENTS_SUCCESS:
      return state.set('specialEvents', action.response.data).set('isLoading', false);

    case GET_SPECIAL_EVENTS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_SPECIAL_EVENTS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_SPECIAL_EVENT_REQUEST:
      return state.set('selectedEvent', null).set('isLoading', true);

    case GET_SPECIAL_EVENT_SUCCESS:
      return state.set('selectedEvent', action.response.data).set('isLoading', false);

    case GET_SPECIAL_EVENT_FAILURE:
      return state.set('isLoading', false);

    case DELETE_SPECIAL_EVENT_SUCCESS: {
      const specialEvents = [...state.get('specialEvents')];

      const idx = findIndex(specialEvents, { id: action.actionMetadata.specialEventId });
      if (idx > -1) {
        specialEvents.splice(idx, 1);
      }

      return state.set('specialEvents', specialEvents).set('isLoading', false);
    }

    case DELETE_SPECIAL_EVENT_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case DELETE_SPECIAL_EVENT_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CLEAR_SPECIAL_EVENTS:
      return state.set('specialEvents', []);

    default:
      return state;
  }
}
