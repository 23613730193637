import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { FormControl, Grid, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty, isNil } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import PurchaserInfo from '../registrations/purchaserInfo.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  suffix: '',
  middleName: '',
  alsoKnownAs: '',
  type: 'agent',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  email: '',
  agencyManagerId: '',
  agentId: '',
  spouseAgentCode: '',
  spouse: null,
  dietaryRestrictions: '',
  isFormValid: false,
  overwriteBadgeName: true,
  hasPreparedForm: false,
};

class TicketAssignment extends Component {
  state = { ...INITIAL_STATE };

  form = createRef();

  componentDidMount() {
    this.isFormValid();
  }

  componentDidUpdate() {
    if (isNil(this.props.assignee) || isEmpty(this.props.assignee) || this.state.hasPreparedForm)
      return;

    this.setState(prevState => {
      const toReturn = {
        ...prevState,
        ...this.props.assignee,
      };
      Object.keys(toReturn).forEach(field => {
        if (!toReturn[field]) {
          toReturn[field] = '';
        }
      });

      // do these here otherwise they will get set to empty string
      toReturn.overwriteBadgeName = false;
      toReturn.hasPreparedForm = true;

      this.isFormValid();
      return toReturn;
    });
  }

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;

    /* Only run the following if the user hasn't entered anything into the badge name field. */
    if (state.overwriteBadgeName) {
      /* If they are currently entering something in the badge name, don't automatically enter anything in the field again. */
      if (field === 'alsoKnownAs') {
        state.overwriteBadgeName = false;
      } else if (field === 'firstName') {
        /* If the user is clearing out the firstName field and has already cleared out the lastName field, clear out the badge name field. */
        if (state.lastName.length === 0 && value.length === 0) {
          state.alsoKnownAs = '';
        } else {
          /* If the user is entering a first name, copy it into the badge name */
          state.alsoKnownAs = `${value} ${state.lastName}`;
        }
      } else if (field === 'lastName') {
        /* If the user is clearing out the lastName field and has already cleared out the firstName field, clear out the badge name field. */
        if (state.firstName.length === 0 && value.length === 0) {
          state.alsoKnownAs = '';
        } else {
          /* If the user is entering a last name, copy it into the badge name */
          state.alsoKnownAs = `${state.firstName} ${value}`;
        }
      }
    }

    /* Update the state and then check to see if the form is valid */
    this.setState(state, () => this.isFormValid());
  };

  submitForm = () => {
    const formData = { ...this.state };

    /* Remove the isFormValid and overwriteBadgeName keys since they are only used in this component. */
    delete formData.isFormValid;
    delete formData.overwriteBadgeName;
    delete formData.hasPreparedForm;

    /* Remove read only keys of the assignee object. */
    delete formData.id;
    delete formData.createdDate;
    delete formData.updatedDate;
    delete formData.agencyManager;

    // remove spouseAgent and spouse from submission - only send spouseAgentCode
    delete formData.spouseAgent;
    delete formData.spouse;

    /* If a value is empty and the original value doesn't exist or is empty,
       don't send anything. But if this is a change from the original, send null
       to overwrite it. */
    Object.keys(formData).forEach(key => {
      if (formData[key] === '')
        if (
          isNil(this.props.assignee) ||
          isEmpty(this.props.assignee) ||
          this.props.assignee[key] === ''
        ) {
          delete formData[key];
        } else {
          formData[key] = null;
        }
    });

    this.props.handleSubmit(formData);
  };

  isFormValid = () => {
    const valid = this.form.current && this.form.current.isFormValid();
    /* If the form valid state has changed, update the isFormValid key. */
    if (valid !== this.state.isFormValid) this.setState({ isFormValid: valid });
  };

  render() {
    const { classes } = this.props;

    return (
      <ValidatorForm ref={this.form} onSubmit={this.submitForm}>
        <PurchaserInfo state={this.state} updateField={this.updateField} isShowSpouseField />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="subheading">Attendance Information</Typography>
            <FormControl>
              <TextValidator
                className={classes.textValidator}
                label="Name to be Printed on Badge"
                placeholder="At least first and last name"
                type="text"
                name="alsoKnownAs"
                margin="normal"
                helperText="First and Last as you'd like them to be seen"
                value={this.state.alsoKnownAs}
                onChange={e => this.updateField('alsoKnownAs', e.target.value)}
                validators={['required']}
                errorMessages={['Name on Badge is required']}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <TextValidator
                label="Dietary Restrictions"
                name="Dietary Restrictions"
                multiline
                rows={6}
                InputProps={{
                  classes: {
                    underline: classes.dietaryRestrictions,
                    root: classes.dietaryRestrictions,
                  },
                  className: classes.drInput,
                }}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.drLabel,
                    focused: classes.drFocusLabel,
                  },
                }}
                FormHelperTextProps={{
                  classes: { root: classes.helperText },
                }}
                onChange={e => this.updateField('dietaryRestrictions', e.target.value)}
                value={this.state.dietaryRestrictions}
                validators={['required']}
                errorMessages={['Dietary Restrictions is required']}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SFGOutlinedButton
              disabled={!this.state.isFormValid}
              style={{ margin: '3rem 0' }}
              type="submit"
            >
              Submit Ticket Assignment
            </SFGOutlinedButton>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

const INPUT_MAX_WIDTH = 320;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    padding: '0.5rem 1rem',
  },
  formControl: {
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  subheading: {
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
  dietaryRestrictions: {
    width: '475px',
    '&:before': {
      borderWidth: '0px !important',
    },
    '&:after': {
      borderWidth: '0px',
    },
    '&:hover': {
      '&:before': {
        borderWidth: '0px !important',
      },
    },
  },
  drLabel: {
    marginLeft: '0.3rem',
  },
  drFocusLabel: {
    transform: 'translate(-05px, 0px) scale(0.75)',
  },
  drInput: {
    padding: '5px',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    '&:hover': {
      borderColor: '#00256b',
    },
    '&:focus': {
      borderColor: '#00256b',
    },
    maxWidth: INPUT_MAX_WIDTH,
  },
  helperText: {
    marginLeft: '1rem',
  },
  textValidator: {
    maxWidth: INPUT_MAX_WIDTH,
    width: '100%',
    marginBottom: '1rem',
  },
});

TicketAssignment.defaultProps = {
  assignee: null,
};

TicketAssignment.propTypes = {
  classes: PropTypes.object.isRequired,

  assignee: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(TicketAssignment);
