import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import ScheduleSlotForm from './scheduleSlotForm.component';
import { createScheduleSlot, CREATE_SCHEDULE_SLOT_SUCCESS } from './schedule.actions';

class ScheduleSlotNew extends Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
  }

  componentWillMount() {
    if (isEmpty(this.props.selectedEvent)) {
      this.props.history.replace('/events');
    }
    this.props.setPageTitle('New Schedule Slot');
  }

  async handleCreate(scheduleSlotData) {
    const response = await this.props.createScheduleSlot(
      this.props.selectedEvent.id,
      scheduleSlotData
    );

    if (response.type === CREATE_SCHEDULE_SLOT_SUCCESS) {
      this.props.handleToastMessage('Schedule Slot Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create schedule slot.', true);
    }
  }

  render() {
    return (
      <div className="container">
        <ScheduleSlotForm submitAction={this.handleCreate} />
      </div>
    );
  }
}

ScheduleSlotNew.defaultProps = {
  selectedEvent: null
};

ScheduleSlotNew.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  selectedEvent: PropTypes.object,

  createScheduleSlot: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedEvent: state.events.get('selectedEvent')
});

export default withRouter(
  connect(mapStateToProps, {
    createScheduleSlot,
    handleToastMessage,
    setPageTitle
  })(ScheduleSlotNew)
);
