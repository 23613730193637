import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { omit, isEmpty } from 'lodash';
import firebase from 'firebase/app';

import { uploadMediaAsFile } from '../../utilities/mediaStorage';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import NotificationForm from './notificationForm.component';
import {
  clearSelectedNotification,
  createNotification,
  CREATE_NOTIFICATION_SUCCESS
} from './notifications.actions';
import inviteeFormTypes from '../../types/inviteeFormTypes';

// Empty description html ends up with this string
const EMPTY_DESCRIPTION_HTML = '<p><br></p>';

class NotificationNewContainer extends Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
  }

  componentWillMount() {
    this.props.setPageTitle('New Notification');
    this.props.clearSelectedNotification();
  }

  async handleCreate(notificationData) {
    // map bulk invitation data
    const recipients = {};
    if (notificationData.isIncludeAllRegistrants === true) {
      recipients.type = inviteeFormTypes.BULK_INVITATION_ALL_REGISTERED;
    }

    if (notificationData.isIncludeAllCheckedIn === true) {
      recipients.type = inviteeFormTypes.BULK_INVITATION_CHECKED_IN;
    }

    if (!isEmpty(notificationData.bulkInvitationEventId) && !isEmpty(recipients.type)) {
      recipients.eventId = notificationData.bulkInvitationEventId;
    }

    if (!isEmpty(recipients)) {
      notificationData.recipients = recipients;
    }

    // Save uploaded image
    if (!isEmpty(notificationData.image)) {
      const time = Date.now();
      const userId = await firebase.auth().currentUser.uid;

      const ref = `/uploads/${userId}/notification_image_${time}.png`;
      await uploadMediaAsFile(notificationData.image.fileData, ref, 'image/png');

      // Instead of image file we want to save the image url
      delete notificationData.image;
      notificationData.imageUrl = ref;
    } else {
      delete notificationData.imageUrl;
      delete notificationData.image;
    }

    // omit mapped fields
    notificationData = omit(notificationData, [
      'bulkInvitationEventId',
      'isIncludeAllCheckedIn',
      'isIncludeAllRegistrants',
      'manualEmailEntry'
    ]);

    // dont send empty fields
    if (isEmpty(notificationData.deepLink)) delete notificationData.deepLink;
    if (isEmpty(notificationData.owner)) delete notificationData.owner;
    if (isEmpty(notificationData.agent)) delete notificationData.agent;
    if (
      isEmpty(notificationData.descriptionHtml) ||
      notificationData.descriptionHtml === EMPTY_DESCRIPTION_HTML
    ) {
      delete notificationData.descriptionHtml;
    }

    const response = await this.props.createNotification(notificationData);

    if (response.type === CREATE_NOTIFICATION_SUCCESS) {
      this.props.handleToastMessage('Notification Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create notification.', true);
    }
  }

  render() {
    return (
      <div className="container">
        <NotificationForm
          agencies={this.props.agencies}
          events={this.props.events}
          submitAction={this.handleCreate}
        />
      </div>
    );
  }
}

NotificationNewContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  agencies: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,

  createNotification: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  clearSelectedNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  agencies: state.bigFive.get('agencies'),
  events: state.events.get('eventsAdmin')
});

export default withRouter(
  connect(mapStateToProps, {
    createNotification,
    handleToastMessage,
    setPageTitle,
    clearSelectedNotification
  })(NotificationNewContainer)
);
