import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import ScheduleSlotForm from './scheduleSlotForm.component';
import {
  updateScheduleSlot,
  UPDATE_SCHEDULE_SLOT_SUCCESS,
  getScheduleSlotSessions,
  clearScheduleSlotSessions
} from './schedule.actions';
import ScheduleSlotSessions from './scheduleSlotSessions.container';

class ScheduleSlotEdit extends Component {
  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentWillMount() {
    if (isEmpty(this.props.selectedEvent)) {
      this.props.history.replace('/events');
    }
    this.props.setPageTitle('Edit Time Slot');

    if (
      !isEmpty(this.props.sessions) &&
      this.props.sessions[0].slotId !== this.props.match.params.slotId
    ) {
      this.props.clearScheduleSlotSessions();
    }

    this.props.getScheduleSlotSessions(this.props.match.params.slotId);
    // this.props.clearSelectedEvent();
  }

  async handleUpdate(scheduleSlotData) {
    const response = await this.props.updateScheduleSlot(
      this.props.match.params.slotId,
      this.props.selectedEvent.id,
      scheduleSlotData
    );

    if (response.type === UPDATE_SCHEDULE_SLOT_SUCCESS) {
      this.props.handleToastMessage('Schedule Slot Updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update schedule slot.', true);
    }
  }

  render() {
    return (
      <div className="container">
        <ScheduleSlotSessions />
        <Typography variant="headline">Time Slot Details</Typography>
        <ScheduleSlotForm
          scheduleSlot={this.props.location.state.slot}
          submitAction={this.handleUpdate}
        />
      </div>
    );
  }
}

ScheduleSlotEdit.defaultProps = {
  selectedEvent: null
};

ScheduleSlotEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  selectedEvent: PropTypes.object,
  sessions: PropTypes.array.isRequired,

  updateScheduleSlot: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getScheduleSlotSessions: PropTypes.func.isRequired,
  clearScheduleSlotSessions: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedEvent: state.events.get('selectedEvent'),
  sessions: state.schedule.get('scheduleSlotSessions')
});

export default withRouter(
  connect(mapStateToProps, {
    updateScheduleSlot,
    handleToastMessage,
    setPageTitle,
    getScheduleSlotSessions,
    clearScheduleSlotSessions
  })(ScheduleSlotEdit)
);
