import firebase from 'firebase';
import { isNil } from 'lodash';

import { CALL_API } from '../../middleware/api';

export const GET_DOWNLINE_AGENTS_REQUEST = 'GET_DOWNLINE_AGENTS_REQUEST';
export const GET_DOWNLINE_AGENTS_SUCCESS = 'GET_DOWNLINE_AGENTS_SUCCESS';
export const GET_DOWNLINE_AGENTS_FAILURE = 'GET_DOWNLINE_AGENTS_FAILURE';

export const GET_AGENT_SESSIONS_REQUEST = 'GET_AGENT_SESSIONS_REQUEST';
export const GET_AGENT_SESSIONS_SUCCESS = 'GET_AGENT_SESSIONS_SUCCESS';
export const GET_AGENT_SESSIONS_FAILURE = 'GET_AGENT_SESSIONS_FAILURE';

export const GET_AGENT_TICKETS_REQUEST = 'GET_AGENT_TICKETS_REQUEST';
export const GET_AGENT_TICKETS_SUCCESS = 'GET_AGENT_TICKETS_SUCCESS';
export const GET_AGENT_TICKETS_FAILURE = 'GET_AGENT_TICKETS_FAILURE';

export const GET_AGENTS_REQUEST = 'GET_AGENTS_REQUEST';
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';
export const GET_AGENTS_FAILURE = 'GET_AGENTS_FAILURE';

export const getDownlineAgents = (eventId = null) => {
  let endpoint = 'v1/agents?type=downline&mode=tree';

  if (eventId) {
    endpoint = `${endpoint}&eventId=${eventId}`;
  }

  return {
    [CALL_API]: {
      types: [
        GET_DOWNLINE_AGENTS_REQUEST,
        GET_DOWNLINE_AGENTS_SUCCESS,
        GET_DOWNLINE_AGENTS_FAILURE,
      ],
      authenticated: true,
      endpoint,
      method: 'GET',
      actionMetadata: {
        eventId,
      },
    },
  };
};

export const getSessionsForAgent = email => {
  return {
    [CALL_API]: {
      types: [GET_AGENT_SESSIONS_REQUEST, GET_AGENT_SESSIONS_SUCCESS, GET_AGENT_SESSIONS_FAILURE],
      authenticated: true,
      endpoint: `v1/agent/sessions?email=${encodeURIComponent(email)}`,
      method: 'GET',
      actionMetadata: {
        email,
      },
    },
  };
};

export const getTicketsForAgent = email => {
  return {
    [CALL_API]: {
      types: [GET_AGENT_TICKETS_REQUEST, GET_AGENT_TICKETS_SUCCESS, GET_AGENT_TICKETS_FAILURE],
      authenticated: true,
      endpoint: `v1/agent/tickets?email=${encodeURIComponent(email)}`,
      method: 'GET',
      actionMetadata: {
        email,
      },
    },
  };
};

export const getAgents = query => {
  return {
    [CALL_API]: {
      types: [GET_AGENTS_REQUEST, GET_AGENTS_SUCCESS, GET_AGENTS_FAILURE],
      authenticated: !isNil(firebase.auth().currentUser),
      endpoint: `v1/agentsearch?query=${encodeURIComponent(query)}`,
      method: 'GET',
    },
  };
};
