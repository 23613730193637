import EMAIL_ADDRESSES from './emailAddresses';

export default Object.freeze({
  ACCOUNT_NOT_VERIFIED: `Your account must be verified in order to log in. Please check your email for verification steps or contact ${EMAIL_ADDRESSES.SUPPORT}.`,
  GET_EVENT_SCHEDULE_FAILURE: 'There was an error retrieving the event schedule. Check back later.',
  EMAIL_TEMPLATES_UPDATE_FAILURE: 'There was an error updating the email templates.',
  SEND_TICKET_FAILURE: 'Unable to send ticket. Please try again.',
  GET_DELEGATED_TICKET_FAILURE: 'Unable to find ticket.',
  CANCEL_DELEGATED_TICKET_FAILURE: 'Unable to unsend ticket.',
  RESET_PASSWORD_FAILURE: 'Failed to reset password.',
});
