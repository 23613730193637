import firebase from 'firebase/app';
import { Auth0Client } from '@auth0/auth0-spa-js';

import { LOGIN_AUTH_0_SUCCESS, loginAuth0, setUserEmail } from '../modules/login/login.actions';

export const QUERY_PARAMETER_EMAIL_TO_VERIFY = 'emailToVerify';
export const QUERY_PARAMETER_NON_ADMIN_EMAIL = 'nonAdminEmail';
export const QUERY_PARAMETER_SHOW_VERIFY_ACTION = 'showVerifyAction';

class LoginHandler {
  constructor() {
    this.auth0 = new Auth0Client({
      client_id: process.env.REACT_APP_AUTH_0_CLIENT_ID,
      domain: process.env.REACT_APP_AUTH_0_DOMAIN,
      redirect_uri: process.env.REACT_APP_USER_BASE_URL,
    });
  }

  async handleSSO(store) {
    const query = window.location.search;
    if (query.includes('sso=')) {
      await this.auth0.loginWithRedirect({});
    } else if (query.includes('code=') && query.includes('state=')) {
      await this.auth0.handleRedirectCallback();
      try {
        const token = await this.auth0.getTokenSilently();
        const result = await store.dispatch(loginAuth0(token));

        if (result.type === LOGIN_AUTH_0_SUCCESS) {
          try {
            /* Trigger the firebase login */
            await firebase.auth().signInWithCustomToken(result.response.token);
            /* Save the email address for use later. */
            const user = await this.auth0.getUser();
            store.dispatch(setUserEmail(user.email));
          } catch (e) {
            console.log(`Error during login: ${e.message}`);
          }
        } else {
          console.log(`Login failed`);
        }
      } catch (err) {
        // Fails if it can't return a token for some reason
        console.log(err);
      }
    }
  }

  handleLogout(
    { emailToVerify, showVerifyAction, nonAdminEmail } = {
      emailToVerify: null,
      showVerifyAction: false,
      nonAdminEmail: null,
    }
  ) {
    let returnTo = `${process.env.REACT_APP_USER_BASE_URL}?`;

    if (nonAdminEmail) {
      returnTo += `${QUERY_PARAMETER_NON_ADMIN_EMAIL}=${encodeURIComponent(nonAdminEmail)}&`;
    }

    if (emailToVerify) {
      returnTo += `${QUERY_PARAMETER_EMAIL_TO_VERIFY}=${encodeURIComponent(emailToVerify)}&`;
    }

    if (showVerifyAction) {
      returnTo += `${QUERY_PARAMETER_SHOW_VERIFY_ACTION}=true`;
    }

    // example url result <base_url>?emailToVerify=<emailToVerify>&showVerifyAction=true

    this.auth0.logout({
      returnTo,
    });
  }
}

export default new LoginHandler();
