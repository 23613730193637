import { combineReducers } from 'redux';

import agents from './modules/agents/agents.reducer';
import bigFive from './modules/bigFive/bigFive.reducer';
import cart from './modules/cart/cart.reducer';
import coupons from './modules/coupons/coupons.reducer';
import events from './modules/events/events.reducer';
import gatherings from './modules/gatherings/gatherings.reducer';
import layout from './modules/layout/layout.reducer';
import login from './modules/login/login.reducer';
import managers from './modules/managers/managers.reducer';
import mySchedule from './modules/mySchedule/mySchedule.reducer';
import notifications from './modules/notifications/notifications.reducer';
import products from './modules/products/products.reducer';
import profile from './modules/profile/profile.reducer';
import purchasePlans from './modules/purchasePlans/purchasePlans.reducer';
import purchases from './modules/purchases/purchases.reducer';
import registrations from './modules/registrations/registrations.reducer';
import reports from './modules/reports/reports.reducer';
import schedule from './modules/schedule/schedule.reducer';
import specialEvents from './modules/specialEvents/specialEvents.reducer';
import tickets from './modules/tickets/tickets.reducer';
import vips from './modules/vips/vips.reducer';
import { LOG_OUT } from './modules/login/login.actions';

const appReducer = combineReducers({
  bigFive,
  layout,
  login,
  events,
  registrations,
  managers,
  products,
  purchasePlans,
  cart,
  coupons,
  tickets,
  schedule,
  notifications,
  specialEvents,
  profile,
  reports,
  agents,
  vips,
  purchases,
  gatherings,
  mySchedule,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
