import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import ScheduleSlotSessionForm from './scheduleSlotSessionForm.component';
import {
  createScheduleSlotSession,
  CREATE_SCHEDULE_SLOT_SESSION_SUCCESS
} from './schedule.actions';

class ScheduleSlotSessionNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  componentWillMount() {
    if (isEmpty(this.props.selectedEvent)) {
      this.props.history.replace('/events');
    }
    this.props.setPageTitle('New Session');
  }

  handleCreate = async sessionData => {
    this.setState({
      isLoading: true
    });

    const response = await this.props.createScheduleSlotSession(
      this.props.location.state.slot.id,
      sessionData
    );

    if (response.type === CREATE_SCHEDULE_SLOT_SESSION_SUCCESS) {
      this.props.handleToastMessage('Session created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create session.', true);
    }

    this.setState({
      isLoading: false
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <div className="container">
        <ScheduleSlotSessionForm
          slot={this.props.location.state.slot}
          submitAction={this.handleCreate}
          cancelAction={() => this.props.history.goBack()}
          disableSubmit={isLoading}
        />
      </div>
    );
  }
}

ScheduleSlotSessionNew.defaultProps = {
  selectedEvent: null
};

ScheduleSlotSessionNew.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  selectedEvent: PropTypes.object,

  createScheduleSlotSession: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedEvent: state.events.get('selectedEvent')
});

export default withRouter(
  connect(mapStateToProps, {
    createScheduleSlotSession,
    handleToastMessage,
    setPageTitle
  })(ScheduleSlotSessionNew)
);
