import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import LoadingOverlay from '../layout/loadingOverlay.component';
import ProfileCard from './profileCard.component';
import { getMyTickets } from '../purchases/purchases.actions';
import { getProfile } from './profile.actions';
import { setPageTitle } from '../layout/layout.actions';

class Profile extends Component {
  componentDidMount() {
    this.props.setPageTitle('Profile');
    /* Only run the network requests if they aren't already in progress. We check this because componentDidMount runs multiple times. */
    if (!this.props.isLoading) {
      /* SFGCR-720: Always pull the latest profile information. */
      this.props.getProfile();
      this.props.getMyTickets();
    }
  }

  render() {
    const { isLoading, profile } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <div className="container">
        <Grid container justify="center">
          {isEmpty(profile) ? (
            <Grid item xs={12}>
              {isEmpty(profile) && <p>No profile information available.</p>}
            </Grid>
          ) : (
            <Grid item xs md={6}>
              <ProfileCard profile={profile} />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getMyTickets: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile.get('profile'),
  isLoading: state.profile.get('isLoading') || state.events.get('isLoading'),
});

export default connect(mapStateToProps, {
  setPageTitle,
  getProfile,
  getMyTickets,
})(Profile);
