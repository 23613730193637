import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

class CustomAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      this.setState({ value: nextProps.initialValue });
    }
  }

  handleChange = (event, { newValue }) => {
    if (this.props.clearSelectionOnEdit === true) newValue = '';
    if (newValue === '' && this.props.onInputClear) this.props.onInputClear();

    this.setState({
      value: newValue,
    });

    /* If we are supposed to clear invalid selections on blur and handleChange isn't called by handleSelection,
       set the selectionChosen flag to false. */
    if (this.props.clearInvalidSelectionOnBlur && event !== null && newValue !== '') {
      this.setState({ selectionChosen: false });
    }

    if (this.props.onInputEdit) this.props.onInputEdit(newValue);
  };

  handleSelection = (event, selection) => {
    this.setState({ selectionChosen: true });
    if (this.props.clearInputOnSelect === true) {
      this.handleChange(null, { newValue: '' });
    }

    // console.log('selection:', selection);

    this.props.handleSelection(event, selection);
  };

  renderInput = inputProps => {
    const { classes, error, ref, label, ...other } = inputProps;

    return (
      <TextField
        error={error}
        fullWidth
        helperText={!isEmpty(this.props.helperText) ? this.props.helperText : ''}
        label={label}
        margin="normal"
        InputProps={{
          inputRef: ref,
          classes: {
            input: classes.input,
          },
          ...other,
        }}
      />
    );
  };

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;

    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  handleBlur = () => {
    if (this.props.clearInvalidSelectionOnBlur && !this.state.selectionChosen) {
      /* If the user clicks away without choosing a valid selection, clear the field */
      this.setState({ value: '' });
    }
  };

  render() {
    const { classes, error } = this.props;

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={this.props.suggestions}
        onSuggestionsFetchRequested={this.props.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.props.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSelection}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={this.props.getSuggestionValue}
        renderSuggestion={this.props.renderSuggestion}
        inputProps={{
          classes,
          error,
          label: this.props.label,
          placeholder: this.props.placeholder,
          value: this.state.value,
          onChange: this.handleChange,
          onBlur: this.handleBlur,
        }}
      />
    );
  }
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    // height: 250
  },
  suggestionsContainerOpen: {
    left: 0,
    marginTop: theme.spacing.unit,
    maxHeight: 250,
    overflowY: 'scroll',
    position: 'absolute',
    right: 0,
    zIndex: 10,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

CustomAutocomplete.defaultProps = {
  onInputClear: null,
  onInputEdit: null,

  clearInputOnSelect: false,
  clearInvalidSelectionOnBlur: false,
  clearSelectionOnEdit: false,
  error: false,
  helperText: '',
};

CustomAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,

  getSuggestionValue: PropTypes.func.isRequired,
  handleSuggestionsFetchRequested: PropTypes.func.isRequired,
  handleSuggestionsClearRequested: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  onInputClear: PropTypes.func,
  onInputEdit: PropTypes.func,
  clearInvalidSelectionOnBlur: PropTypes.bool,
  clearInputOnSelect: PropTypes.bool,

  clearSelectionOnEdit: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  initialValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
};

export default withStyles(styles)(CustomAutocomplete);
