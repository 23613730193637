import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { setResponsiveBreakpoint } from './layout.actions';
import responsiveBreakpoints from './responsiveBreakpoints';

class ResizeHandler extends Component {
  componentDidMount() {
    // Create the event. (This method is necessary for IE support)
    const event = document.createEvent('Event');
    // Define that the event name is 'resize'.
    event.initEvent('resize', true, true);

    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(this.handleResize, 100);
    });

    window.dispatchEvent(event);
  }

  /**
   * https://github.com/ReactTraining/react-router/issues/4630
   *
   * Currently there is an issue with react-router-dom where any route
   * that uses react-router's withRouter HOC will remount when the parent component
   * has properties that update. In this case, the responsiveBreakpoint property
   * gets tracked and updated on main layout, so any of the child routes that use withRouter
   * will get remounted whenever that property is changed. Using should component update lifecycle method
   * is not a good idea as that will prevent updates for children as well.
   *
   * The responsive breakpoint handling should be done in any component that needs to make use of it.
   *
   * For now, I elected to stop checking if the breakpoint is different, because this app doesn't need that
   * functionality. Apps with a sidebar for example, will want to use that there.
   */
  handleResize = () => {
    const width = window.innerWidth;
    let breakpoint;

    if (width < responsiveBreakpoints.SMALL.maxWidth) {
      breakpoint = responsiveBreakpoints.SMALL;
    } else if (
      width >= responsiveBreakpoints.SMALL.maxWidth &&
      width < responsiveBreakpoints.MEDIUM.maxWidth
    ) {
      breakpoint = responsiveBreakpoints.MEDIUM;
    } else if (width >= responsiveBreakpoints.MEDIUM.maxWidth) {
      breakpoint = responsiveBreakpoints.LARGE;
    }
    if (breakpoint !== this.props.responsiveBreakpoint) {
      this.props.setResponsiveBreakpoint(breakpoint);
    }
  };

  render() {
    return <div className="resizeHandler" />;
  }
}

ResizeHandler.propTypes = {
  responsiveBreakpoint: PropTypes.object.isRequired,
  setResponsiveBreakpoint: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    responsiveBreakpoint: state.layout.get('responsiveBreakpoint')
  };
};

export default connect(mapStateToProps, {
  setResponsiveBreakpoint
})(ResizeHandler);
