import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import EventCheckInQuestions from './eventCheckInQuestions.component';

class EventHelpDeskCheckInForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkInResponses: {}
    };
  }

  componentWillMount() {
    // dont require responses if no questions are provided
    if (
      isEmpty(this.props.event.checkinQuestions) ||
      this.props.event.checkinQuestions.questions.length <= 0
    ) {
      this.props.onFormUpdate({
        isValid: true
      });
    }
  }

  handleCheckInQuestionResponse = (id, value) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const updateResponses = { ...this.state.checkInResponses };
    updateResponses[id] = value;

    this.setState({
      checkInResponses: updateResponses
    });

    this.props.onFormUpdate({
      responses: updateResponses,
      isValid:
        Object.keys(updateResponses).length === this.props.event.checkinQuestions.questions.length
    });
  };

  render() {
    const { event, attendee } = this.props;
    const { checkInResponses } = this.state;

    return (
      <div className="container">
        {!isEmpty(event.checkinQuestions) && event.checkinQuestions.questions.length > 0 && (
          <EventCheckInQuestions
            questions={event.checkinQuestions.questions}
            responses={checkInResponses}
            handleCheckInQuestionResponse={this.handleCheckInQuestionResponse}
          />
        )}
        {(isEmpty(event.checkinQuestions) || event.checkinQuestions.questions.length <= 0) && (
          <div>{`This will complete check-in for ${attendee.name}`}</div>
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  formControl: {
    display: 'block'
  }
});

EventHelpDeskCheckInForm.defaultProps = {
  event: null,
  attendee: null
};

EventHelpDeskCheckInForm.propTypes = {
  event: PropTypes.object,
  attendee: PropTypes.object,
  onFormUpdate: PropTypes.func.isRequired
};

export default withStyles(styles)(EventHelpDeskCheckInForm);
