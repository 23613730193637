import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoadingOverlay from '../layout/loadingOverlay.component';
import NameUtils from '../../utilities/nameUtils';
import TicketAssignment from './ticketAssignment.component';
import {
  GET_ASSIGNEE_SUCCESS,
  UPDATE_ASSIGNEE_SUCCESS,
  clearPurchaserForAttendee,
  getAssignee,
  getPurchaserForAttendee,
  updateAssignee,
} from './tickets.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EditTicketAssignmentAdmin extends Component {
  state = {
    id: '',
    isLoading: false,
  };

  async componentDidMount() {
    this.props.setPageTitle(`Update Ticket Assignment`);

    this.props.clearPurchaserForAttendee();
    const assigneeId = this.props.match.params.id;
    this.setState({ id: assigneeId });

    const getAssigneeResponse = await this.props.getAssignee(assigneeId);
    if (getAssigneeResponse.type === GET_ASSIGNEE_SUCCESS) {
      this.props.getPurchaserForAttendee(getAssigneeResponse.response);
    }
  }

  handleSubmit = async formData => {
    this.setState({
      isLoading: true,
    });

    /* Update the assignee */
    const response = await this.props.updateAssignee(this.state.id, formData);
    if (response.type === UPDATE_ASSIGNEE_SUCCESS) {
      this.props.handleToastMessage('Updated ticket assignment', false);
      this.props.history.goBack();
    } else {
      this.setState({
        isLoading: false,
      });
      this.props.handleToastMessage('Failed to updated ticket assignment', true);
    }
  };

  render() {
    if (this.props.isLoading === true || this.state.isLoading === true) return <LoadingOverlay />;

    const { selectedAssigneePurchaser } = this.props;

    return (
      <div className="container">
        {selectedAssigneePurchaser && (
          <Typography variant="title" style={{ margin: '1rem 0 0 1rem' }}>
            {`Ticket Purchased By: ${NameUtils.getFormattedName(selectedAssigneePurchaser)}`}
          </Typography>
        )}
        <TicketAssignment assignee={this.props.assignee} handleSubmit={this.handleSubmit} />
      </div>
    );
  }
}

EditTicketAssignmentAdmin.defaultProps = {
  assignee: null,
  selectedAssigneePurchaser: null,
};

EditTicketAssignmentAdmin.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  assignee: PropTypes.object,
  selectedAssigneePurchaser: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getAssignee: PropTypes.func.isRequired,
  updateAssignee: PropTypes.func.isRequired,
  clearPurchaserForAttendee: PropTypes.func.isRequired,
  getPurchaserForAttendee: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    assignee: state.tickets.get('selectedAssignee'),
    selectedAssigneePurchaser: state.tickets.get('selectedAssigneePurchaser'),
    isLoading: state.tickets.get('isLoading'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getAssignee,
    updateAssignee,
    clearPurchaserForAttendee,
    getPurchaserForAttendee,
  })(EditTicketAssignmentAdmin)
);
