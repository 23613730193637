import { CALL_API } from '../../middleware/api';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCES';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const getProducts = eventId => {
  return {
    [CALL_API]: {
      types: [GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILURE],
      authenticated: true,
      endpoint: eventId ? `v1/events/${eventId}/products` : `admin/products`,
      method: 'GET'
    }
  };
};

export const createProduct = productData => {
  return {
    [CALL_API]: {
      types: [CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAILURE],
      authenticated: true,
      endpoint: `admin/products`,
      method: 'POST',
      payload: productData
    }
  };
};

export const getProduct = productId => {
  return {
    [CALL_API]: {
      types: [GET_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE],
      authenticated: true,
      endpoint: `admin/products/${productId}`,
      method: 'GET',
      actionMetadata: {
        productId
      }
    }
  };
};

export const setSelectedProduct = product => {
  return {
    type: SET_SELECTED_PRODUCT,
    response: product
  };
};

export const clearSelectedProduct = () => {
  return {
    type: CLEAR_SELECTED_PRODUCT
  };
};

export const updateProduct = (productId, productData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILURE],
      authenticated: true,
      endpoint: `admin/products/${productId}`,
      method: 'PUT',
      payload: productData,
      actionMetadata: {
        productId
      }
    }
  };
};

export const deleteProduct = productId => {
  return {
    [CALL_API]: {
      types: [DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE],
      authenticated: true,
      endpoint: `admin/products/${productId}`,
      method: 'DELETE',
      actionMetadata: {
        productId
      }
    }
  };
};
