import Immutable from 'immutable';
import {
  SET_PAGE_TITLE,
  SET_RESPONSIVE_BREAKPOINT,
  SHOW_TOAST_MESSAGE,
  HIDE_TOAST_MESSAGE,
  SET_TOAST_POSITION,
  TOGGLE_DRAWER_MENU,
  SHOW_UPDATE_TOAST_MESSAGE,
  HIDE_UPDATE_TOAST_MESSAGE
} from './layout.actions';
import responsiveBreakpoints from './responsiveBreakpoints';

const initialState = Immutable.Map({
  pageTitle: 'Home',
  responsiveBreakpoint: responsiveBreakpoints.LARGE,
  isShowUpdateToast: false,
  isShowToast: false,
  toastMessage: '',
  isToastFailure: false,
  toastPosition: '0',
  isDrawerMenuOpen: false
});

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return state.set('pageTitle', action.payload);

    case SET_RESPONSIVE_BREAKPOINT:
      return state.set('responsiveBreakpoint', action.payload);

    case SHOW_UPDATE_TOAST_MESSAGE:
      return state.set('isShowUpdateToast', true);

    case HIDE_UPDATE_TOAST_MESSAGE:
      return state.set('isShowUpdateToast', false);

    case SHOW_TOAST_MESSAGE:
      return state
        .set('isShowToast', true)
        .set('toastMessage', action.message)
        .set('isToastFailure', action.isFailure);

    case HIDE_TOAST_MESSAGE:
      return state.set('isShowToast', false);

    case SET_TOAST_POSITION:
      return state.set('toastPosition', action.position);

    case TOGGLE_DRAWER_MENU:
      return state.set('isDrawerMenuOpen', !state.get('isDrawerMenuOpen'));

    default:
      return state;
  }
}
