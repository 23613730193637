import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { Grid, TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';

class ScheduleSlotForm extends Component {
  constructor(props) {
    super(props);

    let state = {
      date: '',
      startTime: '',
      endTime: '',
    };

    if (!isEmpty(props.scheduleSlot)) {
      state = {
        ...props.scheduleSlot,
      };
    }
    this.state = state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleSlot !== this.props.scheduleSlot) {
      const state = { ...nextProps.scheduleSlot };
      this.setState(state);
    }
  }

  isFormValid = () => {
    return (
      !isEmpty(this.state.date) && !isEmpty(this.state.startTime) && !isEmpty(this.state.endTime)
    );
  };

  handleSubmit = e => {
    e.preventDefault();

    const scheduleSlotData = {
      date: moment(this.state.date).format('YYYY-MM-DD'),
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    };

    // console.log('SCHEDULE SLOT DATA!', scheduleSlotData);
    this.props.submitAction(scheduleSlotData);
  };

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Date"
              type="date"
              margin="normal"
              value={this.state.date}
              onChange={e => this.updateField('date', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Start Time"
              type="time"
              margin="normal"
              value={this.state.startTime}
              onChange={e => this.updateField('startTime', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="End Time"
              type="time"
              margin="normal"
              value={this.state.endTime}
              onChange={e => this.updateField('endTime', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <div style={{ marginTop: '4rem' }}>
          <SFGOutlinedButton
            className="button expanded"
            color="primary"
            disabled={!this.isFormValid()}
            type="submit"
            onClick={this.handleSubmit}
          >
            Submit
          </SFGOutlinedButton>
        </div>
      </form>
    );
  }
}

ScheduleSlotForm.defaultProps = {
  scheduleSlot: null,
};

ScheduleSlotForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  scheduleSlot: PropTypes.object,
};

export default ScheduleSlotForm;
