import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import ReadOnlyTextField from '../layout/readOnlyTextField/readOnlyTextField.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { getDateTimeFromSlotData } from '../../utilities/convertTimes';

const DATE_FORMAT = 'MMM DD';
const TIME_FORMAT = 'h:mm a';

class ScheduleSlotSessionForm extends Component {
  constructor(props) {
    super(props);

    let state = {
      title: '',
      location: '',
      description: '',
      speakers: '',
      seats: 0,
      registrations: [],
      exclusive: false,
    };

    if (!isEmpty(props.session)) {
      state = {
        ...props.session,
      };

      state.speakers = props.session.speakers.join();
    }
    this.state = state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session !== this.props.session) {
      const state = { ...nextProps.session };
      this.setState(state);
    }
  }

  isFormValid = () => {
    return (
      !isEmpty(this.state.title) &&
      !isEmpty(this.state.location) &&
      !isEmpty(this.state.description) &&
      !isEmpty(this.state.speakers)
    );
  };

  handleSubmit = e => {
    e.preventDefault();

    const sessionData = {
      title: this.state.title,
      location: this.state.location,
      description: this.state.description,
      speakers: this.state.speakers,
      seats: this.state.seats,
      exclusive: this.state.exclusive,
    };

    // console.log('SESSION DATA!', sessionData);

    this.props.submitAction(sessionData);
  };

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
  };

  getSlotSummary = () => {
    let toReturn = '';
    try {
      const { slot } = this.props;
      const slotStartDateTime = getDateTimeFromSlotData(slot, slot.startTime);
      const slotEndDateTime = getDateTimeFromSlotData(slot, slot.endTime);
      toReturn = `${moment(slot.date).format(DATE_FORMAT)} ${moment(slotStartDateTime).format(
        TIME_FORMAT
      )} - ${moment(slotEndDateTime).format(TIME_FORMAT)}`;
    } catch (e) {
      // TODO: perhaps some helpful text here to show the user what is wrong
      // console.log(this.props.location.state, e);
    }

    return toReturn;
  };

  render() {
    return (
      // eslint-disable-next-line react/jsx-fragments
      <Fragment>
        <Typography variant="body1" style={{ marginBottom: '1rem' }}>
          {this.getSlotSummary()}
        </Typography>

        <form onSubmit={this.handleSubmit}>
          <TextField
            label="Title"
            type="text"
            margin="normal"
            value={this.state.title}
            onChange={e => this.updateField('title', e.target.value)}
            fullWidth
          />

          <TextField
            label="Location"
            type="text"
            margin="normal"
            value={this.state.location}
            onChange={e => this.updateField('location', e.target.value)}
            fullWidth
          />

          <TextField
            label="Description"
            type="text"
            margin="normal"
            value={this.state.description}
            onChange={e => this.updateField('description', e.target.value)}
            fullWidth
          />

          <TextField
            label="Speakers"
            type="text"
            margin="normal"
            value={this.state.speakers}
            onChange={e => this.updateField('speakers', e.target.value)}
            fullWidth
          />

          <TextField
            label="Number of seats (leave blank for unlimited)"
            type="number"
            margin="normal"
            value={!isEmpty(this.state.seats) ? this.state.seats : ''}
            onChange={e => this.updateField('seats', e.target.value)}
            fullWidth
          />

          {!isEmpty(this.props.session) && (
            <ReadOnlyTextField
              label="Seats Filled"
              value={isEmpty(this.state.registrations) ? '0' : this.state.registrations.length}
              disableUnderline
            />
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.exclusive}
                onChange={() => {
                  const currentExclusive = this.state.exclusive;
                  this.setState({ exclusive: !currentExclusive });
                }}
                value="exclusive"
                color="primary"
              />
            }
            label="General Session"
          />

          <div style={{ marginTop: '4rem' }}>
            <SFGOutlinedButton
              className="button expanded"
              color="primary"
              disabled={!this.isFormValid() || this.props.disableSubmit === true}
              style={{ marginRight: '1rem' }}
              type="submit"
              onClick={this.handleSubmit}
            >
              Submit
            </SFGOutlinedButton>
            <SFGOutlinedButton
              className="button expanded"
              color="primary"
              onClick={this.props.cancelAction}
            >
              Cancel
            </SFGOutlinedButton>
          </div>
        </form>
      </Fragment>
    );
  }
}

ScheduleSlotSessionForm.defaultProps = {
  session: null,
  disableSubmit: false,
};

ScheduleSlotSessionForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  session: PropTypes.object,
  slot: PropTypes.object.isRequired,
  disableSubmit: PropTypes.bool,
};

export default ScheduleSlotSessionForm;
