import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import axios from 'axios';
import firebase from 'firebase/app';
import moment from 'moment';
import 'firebase/auth';
import {
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import CustomSimpleMenu from '../layout/customSimpleMenu/customSimpleMenu.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import ProductCounts from './productCounts.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { BASE_URL } from '../../middleware/api';
import {
  DELETE_REGISTRATION_SUCCESS,
  RESEND_REGISTRATION_EMAIL_SUCCESS,
  deleteRegistration,
  getRegistrations,
  resendRegistrationEmail,
} from '../registrations/registrations.actions';
import { clearSelectedEvent, getEvent } from './events.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

class EventPurchasesContainer extends Component {
  state = {
    searchBox: '',
    registrations: [],
    rowsPerPage: 10,
    page: 0,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { match, selectedEvent } = this.props;
    this.props.setPageTitle('Event Purchases');

    const eventId = match.params.id;
    /* Always query the event details to get the product counts. */
    if (!isEmpty(selectedEvent) && selectedEvent.id.toString() !== match.params.id.toString()) {
      this.props.clearSelectedEvent();
    }

    this.props.getEvent(eventId);
    this.props.getRegistrations(eventId);

    this.search = this.search.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.registrations !== this.props.registrations) {
      const registrations = nextProps.registrations.filter(r => r.cart.confirmationCode !== null);
      this.setState({
        registrations,
        origRegistrations: registrations,
      });
    }
  }

  getManagerName = registration =>
    registration.agencyManager ? registration.agencyManager.name : 'N/A';

  resendEmail = async id => {
    if (window.confirm(`Are you sure you want to send this registration email again?`)) {
      const response = await this.props.resendRegistrationEmail(id);
      if (response.type === RESEND_REGISTRATION_EMAIL_SUCCESS) {
        this.props.handleToastMessage('Registration email resent.');
      } else {
        this.props.handleToastMessage('Failed to resend registration email', true);
      }
    }
  };

  removeRegistration = async (id, index) => {
    if (
      window.confirm(
        `Are you sure you want to remove registration: ${this.props.registrations[index].firstName}?`
      )
    ) {
      const response = await this.props.deleteRegistration(id);
      if (response.type === DELETE_REGISTRATION_SUCCESS) {
        this.props.handleToastMessage('Registration deleted.');
      } else {
        this.props.handleToastMessage('Failed to delete registration', true);
      }
    }
  };

  search = value => {
    this.setState({ searchBox: value });
    const toMatch = new RegExp(value, 'i');
    if (value.length >= 2) {
      // JM: This needs to be reworked or at least have comments to describe what is going on
      // eslint-disable-next-line react/no-access-state-in-setstate
      const registrations = this.state.registrations.filter(
        r => r.firstName.match(toMatch) || r.lastName.match(toMatch)
      );
      this.setState({ registrations });
    } else {
      this.setState(prevState => ({ registrations: prevState.origRegistrations }));
    }
  };

  handleExport = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      // Set the authorization header for this request instance;
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await instance({
        url: `${BASE_URL}admin/events/${this.props.selectedEvent.id}/ticketcsv`,
        method: 'GET',
        responseType: 'blob', // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PurchasesExport.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.props.handleToastMessage('Failed to export list!', true);
      console.error(e);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { products, selectedEvent, isLoading } = this.props;
    const { page, rowsPerPage } = this.state;

    if (isLoading) return <LoadingOverlay />;

    return (
      // eslint-disable-next-line react/jsx-fragments
      <Fragment>
        <div className="container">
          <Grid container spacing={24}>
            <Grid item>
              {!isEmpty(selectedEvent) && (
                <Typography color="primary" variant="title" style={{ marginBottom: '2rem' }}>
                  {`${selectedEvent.name} - Purchases`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <SFGOutlinedButton color="primary" onClick={this.handleExport}>
                Export List
              </SFGOutlinedButton>
              <ProductCounts event={selectedEvent} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                placeholder="Filter"
                disableUnderline={false}
                inputProps={{
                  'aria-label': 'Filter',
                }}
                variant="outlined"
                onChange={e => this.search(e.target.value)}
                style={{ float: 'right', minWidth: '20rem', padding: '0.15rem 0.25rem' }}
                value={this.state.searchBox}
              />
            </Grid>
          </Grid>

          {this.state.registrations.length > 0 ? (
            <>
              <Table style={{ marginTop: '1.5rem' }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="15%">First Name</TableCell>
                    <TableCell width="15%">Last Name</TableCell>
                    <TableCell width="15%">Agency Manager</TableCell>
                    <TableCell width="15%">Items Purchased</TableCell>
                    <TableCell width="15%" style={{ textAlign: 'center' }}>
                      Number of Payments
                    </TableCell>
                    <TableCell width="15%" style={{ textAlign: 'center' }}>
                      Purchase Date
                    </TableCell>
                    <TableCell width="15%" style={{ textAlign: 'center' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.registrations
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((registration, i) => {
                      return (
                        <TableRow key={registration.id}>
                          <TableCell>
                            <Typography variant="body1">{registration.firstName}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {registration.suffix
                                ? `${registration.lastName} ${registration.suffix}`
                                : registration.lastName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {this.getManagerName(registration)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {products &&
                              registration.cart.items.map(prod => {
                                const product = products.filter(p => p.id === prod.productId)[0];
                                if (product) {
                                  return (
                                    <Typography key={prod.id} variant="body1">
                                      {`${prod.quantity} ${product.name}`}
                                    </Typography>
                                  );
                                }
                                return <span key={prod.productId} />;
                              })}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Typography variant="body1">
                              {registration.cart.installments}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Typography variant="body1" style={{ fontSize: '0.75rem' }}>
                              {moment(registration.createdDate).format("MMM DD, 'YY hh:mm")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <CustomSimpleMenu
                              menuItems={[
                                {
                                  text: 'View Purchase Details',
                                  action: () =>
                                    this.props.history.push(
                                      `/events/${selectedEvent.id}/registrations/${registration.id}`,
                                      { registration }
                                    ),
                                },
                                {
                                  text: 'Resend Registration Email',
                                  action: () => this.resendEmail(registration.id, i),
                                },
                              ]}
                              icon={<MoreVert />}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.registrations.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Typography variant="subheading" style={{ marginTop: '1rem' }}>
              No purchases for this event.
            </Typography>
          )}
        </div>
      </Fragment>
    );
  }
}

EventPurchasesContainer.defaultProps = {
  selectedEvent: null,
};

EventPurchasesContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  products: PropTypes.array.isRequired,
  registrations: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getRegistrations: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  clearSelectedEvent: PropTypes.func.isRequired,
  deleteRegistration: PropTypes.func.isRequired,
  resendRegistrationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    products: state.products.get('products'),
    registrations: state.registrations.get('registrations'),
    selectedEvent: state.events.get('selectedEvent'),
    isLoading:
      state.registrations.get('isLoading') ||
      state.events.get('isLoading') ||
      state.products.get('isLoading'),
    managers: state.managers.get('managers'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getRegistrations,
    getEvent,
    clearSelectedEvent,
    deleteRegistration,
    resendRegistrationEmail,
  })(EventPurchasesContainer)
);
