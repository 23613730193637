import DocumentTitle from 'react-document-title';
import React, { PureComponent } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

import DocumentTitleFormatter from '../../utilities/documentTitleFormatter';
import LoginWarning from '../login/loginWarning.component';
import MapRouteTitle from '../../utilities/mapRouteTitle';
import ResizeHandler from './resizeHandler.component';
import RoutesManager from './routesManager.component';
import Toast from './toast.container';

class MainLayout extends PureComponent {
  render() {
    return (
      <DocumentTitle title={DocumentTitleFormatter(MapRouteTitle(this.props.location))}>
        <>
          <div className="main-content-wrapper">
            <RoutesManager />
          </div>
          <LoginWarning />
          <ResizeHandler />
          <Toast />
        </>
      </DocumentTitle>
    );
  }
}

MainLayout.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(MainLayout);
