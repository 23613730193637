import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Divider, Typography, withStyles } from '@material-ui/core';

import Session from './session.component';
import { TIME_FORMAT, getDateTimeFromSlotData } from '../../utilities/scheduleHelper';

const SessionSlot = ({ classes, eventId, isBreakouts, isLastSlot, slot, slotIndex }) => {
  const slotStartDateTime = getDateTimeFromSlotData(slot, slot.startTime);
  const slotEndDateTime = getDateTimeFromSlotData(slot, slot.endTime);

  return (
    <>
      <div
        className={classNames(
          classes.container,
          isLastSlot && classes.isLast,
          slotIndex % 2 !== 0 && isBreakouts && classes.isOdd
        )}
      >
        <Typography className={classes.slotTitle} variant="headline">
          {`${moment(slotStartDateTime).format(TIME_FORMAT)} - ${moment(slotEndDateTime).format(
            TIME_FORMAT
          )}`}
        </Typography>
        <div className={isBreakouts ? classes.breakoutContainer : ''}>
          {slot.sessions.length === 0 && <Typography>No sessions for this time slot</Typography>}
          {slot.sessions.map((session, sessionIndex) => {
            if (isBreakouts)
              return !session.exclusive ? (
                <Session
                  key={session.id}
                  eventId={eventId}
                  isBreakouts={isBreakouts}
                  isLast={slot.sessions.length === sessionIndex + 1}
                  session={session}
                  slot={slot}
                />
              ) : null;

            return (
              <Session
                key={session.id}
                eventId={eventId}
                isBreakouts={isBreakouts}
                isLast={slot.sessions.length === sessionIndex + 1}
                session={session}
                slot={slot}
              />
            );
          })}
        </div>
        {!isLastSlot && <Divider className={classes.divider} />}
      </div>
    </>
  );
};

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 2,
    paddingBottom: 0,
  },
  isLast: {
    paddingBottom: theme.spacing.unit * 2,
  },
  isOdd: {
    backgroundColor: `${theme.palette.primary.main}33`,
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
  },
  breakoutContainer: {
    display: 'flex',
    overflow: 'scroll',
  },
  slotTitle: {
    marginBottom: theme.spacing.unit,
  },
});

SessionSlot.defaultProps = {
  isBreakouts: false,
};

SessionSlot.propTypes = {
  classes: PropTypes.object.isRequired,

  eventId: PropTypes.string.isRequired,
  isBreakouts: PropTypes.bool,
  isLastSlot: PropTypes.bool.isRequired,
  slot: PropTypes.object.isRequired,
  slotIndex: PropTypes.number.isRequired,
};

export default withStyles(styles)(SessionSlot);
