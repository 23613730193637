import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const ProductCounts = ({ classes, event }) => {
  if (!event) return <span />;

  const { productCounts } = event;
  if (!productCounts || productCounts.length === 0) return <span />;

  return (
    <div className={classes.container}>
      {productCounts.map((product, idx) => (
        <div
          key={product.id}
          className={classNames(classes.item, {
            [classes.lastItem]: idx === productCounts.length - 1
          })}
        >
          <Typography variant="body2" className={classes.text}>
            {product.name}
            s:&nbsp;
          </Typography>
          <Typography className={classes.text}>{product.count}</Typography>
        </div>
      ))}
    </div>
  );
};

const styles = (/* theme */) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2rem 0.35rem 1rem 0.35rem'
  },
  item: { display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '0.80rem' },
  text: {
    fontSize: '14px'
  },
  lastItem: {
    paddingRight: 0
  }
});

ProductCounts.defaultProps = {
  event: null
};

ProductCounts.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object
};

export default withStyles(styles)(ProductCounts);
