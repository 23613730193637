import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Divider, Menu, MenuItem } from '@material-ui/core';

import SFGTextButton from '../../../common/buttons/sfgTextButton.component';

class CustomSimpleMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { menuItems, icon } = this.props;

    return (
      <div>
        <SFGTextButton
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {icon}
        </SFGTextButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {menuItems.map((item, i) => {
            if (item.isDivider) {
              // eslint-disable-next-line react/no-array-index-key
              return <Divider key={i} />;
            }

            return (
              <MenuItem
                onClick={e => {
                  // Close the menu and call the item action.
                  this.handleClose();
                  item.action(e);
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                disabled={item.disabled === true}
              >
                {item.text}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

CustomSimpleMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  icon: PropTypes.element.isRequired,
};

export default CustomSimpleMenu;
