import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import React, { Component, Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CheckinStatusHelper from '../../utilities/checkinStatusHelper';
import Colors from '../../styles/colors';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';

class CheckinDialog extends Component {
  state = {
    imgQRCode: null,
  };

  async componentDidMount() {
    // console.log('TICKET!', this.props.ticket);
    const imgQRCode = await QRCode.toDataURL(this.props.ticket.qrCode);
    this.setState({ imgQRCode });
  }

  render() {
    const { onClose, isOpen, ticket, classes } = this.props;
    const { imgQRCode } = this.state;
    return (
      <Dialog open={isOpen} onClose={onClose}>
        {ticket ? (
          <Fragment>
            <DialogTitle className={classes.textCentered}>{ticket.event.name}</DialogTitle>
            <DialogContent className={classes.textCentered}>
              {CheckinStatusHelper.isVip(ticket) && (
                <Typography variant="headline" className={classes.vipText}>
                  VIP
                </Typography>
              )}
              <img src={imgQRCode} alt="my ticket" width="240" height="240" />

              {ticket.badge && (
                <div className={`${classes.textCentered} ${classes.badgeStatusText}`}>
                  {`Badge Status Code: ${ticket.badge}`}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <SFGOutlinedButton onClick={onClose}>Close</SFGOutlinedButton>
            </DialogActions>
          </Fragment>
        ) : (
          <DialogContent />
        )}
      </Dialog>
    );
  }
}

const styles = () => ({
  textCentered: {
    textAlign: 'center',
  },
  vipText: {
    color: Colors.error,
    margin: 0,
  },
  badgeStatusText: {
    marginTop: '1rem',
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
});

CheckinDialog.propTypes = {
  classes: PropTypes.object.isRequired,

  isOpen: PropTypes.bool.isRequired,
  ticket: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(CheckinDialog);
