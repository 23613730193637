import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import LoadingOverlay from '../layout/loadingOverlay.component';
import Ticket from './ticket.container';
import { getMyTickets } from './purchases.actions';
import { setPageTitle } from '../layout/layout.actions';

class MyTickets extends Component {
  componentDidMount() {
    this.props.setPageTitle('My Tickets');

    /* Only run the network requests if they aren't already in progress. We check this because componentDidMount runs multiple times. */
    if (!this.props.isLoading) {
      this.props.getMyTickets();
    }
  }

  render() {
    const { isLoading, myTickets } = this.props;

    if (isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <div className="container">
        <Grid container justify="center">
          <Grid item container md={6} sm={12} spacing={8}>
            {myTickets.length === 0 && (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="headline">No tickets to upcoming events</Typography>
              </Grid>
            )}

            {myTickets.map(ticket => {
              return (
                <Grid item xs={12} key={ticket.id}>
                  <Ticket key={ticket.id} ticket={ticket} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = () => ({
  title: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
});

MyTickets.propTypes = {
  myTickets: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getMyTickets: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  myTickets: state.purchases.get('myTickets').sort((a, b) => {
    return moment(a.startDate) - moment(b.startDate);
  }),
  isLoading: state.purchases.get('isLoading'),
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    setPageTitle,
    getMyTickets,
  })(MyTickets)
);
