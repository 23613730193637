import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty, omit } from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import { withRouter } from 'react-router-dom';

import AdminInviteeForm from '../layout/inviteeForm/adminInviteeForm.container';
import NotificationBasicInfoForm from './notificationsForms/notificationBasicInfoForm';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import inviteeFormTypes from '../../types/inviteeFormTypes';

class NotificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationData: {
        title: '',
        description: '',
        deepLink: '',
        recipientEmails: [],
        descriptionHtml: '', // this should get overwritten with editorState
      },
    };
  }

  onFormUpdate = notificationData => {
    let updateNotification = { ...notificationData };

    // map emails to recipient emails
    if (updateNotification.emails) {
      updateNotification.recipientEmails = updateNotification.emails;
      updateNotification = omit(updateNotification, 'emails');
    }

    this.setState(prevState => ({
      notificationData: {
        ...prevState.notificationData,
        ...updateNotification,
      },
    }));
  };

  isFormValid = () => {
    const requiredFields = ['title', 'description'];
    const requiredSelectionFields = ['recipientEmails'];

    const invalidFields = requiredFields
      .filter(field => {
        return isEmpty(this.state.notificationData[field]);
      })
      .concat(
        requiredSelectionFields.filter(field => {
          // return no errors if a bulk selection has been made
          if (
            this.state.notificationData.isIncludeAllCheckedIn === true ||
            this.state.notificationData.isIncludeAllRegistrants === true
          ) {
            return false;
          }
          return this.state.notificationData[field].length <= 0;
        })
      );

    return !(invalidFields.length > 0);
  };

  handleSubmit = e => {
    e.preventDefault();

    const { notificationData } = this.state;

    notificationData.recipientEmails = notificationData.recipientEmails.map(obj => obj.email);
    notificationData.descriptionHtml = stateToHTML(
      notificationData.descriptionHtml.getCurrentContent()
    );
    this.props.submitAction(this.state.notificationData);
  };

  render() {
    const { agencies, isReadOnly, notification, events } = this.props;

    return (
      <ValidatorForm onSubmit={this.handleSubmit}>
        <NotificationBasicInfoForm
          agencies={agencies}
          notification={notification}
          onFormUpdate={this.onFormUpdate}
          isReadOnly={isReadOnly}
          events={events}
        />
        <AdminInviteeForm
          emails={notification ? notification.recipients : []}
          headingText={inviteeFormTypes.RECIPIENT}
          onFormUpdate={this.onFormUpdate}
          canDeleteEntries={!!isEmpty(notification)}
          isReadOnly={isReadOnly}
          events={events}
        />
        {!isReadOnly && (
          <div style={{ marginTop: '4rem' }}>
            <SFGOutlinedButton
              className="button expanded"
              color="primary"
              style={{ marginRight: '1rem' }}
              onClick={() => this.props.history.goBack()}
            >
              Cancel
            </SFGOutlinedButton>
            <SFGOutlinedButton
              className="button expanded"
              color="primary"
              disabled={!this.isFormValid()}
              type="submit"
              onClick={this.handleSubmit}
            >
              Submit
            </SFGOutlinedButton>
          </div>
        )}
      </ValidatorForm>
    );
  }
}

NotificationForm.defaultProps = {
  notification: null,
  isReadOnly: false,
};

NotificationForm.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  agencies: PropTypes.array.isRequired,
  isReadOnly: PropTypes.bool,
  notification: PropTypes.object,
  events: PropTypes.array.isRequired,

  submitAction: PropTypes.func.isRequired,
};

export default withRouter(NotificationForm);
