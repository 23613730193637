import moment from 'moment';

export const areTicketsChosen = tickets => {
  if (!tickets) {
    return false;
  }
  return Object.values(tickets).reduce((acc, curr) => {
    if (curr > 0) {
      return true;
    }
    return acc;
  }, false);
};

function calculateTotal() {
  /* Only calculate a total if the purchase plan and at least one ticket is selected. */
  if (this.state.purchasePlan < 1 || !areTicketsChosen(this.state.tickets)) {
    console.log(`Tickets: ${areTicketsChosen()}`);
    console.log(`Purchase Plan: ${this.state.purchasePlan}`);
    this.setState({
      currentTotal:
        'Please enter the number of tickets desired and a product plan to see your total.'
    });
    return;
  }
  /* This sums the costs of each ticket type */
  const sumPrices = (total, ticket) => {
    let runningTotal = total;
    const quantity = this.state.tickets[ticket];
    if (quantity > 0) {
      const purchasePlan = this.props.purchasePlans.find(p => {
        let extraChecks = true;
        /* If an event ID is defined, it must match. */
        if (p && p.eventId && this.state.selectedEvent) {
          extraChecks = p.eventId === this.state.selectedEvent.id;
        }
        /* If a purchase date is defined, it must fall within the valid dates for this purchase plan. */
        if ('purchaseDate' in this.state) {
          extraChecks =
            extraChecks &&
            moment(this.state.purchaseDate).isSameOrBefore(p.endDate) &&
            moment(this.state.purchaseDate).isSameOrAfter(p.startDate);
        }

        return p.installments === this.state.purchasePlan && p.productId === ticket && extraChecks;
      });
      if (purchasePlan) runningTotal = Number(runningTotal) + Number(purchasePlan.cost) * quantity;
    }
    return runningTotal;
  };

  const total = Object.keys(this.state.tickets).reduce(sumPrices, 0);
  const totalAmount = parseFloat(total / 100).toFixed(2);

  this.setState(prevState => ({
    currentTotal: `${prevState.purchasePlan} installment(s) of $${totalAmount}`,
    totalAmount
  }));
}
export default calculateTotal;
