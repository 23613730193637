import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Input, Toolbar, Typography, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Colors from '../../styles/colors';

const AgencyAdminTableToolbar = props => {
  const {
    numSelected,
    classes,
    handleShowDeleteDialog,
    searchText,
    onSearchChange,
    headingText,
    isReadOnly,
    canDeleteEntries
  } = props;
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography>{`${numSelected} Selected`}</Typography>
        ) : (
          <div>
            <Typography id="tableTitle" variant="body2">
              {headingText}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && canDeleteEntries && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={handleShowDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {numSelected <= 0 && !isReadOnly && (
          <Input
            className={classes.searchInput}
            placeholder="Search"
            disableUnderline={false}
            inputProps={{
              'aria-label': 'Search'
            }}
            variant="outlined"
            onChange={e => onSearchChange(e.target.value)}
            value={searchText}
          />
        )}
      </div>
    </Toolbar>
  );
};

const styles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight: {
    color: Colors.white,
    backgroundColor: Colors.grayLight
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: Colors.white
  },
  title: {
    flex: '0 0 auto'
  },
  searchInput: { float: 'right', minWidth: '20rem', padding: '0.15rem 0.25rem' }
});

AgencyAdminTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  handleShowDeleteDialog: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  canDeleteEntries: PropTypes.bool.isRequired
};

export default withStyles(styles)(AgencyAdminTableToolbar);
