import { CALL_API } from '../../middleware/api';

export const GET_CART_REQUEST = 'GET_CART_REQUEST';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILURE = 'GET_CART_FAILURE';

export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';

export const UPDATE_CART_PURCHASER_REQUEST = 'UPDATE_CART_PURCHASER_REQUEST';
export const UPDATE_CART_PURCHASER_SUCCESS = 'UPDATE_CART_PURCHASER_SUCCESS';
export const UPDATE_CART_PURCHASER_FAILURE = 'UPDATE_CART_PURCHASER_FAILURE';

export const CREATE_PRECHECKOUT_REQUEST = 'CREATE_PRECHECKOUT_REQUEST';
export const CREATE_PRECHECKOUT_SUCCESS = 'CREATE_PRECHECKOUT_SUCCESS';
export const CREATE_PRECHECKOUT_FAILURE = 'CREATE_PRECHECKOUT_FAILURE';

export const COMPLETE_CHECKOUT_REQUEST = 'COMPLETE_CHECKOUT_REQUEST';
export const COMPLETE_CHECKOUT_SUCCESS = 'COMPLETE_CHECKOUT_SUCCESS';
export const COMPLETE_CHECKOUT_FAILURE = 'COMPLETE_CHECKOUT_FAILURE';
/*
NOTE: Ultimately, the server will be handling the return of the particular cart for this user
based on their user ID that is passed along as part of the authenticated request. Until that is available
use the db-sfg.json files and request a particular cart by ID
*/
export const getCart = () => {
  return {
    [CALL_API]: {
      types: [GET_CART_REQUEST, GET_CART_SUCCESS, GET_CART_FAILURE],
      authenticated: true,
      endpoint: `v1/cart`,
      method: 'GET'
    }
  };
};

export const updateCartPurchaser = purchaserData => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_CART_PURCHASER_REQUEST,
        UPDATE_CART_PURCHASER_SUCCESS,
        UPDATE_CART_PURCHASER_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/cart/purchaser`,
      method: 'PUT',
      payload: purchaserData
    }
  };
};

export const updateCart = checkoutData => {
  return {
    [CALL_API]: {
      types: [UPDATE_CART_REQUEST, UPDATE_CART_SUCCESS, UPDATE_CART_FAILURE],
      authenticated: true,
      endpoint: `v1/cart`,
      method: 'PUT',
      payload: checkoutData
    }
  };
};

export const createPrecheckout = checkoutData => {
  return {
    [CALL_API]: {
      types: [CREATE_PRECHECKOUT_REQUEST, CREATE_PRECHECKOUT_SUCCESS, CREATE_PRECHECKOUT_FAILURE],
      authenticated: true,
      endpoint: `v1/cart/precheckout`,
      method: 'POST',
      payload: checkoutData
    }
  };
};

export const completeCheckout = (amount, assignTicket = false) => {
  return {
    [CALL_API]: {
      types: [COMPLETE_CHECKOUT_REQUEST, COMPLETE_CHECKOUT_SUCCESS, COMPLETE_CHECKOUT_FAILURE],
      authenticated: true,
      endpoint: `v1/cart/checkout`,
      method: 'POST',
      payload: { amount, assignTicket }
    }
  };
};
