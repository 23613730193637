import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setPageTitle, handleToastMessage } from '../layout/layout.actions';
import CouponForm from './couponForm.component';
import {
  getCoupon,
  setSelectedCoupon,
  updateCoupon,
  UPDATE_COUPON_SUCCESS
} from './coupons.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

class CouponEditContainer extends Component {
  constructor(props) {
    super(props);

    this.handleEdit = this.handleEdit.bind(this);
  }

  /*
  JM: Get Events and Get Agencies should be called as part of the auth loading procedure when that is called
  */

  componentDidMount() {
    const { selectedCoupon, match } = this.props;
    const couponId = match.params.id;
    this.props.setPageTitle('Edit Coupon');
    if (isEmpty(selectedCoupon) || couponId !== selectedCoupon.id) this.props.getCoupon(couponId);
  }

  async handleEdit(couponData) {
    const response = await this.props.updateCoupon(this.props.selectedCoupon.id, couponData);

    if (response.type === UPDATE_COUPON_SUCCESS) {
      this.props.handleToastMessage('Coupon updated.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to update coupon.', true);
    }
  }

  render() {
    const { selectedCoupon, events, agencies, isLoading } = this.props;
    if (isLoading) {
      return <LoadingOverlay />;
    }
    return (
      <div className="container">
        <CouponForm
          agencies={agencies}
          events={events}
          coupon={selectedCoupon}
          submitAction={this.handleEdit}
        />
      </div>
    );
  }
}

CouponEditContainer.defaultProps = {
  selectedCoupon: null
};

CouponEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  selectedCoupon: PropTypes.object,
  // isLoading: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  agencies: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getCoupon: PropTypes.func.isRequired,
  updateCoupon: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    selectedCoupon: state.coupons.get('selectedCoupon'),
    isLoading:
      state.coupons.get('isLoading') ||
      state.bigFive.get('isLoading') ||
      state.events.get('isLoading'),
    events: state.events.get('eventsAdmin'),
    agencies: state.bigFive.get('agencies')
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setPageTitle,
    getCoupon,
    setSelectedCoupon,
    updateCoupon,
    handleToastMessage
  })(CouponEditContainer)
);
