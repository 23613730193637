import Immutable from 'immutable';
import {
  GET_MANAGERS_REQUEST,
  GET_MANAGERS_SUCCESS,
  GET_MANAGERS_FAILURE
} from './managers.actions';

const initialState = Immutable.Map({
  managers: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MANAGERS_SUCCESS: {
      return state.set('managers', action.response.data).set('isLoading', false);
    }

    case GET_MANAGERS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_MANAGERS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    default:
      return state;
  }
}
