import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ImpersonateForm from './impersonateForm.container';
import logo from '../../images/logoHorizontal.png';

const LoginContainer = ({ classes }) => {
  return (
    <div className={`${classes.pageContent} registration-form-wrapper`}>
      <div className="container">
        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
            <div className={classes.spacerBottom}>
              <img src={logo} className={classes.logo} alt="Quility Logo" />
            </div>
            <div className={classes.spacerBottom}>
              <Typography variant="headline">Impersonate User</Typography>
            </div>
            <ImpersonateForm />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const styles = (/* theme */) => ({
  pageContent: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spacerBottom: {
    marginBottom: '2rem',
  },
  logo: {
    maxWidth: '100%',
  },
});

LoginContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const loginContainerWithRouter = withRouter(LoginContainer);

export default withStyles(styles)(loginContainerWithRouter);
