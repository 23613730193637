import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { find, isEmpty } from 'lodash';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { formatDate, utcToLocal } from '../../utilities/convertTimes';

class PurchasePlanForm extends Component {
  constructor(props) {
    super(props);

    let state = {
      eventId: '',
      productId: '',
      name: '',
      cost: '',
      installments: '',
      startDate: '',
      endDate: '',
      limit: '',
    };

    if (!isEmpty(props.purchasePlan)) {
      state = {
        ...props.purchasePlan,
      };
      if (state.cost) {
        /* Prices are stored on the server in cents, but we display dollars */
        state.cost = parseFloat(state.cost / 100).toFixed(2);
      }
      /* If the values aren't set, use an empty string instead of null so React
      knows the component is controlled. */
      if (state.limit === null) state.limit = '';
      if (state.name === null) state.name = '';

      /* Dates are stored in UTC time and need to be converted to local time */
      state.startDate = formatDate(utcToLocal(props.purchasePlan.startDate), false);
      state.endDate = formatDate(utcToLocal(props.purchasePlan.endDate), false);
    }

    this.state = state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.purchasePlan !== this.props.purchasePlan) {
      const state = {
        ...nextProps.purchasePlan,
      };

      /* Dates are stored in UTC time and need to be converted to local time */
      state.startDate = formatDate(nextProps.purchasePlan.startDate, false);
      state.endDate = formatDate(nextProps.purchasePlan.endDate, false);

      /* Prices are stored on the server in cents, but we display dollars */
      if (state.cost) {
        state.cost = parseFloat(state.cost / 100).toFixed(2);
      }

      /* If the limit isn't set, use an empty string instead of null so React
      knows the component is controlled. */
      if (state.limit === null) state.limit = '';
      if (state.name === null) state.name = '';

      this.setState(state);
    }
  }

  isFormValid = () => {
    const requiredFields = ['installments', 'name', 'cost', 'eventId', 'productId'];
    const invalidFields = requiredFields.filter(field => {
      if (isEmpty(this.state[field]) && !Number.isInteger(this.state[field])) {
        return true;
      }
      return false;
    });
    // console.log(invalidFields);
    return !invalidFields.length > 0;
  };

  handleSubmit = e => {
    e.preventDefault();

    const purchasePlanData = {
      eventId: this.state.eventId,
      productId: this.state.productId,
      name: this.state.name,
      cost: this.state.cost,
      installments: this.state.installments,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      limit: this.state.limit,
    };

    /* Don't send a limit if the value isn't set. */
    if (!purchasePlanData.limit) delete purchasePlanData.limit;

    purchasePlanData.startDate = formatDate(purchasePlanData.startDate);
    purchasePlanData.endDate = formatDate(purchasePlanData.endDate);
    console.log('PURCHASE PLAN DATA!', purchasePlanData);

    this.props.submitAction(purchasePlanData);
  };

  updateField = (field, value) => {
    const state = { ...this.state };

    state[field] = value;
    this.setState(state);
  };

  render() {
    const { agencies, events, products } = this.props;
    const product = find(products, { id: this.state.productId });
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm>
            <TextField
              fullWidth
              label="Name"
              margin="normal"
              value={this.state.name}
              onChange={e => this.updateField('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm>
            <FormControl style={{ minWidth: 140, marginTop: '1rem' }} fullWidth>
              <InputLabel htmlFor="eventName">Event</InputLabel>
              <Select
                fullWidth
                value={this.state.eventId}
                onChange={e => this.updateField('eventId', e.target.value)}
                inputProps={{
                  name: 'eventName',
                }}
              >
                {isEmpty(events) ? (
                  <MenuItem value="" />
                ) : (
                  events.map(evt => {
                    const agency = find(agencies, { id: evt.agencyId });
                    return (
                      <MenuItem key={evt.id} value={evt.id}>
                        {evt.name}
                        {agency && `(${agency.owner.name})`}
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm>
            <FormControl style={{ minWidth: 140, marginTop: '1rem' }} fullWidth>
              <InputLabel htmlFor="productName">Product</InputLabel>
              <Select
                fullWidth
                value={this.state.productId}
                onChange={e => this.updateField('productId', e.target.value)}
                inputProps={{
                  name: 'productName',
                }}
              >
                {isEmpty(products) ? (
                  <MenuItem value="" />
                ) : (
                  products.map(prod => (
                    <MenuItem key={prod.id} value={prod.id}>
                      {prod.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} sm>
            <TextField
              fullWidth
              label="Cost (per installment)"
              /* Add empty helper text so the line height doesn't change if the
                 per person limit changes visibility. */
              helperText=" "
              type="number"
              margin="normal"
              value={this.state.cost}
              onChange={e => this.updateField('cost', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm>
            <TextField
              fullWidth
              label="Installments"
              placeholder="Max of 5"
              type="number"
              margin="normal"
              value={this.state.installments}
              onChange={e =>
                this.updateField('installments', Math.min(5, parseInt(e.target.value, 10)))
              }
            />
          </Grid>
          <Grid item xs={12} sm>
            {/* Only show the quantity limit for products with a count of one. */}
            {product && product.count === 1 && (
              <TextField
                fullWidth
                label="Per person limit"
                helperText="Leave blank for no limit"
                type="number"
                margin="normal"
                value={this.state.limit}
                onChange={e => this.updateField('limit', e.target.value)}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="startDate"
              label="Start Date"
              helperText="All dates are relative to EST"
              type="date"
              value={this.state.startDate}
              onChange={e => this.updateField('startDate', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: '1rem' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="endDate"
              label="End Date"
              helperText="All dates are relative to EST"
              type="date"
              value={this.state.endDate}
              onChange={e => this.updateField('endDate', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: '1rem' }}
            />
          </Grid>
        </Grid>

        <div style={{ marginTop: '4rem' }}>
          <SFGOutlinedButton
            className="button expanded"
            color="primary"
            disabled={!this.isFormValid()}
            type="submit"
          >
            Submit
          </SFGOutlinedButton>
        </div>
      </form>
    );
  }
}

PurchasePlanForm.defaultProps = {
  purchasePlan: null,
};

PurchasePlanForm.propTypes = {
  purchasePlan: PropTypes.object,
  agencies: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  submitAction: PropTypes.func.isRequired,
};

export default PurchasePlanForm;
