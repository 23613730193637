import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import Colors from '../../styles/colors';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import {
  CHECK_IN_GATHERING_ATTENDEE_SUCCESS,
  checkinGatheringAttendee as reducerCheckinGatheringAttendee,
} from './gatherings.actions';
import { fieldValidators, getValidationErrorMessages } from '../../utilities/fieldValidation';
import {
  setPageTitle as reducerSetPageTitle,
  showToast as reducerShowToast,
} from '../layout/layout.actions';

const INITIAL_ATTENDEE = { firstName: '', lastName: '', email: '', phoneNumber: '' };

const CheckInContainer = ({
  classes,
  gathering,
  isCheckingIn,
  profile,
  checkinGatheringAttendee,
  setPageTitle,
  showToast,
}) => {
  const history = useHistory();
  const [attendee, setAttendee] = useState(INITIAL_ATTENDEE);

  const formRef = useRef();

  useEffect(
    () => {
      ValidatorForm.addValidationRule('validPhoneNumber', value => {
        let message = false;

        if (fieldValidators.validPhoneNumber) {
          message = fieldValidators.validPhoneNumber(value);
        }

        return message === false;
      });
    },
    [
      /* Intentionally left empty for on mount */
    ]
  );

  useEffect(() => {
    if (
      !isEmpty(gathering.organizers) &&
      !gathering.organizers.some(organizer => organizer.email === profile.email)
    ) {
      history.replace('/events');
    }
  }, [history, gathering, profile]);

  useEffect(() => {
    setPageTitle('Event Check In');
  }, [setPageTitle]);

  const formatDateAndTime = (startDate, endDate) => {
    const momentStart = moment(startDate);
    const momentEnd = moment(endDate);

    const startMonth = momentStart.format('MMMM');
    const startDay = momentStart.format('DD');
    const startTime = momentStart.format('h:mm');
    const endTime = momentEnd.format('h:mm');

    return `${startMonth} ${startDay} @ ${startTime} - ${endTime}`;
  };

  const handleAttendeeChange = ({ target: { name, value } }) => {
    setAttendee({
      ...attendee,
      [name]: value,
    });
  };

  const handleCheckInAttendee = async () => {
    const payload = attendee;

    if (isEmpty(payload.phoneNumber)) delete payload.phoneNumber;

    const response = await checkinGatheringAttendee(gathering.id, attendee);

    if (response.type === CHECK_IN_GATHERING_ATTENDEE_SUCCESS) {
      showToast(`${attendee.firstName} ${attendee.lastName} checked in successfully`, false);
    } else {
      showToast('Attendee check in failed. Please check your connection and try again.', true);
    }

    setAttendee(INITIAL_ATTENDEE);
  };

  return (
    <div>
      <div className="event-banner" style={{ backgroundImage: `url(${gathering.image})` }} />
      <div className="container">
        <div className={classes.header}>
          <Typography
            className={classes.headerText}
            variant="headline"
            // These have HTML encoded characters in them, so need to have them appear as html and not regular text
            dangerouslySetInnerHTML={{ __html: gathering.title }}
          />
          <Typography className={classes.headerText} variant="headline">
            &nbsp;-&nbsp;
          </Typography>
          <Typography className={classes.headerText} variant="headline">
            {`${formatDateAndTime(gathering.startTime, gathering.endTime)} ${gathering.timezone}`}
          </Typography>
        </div>
        <ValidatorForm ref={formRef} onSubmit={handleCheckInAttendee}>
          <Grid container className={classes.gridContainer} spacing={16}>
            <Grid item xs={12} sm={6}>
              <TextValidator
                autoFocus
                errorMessages={['First name is required']}
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleAttendeeChange}
                validators={['required']}
                value={attendee.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                errorMessages={['Last name is required']}
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleAttendeeChange}
                validators={['required']}
                value={attendee.lastName}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer} spacing={16}>
            <Grid item xs={12} sm={6}>
              <TextValidator
                errorMessages={['Email is required', 'Must enter a valid email address.']}
                fullWidth
                label="Email Address"
                name="email"
                validators={['required', 'isEmail']}
                value={attendee.email}
                onChange={handleAttendeeChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                errorMessages={getValidationErrorMessages(attendee.phoneNumber, 'Phone Number', [
                  'validPhoneNumber',
                ])}
                fullWidth
                label="Phone"
                name="phoneNumber"
                value={attendee.phoneNumber}
                validators={['validPhoneNumber']}
                onChange={handleAttendeeChange}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer} spacing={16}>
            <Grid item sm={4} />
            <Grid item sm={4}>
              <SFGOutlinedButton className="button" disabled={isCheckingIn} fullWidth type="submit">
                {isCheckingIn ? 'Checking In...' : 'Check In'}
              </SFGOutlinedButton>
            </Grid>
            <Grid item sm={4} />
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  );
};

const styles = theme => ({
  gridContainer: {
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
  header: {
    display: 'flex',
  },
  headerText: {
    color: Colors.grayMiddle,
  },
});

CheckInContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  gathering: PropTypes.object.isRequired,
  isCheckingIn: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,

  checkinGatheringAttendee: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const gathering = state.gatherings
    .get('gatherings')
    .filter(item => item.id.toString() === props.match.params.eventId);

  return {
    gathering: !isEmpty(gathering) ? gathering[0] : {},
    isCheckingIn: state.gatherings.get('isCheckingIn'),
    profile: state.profile.get('profile'),
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    checkinGatheringAttendee: reducerCheckinGatheringAttendee,
    setPageTitle: reducerSetPageTitle,
    showToast: reducerShowToast,
  })
)(CheckInContainer);
