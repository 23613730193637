import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import { isEmpty } from 'lodash';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MANAGE_TICKET_ASSIGNMENTS, hasPermission } from '../login/permissions';
import CustomSimpleMenu from '../layout/customSimpleMenu/customSimpleMenu.component';

const DATE_TIME_FORMAT = 'MMM DD, YYYY h:mm a';

class EventStatusAssignmentsList extends Component {
  unassignedTicketsFirst = (a, b) => {
    if (!a.assignee && b.assignee) {
      return -1;
    }
    if (a.assignee && !b.assignee) {
      return 1;
    }

    if (a.assignee && b.assignee) {
      const tmp = [a.assignee.lastName, b.assignee.lastName].sort();
      if (tmp[0] === a.assignee.lastName) {
        return -1;
      }
      return 1;
    }
    return 0;
  };

  removeAssignment = attendeeEventStatus => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to remove from the list?')) {
      console.log('remove VIP attendeeEventStatus designation');
      this.props.removeAction(attendeeEventStatus);
    }
  };

  handleUpdate = attendeeEventStatus => {
    this.props.history.push(`${this.props.updateUrl}/${attendeeEventStatus.assignee.id}`);
  };

  getActionMenuItems = attendeeEventStatus => {
    const toReturn = [
      {
        text: 'Remove from VIP list',
        action: () => this.removeAssignment(attendeeEventStatus)
      }
    ];

    if (attendeeEventStatus.assignee) {
      // insert to beginning of array
      toReturn.unshift({
        text: 'View Ticket Assignment',
        action: () => this.handleUpdate(attendeeEventStatus)
      });
    }

    return toReturn;
  };

  renderNameColumn = attendeeEventStatus => {
    let toReturn = null;
    if (!attendeeEventStatus.assignee) {
      toReturn = (
        <Typography variant="body1" style={{ color: 'red' }}>
          No Ticket Assignment
        </Typography>
      );
    } else {
      toReturn = !isEmpty(attendeeEventStatus.assignee.suffix) ? (
        <Typography variant="body1">{`${attendeeEventStatus.assignee.firstName} ${attendeeEventStatus.assignee.lastName} ${attendeeEventStatus.assignee.suffix}`}</Typography>
      ) : (
        <Typography variant="body1">{`${attendeeEventStatus.assignee.firstName} ${attendeeEventStatus.assignee.lastName}`}</Typography>
      );
    }

    return toReturn;
  };

  renderEmailColumn = attendeeEventStatus => {
    return <Typography variant="body1">{attendeeEventStatus.email}</Typography>;
  };

  renderDateColumn = attendeeEventStatus => {
    return (
      <Typography variant="body1">
        {moment(attendeeEventStatus.created).format(DATE_TIME_FORMAT)}
      </Typography>
    );
  };

  render() {
    const { attendeeEventStatuses, updateUrl, permissions } = this.props;
    if (attendeeEventStatuses.length === 0) {
      return (
        <div className="container">
          <Typography variant="body1">No VIPs for this event.</Typography>
        </div>
      );
    }

    return (
      <div>
        {attendeeEventStatuses && attendeeEventStatuses.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="30%">Assignee Name</TableCell>
                <TableCell width="30%">Email</TableCell>
                <TableCell width="20%" style={{ textAlign: 'center' }}>
                  VIP Assignment Date
                </TableCell>
                <TableCell width="20%" style={{ textAlign: 'center' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendeeEventStatuses
                .sort(this.unassignedTicketsFirst)
                .map(attendeeEventStatus => {
                  return (
                    <TableRow key={attendeeEventStatus.id}>
                      <TableCell>{this.renderNameColumn(attendeeEventStatus)}</TableCell>
                      <TableCell>{this.renderEmailColumn(attendeeEventStatus)}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {this.renderDateColumn(attendeeEventStatus)}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {hasPermission(MANAGE_TICKET_ASSIGNMENTS, permissions) && updateUrl && (
                          <CustomSimpleMenu
                            menuItems={this.getActionMenuItems(attendeeEventStatus)}
                            icon={<MoreVertIcon />}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

EventStatusAssignmentsList.defaultProps = {
  attendeeEventStatuses: null,
  permissions: [],
  updateUrl: null,
  removeAction: null
};

EventStatusAssignmentsList.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,

  attendeeEventStatuses: PropTypes.array,
  permissions: PropTypes.array,
  updateUrl: PropTypes.string,
  removeAction: PropTypes.func
};

const wrappedByRouter = withRouter(EventStatusAssignmentsList);
export default connect(null, null)(wrappedByRouter);
