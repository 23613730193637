import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Colors from '../../styles/colors';
import { hideToast } from './layout.actions';

const Toast = ({ isShowToast, isToastFailure, toastMessage, handleHideToast }) => {
  let toastWrapperClassName = 'toast-wrapper';
  if (isShowToast) {
    toastWrapperClassName += ' active';
  }

  return (
    <div
      className={toastWrapperClassName}
      style={
        {
          // bottom: this.props.toastPosition, --- in layout reducer, if needed
        }
      }
    >
      <div
        className="toast-message"
        style={{
          backgroundColor: isToastFailure ? Colors.error : Colors.highlight,
        }}
      >
        <div className="toast-message-text-wrapper">
          <div className="toast-message-text">{toastMessage}</div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className="close-button clickable" onClick={handleHideToast}>
            <i className="material-icons">close</i>
          </div>
        </div>
      </div>
    </div>
  );
};

Toast.defaultProps = {
  isToastFailure: false,
};

Toast.propTypes = {
  isShowToast: PropTypes.bool.isRequired,
  toastMessage: PropTypes.string.isRequired,
  isToastFailure: PropTypes.bool,

  handleHideToast: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isShowToast: state.layout.get('isShowToast'),
    toastMessage: state.layout.get('toastMessage'),
    isToastFailure: state.layout.get('isToastFailure'),
  };
};

export default connect(mapStateToProps, {
  handleHideToast: hideToast,
})(Toast);
