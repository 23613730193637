import moment from 'moment';
import { findIndex } from 'lodash';
import { getDateTimeFromSlotData } from '../../utilities/convertTimes';

export default scheduleSlots => {
  const toReturn = [];

  scheduleSlots = scheduleSlots.sort((a, b) => {
    const slotAStartDateTime = getDateTimeFromSlotData(a, a.startTime);
    const slotBStartDateTime = getDateTimeFromSlotData(b, b.startTime);
    const momentA = moment(slotAStartDateTime);
    const momentB = moment(slotBStartDateTime);
    if (momentA.isAfter(momentB)) {
      return 1;
    }
    if (momentB.isAfter(momentA)) {
      return -1;
    }

    return 0;
  });

  scheduleSlots.forEach(slot => {
    const dateIdx = findIndex(toReturn, { date: slot.date });
    if (dateIdx === -1) {
      toReturn.push({
        date: slot.date,
        slots: [slot]
      });
    } else {
      toReturn[dateIdx].slots.push(slot);
    }
  });

  // console.log('RETURNING SLOTS BY DAY!', toReturn);

  return toReturn;
};

export const isLoadingSelector = state => {
  return state.events.get('isLoading') || state.schedule.get('isLoading');
};
