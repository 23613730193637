import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableSortLabel,
  TableHead,
  TableCell,
  TableRow,
  Tooltip
} from '@material-ui/core';
import Colors from '../../styles/colors';

const rows = [
  { id: 'email', numeric: false, disablePadding: true, label: 'E-Mail' },
  { id: 'role', numeric: false, disablePadding: true, label: 'Role' }
];

class AgencyAdminTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, isReadOnly } = this.props;

    return (
      <TableHead>
        <TableRow>
          {!isReadOnly && (
            <TableCell padding="checkbox">
              <Checkbox
                style={{ color: Colors.grayDark }}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}

          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding="default"
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

AgencyAdminTableHead.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired
};

AgencyAdminTableHead.defaultProps = {
  order: 'desc',
  orderBy: ''
};

export default AgencyAdminTableHead;
