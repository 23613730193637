import { CALL_API } from '../../middleware/api';

export const GET_ATTENDEE_EVENT_STATUS_REQUEST = 'GET_ATTENDEE_EVENT_STATUS_REQUEST';
export const GET_ATTENDEE_EVENT_STATUS_SUCCESS = 'GET_ATTENDEE_EVENT_STATUS_SUCCESS';
export const GET_ATTENDEE_EVENT_STATUS_FAILURE = 'GET_ATTENDEE_EVENT_STATUS_FAILURE';

export const SET_ATTENDEE_EVENT_STATUS_REQUEST = 'SET_ATTENDEE_EVENT_STATUS_REQUEST';
export const SET_ATTENDEE_EVENT_STATUS_SUCCESS = 'SET_ATTENDEE_EVENT_STATUS_SUCCESS';
export const SET_ATTENDEE_EVENT_STATUS_FAILURE = 'SET_ATTENDEE_EVENT_STATUS_FAILURE';

export const DELETE_ATTENDEE_EVENT_STATUS_REQUEST = 'DELETE_ATTENDEE_EVENT_STATUS_REQUEST';
export const DELETE_ATTENDEE_EVENT_STATUS_SUCCESS = 'DELETE_ATTENDEE_EVENT_STATUS_SUCCESS';
export const DELETE_ATTENDEE_EVENT_STATUS_FAILURE = 'DELETE_ATTENDEE_EVENT_STATUS_FAILURE';

export const CLEAR_ATTENDEE_EVENT_STATUSES = [];

export const getAttendeeEventStatuses = eventId => {
  return {
    [CALL_API]: {
      types: [
        GET_ATTENDEE_EVENT_STATUS_REQUEST,
        GET_ATTENDEE_EVENT_STATUS_SUCCESS,
        GET_ATTENDEE_EVENT_STATUS_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/attendeeeventstatuses?eventId=${eventId}`,
      method: 'GET'
    }
  };
};

export const setAttendeeEventStatus = ({ eventId, email, status }) => {
  return {
    [CALL_API]: {
      types: [
        SET_ATTENDEE_EVENT_STATUS_REQUEST,
        SET_ATTENDEE_EVENT_STATUS_SUCCESS,
        SET_ATTENDEE_EVENT_STATUS_FAILURE
      ],
      authenticated: true,
      endpoint: 'admin/attendeeeventstatuses',
      method: 'POST',
      payload: {
        eventId,
        email,
        status
      }
    }
  };
};

export const removeAttendeeEventStatus = attendeeEventStatusId => {
  return {
    [CALL_API]: {
      types: [
        DELETE_ATTENDEE_EVENT_STATUS_REQUEST,
        DELETE_ATTENDEE_EVENT_STATUS_SUCCESS,
        DELETE_ATTENDEE_EVENT_STATUS_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/attendeeeventstatuses/${attendeeEventStatusId}`,
      method: 'DELETE',
      actionMetadata: {
        attendeeEventStatusId
      }
    }
  };
};

export const clearAttendeeEventStatuses = () => ({
  type: CLEAR_ATTENDEE_EVENT_STATUSES
});
