import CustomAutocomplete from './customAutocomplete.component';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import agentSearchTypes from '../../../types/agentSearchTypes';
import { LOOKUP_AGENTS_SUCCESS, lookupAgents } from './autocomplete.actions';
import { connect } from 'react-redux';

class AgencyOwnerAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      hasSelection: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    // if initial value is provided, assume a selection has been made (such as in edit case)
    this.setState({
      hasSelection: nextProps.initialValue !== '',
    });
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();

    if (inputValue.length > 2) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }

      this.searchTimer = setTimeout(async () => {
        const result = await this.props.lookupAgents(
          this.props.searchType,
          value,
          null,
          this.props.isOwnerSearch
        );
        if (result.type === LOOKUP_AGENTS_SUCCESS) {
          // console.log('SUGGESTIONS!', result.response.data);
          this.setState({
            suggestions: result.response.data.slice(0, 5),
          });
        }
      }, 300);
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = suggestion => {
    return suggestion.name;
  };

  // the object in this signature can also have "query"
  renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {`${suggestion.name} ${suggestion.email ? `(${suggestion.email})` : ''}`}
      </MenuItem>
    );
  };

  // the object in this signature can also have "suggestionValue", "suggestionIndex", and "sectionIndex"
  handleSelection = (event, { suggestion, method }) => {
    if (method === 'enter') {
      event.preventDefault();
    }

    // remove trailing space after autocomplete name
    suggestion.name = suggestion.name.trim();

    this.props.onSelect(suggestion);

    this.setState({
      hasSelection: true,
    });
  };

  handleInputClear = () => {
    this.setState({
      hasSelection: false,
    });

    if (this.props.onInputClear) this.props.onInputClear();
  };

  render() {
    return (
      <CustomAutocomplete
        getSuggestionValue={this.getSuggestionValue}
        handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestion={this.renderSuggestion}
        handleSelection={this.handleSelection}
        suggestions={this.state.suggestions}
        initialValue={this.props.initialValue}
        label={this.props.label}
        placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        clearInputOnSelect={this.props.clearInputOnSelect}
        clearSelectionOnEdit={
          this.state.hasSelection === true && this.props.clearSelectionOnEdit === true
        }
        onInputClear={this.handleInputClear}
        clearInvalidSelectionOnBlur={this.props.clearInvalidSelectionOnBlur}
      />
    );
  }
}

AgencyOwnerAutocomplete.defaultProps = {
  clearInvalidSelectionOnBlur: false,
  searchType: agentSearchTypes.ALL,
  isOwnerSearch: false,
  helperText: '',
  onInputClear: null,
  clearSelectionOnEdit: false,
};

AgencyOwnerAutocomplete.propTypes = {
  clearInvalidSelectionOnBlur: PropTypes.bool,
  clearInputOnSelect: PropTypes.bool.isRequired,
  initialValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchType: PropTypes.string,
  isOwnerSearch: PropTypes.bool,
  helperText: PropTypes.string,
  clearSelectionOnEdit: PropTypes.bool,

  onInputClear: PropTypes.func,
  lookupAgents: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default connect(null, {
  lookupAgents,
})(AgencyOwnerAutocomplete);
