import Immutable from 'immutable';
import {
  GET_DOWNLINE_AGENTS_REQUEST,
  GET_DOWNLINE_AGENTS_SUCCESS,
  GET_DOWNLINE_AGENTS_FAILURE
} from './agents.actions';

const initialState = Immutable.Map({
  agents: [],
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DOWNLINE_AGENTS_SUCCESS: {
      const { eventId } = action.actionMetadata;

      let dataSet = action.response.data;

      if (eventId) {
        dataSet = action.response.data.map(agent => ({
          ...agent,
          hasTicketInDownline: agentHasTicketInDownline(agent)
        }));
      }

      return state.set('agents', dataSet).set('isLoading', false);
    }

    case GET_DOWNLINE_AGENTS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_DOWNLINE_AGENTS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    default:
      return state;
  }
}

function agentHasTicketInDownline(agent) {
  if (agent.children) {
    agent.hasTicketInDownline =
      agent.children.some(child => {
        return agentHasTicketInDownline(child);
      }) || agent.hasTicket;
  } else {
    agent.hasTicketInDownline = agent.hasTicket;
  }
  return agent.hasTicketInDownline;
}
