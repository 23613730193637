import { CALL_API } from '../../middleware/api';

export const CREATE_BIG5_AGENCY_REQUEST = 'CREATE_BIG5_AGENCY_REQUEST';
export const CREATE_BIG5_AGENCY_SUCCESS = 'CREATE_BIG5_AGENCY_SUCCESS';
export const CREATE_BIG5_AGENCY_FAILURE = 'CREATE_BIG5_AGENCY_FAILURE';

export const UPDATE_BIG5_AGENCY_REQUEST = 'UPDATE_BIG5_AGENCY_REQUEST';
export const UPDATE_BIG5_AGENCY_SUCCESS = 'UPDATE_BIG5_AGENCY_SUCCESS';
export const UPDATE_BIG5_AGENCY_FAILURE = 'UPDATE_BIG5_AGENCY_FAILURE';

export const GET_BIG5_AGENCIES_REQUEST = 'GET_BIG5_AGENCIES_REQUEST';
export const GET_BIG5_AGENCIES_SUCCESS = 'GET_BIG5_AGENCIES_SUCCESS';
export const GET_BIG5_AGENCIES_FAILURE = 'GET_BIG5_AGENCIES_FAILURE';

export const GET_BIG5_AGENCY_ADMINS_REQUEST = 'GET_BIG5_AGENCY_ADMINS_REQUEST';
export const GET_BIG5_AGENCY_ADMINS_SUCCESS = 'GET_BIG5_AGENCY_ADMINS_SUCCESS';
export const GET_BIG5_AGENCY_ADMINS_FAILURE = 'GET_BIG5_AGENCY_ADMINS_FAILURE';

export const ADD_BIG5_AGENCY_ADMIN_REQUEST = 'ADD_BIG5_AGENCY_ADMIN_REQUEST';
export const ADD_BIG5_AGENCY_ADMIN_SUCCESS = 'ADD_BIG5_AGENCY_ADMIN_SUCCESS';
export const ADD_BIG5_AGENCY_ADMIN_FAILURE = 'ADD_BIG5_AGENCY_ADMIN_FAILURE';

export const REMOVE_BIG5_AGENCY_ADMIN_REQUEST = 'REMOVE_BIG5_AGENCY_ADMIN_REQUEST';
export const REMOVE_BIG5_AGENCY_ADMIN_SUCCESS = 'REMOVE_BIG5_AGENCY_ADMIN_SUCCESS';
export const REMOVE_BIG5_AGENCY_ADMIN_FAILURE = 'REMOVE_BIG5_AGENCY_ADMIN_FAILURE';

export const DELETE_BIG5_AGENCY_REQUEST = 'DELETE_BIG5_AGENCY_REQUEST';
export const DELETE_BIG5_AGENCY_SUCCESS = 'DELETE_BIG5_AGENCY_SUCCESS';
export const DELETE_BIG5_AGENCY_FAILURE = 'DELETE_BIG5_AGENCY_FAILURE';

export const CLEAR_SELECTED_AGENCY_ADMINS = 'CLEAR_SELECTED_AGENCY_ADMINS';

const addAgency = data => {
  return {
    [CALL_API]: {
      types: [CREATE_BIG5_AGENCY_REQUEST, CREATE_BIG5_AGENCY_SUCCESS, CREATE_BIG5_AGENCY_FAILURE],
      authenticated: true,
      endpoint: `admin/agencies`,
      method: 'POST',
      payload: data
    }
  };
};

export const updateAgency = agency => {
  return {
    [CALL_API]: {
      types: [UPDATE_BIG5_AGENCY_REQUEST, UPDATE_BIG5_AGENCY_SUCCESS, UPDATE_BIG5_AGENCY_FAILURE],
      authenticated: true,
      endpoint: `admin/agencies/${agency.id}`,
      method: 'PUT',
      payload: agency
    }
  };
};

export const createAgency = data => {
  return async dispatch => {
    const res = await dispatch(addAgency(data));
    if (res.type === CREATE_BIG5_AGENCY_SUCCESS) {
      await dispatch(getAgencies());
    }
    return res;
  };
};

export const getAgencies = () => {
  return {
    [CALL_API]: {
      types: [GET_BIG5_AGENCIES_REQUEST, GET_BIG5_AGENCIES_SUCCESS, GET_BIG5_AGENCIES_FAILURE],
      authenticated: true,
      endpoint: `admin/agencies`,
      method: 'GET'
    }
  };
};
export const deleteAgency = agencyId => {
  return {
    [CALL_API]: {
      types: [DELETE_BIG5_AGENCY_REQUEST, DELETE_BIG5_AGENCY_SUCCESS, DELETE_BIG5_AGENCY_FAILURE],
      authenticated: true,
      endpoint: `admin/agencies/${agencyId}`,
      method: 'DELETE'
    }
  };
};

export const getAgencyAdmins = agencyId => {
  return {
    [CALL_API]: {
      types: [
        GET_BIG5_AGENCY_ADMINS_REQUEST,
        GET_BIG5_AGENCY_ADMINS_SUCCESS,
        GET_BIG5_AGENCY_ADMINS_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/agencies/${agencyId}/admins`,
      method: 'GET'
    }
  };
};

export const addAgencyAdmin = (agencyId, agencyAdmin) => {
  return {
    [CALL_API]: {
      types: [
        ADD_BIG5_AGENCY_ADMIN_REQUEST,
        ADD_BIG5_AGENCY_ADMIN_SUCCESS,
        ADD_BIG5_AGENCY_ADMIN_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/agencies/${agencyId}/admins`,
      method: 'POST',
      payload: agencyAdmin
    }
  };
};

export const removeAgencyAdmin = (agencyId, adminId) => {
  return {
    [CALL_API]: {
      types: [
        REMOVE_BIG5_AGENCY_ADMIN_REQUEST,
        REMOVE_BIG5_AGENCY_ADMIN_SUCCESS,
        REMOVE_BIG5_AGENCY_ADMIN_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/agencies/${agencyId}/admins/${adminId}`,
      method: 'DELETE'
    }
  };
};

export const clearSelectedAgencyAdmins = () => {
  return {
    type: CLEAR_SELECTED_AGENCY_ADMINS
  };
};
