import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import LoadingOverlay from '../../layout/loadingOverlay.component';
import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';
import {
  CANCEL_TICKETS_SUCCESS,
  GET_TICKETS_SUCCESS,
  cancelTickets,
} from '../../tickets/tickets.actions';
import { handleToastMessage } from '../../layout/layout.actions';

class CancelTickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allTickets: [],
      allSelected: false,
      isReady: false,
      ticketsToRemove: {},
    };
  }

  async componentWillMount() {
    const result = await this.props.getTickets(this.props.eventId, this.props.purchase.id);
    if (result.type === GET_TICKETS_SUCCESS) {
      this.setState({ allTickets: result.response.data, isReady: true });
    } else {
      this.setState({ isReady: true });
    }

    if (isEmpty(this.state.ticketsToRemove)) {
      // JM: This needs to have more explanation of what is happening.
      const ticketsToRemove = this.props.tickets.reduce((all, t) => {
        all[t.id] = false;
        return all;
      }, {});
      this.setState({ ticketsToRemove });
    }
  }

  processTicketCancelation = async () => {
    const ticketIds = [];
    Object.keys(this.state.ticketsToRemove).forEach(ticketId => {
      if (this.state.ticketsToRemove[ticketId]) {
        ticketIds.push(ticketId);
      }
    });
    if (ticketIds.length) {
      if (!window.confirm('Are you sure you want to cancel these tickets?')) {
        this.props.closeDialog();
        return;
      }
      // NOTE: "purchase.id" is actually referring to a purchaser.id ... There
      // was some misnaming of endpoints and UI vars that resulted in this discrepancy
      const result = await this.props.cancelTickets(this.props.purchase.id, ticketIds);
      this.props.closeDialog();
      if (result.type === CANCEL_TICKETS_SUCCESS) {
        this.props.handleToastMessage(`Successfully canceled ${ticketIds.length} tickets.`);
      } else {
        this.props.handleToastMessage(`Failed to cancel tickets! ${result.messages[0]}`, true);
      }
    }
  };

  toggleSelectAll = () => {
    const { allSelected, ticketsToRemove } = this.state;

    this.state.allTickets.forEach(ticket => {
      if (ticket && !ticket.canceled) {
        ticketsToRemove[ticket.id] = !allSelected;
      }
    });

    this.setState({ ticketsToRemove, allSelected: !allSelected });
  };

  toggleCheckbox = e => {
    const { name, checked } = e.target;
    let { allSelected } = this.state;
    const { ticketsToRemove } = this.state;

    /* If a checkbox is changed, all selected shouldn't show */
    if (allSelected) {
      allSelected = false;
    }
    ticketsToRemove[name] = checked;
    this.setState({ ticketsToRemove, allSelected });
  };

  render() {
    const { closeDialog } = this.props;
    const { allTickets, allSelected, isReady, ticketsToRemove } = this.state;

    if (!isReady) return <LoadingOverlay />;

    if (allTickets.length === 0) {
      return (
        <div style={{ marginTop: '2rem', textAlign: 'center' }}>
          No tickets found for this purchase.
          <SFGOutlinedButton
            style={{ display: 'block', marginTop: '1rem', marginLeft: 'auto' }}
            onClick={closeDialog}
          >
            Close
          </SFGOutlinedButton>
        </div>
      );
    }
    const disabledStyle = { fontStyle: 'italic', opacity: 0.75 };
    return (
      // eslint-disable-next-line react/jsx-fragments
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="15%">
                <Checkbox checked={allSelected} onChange={this.toggleSelectAll} color="primary" />
              </TableCell>
              <TableCell width="15%">Ticket #</TableCell>
              <TableCell width="55%">Assigned to</TableCell>
              <TableCell width="15%">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allTickets.map((ticket, i) => {
              const isCanceled = !ticket || ticket.canceled;
              return (
                <TableRow key={ticket.id} style={isCanceled ? disabledStyle : {}}>
                  <TableCell>
                    <Checkbox
                      name={ticket.id}
                      checked={!!ticketsToRemove[ticket.id]}
                      onChange={this.toggleCheckbox}
                      disabled={isCanceled}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    {ticket && ticket.assignee !== null
                      ? `${ticket.assignee.firstName} ${ticket.assignee.lastName} 
                    ${!isEmpty(ticket.assignee.suffix) ? ` ${ticket.assignee.suffix}` : ''} (${
                          ticket.assignee.email
                        })`
                      : 'Unassigned'}
                  </TableCell>
                  <TableCell>{isCanceled ? 'Canceled' : 'Active'}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div style={{ flexDirection: 'column', marginTop: '1rem' }}>
          <SFGOutlinedButton style={{ marginRight: '1rem' }} onClick={closeDialog}>
            Close
          </SFGOutlinedButton>
          <SFGOutlinedButton onClick={this.processTicketCancelation}>
            Cancel Tickets
          </SFGOutlinedButton>
        </div>
      </Fragment>
    );
  }
}

CancelTickets.propTypes = {
  eventId: PropTypes.string.isRequired,
  purchase: PropTypes.object.isRequired,
  tickets: PropTypes.array.isRequired,

  getTickets: PropTypes.func.isRequired,
  cancelTickets: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

export default connect(null, {
  handleToastMessage,
  cancelTickets,
})(CancelTickets);
