import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Close, MoreVert as MoreVertIcon, Redo, ShoppingCart, Update } from '@material-ui/icons';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { isEmpty, orderBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import BillingInfo from '../../registrations/billingInfo.component';
import CancelTickets from './cancelTickets.component';
import CustomSimpleMenu from '../../layout/customSimpleMenu/customSimpleMenu.component';
import LoadingOverlay from '../../layout/loadingOverlay.component';
import MaxWidthDialog from '../../layout/dialogs/maxWidthDialog.component';
import PurchaseRefundDialog from './purchaseRefundDialog.component';
import SFGOutlinedButton from '../../../common/buttons/sfgOutlinedButton.component';
import transactionStatusTypes from '../../../types/transactionStatusTypes';
import {
  REFUND_PURCHASE_TRANSACTION_SUCCESS,
  RETRY_PURCHASE_TRANSACTION_SUCCESS,
  UPDATE_PURCHASE_CREDIT_CARD_SUCCESS,
  UPDATE_PURCHASE_TRANSACTIONS_SUCCESS,
  refundTransaction,
  retryTransaction,
  updatePurchaseCreditCard,
  updateTransactions,
} from '../events.actions';
import {
  RESEND_REGISTRATION_EMAIL_SUCCESS,
  resendRegistrationEmail,
} from '../../registrations/registrations.actions';
import { cancelTickets, getTicketsAdmin } from '../../tickets/tickets.actions';
import { handleToastMessage } from '../../layout/layout.actions';

class PurchaseTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionForRefund: null,
      showCancelTickets: false,
      showUpdateCard: false,
      loadingStripe: false,
      billingFields: {
        nameOnCard: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        creditCardNumber: '',
        ccExpirationMonth: '',
        ccExpirationYear: '',
        cvcNumber: '',
      },
    };

    [
      'closeCancelDialog',
      'toggleUpdateCard',
      'updateBilling',
      'getStripeToken',
      'cancelUpdateCard',
      'markTransactionComplete',
      'resendEmail',
    ].forEach(k => {
      this[k] = this[k].bind(this);
    });
  }

  markTransactionComplete = async (transactionId, purchaseId) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you want to mark this transaction as complete? This assumes that payment has been received in another form!`
      )
    ) {
      const result = await this.props.updateTransactions(
        purchaseId,
        [transactionId],
        transactionStatusTypes.COMPLETE
      );
      if (result.type === UPDATE_PURCHASE_TRANSACTIONS_SUCCESS) {
        this.props.handleToastMessage('Transaction successfully marked as complete');
        this.props.updatePurchases();
      } else {
        this.props.handleToastMessage(
          `Failed to mark transaction as complete. ${result.messages[0]}`,
          true
        );
      }
    }
  };

  toggleUpdateCard = () => {
    this.setState(prevState => ({
      showUpdateCard: !prevState.showUpdateCard,
    }));
  };

  reprocessTransaction = async transactionId => {
    const result = await this.props.retryTransaction(transactionId);
    if (result.type === RETRY_PURCHASE_TRANSACTION_SUCCESS) {
      this.props.handleToastMessage('Transaction reprocessed successfully');
      this.props.updatePurchases();
    } else {
      this.props.handleToastMessage(`Failed to reprocess transaction. ${result.messages[0]}`, true);
    }
  };

  transactionRefund = async ({ transactionId, amount }) => {
    const result = await this.props.refundTransaction({
      transactionId,
      amount: amount * 100,
    });
    if (result.type === REFUND_PURCHASE_TRANSACTION_SUCCESS) {
      this.props.handleToastMessage('Transaction refunded successfully');
      this.props.updatePurchases();
    } else {
      this.props.handleToastMessage(`Failed to refund transaction. ${result.messages[0]}`, true);
    }
    this.closeRefundDialog();
  };

  closeRefundDialog = () => this.setState({ transactionForRefund: null });

  cancelTransaction = async (transactionId, purchaseId) => {
    const result = await this.props.updateTransactions(purchaseId, [transactionId], 'CANCELED');
    if (result.type === UPDATE_PURCHASE_TRANSACTIONS_SUCCESS) {
      this.props.handleToastMessage('Transaction canceled successfully');
      this.props.updatePurchases();
    } else {
      this.props.handleToastMessage(`Failed to cancel transaction. ${result.messages[0]}`, true);
    }
  };

  resendEmail = async id => {
    if (window.confirm(`Are you sure you want to send this registration email again?`)) {
      const response = await this.props.resendRegistrationEmail(id);
      if (response.type === RESEND_REGISTRATION_EMAIL_SUCCESS) {
        this.props.handleToastMessage('Registration email resent.');
      } else {
        this.props.handleToastMessage('Failed to resend registration email', true);
      }
    }
  };

  getRowActionMenuItems = (transaction, purchase) => {
    return [
      {
        text: 'Cancel',
        action: (/* e */) => {
          console.log('Cancel', transaction);
          this.cancelTransaction(transaction.id, purchase.id);
        },
        disabled: transaction.status !== transactionStatusTypes.PENDING,
      },
      {
        text: 'Mark Transaction as Complete',
        action: (/* e */) => {
          this.markTransactionComplete(transaction.id, purchase.id);
        },
        disabled: transaction.status === transactionStatusTypes.COMPLETE,
      },
      {
        text: 'Refund',
        action: (/* e */) => {
          this.setState({ transactionForRefund: transaction });
        },
        disabled:
          (transaction.status !== transactionStatusTypes.COMPLETE &&
            transaction.status !== transactionStatusTypes.REFUNDED) ||
          transaction.refundAmount >= transaction.amount,
      },
      {
        text: 'Reprocess',
        action: (/* e */) => {
          this.reprocessTransaction(transaction.id);
        },
        disabled: transaction.status !== transactionStatusTypes.FAILED,
      },
    ];
  };

  cancelUpdateCard = () => {
    this.setState({
      billingFields: {
        nameOnCard: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        creditCardNumber: '',
        ccExpirationMonth: '',
        ccExpirationYear: '',
        cvcNumber: '',
      },
      showUpdateCard: false,
    });
  };

  updateBilling = (field, value) => {
    const billingFields = {
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state.billingFields,
    };
    billingFields[field] = value;
    this.setState({ billingFields });
  };

  getStripeToken = () => {
    this.setState({ loadingStripe: true });
    async function stripeResponseHandler(responseCode, responseObject) {
      if (responseCode === 200) {
        // success - update the credit card token on the backend
        const result = await this.props.updatePurchaseCreditCard(
          this.props.purchase.id,
          responseObject.id
        );
        this.setState({ loadingStripe: false });
        if (result.type === UPDATE_PURCHASE_CREDIT_CARD_SUCCESS) {
          this.props.handleToastMessage('Successfully updated credit card information!');
          this.setState({ showUpdateCard: false });
        } else {
          this.props.handleToastMessage('Failed while updating credit card information!', true);
        }
      } else {
        // failure - set error message in form, do not complete registration
        this.setState({ loadingStripe: false });
        this.props.handleToastMessage(
          `Failed to update credit card information! ${responseObject.error.message}`,
          true
        );
      }
    }
    const stripeInfo = {
      number: this.state.billingFields.creditCardNumber,
      cvc: this.state.billingFields.cvcNumber,
      exp_month: this.state.billingFields.ccExpirationMonth,
      exp_year: this.state.billingFields.ccExpirationYear,
      // optional fields but want them stored in stripe
      name: this.state.billingFields.nameOnCard,
      address_line1: this.state.billingFields.address,
      address_line2: this.state.billingFields.address2,
      address_city: this.state.billingFields.city,
      address_state: this.state.billingFields.state,
      address_zip: this.state.billingFields.zip,
      address_country: 'USA',
    };
    window.Stripe.card.createToken(stripeInfo, stripeResponseHandler.bind(this));
  };

  closeCancelDialog() {
    this.setState({ showCancelTickets: false });
  }

  render() {
    const { classes, purchase, readOnly, registration } = this.props;
    const { showUpdateCard, loadingStripe, showCancelTickets, transactionForRefund } = this.state;

    if (loadingStripe) {
      return <LoadingOverlay />;
    }

    /* If no purchase is defined, don't show anything. */
    if (isEmpty(purchase)) {
      return <div />;
    }
    const date = purchase.createdDate.substring(0, purchase.createdDate.indexOf('T'));
    return showUpdateCard ? (
      <ValidatorForm onSubmit={this.getStripeToken}>
        <BillingInfo
          state={this.state.billingFields}
          updateField={this.updateBilling}
          hideHeader
          hideCouponCode
        />
        <SFGOutlinedButton style={{ marginRight: '1rem' }} onClick={this.cancelUpdateCard}>
          Cancel
        </SFGOutlinedButton>
        <SFGOutlinedButton type="submit">Update Card</SFGOutlinedButton>
      </ValidatorForm>
    ) : (
      <Fragment key={purchase.id}>
        <div style={{ display: !readOnly ? 'inherit' : 'none' }}>
          <Typography
            id={date}
            variant="display1"
            style={{ marginTop: '1rem', marginBottom: '1rem', fontSize: '1.75rem', color: 'black' }}
          >
            Purchase on {date}
          </Typography>
          <SFGOutlinedButton
            disabled={
              purchase.transactions.filter(
                t =>
                  t.status !== transactionStatusTypes.COMPLETE &&
                  t.status !== transactionStatusTypes.REFUNDED
              ).length === 0
            }
            onClick={() => this.toggleUpdateCard(purchase)}
          >
            <Update className={classes.buttonIcon} />
            Update Credit Card
          </SFGOutlinedButton>
          <SFGOutlinedButton
            style={{ marginLeft: '1rem' }}
            onClick={() => this.setState({ showCancelTickets: true })}
          >
            <Close className={classes.buttonIcon} />
            Cancel Tickets
          </SFGOutlinedButton>
          <SFGOutlinedButton
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              this.props.history.push(
                `/events/${this.props.match.params.eventId}/registrations/${purchase.id}`,
                { eventId: this.props.match.params.eventId }
              );
            }}
          >
            <ShoppingCart className={classes.buttonIcon} />
            View Purchase Record
          </SFGOutlinedButton>
          <SFGOutlinedButton
            style={{ marginLeft: '1rem' }}
            onClick={() => this.resendEmail(purchase.id)}
          >
            <Redo className={classes.buttonIcon} />
            Resend Purchase Email
          </SFGOutlinedButton>
          <br />
        </div>
        <Typography variant="body2" style={{ marginTop: '1rem' }}>
          Transactions
          {!isEmpty(registration) &&
            registration.discount < 0 &&
            ` (Total Discount Applied: $${(registration.discount * -1).toFixed(2)})`}
        </Typography>

        <Table style={{ paddingTop: '1rem' }}>
          <TableHead>
            <TableRow>
              <TableCell width="30%">Date</TableCell>
              <TableCell width="10%">Transaction Number</TableCell>
              <TableCell width="20%">Amount</TableCell>
              <TableCell width="10%">Refunded Amount</TableCell>
              <TableCell width="20%">Status</TableCell>
              <TableCell
                width="10%"
                style={{ textAlign: 'center', display: !readOnly ? 'table-cell' : 'none' }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(purchase.transactions, ['number']).map((transaction, i) => {
              const actionMenuItems = this.getRowActionMenuItems(transaction, purchase, i);
              return (
                <TableRow key={transaction.number}>
                  <TableCell>
                    {transaction.scheduleDate.substring(0, transaction.scheduleDate.indexOf('T'))}
                  </TableCell>
                  <TableCell>
                    {transaction.number} /{purchase.transactions.length}
                  </TableCell>
                  <TableCell>${(transaction.amount / 100).toFixed(2)}</TableCell>
                  <TableCell>${(transaction.refundAmount / 100).toFixed(2)}</TableCell>
                  <TableCell>{transaction.status}</TableCell>
                  <TableCell style={{ display: !readOnly ? 'table-cell' : 'none' }}>
                    <CustomSimpleMenu menuItems={actionMenuItems} icon={<MoreVertIcon />} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <br />
        <br />
        <MaxWidthDialog
          open={showCancelTickets}
          headingText="Cancel Tickets"
          dialogContentElem={
            <CancelTickets
              eventId={this.props.match.params.eventId}
              purchase={this.props.purchase}
              tickets={this.props.purchase.tickets}
              getTickets={this.props.getTicketsAdmin}
              closeDialog={this.closeCancelDialog}
              cancelTickets={this.props.cancelTickets}
              handleToastMessage={this.props.handleToastMessage}
            />
          }
          onDialogClose={this.closeCancelDialog}
        />
        {transactionForRefund && (
          <PurchaseRefundDialog
            show={this.state.transactionForRefund !== null}
            transaction={this.state.transactionForRefund}
            maxRefund={(transactionForRefund.amount - transactionForRefund.refundAmount) / 100}
            closeDialog={this.closeRefundDialog}
            handleSubmit={this.transactionRefund}
          />
        )}
      </Fragment>
    );
  }
}

const styles = (/* theme */) => ({
  formControl: {
    display: 'block',
  },
  buttonIcon: {
    marginRight: '0.5rem',
  },
});

PurchaseTransactions.defaultProps = {
  registration: null,
};

PurchaseTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  registration: PropTypes.object,
  purchase: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,

  updatePurchases: PropTypes.func.isRequired,

  handleToastMessage: PropTypes.func.isRequired,
  updatePurchaseCreditCard: PropTypes.func.isRequired,
  retryTransaction: PropTypes.func.isRequired,
  refundTransaction: PropTypes.func.isRequired,
  updateTransactions: PropTypes.func.isRequired,
  getTicketsAdmin: PropTypes.func.isRequired,
  cancelTickets: PropTypes.func.isRequired,
  resendRegistrationEmail: PropTypes.func.isRequired,
};

export default connect(null, {
  handleToastMessage,
  updatePurchaseCreditCard,
  retryTransaction,
  refundTransaction,
  updateTransactions,
  getTicketsAdmin,
  cancelTickets,
  resendRegistrationEmail,
})(withStyles(styles)(withRouter(PurchaseTransactions)));
