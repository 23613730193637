import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import BillingInfo from '../registrations/billingInfo.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import PurchasesSelector from './purchases.selector';
import TransactionsTable from './transactionsTable.component';
import {
  GET_USER_PURCHASE_FAILURE,
  RETRY_TRANSACTION_SUCCESS,
  UPDATE_CARD_SUCCESS,
  clearSelectedPurchase,
  getPurchase,
  retryTransaction,
  updateCard,
} from './purchases.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

import MaxWidthDialog from '../layout/dialogs/maxWidthDialog.component';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import transactionStatusTypes from '../../types/transactionStatusTypes';

const DATE_FORMAT = 'MMM DD, YYYY h:mm a';

class PurchaseDetails extends Component {
  billingFormRef = React.createRef();

  state = {
    showUpdateCard: false,
    loadingStripe: false,
    billingFields: {
      nameOnCard: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      creditCardNumber: '',
      ccExpirationMonth: '',
      ccExpirationYear: '',
      cvcNumber: '',
    },
  };

  componentDidMount = async () => {
    this.props.setPageTitle('Purchase Details');
    this.props.clearSelectedPurchase();
    this.loadPurchaseDetails();
  };

  loadPurchaseDetails = async () => {
    const response = await this.props.getPurchase(this.props.match.params.purchaseId);
    if (response.type === GET_USER_PURCHASE_FAILURE) {
      this.props.history.push({
        pathname: `/myPurchases`,
      });
      this.props.handleToastMessage('Could not load purchase details.', true);
    }
  };

  reprocessTransaction = async transactionId => {
    const result = await this.props.retryTransaction({
      transactionId,
      purchaseId: this.props.selectedPurchase.id,
    });
    if (result.type === RETRY_TRANSACTION_SUCCESS) {
      this.props.handleToastMessage('Transaction reprocessed successfully');
      this.loadPurchaseDetails();
    } else {
      this.props.handleToastMessage(`Failed to reprocess transaction. ${result.messages[0]}`, true);
    }
  };

  openUpdateCard = () => {
    this.setState({
      showUpdateCard: true,
    });
  };

  closeModal = () => {
    // Clear billing form and close modal
    this.setState({
      billingFields: {
        nameOnCard: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        creditCardNumber: '',
        ccExpirationMonth: '',
        ccExpirationYear: '',
        cvcNumber: '',
      },
      showUpdateCard: false,
    });
  };

  updateBilling = (field, value) => {
    const billingFields = {
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state.billingFields,
    };
    billingFields[field] = value;
    this.setState({ billingFields });
  };

  submitBillingForm = () => {
    this.billingFormRef.current.submit();
  };

  promptRetry = () => {
    const { sortedTransactions } = this.props;
    const failedIndex = sortedTransactions.findIndex(transaction => {
      return transaction.status === transactionStatusTypes.FAILED;
    });
    if (failedIndex !== -1) {
      // eslint-disable-next-line no-alert
      window.confirm(
        'You can reprocess your FAILED transactions now by choosing the option button next to each transaction.'
      );
    }
  };

  getStripeToken = () => {
    this.setState({ loadingStripe: true });
    async function stripeResponseHandler(responseCode, responseObject) {
      if (responseCode === 200) {
        // success - update the credit card token on the backend
        const result = await this.props.updateCard({
          purchaseId: this.props.selectedPurchase.id,
          token: responseObject.id,
        });
        this.setState({ loadingStripe: false });
        if (result.type === UPDATE_CARD_SUCCESS) {
          this.props.handleToastMessage('Successfully updated credit card information!');
          this.closeModal();
          // Little timeout so window.confirm doesn't get in the way
          setTimeout(() => {
            this.promptRetry();
          }, 500);
        } else {
          this.props.handleToastMessage('Failed while updating credit card information!', true);
        }
      } else {
        // failure - set error message in form, do not complete registration
        this.setState({ loadingStripe: false });
        this.props.handleToastMessage(
          `Failed to update credit card information! ${responseObject.error.message}`,
          true
        );
      }
    }
    const stripeInfo = {
      number: this.state.billingFields.creditCardNumber,
      cvc: this.state.billingFields.cvcNumber,
      exp_month: this.state.billingFields.ccExpirationMonth,
      exp_year: this.state.billingFields.ccExpirationYear,
      // optional fields but want them stored in stripe
      name: this.state.billingFields.nameOnCard,
      address_line1: this.state.billingFields.address,
      address_line2: this.state.billingFields.address2,
      address_city: this.state.billingFields.city,
      address_state: this.state.billingFields.state,
      address_zip: this.state.billingFields.zip,
      address_country: 'USA',
    };
    window.Stripe.card.createToken(stripeInfo, stripeResponseHandler.bind(this));
  };

  render() {
    const {
      classes,
      isLoading,
      selectedPurchase,
      sortedTransactions,
      incompleteTransactions,
    } = this.props;
    const { showUpdateCard, loadingStripe } = this.state;

    return (
      <div className="container">
        {(isLoading || loadingStripe) && <LoadingOverlay />}

        <MaxWidthDialog
          open={showUpdateCard}
          headingText="Update Billing"
          actionText="Update Card"
          cancelText="Cancel"
          dialogContentElem={
            <ValidatorForm onSubmit={this.getStripeToken} ref={this.billingFormRef}>
              <BillingInfo
                state={this.state.billingFields}
                updateField={this.updateBilling}
                hideHeader
                hideCouponCode
                fullscreen
              />
            </ValidatorForm>
          }
          handleAction={this.submitBillingForm}
          onDialogClose={this.closeModal}
        />

        {!isEmpty(selectedPurchase) && (
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="title" className={classes.title}>
                Purchase Details
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subheading">
                <strong>Purchaser: </strong>
                {`${selectedPurchase.firstName} ${selectedPurchase.lastName}`}
              </Typography>

              <Typography variant="subheading">
                <strong>Purchase Date: </strong>
                {moment(selectedPurchase.createdDate).format(DATE_FORMAT)}
              </Typography>

              <Typography variant="subheading">
                <strong>Tickets Purchased: </strong>
                {selectedPurchase.tickets.length}
              </Typography>

              <Typography variant="subheading">
                <strong>Transactions: </strong>
                {selectedPurchase.transactions.length}
              </Typography>
            </Grid>

            {incompleteTransactions.length > 0 && (
              <Grid item xs={12}>
                <SFGOutlinedButton onClick={this.openUpdateCard}>
                  <Update className={classes.buttonIcon} />
                  Update Credit Card
                </SFGOutlinedButton>
              </Grid>
            )}

            <Grid item xs={12}>
              <TransactionsTable
                transactions={sortedTransactions}
                retryTransaction={this.reprocessTransaction}
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const styles = () => ({
  title: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
  buttonIcon: {
    marginRight: '0.5rem',
  },
});

PurchaseDetails.defaultProps = {
  selectedPurchase: null,
};

PurchaseDetails.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedPurchase: PropTypes.object,
  sortedTransactions: PropTypes.array.isRequired,
  incompleteTransactions: PropTypes.array.isRequired,
  handleToastMessage: PropTypes.func.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getPurchase: PropTypes.func.isRequired,
  clearSelectedPurchase: PropTypes.func.isRequired,
  retryTransaction: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.purchases.get('isLoading'),
  errors: state.purchases.get('errors'),
  selectedPurchase: state.purchases.get('selectedPurchase'),
  sortedTransactions: PurchasesSelector.selectSortedTransactions(state),
  incompleteTransactions: PurchasesSelector.selectIncompleteTransactions(state),
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    setPageTitle,
    getPurchase,
    clearSelectedPurchase,
    handleToastMessage,
    retryTransaction,
    updateCard,
  })(PurchaseDetails)
);
