import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import RoutesAdmin from './routesAdmin.component';
import RoutesPublic from './routesPublic.component';
import RoutesUser from './routesUser.component';
import { LOGIN_STATE } from '../login/login.actions';

class MainLayout extends PureComponent {
  // This doesn't appear to be needed anymore
  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.loginStatus === LOGIN_STATE.SYSTEM_ADMIN &&
  //     this.props.loginStatus !== LOGIN_STATE.SYSTEM_ADMIN &&
  //     !nextProps.location.pathname.match('^/attendee')
  //   ) {
  //     console.log('HEADING TO HOME ROUTE FROM PROPS');
  //     this.props.history.replace('/');
  //   }
  // }

  render() {
    const { loginStatus } = this.props;
    const loginStateRouteMap = {
      [LOGIN_STATE.SYSTEM_ADMIN]: RoutesAdmin,
      [LOGIN_STATE.ALTITUDE_USER]: RoutesAdmin,
      [LOGIN_STATE.STANDARD_USER]: RoutesUser,
      [LOGIN_STATE.NOT_LOGGED_IN]: RoutesPublic,
    };
    const RoutesComponent = loginStateRouteMap[loginStatus];

    return <RoutesComponent />;
  }
}

MainLayout.propTypes = {
  loginStatus: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    loginStatus: state.login.get('loginStatus'),
  };
};

export default connect(mapStateToProps, null)(MainLayout);
