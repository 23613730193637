import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import { Dialog, Grid, TextField, Typography } from '@material-ui/core';
import { find, findIndex } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import EventFormHelper from '../../utilities/eventFormHelper';
import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import SFGPrimaryButton from '../../common/buttons/sfgPrimaryButton.component';

const BADGE_COLORS_FIELDS = ['BL', 'KL', 'OC', 'AO', 'VIP'];

class EventBadgeLanyardColorsForm extends Component {
  constructor(props) {
    super(props);
    const isColorPickerOpen = {};
    BADGE_COLORS_FIELDS.forEach(field => {
      isColorPickerOpen[field] = false;
    });
    this.state = {
      event: EventFormHelper.initEventState(props.event),
      isColorPickerOpen,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.setState({ event: EventFormHelper.initEventState(this.props.event) });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const eventData = EventFormHelper.prepareEventData(this.state.event);

    this.props.submitAction(eventData);
  };

  getColor = label => {
    const { badgeColors } = this.state.event;
    let toReturn = '';
    if (badgeColors) {
      // find the one with the correct label
      const fieldObject = find(badgeColors, { status: label });
      if (fieldObject) {
        toReturn = fieldObject.color;
      }
    }

    return toReturn;
  };

  getHex = label => {
    const { badgeColors } = this.state.event;
    let toReturn = '';
    if (badgeColors) {
      // find the one with the correct label
      const fieldObject = find(badgeColors, { status: label });
      if (fieldObject) {
        toReturn = fieldObject.hex;
      }
    }

    return toReturn;
  };

  handleUpdateColor = (field, value) => {
    const { event } = this.state;
    const updatedEvent = { ...event };

    const updatedObject = { status: field, hex: this.getHex(field), color: value };

    if (event.badgeColors) {
      // see if the entry for this field already exists
      // if it does not create one
      // if it does replace the 'color' attribute with value
      const idx = findIndex(event.badgeColors, { status: field });
      if (idx === -1) {
        updatedEvent.badgeColors.push(updatedObject);
      } else if (value === '') {
        updatedEvent.badgeColors.splice(idx, 1);
      } else {
        updatedEvent.badgeColors[idx] = updatedObject;
      }
    } else {
      // if there is no badgeColors array, create it
      updatedEvent.badgeColors = [updatedObject];
    }

    this.setState({ event: updatedEvent });
  };

  handleUpdateHex = (field, value) => {
    const { event } = this.state;
    const updatedEvent = { ...event };

    const updatedObject = { status: field, hex: value, color: this.getColor(field) };

    if (event.badgeColors) {
      // see if the entry for this field already exists
      // if it does not create one
      // if it does replace the 'color' attribute with value
      const idx = findIndex(event.badgeColors, { status: field });
      if (idx === -1) {
        updatedEvent.badgeColors.push(updatedObject);
      } else if (value === '') {
        updatedEvent.badgeColors.splice(idx, 1);
      } else {
        updatedEvent.badgeColors[idx] = updatedObject;
      }
    } else {
      // if there is no badgeColors array, create it
      updatedEvent.badgeColors = [updatedObject];
    }

    this.setState({ event: updatedEvent });
  };

  handleToggleColorPicker = field => {
    const { isColorPickerOpen } = this.state;
    isColorPickerOpen[field] = !isColorPickerOpen[field];
    this.setState({ isColorPickerOpen });
  };

  render() {
    const { isColorPickerOpen } = this.state;
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={8}>
          {BADGE_COLORS_FIELDS.map(label => (
            <Grid item xs={12} key={label}>
              <TextField
                key={label}
                label={`Lanyard Color Name for ${label}`}
                placeholder={`Describe the Badge Lanyard Color for ${label}`}
                type="text"
                margin="normal"
                value={this.getColor(label)}
                onChange={e => this.handleUpdateColor(label, e.target.value)}
                fullWidth
              />
              {this.getHex(label) ? (
                <SFGPrimaryButton
                  className={classes.colorButton}
                  style={{ backgroundColor: this.getHex(label) }}
                  onClick={() => this.handleToggleColorPicker(label)}
                >
                  {this.getHex(label)}
                </SFGPrimaryButton>
              ) : (
                <SFGPrimaryButton onClick={() => this.handleToggleColorPicker(label)}>
                  No Color Set
                </SFGPrimaryButton>
              )}

              <Typography variant="caption">Click to open color picker</Typography>
              <Dialog
                open={isColorPickerOpen[label]}
                onClose={() => this.handleToggleColorPicker(label)}
              >
                <ChromePicker
                  color={this.getHex(label)}
                  onChangeComplete={color => this.handleUpdateHex(label, color.hex)}
                />
              </Dialog>
            </Grid>
          ))}
        </Grid>

        <div className={classes.actionsWrapper}>
          <SFGOutlinedButton color="primary" type="submit" onClick={this.handleSubmit}>
            Save
          </SFGOutlinedButton>
        </div>
      </form>
    );
  }
}

const styles = {
  actionsWrapper: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  colorButton: {
    color: 'white',
  },
};

EventBadgeLanyardColorsForm.defaultProps = {
  event: null,
};

EventBadgeLanyardColorsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object,

  submitAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(EventBadgeLanyardColorsForm);
