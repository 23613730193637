import { CALL_API } from '../../middleware/api';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const CREATE_NOTIFICATION_REQUEST = 'CREATE_NOTIFICATION_REQUEST';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const SET_SELECTED_NOTIFICATION = 'SET_SELECTED_NOTIFICATION';
export const CLEAR_SELECTED_NOTIFICATION = 'CLEAR_SELECTED_NOTIFICATION';

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCES';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const getNotifications = () => {
  return {
    [CALL_API]: {
      types: [GET_NOTIFICATIONS_REQUEST, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAILURE],
      authenticated: true,
      endpoint: `v1/messages`,
      method: 'GET'
    }
  };
};

export const createNotification = notificationData => {
  return {
    [CALL_API]: {
      types: [
        CREATE_NOTIFICATION_REQUEST,
        CREATE_NOTIFICATION_SUCCESS,
        CREATE_NOTIFICATION_FAILURE
      ],
      authenticated: true,
      endpoint: `v1/messages`,
      method: 'POST',
      payload: notificationData
    }
  };
};

export const getNotification = notificationId => {
  return {
    [CALL_API]: {
      types: [GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE],
      authenticated: true,
      endpoint: `v1/messages/${notificationId}`,
      method: 'GET',
      actionMetadata: {
        notificationId
      }
    }
  };
};

export const setSelectedNotification = notification => {
  return {
    type: SET_SELECTED_NOTIFICATION,
    response: notification
  };
};

export const clearSelectedNotification = () => {
  return {
    type: CLEAR_SELECTED_NOTIFICATION
  };
};

export const updateNotification = (notificationId, notificationData) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_NOTIFICATION_REQUEST,
        UPDATE_NOTIFICATION_SUCCESS,
        UPDATE_NOTIFICATION_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/notifications/${notificationId}`,
      method: 'PUT',
      payload: notificationData,
      actionMetadata: {
        notificationId
      }
    }
  };
};

export const deleteNotification = notificationId => {
  return {
    [CALL_API]: {
      types: [
        DELETE_NOTIFICATION_REQUEST,
        DELETE_NOTIFICATION_SUCCESS,
        DELETE_NOTIFICATION_FAILURE
      ],
      authenticated: true,
      endpoint: `admin/notifications/${notificationId}`,
      method: 'DELETE',
      actionMetadata: {
        notificationId
      }
    }
  };
};
