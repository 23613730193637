import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';

const ConfirmationInformationPage = ({ state, cart }) => {
  if (!cart) {
    cart = {};
  }

  if (!isEmpty(state)) {
    return (
      <div>
        <div className="page-padding-bottom">
          {state.purchaser && (
            <Fragment>
              <Typography variant="subheading">Registrant Information</Typography>
              <p>
                {state.purchaser.firstName}
                {state.purchaser.middleName && state.purchaser.middleName !== ''
                  ? ` ${state.purchaser.middleName}.`
                  : ''}
                &nbsp;
                {state.purchaser.lastName}
                {!isEmpty(state.purchaser.suffix) ? ` ${state.purchaser.suffix}` : ''}
                <br />
                {state.purchaser.address}
                <br />
                {!isEmpty(state.purchaser.address2) && (
                  <>
                    {state.purchaser.address2}
                    <br />
                  </>
                )}
                {`${state.purchaser.city},${state.purchaser.state} ${state.purchaser.zip}`}
                <br />
                {state.purchaser.alsoKnownAs !== '' && (
                  <span>
                    {`Name on Badge: ${state.purchaser.alsoKnownAs} `}
                    <br />
                  </span>
                )}
                {`Phone: ${state.purchaser.phone}`}
                <br />
                {`Email: ${state.purchaser.email}`}
              </p>
            </Fragment>
          )}
          {cart.source && !isEmpty(cart.source) && (
            <Fragment>
              <Typography variant="subheading">Billing Information</Typography>
              {`${state.billing.nameOnCard}`}
              <br />
              {state.billing.address}
              {state.billing.address2 !== '' && (
                <span>
                  <br />
                  {state.billing.address2}
                </span>
              )}
              <br />
              {`${state.billing.city} ${state.billing.state} ${state.billing.zip}`}
              <br />
              <br />
              {`${cart.source.brand} ********${cart.source.last4}`}
              <br />
              {`Expiration Date: ${cart.source.exp_month}/${cart.source.exp_year}`}
            </Fragment>
          )}
        </div>
        <div className="page-padding-bottom">
          <Typography variant="subheading">Tickets</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="30%" />
                <TableCell style={{ textAlign: 'center' }} width="10%">
                  Quantity
                </TableCell>
                <TableCell width="30%">Subtotal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.items &&
                cart.items.map(i => {
                  let numInstallments = 1;

                  if (i.purchasePlans) {
                    i.purchasePlans.forEach(p => {
                      if (p.installments > 1) numInstallments = p.installments;
                    });
                  }

                  return (
                    <TableRow key={i.name}>
                      <TableCell>{i.name}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>{i.quantity}</TableCell>
                      {numInstallments > 1 ? (
                        <TableCell>
                          {`$${parseFloat(i.subtotal / 100).toFixed(
                            2
                          )} (1st of ${numInstallments} installments)`}
                        </TableCell>
                      ) : (
                        <TableCell>{`$${parseFloat(i.subtotal / 100).toFixed(2)}`}</TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {cart.tax > 0 && (
                <TableRow>
                  <TableCell>
                    <em>Taxes</em>
                  </TableCell>
                  <TableCell />
                  <TableCell>
${parseFloat(cart.tax / 100).toFixed(2)}</TableCell>
                </TableRow>
              )}
              {cart.discount !== 0 && (
                <TableRow>
                  <TableCell>
                    <em>Discounts</em>
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    -$
                    {parseFloat((-1 * cart.discount) / 100).toFixed(2)}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <strong>Total Billed Today</strong>
                </TableCell>
                <TableCell />
                <TableCell>
${parseFloat(cart.total / 100).toFixed(2)}</TableCell>
              </TableRow>

              {/* NOTE: installments is not typically a property of the cart so this whole section is likely not shown */}
              {cart.installments && cart.installments > 1 && (
                <TableRow>
                  <TableCell>
                    <strong>Installments Due:</strong>
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    {`${cart.installments}`}
{' '}
of $
{parseFloat(cart.purchasePlans.subtotal / 100).toFixed(2)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
  return <div />;
};

ConfirmationInformationPage.defaultProps = {
  cart: null,
};

ConfirmationInformationPage.propTypes = {
  state: PropTypes.object.isRequired,
  cart: PropTypes.object,
};

export default ConfirmationInformationPage;
