import PropTypes from 'prop-types';
import React, { Component } from 'react';
import firebase from 'firebase/app';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import SFGOutlinedButton from '../../common/buttons/sfgOutlinedButton.component';
import { IMPERSONATE_USER_FAILURE, impersonate } from './login.actions';
import { handleToastMessage } from '../layout/layout.actions';

class Login extends Component {
  state = {
    // email: 'test-edward@pritchettagency.com',
    email: '',
  };

  onSubmit = async () => {
    const { email } = this.state;

    /*
    call impersonate endpoint with email
    if not successful, show toast message, and return
    otherwise - 
      signin with custom token. index.js has the authstatechanged
      handling that should take over when the authentication aspect takes place
    */

    const response = await this.props.impersonate(email);
    if (response.type === IMPERSONATE_USER_FAILURE) {
      this.props.handleToastMessage(`${response.messages[0]}`, true);
      return;
    }

    this.props.handleToastMessage(`Impersonating ${email}`);

    firebase.auth().signInWithCustomToken(response.response.token);
  };

  render() {
    const { classes, isLoading } = this.props;
    const { email } = this.state;

    return (
      <ValidatorForm onSubmit={this.onSubmit}>
        <TextValidator
          label="Email Address"
          name="email"
          value={email}
          onChange={e => this.setState({ email: e.target.value })}
          type="text"
          margin="normal"
          validators={['required', 'isEmail']}
          errorMessages={['Email address is required', 'Must enter a valid email address.']}
          fullWidth
          className={classes.spacerBottom}
          autoFocus
        />
        <div className={classes.spacerBottom}>
          <SFGOutlinedButton
            id="impersonateBtn"
            className="button"
            color="primary"
            disabled={email.length === 0 || isLoading}
            fullWidth
            type="submit"
          >
            Impersonate
          </SFGOutlinedButton>
        </div>
      </ValidatorForm>
    );
  }
}

const styles = theme => ({
  spacerBottom: {
    marginBottom: '1rem',
  },
  helpWrapper: {
    marginTop: '4rem',
  },
  mailTo: {
    display: 'block',
    color: theme.palette.primary.main,
  },
});

Login.propTypes = {
  classes: PropTypes.object.isRequired,

  isLoading: PropTypes.bool.isRequired,

  handleToastMessage: PropTypes.func.isRequired,
  impersonate: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: state.login.get('isLoading'),
  };
};

const connectedLogin = connect(mapStateToProps, {
  handleToastMessage,
  impersonate,
})(Login);

export default withStyles(styles)(connectedLogin);
