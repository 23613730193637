import Immutable from 'immutable';
import { findIndex, isEmpty } from 'lodash';
import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_ADMIN_REQUEST,
  GET_EVENTS_ADMIN_SUCCESS,
  GET_EVENTS_ADMIN_FAILURE,
  GET_EVENTS_ADMIN_ARCHIVED_REQUEST,
  GET_EVENTS_ADMIN_ARCHIVED_FAILURE,
  GET_EVENTS_ADMIN_ARCHIVED_SUCCESS,
  CREATE_EVENT_SUCCESS,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  SET_SELECTED_EVENT,
  CLEAR_SELECTED_EVENT,
  UPDATE_EVENT_SUCCESS,
  DELETE_EVENT_SUCCESS,
  ARCHIVE_EVENT_SUCCESS,
  UNARCHIVE_EVENT_SUCCESS
} from './events.actions';

const initialState = Immutable.Map({
  events: [],
  eventsAdmin: [],
  eventsAdminArchived: [],
  selectedEvent: null,
  isLoading: false,
  errors: []
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return state.set('events', action.response.data).set('isLoading', false);

    case GET_EVENTS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_EVENTS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_EVENTS_ADMIN_SUCCESS:
      return state.set('eventsAdmin', action.response.data).set('isLoading', false);

    case GET_EVENTS_ADMIN_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_EVENTS_ADMIN_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case GET_EVENTS_ADMIN_ARCHIVED_SUCCESS:
      return state.set('eventsAdminArchived', action.response.data).set('isLoading', false);

    case GET_EVENTS_ADMIN_ARCHIVED_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_EVENTS_ADMIN_ARCHIVED_REQUEST:
      return state.set('isLoading', true);

    case CREATE_EVENT_SUCCESS: {
      const events = [...state.get('eventsAdmin')];
      const newEvent = { ...action.response };
      events.push(newEvent);

      return state.set('events', events).set('isLoading', false);
    }

    case GET_EVENT_SUCCESS:
      return state.set('selectedEvent', action.response).set('isLoading', false);

    case GET_EVENT_FAILURE:
      return state.set('isLoading', false);

    case GET_EVENT_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_EVENT:
      return state.set('selectedEvent', action.response);

    case CLEAR_SELECTED_EVENT:
      return state.set('selectedEvent', null);

    case UPDATE_EVENT_SUCCESS: {
      let selectedEvent = { ...state.get('selectedEvent') };
      const events = [...state.get('eventsAdmin')];
      const { eventId } = action.actionMetadata;

      if (!isEmpty(selectedEvent) && selectedEvent.id === eventId) {
        selectedEvent = Object.assign(selectedEvent, action.payload);
      }

      const idx = findIndex(events, { id: eventId });
      if (idx > -1) {
        events[idx] = Object.assign(events[idx], action.payload);
      }

      return state
        .set('selectedEvent', selectedEvent)
        .set('eventsAdmin', events)
        .set('isLoading', false);
    }

    case DELETE_EVENT_SUCCESS: {
      const events = [...state.get('eventsAdmin')];

      const idx = findIndex(events, { id: action.actionMetadata.eventId });
      if (idx > -1) {
        events.splice(idx, 1);
      }

      return state.set('eventsAdmin', events).set('isLoading', false);
    }

    case ARCHIVE_EVENT_SUCCESS: {
      const events = [...state.get('eventsAdmin')];

      const idx = findIndex(events, { id: action.actionMetadata.eventId });
      if (idx > -1) {
        events.splice(idx, 1);
      }

      return state.set('eventsAdmin', events).set('isLoading', false);
    }

    case UNARCHIVE_EVENT_SUCCESS: {
      const events = [...state.get('eventsAdminArchived')];

      const idx = findIndex(events, { id: action.actionMetadata.eventId });
      if (idx > -1) {
        events.splice(idx, 1);
      }

      return state.set('eventsAdminArchived', events).set('isLoading', false);
    }

    default:
      return state;
  }
}
