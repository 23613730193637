/* eslint-disable react/jsx-fragments */
import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import SFGTextButton from '../../../common/buttons/sfgTextButton.component';

const MaxWidthDialog = ({
  actionText,
  cancelText,
  classes,
  dialogContentElem,
  headingText,
  isActionDisabled,
  maxWidth,
  open,
  handleAction,
  onDialogClose,
}) => (
  <Dialog
    fullWidth
    open={open}
    maxWidth={maxWidth}
    onClose={onDialogClose}
    aria-labelledby="max-width-dialog-title"
  >
    <DialogContent>
      <DialogTitle className={classes.title} align="center">
        {headingText}
      </DialogTitle>
      {dialogContentElem}
    </DialogContent>
    <DialogActions
      style={{ display: !isEmpty(actionText) || cancelText.length ? 'inherit' : 'none' }}
    >
      {!isEmpty(actionText) && (
        <SFGTextButton disabled={isActionDisabled} onClick={handleAction} color="primary">
          {actionText}
        </SFGTextButton>
      )}
      {cancelText.length > 0 && (
        <SFGTextButton onClick={onDialogClose} color="primary">
          {cancelText}
        </SFGTextButton>
      )}
    </DialogActions>
  </Dialog>
);

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
  title: {
    color: theme.palette.primary.main,
  },
});

MaxWidthDialog.defaultProps = {
  actionText: '',
  cancelText: 'Close',
  headingText: 'Dialog',
  isActionDisabled: false,
  maxWidth: 'md',
  handleAction: null,
  onDialogClose: null,
};

MaxWidthDialog.propTypes = {
  actionText: PropTypes.string,
  cancelText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dialogContentElem: PropTypes.element.isRequired,
  headingText: PropTypes.string,
  isActionDisabled: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleAction: PropTypes.func,
  onDialogClose: PropTypes.func,
};

export default withStyles(styles)(MaxWidthDialog);
